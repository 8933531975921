export default (theme) => ({

  item: {
    display: 'flex',
    margin: '0 15px 30px',
    width: 'calc(50% - 30px)',
    // transition: 'filter .5s ease',
    // filter: 'grayscale(100%)',
    position: 'relative',
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 0,
      top: 0,
      left: 4,
      width: 'calc(100% - 8px)',
      height: '100%',
      borderRadius: 6,
      boxShadow: '0 8px 14px 0 rgba(60, 60, 60, 0.08)',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 6,
      backgroundColor: '#ffffff',
      boxShadow: '0 6px 20px 0 rgba(20, 100, 220, 0.09)',
    },
    // '&:hover': {
    //   filter: 'grayscale(0%)',
    // },
    // '&:hover $img': {
    //   opacity: 1,
    // },
  },
  img: {
    width: 'calc(50% - 30px)',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    // transition: 'opacity .5s ease',
    // opacity: .6,
    position: 'relative',
    zIndex: 2,
    borderRadius: '6px 0 0 6px',
  },

  itemContent: {
    width: 'calc(50% + 30px)',
    position: 'relative',
    zIndex: 2,
    padding: '20px 30px',
  },

  itemDealer: {
    fontSize: 14,
    lineHeight: '28px',
    color: theme.colors.gray.main,
  },

  itemName: {
    paddingBottom: '15px',
    fontSize: 24,
    lineHeight: '36px',
    fontWeight: '500',
  },

  itemDescription: {
    fontSize: 14,
    lineHeight: 2,
    color: '#282828',

    maxHeight: 'calc(14px * 2 * 3)',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',

  },

  [theme.queries.xsDevicesDown]: {
    itemContent: {
      display: 'flex',
      minHeight: '140px',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    itemDescription: {
      display: 'none',
    },
    itemName: {
      paddingBottom: 0,
      fontSize: '14px',
      lineHeight: '24px',
    },
  },

  [theme.queries.mdDevicesDown]: {
    item: {
      margin: '0 8px 12px',
      width: '100%',
    },
    itemContent: {
      padding: '20px 16px',
    },
  },

  [theme.queries.lgDevicesUp]: {
  },
  [theme.queries.lgDevicesOnly]: {
  },
});
