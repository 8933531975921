import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import InputMask from 'react-input-mask';

import { injectStyles } from '~app/theme';
import { styles } from './TextInputStyles';

@injectStyles(styles)
class TextInput extends Component {

  onChange = (event) => {
    if (this.props.onChange !== null)
      this.props.onChange(event);
  }

  onBlur = (event) => {
    if (this.props.onBlur !== null)
      this.props.onBlur(event);
  }

  onPriceChange = (event) => {
    const re = /^[0-9\b]+$/;

    if (event.target.value == '' || re.test(event.target.value)) {
      this.props.onChangeValue(event.target.value);
    }
  }

  componentDidMount = () => {
    const {
      name,
      autofocus,
    } = this.props;

    if (autofocus) {
      document.getElementById(`input${name}`).focus();
    }
  }

  renderInput = () => {
    const {
      classes,
      placeholder,
      code,
      success,
      error,
      small,
      value,
      name,
      maxLength,
      type,
      mask,
      autofocus,
      leftIcon,
    } = this.props;

    switch(type) {
    case 'textarea': {
      return (
        <textarea
          id={autofocus ? `input${name}` : ''}
          className={cn(classes.input, classes.textarea, (error ? classes.inputError : null), (code ? classes.code : null), (success ? classes.success : null),
            {
              [classes.small]: small
            }
          )}
          placeholder={placeholder}
          onChange={this.onChange}
          onBlur={this.onBlur}
          value={value}
          name={name}
          maxLength={maxLength}
        />
      );
    }
    case 'price': {
      return (
        <input
          id={autofocus ? `input${name}` : ''}
          type={'string'}
          className={cn(classes.input, (error ? classes.inputError : null), (code ? classes.code : null), (success ? classes.success : null))}
          placeholder={placeholder}
          onChange={this.onPriceChange}
          onBlur={this.onBlur}
          value={value}
          name={name}
          maxLength={maxLength}
        />
      );
    }
    default: {
      if (mask === null) {
        return (
          <div className={classes.inputWrapper}>
            {leftIcon !== null ? 
              <div className={classes.inputIcon}>{leftIcon}</div>
              : null
            }
            <input
              id={autofocus ? `input${name}` : ''}
              type={type}
              className={cn(classes.input,
                {
                  [classes.small]: small,
                  [classes.inputError]: error,
                  [classes.code]: code,
                  [classes.success]: success,
                  [classes.withIcon]: leftIcon,
                }
              )}
              placeholder={placeholder}
              onChange={this.onChange}
              onBlur={this.onBlur}
              value={value}
              name={name}
              maxLength={maxLength}
            />
          </div>
        );
      }
      else {
        return (
          <InputMask
            id={autofocus ? `input${name}` : ''}
            type={type}
            className={cn(classes.input, (error ? classes.inputError : null), (code ? classes.code : null), (success ? classes.success : null))}
            placeholder={placeholder}
            onChange={this.onChange}
            onBlur={this.onBlur}
            value={value}
            name={name}
            maxLength={maxLength}
            mask={mask}
          />
        );
      }
    }
    }
  }

  render() {
    const {
      classes,
      error,
      errorMessage,
    } = this.props;

    return (
      <div className={classes.root}>
        {this.renderInput()}
        {error &&
          <div className={classes.errorMessage}>{errorMessage}</div>
        }
      </div>
    );
  }
}

TextInput.propTypes = {
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  mask: PropTypes.string,
  autofocus: PropTypes.bool,
  small: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  leftIcon: PropTypes.object,
};

TextInput.defaultProps = {
  placeholder: '',
  code: false,
  success: false,
  error: false,
  errorMessage: '',
  value: '',
  name: '',
  type: 'text',
  maxLength: undefined,
  mask: null,
  autofocus: false,
  small: false,
  onChange: null,
  onBlur: null,
  leftIcon: null,
};

export default TextInput;
