export const initialState = {

  dealerItems: null,
  getDealersInProgress: false,
  getDealersError: null,


  dealer: null,
  getDealerInProgress: false,
  getDealerError: null,

  brandsByDealer: null,
  brandsByDealerInProgress: false,
  brandsByDealerError: null,
};

// Get dealers list
export const getDealersRequest = (state) => ({
  ...state,
  getDealersInProgress: true
});

export const getDealersSuccess = (state, { items }) => ({
  ...state,
  dealerItems: items,
  getDealersInProgress: false,
  getDealersError: null,
});

export const getDealersFailure = (state, payload) => ({
  ...state,
  getDealersInProgress: false,
  getDealersError: null,
});

export const getDealersReset = (state) => ({
  ...state,
  dealerItems: null,
  getDealersInProgress: false,
  getDealersError: null,
});

// Get dealer by id
export const getDealerRequest = (state) => ({
  ...state,
  getDealerInProgress: true
});

export const getDealerSuccess = (state, payload) => ({
  ...state,
  dealer: payload,
  getDealerInProgress: false,
  getDealerError: null,
});

export const getDealerFailure = (state, payload) => ({
  ...state,
  getDealerInProgress: false,
  getDealerError: null,
});

export const getDealerReset = (state) => ({
  ...state,
  dealer: null,
  getDealerInProgress: false,
  getDealerError: null,
});

// Get brands by dealer
export const getBrandsByDealerRequest = (state) => ({
  ...state,
  brandsByDealerInProgress: true
});

export const getBrandsByDealerSuccess = (state, { items }) => ({
  ...state,
  brandsByDealer: items,
  brandsByDealerInProgress: false,
  brandsByDealerError: null,
});

export const getBrandsByDealerFailure = (state, payload) => ({
  ...state,
  brandsByDealerInProgress: false,
  brandsByDealerError: null,
});

export const getBrandsByDealerReset = (state) => ({
  ...state,
  brandsByDealer: null,
  brandsByDealerInProgress: false,
  brandsByDealerError: null,
});