export default theme => ({
  
  root: {
    position: 'relative',
    padding: '21px 50px 24px',

    '&:before': {
      content: '""',
      display: 'block',
      width: 4,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
    }
  },
  rootNegative: {
    '&:before': {
      backgroundColor: theme.colors.rare.main,
    }
  },
  rootPositive: {
    '&:before': {
      backgroundColor: theme.colors.general.main,
    }
  },

  rating: {
    position: 'absolute',
    top: 20,

    fontSize: 18,
    fontWeight: '500',
  },
  ratingNegative: {
    color: theme.colors.rare.main,
  },
  ratingPositive: {
    color: theme.colors.general.main,
  },

  name: {
    paddingBottom: 6,

    fontSize: 14,
    lineHeight: '28px',
    fontWeight: '500',

    color: theme.colors.gray.darker,
  },
  message: {
    paddingBottom: 11,

    fontSize: 14,
    lineHeight: '24px',
  },

  bottom: {
    display: 'flex',

    fontSize: 14,

    color: theme.colors.gray.main,
    
    alignItems: 'flex-start',
  },

  date: {
    display: 'inline-flex',
    paddingRight: 8,

    alignItems: 'center',
  },
  dateIcon: {
    width: 14,
    height: 14,
    marginRight: 8,
  },
  package: {
    paddingLeft: 8,
  },
  

  [theme.queries.lgDevicesUp]: {

    rating: {
      right: 50,
    },
  },
  
  [theme.queries.mdDevicesDown]: {

    root: {
      padding: 20,
    },

    rating: {
      right: 20,
    },

    bottom: {
      flexDirection: 'column',
    },
    date: {
      width: '100%',
    },
    package: {
      width: '100%',
      paddingLeft: 0,

      lineHeight: '28px',
    }
  },
});