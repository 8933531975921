const XS = 576;
const SM = 768;
const MD = 1024;
const LG = 1250;

const getWidthBasedQuery = ({ min = null, max = null }) => {
  return `@media only screen${min !== null ? ` and (min-width: ${min}px)`: ''}${max !== null ? ` and (max-width: ${max - 0.02}px)`: ''}`;
};

export default {
  xsDevicesDown: getWidthBasedQuery({ max: XS }),
  xsDevicesOnly: getWidthBasedQuery({ max: XS }),

  smDevicesUp: getWidthBasedQuery({ min: XS }),
  smDevicesDown: getWidthBasedQuery({ max: SM }),
  smDevicesOnly: getWidthBasedQuery({ min: XS, max: SM }),

  mdDevicesUp: getWidthBasedQuery({ min: SM }),
  mdDevicesDown: getWidthBasedQuery({ max: MD }),
  mdDevicesOnly: getWidthBasedQuery({ min: SM, max: MD }),

  lgDevicesUp: getWidthBasedQuery({ min: MD }),
  lgDevicesDown: getWidthBasedQuery({ max: LG }),
  lgDevicesOnly: getWidthBasedQuery({ min: MD, max: LG }),

  xlDevicesUp: getWidthBasedQuery({ min: LG }),
  xlDevicesOnly: getWidthBasedQuery({ min: LG }),
  XS,
  SM,
  MD,
  LG
};
