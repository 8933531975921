import { mapper, fromInput } from 'demapper';

@mapper
export default class {
  @fromInput('carterProtection')
  carterProtection = null;

  @fromInput('tire')
  tire = null;

  @fromInput('carpets')
  carpets = null;

  @fromInput('signaling')
  signaling = null;

  @fromInput('parkingSensors')
  parkingSensors = null;

  @fromInput('tuningStyling')
  tuningStyling = null;

  @fromInput('addSoundInsulation')
  addSoundInsulation = null;

  @fromInput('comment')
  comment = null;
}