export const styles = theme => ({

  itemButtons: {
    display: 'flex',
    height: 84,
    padding: '12px 0 24px',

    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  itemButton: {
    display: 'inline-flex',
    height: 48,
    padding: '5px 10px',

    fontSize: 14,
    fontWeight: '700',

    color: theme.colors.brand.main,

    alignItems: 'center',

    cursor: 'pointer',
  },

  itemButtonIcon: {
    width: 12,
    height: 12,
    position: 'relative',
    marginRight: 9,

    '&:before, &:after': {
      content: '""',
      display: 'block',
      width: 14,
      height: 2.5,
      position: 'absolute',
      top: 4.75,
      left: -1,

      backgroundColor: 'currentColor',

      transformOrigin: 'center',
    },
    '&:before': {
      transform: 'rotate(-45deg)',
    },
    '&:after': {
      transform: 'rotate(45deg)',
    },
  },

  modalArchiveButton: {
    display: 'flex',
    width: 130,
    margin: '0 auto',
    padding: '21px 0 10px',

    justifyContent: 'center',
  },


  [theme.queries.mdDevicesDown]: {
    modalArchiveButton: {
      width: '100%',
    },
    itemButtons: {
      justifyContent: 'center',
    }
  },
});