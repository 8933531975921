import React, { Component } from 'react';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import styles from './RequestLanderFormStyles';

import { connect } from 'react-redux';
import { push } from 'connected-react-router-exposed';
import actions from '~app/redux/actions';

import Constants from 'Constants';
import { IS_BROWSER } from '~app/config';

// import PropTypes from 'prop-types';
import { Select, Checkbox, Price, Button, Icon } from '~app/components/ui';
import { formContainer, formCreate } from 'deforms';

import orderOptions, { schema as orderSchema } from '~app/config/orderOptions';
import SalePercent from '~app/components/ui/SalePecrent/SalePercent';
import { PackageDetailsModal } from '~app/components';


const ImgBlock = ({ img, classes, fromModelPage, selectedModel, discountPercent }) => {
  return (
    <div className={classes.imgContainer}>
      {fromModelPage && selectedModel &&
        <div className={classes.modelText}>{selectedModel}</div>
      }
      {discountPercent > 0 &&
        <SalePercent hint={'Официальный дилер предлагает вам выгодные условия покупки автомобиля со скидкой до 15%'} absolute value={discountPercent} />
      }
      {img &&
        <div className={classes.img}
          style={{
            backgroundImage: `url(${img})`
          }} />}
    </div>
  );
};

const mapStateToProps = ({ dictionary, common, auth, applications }, { brand }) => {
  let currentBrand = { id: brand.id };

  let modelsState = null;
  if (currentBrand !== null && currentBrand.id in dictionary.modelsByBrand) {
    modelsState = dictionary.modelsByBrand[currentBrand.id];
  }

  return {
    modals: common.modals,

    isAuthorized: auth.isAuthorized,
    createApplicationInProgress: applications.createApplicationInProgress,
    createApplicationSuccess: applications.createApplicationSuccess,
    createApplicationError: applications.createApplicationError,

    models: modelsState === null ? null : modelsState.items,
    modelsInProgress: modelsState === null ? false : modelsState.inProgress,
    modelsError: modelsState === null ? null : modelsState.error,

    colorsByModel: dictionary.colorsByModel,
    packagesByModel: dictionary.packagesByModel,
  };
};


@connect(mapStateToProps)

@injectStyles(styles)
@formContainer

class RequestLanderForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      packagePreselected: false,
      colorPreselected: false,
      detailsModalOpened: false,
      brand: props.brand.id,
      model: props.model.id,
      color: null,
      configuration: null,
      tradeInFilled: false,
      tradeInFormData: {},
    };
  }

  @formCreate()
  form = {
    schema: {
      model: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
        }
      },
      color: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
        }
      },
      package: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
        }
      },
      ...orderSchema
    },
    onSubmit: (formData) => {
      this.createApplication(formData);
    },
    onChange: context => {
      this.onFormChange(context.getFormData());
    }
  }

  getPropsByState = () => {

    const { model, color, configuration } = this.state;

    const { models, colorsByModel, packagesByModel } = this.props;

    let currentModel = null;
    if (model !== null && models !== null) {
      currentModel = models.find(x => x.id === model);
    }

    let colorsState = null;
    if (model in colorsByModel) {
      colorsState = colorsByModel[model];
    }

    let currentColor = null;
    if (color !== null && colorsState !== null && colorsState.items !== null) {
      currentColor = colorsState.items.find(x => x.id === color);
    }

    let packagesState = null;
    if (model in packagesByModel) {
      packagesState = packagesByModel[model];
    }

    let currentPackage = null;
    if (configuration !== null && packagesState !== null && packagesState.items !== null) {
      currentPackage = packagesState.items.find(x => x.id === configuration);
    }


    return {
      ...this.props,

      currentModel,
      currentColor,
      currentPackage,

      colors: colorsState === null ? null : colorsState.items,
      colorsInProgress: colorsState === null ? false : colorsState.inProgress,
      colorsError: colorsState === null ? null : colorsState.error,

      packages: packagesState === null ? null : packagesState.items,
      packagesInProgress: packagesState === null ? false : packagesState.inProgress,
      packagesError: packagesState === null ? null : packagesState.error,

    };

  }

  componentDidMount() {
    this.preselectForm();
    this.loadModelsByBrand();
    this.loadPackagesByModel();
    this.runScrollListener();
  }

  runScrollListener = () => {
    window.addEventListener('scroll', () => {

      let scrollTop = document.getElementById('requestLanderFormBtn').getBoundingClientRect().top - window.innerHeight + 80;

      if (scrollTop <= 0) {
        this.setState({
          scrolledApplicationSection: true
        });
      }
      else {
        this.setState({
          scrolledApplicationSection: false
        });
      }
    });
  }

  preselectForm = () => {
    if (this.state.model) {
      this.form.fields.model.setValue(this.state.model);
    }
  }

  loadModelsByBrand = () => {
    const { brand } = this.state;
    const { models, modelsInProgress, modelsError } = this.props;
    if (models === null && !modelsInProgress && modelsError === null) {
      actions.dictionary.getModelsByBrand(brand);
    }
  }

  loadPackagesByModel = () => {
    const { model } = this.state;
    const { packages, packagesInProgress, packagesError } = this.getPropsByState();
    if (packages === null && !packagesInProgress && packagesError === null) {
      actions.dictionary.getPackagesByModel(model);
    }
  }

  onFormChange = (formData = {}) => {
    const state = { ...this.state };
    let modelChanged = false;

    for (let key in formData) {
      if (state.configuration != formData['package']) {
        state.configuration = formData['package'];
        continue;
      }
      if (state[key] != formData[key]) {
        state[key] = formData[key];
        if (key == 'model') {
          modelChanged = true;
        }
      }
    }
    if (modelChanged) {
      state.configuration = null;
      state.color = null;
    }
    this.setState(state, () => {
      if (modelChanged) {
        this.form.fields.package.value = null;
        this.form.fields.color.value = null;
        this.loadPackagesByModel();
      }
    });
  }

  onSaveTradeInForm = ({ data = {}, filled = true }) => {
    this.setState({ tradeInFormData: data, tradeInFilled: filled });
  }

  openTradeInModal = event => {
    event.preventDefault();
    actions.common.openModal({
      type: 'tradeIn',
      params: {
        data: this.state.tradeInFormData,
        onSaveTradeInForm: this.onSaveTradeInForm
      }
    });
  };

  componentDidUpdate(prevProps) {
    // выбор первых значений комплектации и цвета на ленде модели
    if (!this.props.fromModelPage || (this.state.colorPreselected && this.state.packagePreselected)) {
      return;
    }
    const props = this.getPropsByState();

    if (!this.state.packagePreselected) {
      if (!props.packagesInProgress) {
        if (props.packages !== null) {
          setTimeout(() => {
            this.setState({ packagePreselected: true }, () => {
              this.form.fields.package.setValue(props.packages[0].id);
            });
          }, 0);
        }
      }
    }

    if (!this.state.colorPreselected) {
      if (!props.colorsInProgress) {
        if (props.colors !== null) {
          setTimeout(() => {
            this.setState({ colorPreselected: true }, () => {
              this.form.fields.color.setValue(props.colors[0].id);
            });
          }, 0);
        }
      }
    }
  }

  componentWillReceiveProps = (next) => {
    if (!this.props.isAuthorized && next.isAuthorized) {
      this.createApplication(null, true);
      return;
    }
    if (next.createApplicationSuccess && next.createApplicationSuccess !== this.props.createApplicationSuccess) {
      if (IS_BROWSER && window.location.host === Constants.LANDER_DOMAIN) {
        window.location.replace(`${window.location.protocol}//${Constants.UI_DOMAIN}/cabinet?promo=1`);
      } else {
        this.props.dispatch(push('/cabinet?promo=1'));
      }
    }
    if (next.createApplicationError !== null && next.createApplicationError !== this.props.createApplicationError) {
      actions.common.openGenericModal({
        theme: 'rare',
        icon: 'timesCircle',
        title: 'Ошибка!',
        text: next.createApplicationError.message,
      });
    }
  }

  createApplication = (data, afterAuth = false) => {
    const { isAuthorized } = this.props;
    const combinedData = data ? data : this.form.getFormData();

    if (this.state.tradeInFilled) {
      combinedData.tradeIn = true;
      combinedData.oldCar = this.state.tradeInFormData;
    }

    if (!isAuthorized && !afterAuth) {
      actions.common.openAuthModal('register', false, { type: 'landing' });
    } else {
      actions.applications.createApplication(combinedData);
    }
  }

  openModal = () => {
    this.setState({
      detailsModalOpened: true,
    });
  }

  closeModal = () => {
    this.setState({
      detailsModalOpened: false,
    });
  }

  render = () => {
    const {
      classes,
      title,
      titleAdditional,
      fromModelPage,
      // advantages,
      icons,
      brand,
      // model,
      models,
      colors,
      packages,
      currentModel,
      // currentColor,
      currentPackage
    } = this.getPropsByState();


    const advantages = [
      'Выберите подходящую комплектацию и цвет автомобиля на сайте',
      'Сделайте заявку на подбор автомобиля от официального дилера',
      'Получай предложение от дилеров на почту и выбирай лучшие из них'
    ];

    // console.log(currentModel, currentColor, currentPackage);

    // const { tradeInFilled } = this.state;

    const img = currentModel ? currentModel.image : '';
    const selectedModel = currentModel ? `${brand.name} ${currentModel.name}` : '';
    const discountPercent = currentPackage ? currentPackage.discountPercent : 0;
    const discountValue = currentPackage ? currentPackage.discountValue : 0;
    const priceLimit = currentPackage ? currentPackage.price : 0;

    const form = this.form;

    return (
      <div className={cn(classes.root)}>
        {title &&
          <h2 className={classes.title}>
            {`${titleAdditional} ${title}`}
          </h2>
        }
        {fromModelPage &&
          <h2 className={classes.title}>
            {`Сделай заявку и получи предложение от всех официальных дилеров ${brand.name}`}
          </h2>
        }
        <form
          noValidate
          className={cn(classes.container, { [classes.model]: fromModelPage })}
          onSubmit={form.submit}>
          <div className={classes.form}>
            {fromModelPage &&
              <ImgBlock
                fromModelPage
                selectedModel={selectedModel}
                discountPercent={discountPercent}
                img={img}
                classes={classes}
              />
            }
            {!fromModelPage &&
              <div className={classes.field}>
                <Select
                  placeholder={'Все модели'}
                  onChange={form.fields.model.changeValue}
                  value={form.fields.model.value}
                  error={form.submitted && !form.fields.model.valid}
                  options={models !== null ? models : []}
                  textField={'name'}
                  valueField={'id'}
                />
              </div>
            }
            <div className={classes.field}>
              <Select
                placeholder={'Все цвета'}
                onChange={form.fields.color.changeValue}
                value={form.fields.color.value}
                error={form.submitted && !form.fields.color.valid}
                options={colors !== null ? colors : []}
                textField={'name'}
                valueField={'id'}
              />
            </div>
            <div className={classes.field}>
              <Select
                placeholder={'Все комплектации'}
                onChange={form.fields.package.changeValue}
                value={form.fields.package.value}
                error={form.submitted && !form.fields.package.valid}
                options={packages !== null ? packages : []}
                textField={'name'}
                valueField={'id'}
              />
              {form.fields.package.value !== null && form.fields.package.value !== '' &&
                <span onClick={this.openModal} className={classes.detailsLink}>
                  {'Детали'}
                </span>
              }
            </div>
            <div className={classes.cols}>
              {orderOptions.map(item =>
                <div key={item.id} className={classes.colsItem}>
                  <Checkbox
                    name={item.id}
                    label={item.name}
                    labelStyles={classes.label}
                    checked={form.fields[item.id].value}
                    onChangeValue={form.fields[item.id].changeValue}
                  />
                </div>
              )}
            </div>
            {/* <a
              onClick={this.openTradeInModal}
              className={classes.tradeIn}>
              <span className={tradeInFilled ? classes.check : classes.plus} />
              {'Программа Trade-in'}
            </a> */}
          </div>
          {fromModelPage && <div className={classes.divider} />}
          <div className={classes.info}>
            {!fromModelPage &&
              <ImgBlock
                selectedModel={selectedModel}
                discountPercent={discountPercent}
                img={img}
                classes={classes}
              />}
            {fromModelPage && icons && advantages &&
              <div className={classes.icons}>
                {icons.map((icon, index) => {
                  const text = advantages[index];
                  if (!text) {
                    return null;
                  }
                  return (
                    <div key={icon} className={classes.iconItem}>
                      <div className={classes.icon}>
                        <Icon block name={icon} />
                      </div>
                      <div className={classes.iconText}>{text}</div>
                    </div>
                  );
                }
                )}
              </div>
            }
            <div className={classes.footer}>
              <div className={classes.chosen}>
                {(fromModelPage ?
                  (priceLimit > 0 && <span>{'Цена от '}<Price inline price={priceLimit} /></span>)
                  :
                  `Выбрана ${selectedModel}`
                ) || '\u00A0'}
              </div>
              <div className={classes.price}>
                {discountValue > 0 ?
                  <span>{'Твоя экономия: '}<Price inline price={discountValue} /></span>
                  :
                  '\u00A0'
                }
              </div>
              <div id={'requestLanderFormBtn'} className={classes.formButtonContainer}>
                <div className={cn(this.state.scrolledApplicationSection ? classes.formButtonFixed : classes.formButton)}>
                  <Button
                    theme={'rare'}
                    text={'Создать заявку'}
                    rightIcon={<Icon name={'arrowRight'} stroked />}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        {currentPackage !== null &&
          <PackageDetailsModal
            isOpen={this.state.detailsModalOpened}
            onRequestClose={this.closeModal}
            item={currentPackage}
          />
        }
      </div>
    );
  }
}

export default RequestLanderForm;
