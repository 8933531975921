export const styles = theme => ({
  button: {
    display: 'flex',
    width: 130,
    margin: '0 auto',
    padding: '21px 0 10px',

    justifyContent: 'center',
  },

  [theme.queries.mdDevicesDown]: {
    button: {
      width: '100%',
    }
  },
});