// import storage from 'local-storage-fallback';
import Constants from 'Constants';
import Cookies from 'js-cookie';

import { IS_BROWSER } from '~app/config';

const accessTokenKey = 'CredentialsAccessToken';

class Credentials {

  setAccessToken = async (data) => {
    try {
      let dataString = JSON.stringify(data);
      dataString = btoa(dataString);
      // storage.setItem(accessTokenKey, dataString);
      const attrs = {
        expires: 365,
        path: '/'
      };
      if (IS_BROWSER &&
        (window.location.host == Constants.UI_DOMAIN || window.location.host == Constants.LANDER_DOMAIN)
      ) {
        attrs.domain = Constants.UI_DOMAIN;
      }
      Cookies.set(accessTokenKey, dataString, attrs);
    } catch (error) { }
    return true;
  }

  getAccessToken = async () => {
    let accessToken = null;
    try {
      // let dataString = storage.getItem(accessTokenKey);
      let dataString = Cookies.get(accessTokenKey);
      if (dataString) {
        dataString = atob(dataString);
        accessToken = JSON.parse(dataString);
      }
    } catch (error) {
      this.cleanStorage();
    }

    return accessToken;
  }

  // cleanStorage = () => storage.removeItem(accessTokenKey);
  cleanStorage = () => Cookies.remove(accessTokenKey);
}

export default new Credentials();