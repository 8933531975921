export const initialState = {
  isAuthorized: false,
  user: null,
  loginStep: 0,
  registerStep: 0,

  restoreInProgress: false,
  restoreSuccess: null,
  restoreError: null,

  registerInProgress: false,
  registerSuccess: null,
  registerError: null,

  registerSMSInProgress: false,
  registerSMSSuccess: null,
  registerSMSError: null,

  loginInProgress: false,
  loginSuccess: null,
  loginError: null,

  loginSMSInProgress: false,
  loginSMSSuccess: null,
  loginSMSError: null,

  dealerLoginInProgress: false,
  dealerLoginResult: null,
  dealerLoginError: null,

  dealerRegistrationInProgress: false,
  dealerRegistrationResult: null,
  dealerRegistrationError: null,
};

// Restore
export const restoreRequest = (state) => ({
  ...state,
  restoreInProgress: true,
  restoreSuccess: null,
  restoreError: null
});
export const restoreSuccess = (state, payload) => ({
  ...state,
  isAuthorized: true,
  user: payload.user,
  
  restoreInProgress: false,
  restoreSuccess: true,
  restoreError: null
});
export const restoreFailure = (state, payload) => ({
  ...state,
  isAuthorized: false,
  user: null,

  restoreInProgress: false,
  restoreSuccess: false,
  restoreError: payload
});

// Logout 
export const logout = (state) => ({
  ...initialState,
  restoreSuccess: state.restoreSuccess,
  restoreError: state.restoreError
});



// Registration
export const registerRequest = (state) => ({
  ...state,
  registerInProgress: true,
  registerSuccess: null,
  registerError: null
});
export const registerSuccess = (state) => ({
  ...state,
  
  registerStep: 1,
  
  registerInProgress: false,
  registerSuccess: true,
  registerError: null
});
export const registerFailure = (state, payload) => ({
  ...state,

  registerInProgress: false,
  registerSuccess: false,
  registerError: payload,
});
export const registerReset = (state) => ({
  ...state,

  registerStep: 0,

  registerInProgress: false,
  registerSuccess: null,
  registerError: null,
});

// RegistrationSMS
export const registerSMSRequest = (state) => ({
  ...state,
  registerSMSInProgress: true,
  registerSMSSuccess: null,
  registerSMSError: null
});
export const registerSMSSuccess = (state, payload) => ({
  ...state,

  registerSMSInProgress: false,
  registerSMSSuccess: payload,
  registerSMSError: null
});
export const registerSMSFailure = (state, payload) => ({
  ...state,

  registerSMSInProgress: false,
  registerSMSSuccess: null,
  registerSMSError: payload
});

// Login
export const loginRequest = (state) => ({
  ...state,
  loginInProgress: true,
  loginSuccess: null,
  loginError: null
});
export const loginSuccess = (state) => ({
  ...state,

  loginStep: 1,

  loginInProgress: false,
  loginSuccess: true,
  loginError: null
});
export const loginFailure = (state, payload) => ({
  ...state,

  loginInProgress: false,
  loginSuccess: false,
  loginError: payload
});
export const loginReset = (state) => ({
  ...state,

  loginStep: 0,

  loginInProgress: false,
  loginSuccess: null,
  loginError: null,
});
export const loginSMSRequest = (state) => ({
  ...state,
  loginSMSInProgress: true,
  loginSMSSuccess: null,
  loginSMSError: null
});
export const loginSMSSuccess = (state, payload) => ({
  ...state,
  user: payload.user,
  loginSMSInProgress: false,
  loginSMSSuccess: payload,
  loginSMSError: null
});
export const loginSMSFailure = (state, payload) => ({
  ...state,

  loginSMSInProgress: false,
  loginSMSSuccess: null,
  loginSMSError: payload
});

// Dealer login
export const dealerLoginRequest = (state) => ({
  ...state,
  dealerLoginInProgress: true,
  dealerLoginResult: null,
  dealerLoginError: null
});
export const dealerLoginSuccess = (state, payload) => ({
  ...state,
  user: payload.user,
  dealerLoginInProgress: false,
  dealerLoginResult: payload,
  dealerLoginError: null
});
export const dealerLoginFailure = (state, payload) => ({
  ...state,

  dealerLoginInProgress: false,
  dealerLoginResult: null,
  dealerLoginError: payload
});
export const dealerLoginReset = (state) => ({
  ...state,
  dealerLoginInProgress: false,
  dealerLoginResult: null,
  dealerLoginError: null
});
// End

// Dealer registration
export const dealerRegistrationRequest = (state) => ({
  ...state,
  dealerRegistrationInProgress: true,
  dealerRegistrationResult: null,
  dealerRegistrationError: null
});
export const dealerRegistrationSuccess = (state, payload) => ({
  ...state,
  dealerRegistrationInProgress: false,
  dealerRegistrationResult: payload,
  dealerRegistrationError: null
});
export const dealerRegistrationFailure = (state, payload) => ({
  ...state,
  dealerRegistrationInProgress: false,
  dealerRegistrationResult: null,
  dealerRegistrationError: payload
});
export const dealerRegistrationReset = (state) => ({
  ...state,
  dealerRegistrationInProgress: false,
  dealerRegistrationResult: null,
  dealerRegistrationError: null
});
// End

export const setAuthorized = (state, payload) => ({
  ...state,

  isAuthorized: true,
  user: payload,
});

export const setUser = (state, payload) => ({
  ...state,
  user: payload,
});
