import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { injectStyles } from '~app/theme';
import { push } from 'connected-react-router-exposed';
import { formContainer, formCreate } from 'deforms';
import cn from 'classnames';
import actions from '~app/redux/actions';

import { Countdown } from '~app/components';
import { Button, TextInput, Checkbox, Icon, Spacer } from '~app/components/ui';
import { freezeScroll } from '../../utils/helpers';

import styles from './AuthModalStyles';

const mapStateToProps = (state) => ({
  registerSuccess: state.auth.registerSuccess,
  registerInProgress: state.auth.registerInProgress,
  registerError: state.auth.registerError,

  registerSMSSuccess: state.auth.registerSMSSuccess,
  registerSMSInProgress: state.auth.registerSMSInProgress,
  registerSMSError: state.auth.registerSMSError,

  registerStep: state.auth.registerStep,

  loginSuccess: state.auth.loginSuccess,
  loginInProgress: state.auth.loginInProgress,
  loginError: state.auth.loginError,

  loginSMSSuccess: state.auth.loginSMSSuccess,
  loginSMSInProgress: state.auth.loginSMSInProgress,
  loginSMSError: state.auth.loginSMSError,

  loginStep: state.auth.loginStep,

  redirectToCabinet: state.common.authModalUseRedirect,

  type: state.common.authModalProps.type

});
const mapDispatchToProps = {
  push
};

@connect(mapStateToProps, mapDispatchToProps)
@injectStyles(styles)
@formContainer
class AuthModal extends Component {

  state = {
    activeTab: 0,

    lastRegisterSend: null,
    lastLoginSend: null,
    scrollTop: 0,
  }

  @formCreate()
  formLogin = {
    schema: {
      // email: {
      //   defaultValue: '',
      //   validation: {
      //     type: 'string',
      //     allowEmpty: false,
      //     minlength: 5
      //   }
      // },
      phone: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
          minlength: 5
        }
      },
      save: {
        defaultValue: true,
        validation: {
          type: 'boolean',
        }
      },
    },
    onSubmit: (formData) => {
      this.setState({
        lastLoginSend: Date.now()
      }, () => {
        actions.auth.login(formData);
      });
    }
  }
  @formCreate()
  formLoginSMS = {
    schema: {
      code: {
        defaultValue: '',
        validation: {
          type: 'string',
          minLength: 4,
          maxLength: 4,
        }
      },
    },
    onSubmit: (formData) => {
      actions.auth.loginSMS({
        ...formData,
        // email: this.formLogin.fields.email.value,
        phone: this.formLogin.fields.phone.value,
      });
    },
    onChange: (form) => {
      if (form.valid) {
        form.submit();
      }
    }
  }

  @formCreate()
  formRegister = {
    schema: {
      name: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
          minlength: 5
        }
      },
      // surname: {
      //   defaultValue: '',
      //   validation: {
      //     type: 'string',
      //     allowEmpty: false,
      //     minlength: 5
      //   }
      // },
      // email: {
      //   defaultValue: '',
      //   validation: {
      //     type: 'string',
      //     allowEmpty: false,
      //     minlength: 5
      //   }
      // },
      phone: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
          minlength: 5
        }
      },
      agreement: {
        defaultValue: true,
        validation: {
          type: 'boolean',
          conform: x => x
        }
      },
    },
    onSubmit: (formData) => {
      this.setState({
        lastRegisterSend: Date.now()
      }, () => {
        actions.auth.register(formData);
      });
    }
  }
  @formCreate()
  formRegisterSMS = {
    schema: {
      code: {
        defaultValue: '',
        validation: {
          type: 'string',
          minLength: 4,
          maxLength: 4,
        }
      },
    },
    onSubmit: (formData) => {
      actions.auth.registerSMS({
        ...formData,
        // email: this.formRegister.fields.email.value,
        phone: this.formRegister.fields.phone.value,
      });
    },
    onChange: (form) => {
      if (form.valid) {
        form.submit();
      }
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.loginSMSSuccess !== null && nextProps.loginSMSSuccess !== this.props.loginSMSSuccess) {
      actions.auth.setAuthorized(nextProps.loginSMSSuccess);
      if (this.props.redirectToCabinet) {
        this.props.push('/cabinet');
      }
      this.onRequestClose();
    }
    if (nextProps.registerSMSSuccess !== null && nextProps.registerSMSSuccess !== this.props.registerSMSSuccess) {
      actions.auth.setAuthorized(nextProps.registerSMSSuccess);
      if (this.props.redirectToCabinet) {
        this.props.push('/cabinet');
      }
      this.onRequestClose();
    }
  }

  changeTab = tab => {
    this.setState({
      activeTab: tab
    });
  }

  changeRegisterStep = step => {
    this.setState({
      registerStep: step
    });
  }

  changeLoginStep = step => {
    this.setState({
      loginStep: step
    });
  }

  onSuccess = () => {
    this.props.onSuccess();
    this.onRequestClose();
  }

  onOpen = () => {
    freezeScroll();

    const tab = this.props.tab;

    if (tab !== null) {
      if (tab === 'auth' || tab === 0) {
        this.setState({
          activeTab: 0,
        });
      }
      else if (tab === 'register' || tab === 1) {
        this.setState({
          activeTab: 1,
        });
      }
    }
  }

  onRequestClose = () => {
    this.props.onRequestClose();
    freezeScroll(true);
  }

  renderLogin = () => {
    const formLogin = this.formLogin;
    const formLoginSMS = this.formLoginSMS;
    const {
      classes,
      loginStep,
      loginError,
      loginSMSSuccess,
      loginSMSError,
    } = this.props;
    const {
      lastLoginSend
    } = this.state;

    if (loginStep === 0 && loginError === null) {
      return (<div>
        <div className={classes.title}>
          {'Вход в личный кабинет'}
        </div>
        <form noValidate className={classes.form}
          onSubmit={formLogin.submit}
        >
          <div className={classes.formInput}>
            {/* <TextInput
              name={'email'}
              type={'email'}
              placeholder={'Email'}
              onChange={formLogin.fields.email.change}
              value={formLogin.fields.email.value}
              error={formLogin.submitted && !formLogin.fields.email.valid}
            /> */}
          </div>
          <div className={classes.formInput}>
            <TextInput
              name={'phone'}
              type={'tel'}
              placeholder={'Телефон'}
              onChange={(event) => {
                formLogin.fields.phone.changeValue(event.target.value.replace(/ /g, ''));
              }}
              value={formLogin.fields.phone.value}
              error={formLogin.submitted && !formLogin.fields.phone.valid}
              mask={'+9 999 999 99 99'}
            />
          </div>
          <Spacer v={12} />
          <div className={classes.formInput}>
            <Checkbox
              name={'agreement'}
              label={'Запомнить меня'}
              labelStyles={classes.checkboxLabel}
              checked={formLogin.fields.save.value}
              onChangeValue={formLogin.fields.save.changeValue}
            />
          </div>
          <div className={classes.formAction}>
            <Button
              mobileBlock
              theme={'brand'}
              text={'Войти'}
              leftIcon={<Icon name={'enter'} />}
            />
          </div>
        </form>
      </div>);
    }
    if (loginStep === 1) {
      return (
        <div>
          <div className={classes.title}>
            {formLogin.fields.phone.value}
          </div>
          <div className={classes.nav}
            onClick={actions.auth.loginReset}>
            <span className={cn(classes.navIcon, classes.navIconChange)}>
              <Icon name={'arrowRight'} />
            </span>
            <span className={classes.navButton}>Изменить номер</span>
          </div>
          <div className={classes.text}>
            Подтверждение входа в личный кабинет, необходимо ввести код из СМС
          </div>
          <Countdown
            date={lastLoginSend + 180000}
            renderTimer={({ timeString }) => (
              <div className={classes.timer}>
                <span className={classes.timerIcon}>
                  <Icon name={'clock'} />
                </span>
                <span>{timeString}</span>
              </div>
            )}
            renderAction={() => (
              <div className={classes.nav}
                onClick={() => { formLogin.submit(); }}>
                <span className={classes.navButton}>Повторить отправку</span>
              </div>
            )}
          />
          <form noValidate className={classes.form}>
            <div className={classes.formInput}>
              {loginSMSSuccess === null && loginSMSError === null &&
                <label className={classes.formInputLabel}>Ввести код</label>
              }
              {loginSMSError !== null &&
                <label className={cn(classes.formInputLabel, classes.formInputLabelError)}>Неверный код</label>
              }
              {loginSMSSuccess !== null &&
                <label className={cn(classes.formInputLabel, classes.formInputLabelSuccess)}>Код принят</label>
              }
              <TextInput
                code
                maxLength={4}
                success={loginSMSSuccess}
                error={loginSMSError}
                onChange={formLoginSMS.fields.code.change}
                value={formLoginSMS.fields.code.value}
                autofocus
              />
            </div>
          </form>
        </div>);
    }
    if (loginError !== null) {
      return (
        <div>
          <div className={classes.text}>
            Ошибка!
          </div>
          <div className={classes.text}>
            {loginError.message}
          </div>
          <div className={classes.nav}
            onClick={actions.auth.loginReset}>
            <span className={cn(classes.navIcon, classes.navIconChange)}>
              <Icon name={'arrowRight'} />
            </span>
            <span className={classes.navButton}>Вернуться</span>
          </div>
        </div>);
    }
  }

  renderRegister = () => {
    const formRegister = this.formRegister;
    const formRegisterSMS = this.formRegisterSMS;
    const {
      classes,
      registerStep,
      registerError,
      registerSMSSuccess,
      registerSMSError,
      type,
    } = this.props;
    const {
      lastRegisterSend,
    } = this.state;
    if (registerStep === 0 && registerError === null) {
      return (
        <div>
          <div className={classes.title}>
            {type == 'landing' ? 'Осталось пол шага, зарегистрируйся:' : 'Укажи контактные данные'}
          </div>
          {type == 'landing' &&
            <div className={classes.text}>
              {'Получи интересные предложения от всех дилеров в одном месте и выбери лучшее'}
            </div>
          }
          <form noValidate className={classes.form}
            onSubmit={formRegister.submit}
          >
            <div className={classes.formInput}>
              <TextInput
                name={'name'}
                type={'string'}
                placeholder={'Имя'}
                onChange={formRegister.fields.name.change}
                value={formRegister.fields.name.value}
                error={formRegister.submitted && !formRegister.fields.name.valid}
              />
            </div>
            {/* <div className={cn(classes.formInput, classes.formInput_half)}>
              <TextInput
                name={'surname'}
                type={'string'}
                placeholder={'Фамилия'}
                onChange={formRegister.fields.surname.change}
                value={formRegister.fields.surname.value}
                error={formRegister.submitted && !formRegister.fields.surname.valid}
              />
            </div>
            */}
            {/* <div className={classes.formInput}>
              <TextInput
                name={'email'}
                type={'email'}
                placeholder={'Email'}
                onChange={formRegister.fields.email.change}
                value={formRegister.fields.email.value}
                error={formRegister.submitted && !formRegister.fields.email.valid}
              />
            </div>
            */}
            <div className={classes.formInput}>
              <TextInput
                name={'phone'}
                type={'tel'}
                placeholder={'Телефон'}
                onChange={(event) => {
                  formRegister.fields.phone.changeValue(event.target.value.replace(/ /g, ''));
                }}
                value={formRegister.fields.phone.value}
                error={formRegister.submitted && !formRegister.fields.phone.valid}
                mask={'+9 999 999 99 99'}
              />
            </div>
            <Spacer v={12} />
            <div className={classes.formInput}>
              <Checkbox
                name={'agreement'}
                label={'Я принимаю %LINK%'}
                labelStyles={classes.checkboxLabel}
                link={{
                  placeholder: '%LINK%',
                  text: 'условия пользовательского соглашения',
                  url: '/static/files/agreement.pdf'
                }}
                checked={formRegister.fields.agreement.value}
                onChangeValue={formRegister.fields.agreement.changeValue}
                // onChangeValue={(value) => {
                //   console.log(value);
                // }}
                error={formRegister.submitted && !formRegister.fields.agreement.valid}
              />
            </div>
            <div className={classes.formAction}>
              <Button
                mobileBlock
                theme={'brand'}
                text={'Регистрация'}
              />
            </div>
          </form>
        </div>);
    }
    if (registerStep === 1) {
      return (
        <div>
          <div className={classes.title}>
            {formRegister.fields.phone.value}
          </div>
          <div className={classes.nav}
            onClick={actions.auth.registerReset}>
            <span className={cn(classes.navIcon, classes.navIconChange)}>
              <Icon name={'arrowRight'} />
            </span>
            <span className={classes.navButton}>Изменить номер</span>
          </div>
          <div className={classes.text}>
            Для регистрации и входа в личный кабинет необходимо ввести код из СМС
          </div>
          <Countdown
            date={lastRegisterSend + 180000}
            renderTimer={({ timeString }) => (
              <div className={classes.timer}>
                <span className={classes.timerIcon}>
                  <Icon name={'clock'} />
                </span>
                <span>{timeString}</span>
              </div>
            )}
            renderAction={() => (
              <div className={classes.nav}
                onClick={() => { formRegister.submit(); }}>
                <span className={classes.navButton}>Повторить отправку</span>
              </div>
            )}
          />
          <form noValidate className={classes.form} onSubmit={formRegisterSMS.submit}>
            <div className={classes.formInput}>
              {registerSMSSuccess === null && registerSMSError === null &&
                <label className={classes.formInputLabel}>Ввести код</label>
              }
              {registerSMSError !== null &&
                <label className={cn(classes.formInputLabel, classes.formInputLabelError)}>Неверный код</label>
              }
              {registerSMSSuccess !== null &&
                <label className={cn(classes.formInputLabel, classes.formInputLabelSuccess)}>Код принят</label>
              }
              <TextInput
                code
                maxLength={4}
                success={registerSMSSuccess}
                error={registerSMSError}
                onChange={formRegisterSMS.fields.code.change}
                value={formRegisterSMS.fields.code.value}
                autofocus
              />
            </div>
          </form>
        </div>);
    }
    if (registerError !== null) {
      return (
        <div>
          <div className={classes.text}>
            Ошибка!
          </div>
          <div className={classes.text}>
            {registerError.message}
          </div>
          <div className={classes.nav}
            onClick={actions.auth.registerReset}>
            <span className={cn(classes.navIcon, classes.navIconChange)}>
              <Icon name={'arrowRight'} />
            </span>
            <span className={classes.navButton}>Вернуться</span>
          </div>
        </div>);
    }
  }

  render() {
    const {
      classes,
      open,
      type,
    } = this.props;
    const {
      activeTab,
    } = this.state;

    return (
      <ReactModal
        className={{
          base: classes.modal,
          afterOpen: classes.modalAfterOpen,
          beforeClose: classes.modalBeforeClose,
        }}
        overlayClassName={{
          base: classes.overlay,
          afterOpen: classes.overlayAfterOpen,
          beforeClose: classes.overlayBeforeClose,
        }}
        isOpen={open}
        onAfterOpen={this.onOpen}
        closeTimeoutMS={300}
        key={this.props.isOpen}
        contentLabel={'modal'}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick>

        <a className={classes.close} onClick={this.onRequestClose} />
        {type != 'landing' &&
          <div className={classes.tabs}>
            <div className={cn(classes.tab, { [classes.tab_active]: activeTab === 0 })}
              onClick={() => {
                this.changeTab(0);
              }}
            >
              {'Авторизация'}
            </div>
            <div className={cn(classes.tab, { [classes.tab_active]: activeTab === 1 })}
              onClick={() => {
                this.changeTab(1);
              }}
            >
              {'Регистрация'}
            </div>
          </div>
        }
        <div className={classes.content}>
          {activeTab === 0 && this.renderLogin()}
          {activeTab === 1 && this.renderRegister()}
        </div>
      </ReactModal>
    );
  }
}

export default AuthModal;
