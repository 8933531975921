
const getUpdatedByKey = (state, field, initialData, key, data = null) => {
  const item = (field in state && key in state[field]) ? state[field][key] : {
    ...initialData
  };

  const result = {
    ...item,
    ...data
  };

  if (data !== null && data.items && data.paging && data.paging.skip > 0) {
    result.items = [
      ...item.items,
      ...data.items
    ];
  }

  return result;
};

export const updateStateItemByKey = (state, field, initialData, key, data) => {
  let updatedItem = getUpdatedByKey(state, field, initialData, key, data);
  return {
    ...state,
    [field]: {
      ...state[field],
      [key]: updatedItem
    }
  };
};