import client from './_client';

// Send message 
export const sendMessage = (params = {}) => {
  let {
    offerId,
    message,
  } = params;

  return client.request({
    url: `/v1/offer/${offerId}/message`,
    method: 'POST',
    data: {
      text: message
    }
  });
};