import React, { Component } from 'react';

import { Helmet } from 'react-helmet';


import { Container, Carousel, Time } from '~app/components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './PromoItemScreenStyles';

import actions from '~app/redux/actions';
import { connect } from 'react-redux';
import PreFooter from '../HomeScreen/Sections/PreFooter/PreFooter';
import { PromoItemCard } from '~app/components/Promo';
import { NewsItemCard, HeroImage } from '~app/components/News';
import { BrandsItemCard } from '~app/components';

const mapStateToProps = ({ news, dealers, promo }, { reduxKey = 'promoPage' }) => {
  let newsState = {
    items: null,
  };

  let promoState = {
    items: null,
  };

  if (reduxKey in news.newsByKey) {
    newsState = news.newsByKey[reduxKey];
  }

  if (reduxKey in promo.promoByKey) {
    promoState = promo.promoByKey[reduxKey];
  }

  return {
    data: promo.promoItem,
    inProgress: promo.promoItemInProgress,
    success: promo.promoItemSuccess,
    error: promo.promoItemError,

    reduxKey,

    newsItems: newsState.items,
    brandsByDealer: dealers.brandsByDealer,
    promoItems: promoState.items,

  };
};

@connect(mapStateToProps)
@injectStyles(styles)

class PromoItemScreen extends Component {

  static async fetchData({ store, params }) {
    await actions.promo.getPromoItem({ id: params.id });
  }

  componentDidMount() {
    actions.promo.getPromoItem({ id: this.props.match.params.id });
  }

  componentWillUnmount() {
    actions.promo.getPromoItemReset();
  }

  componentDidUpdate(prevProps) {
    if(this.props.match.params.id !== prevProps.match.params.id && !this.props.inProgress) {
      actions.news.getNewsItem({ id: this.props.match.params.id });
    }
    if (!this.props.inProgress && prevProps.inProgress) {
      if (this.props.data !== null) {
        if (this.props.data.dealer !== null) {
          this.loadBrandsByDealer();
          this.loadPromoByDealer();
        }
        this.loadNews();
      }
    }
  }

  loadBrandsByDealer = () => {
    actions.dealers.getBrandsByDealer(this.props.data.dealer.id);
  }

  loadPromoByDealer = () => {
    actions.promo.getPromoByKey('promoPage', {
      limit: 20,
      page: 1,
      id: this.props.data.dealer.id,
    });
  }

  loadNews = () => {
    actions.news.getNewsByKey(this.props.reduxKey, {
      page: 1,
      limit: 20,
      id: this.props.data.dealer !== null && this.props.data.dealer.id,
      type: this.props.data.dealer !== null ? 'withDealer' : 'all',
    });
  }

  render() {
    const {
      classes,
      data,
      brandsByDealer,
      promoItems,
      newsItems,
    } = this.props;


    const isDealer = data !== null && data.dealer !== null;

    return (
      <React.Fragment>
        <Helmet>
          <title>{data && data.name !== null ?
            `Avicar - ${data.name}` :
            'Avicar - Акция'}
          </title>
        </Helmet>
        <Container background={'white'} fullHeight>
          <div className={classes.root}>
            {data !== null &&
              <React.Fragment>
                {data.image !== null &&
                  <HeroImage place={'promo'} data={data} />
                }
                <div className={classes.content}>
                  {data.fullDescription !== null &&
                    <div
                      className={classes.html}
                      dangerouslySetInnerHTML={{ __html: data.fullDescription }} />
                  }
                  {data.date !== null &&
                    <Time date={data.date} />
                  }
                </div>
              </React.Fragment>
            }
          </div>
          {isDealer &&
            promoItems !== null &&
            Array.isArray(promoItems) &&
            promoItems.length > 0 &&
            <div className={classes.section}>
              <div className={classes.sectionTitle}>
                {'Акции дилера'}
              </div>
              <div className={classes.carousel}>
                <Carousel
                  name={'promo-carousel'}
                  desktopItems={2}
                  mobileItems={1}
                  items={promoItems.map(item =>
                    <PromoItemCard item={item} key={item.id} />
                  )}
                />
              </div>
            </div>
          }
          {isDealer &&
            brandsByDealer !== null &&
            Array.isArray(brandsByDealer) &&
            brandsByDealer.length > 0 &&
            <div className={classes.section}>
              <div className={classes.sectionTitle}>
                {`Бренды дилера ${data.dealer.name}`}
              </div>
              <div className={classes.carousel}>
                <Carousel
                  name={'brands-carousel'}
                  desktopItems={8}
                  mobileItems={1}
                  items={brandsByDealer.map(item =>
                    <BrandsItemCard item={item} key={item.id} />
                  )}
                />
              </div>
            </div>
          }
          {newsItems !== null &&
            Array.isArray(newsItems) &&
            newsItems.length > 0 &&
            <div className={classes.section}>
              <div className={classes.sectionTitle}>
                {isDealer ? 'Новости дилера' : 'Новости рынка'}
              </div>
              <div className={classes.carousel}>
                <Carousel
                  name={'news-carousel'}
                  desktopItems={4}
                  mobileItems={1}
                  items={newsItems.map(item =>
                    <NewsItemCard item={item} key={item.id} />
                  )}
                />
              </div>
            </div>
          }
        </Container>
        <Container >
          <PreFooter />
        </Container>
      </React.Fragment>
    );
  }
}

export default PromoItemScreen;
