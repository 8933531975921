export const styles = theme => ({
  root: {
    paddingTop: 89,
    paddingBottom: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: 18,

    color: theme.colors.gray.dark,
    fontSize: '36px',
    fontWeight: 'bold',
    lineHeight: 1.33,
    textAlign: 'center',
  },
  subtitle: {
    marginBottom: 31,

    color: theme.colors.gray.main,
    fontSize: '14px',
    lineHeight: 2,
    textAlign: 'center',
  },


  [theme.queries.smDevicesDown]: {
    root: {
      padding: '49px 8px 80px',
    },
    title: {
      marginBottom: 8,
  
      fontSize: '24px',
      lineHeight: 1.5,
    },
    button: {
      maxWidth: 320,
      width: '100%',
    },
  },
});