import React, { Component } from 'react';
import cn from 'classnames';
import numeral from 'numeral';

import { injectStyles } from '~app/theme';
import { styles } from './AboutStyles';

@injectStyles(styles)
class About extends Component {

  renderItem = (item, index, total) => {
    const { classes } = this.props;

    return (
      <div className={cn(classes.item, {[classes.item_reversed]: index % 2 !== 0})} key={index}>
        <div className={classes.itemImage} style={{backgroundImage: `url(${item.image})`}}></div>
        <div className={classes.itemSeparator}></div>
        <div className={classes.itemInfo}>
          <div className={classes.itemIcon}>
            <div className={classes.itemIconWrapper} style={{width: item.iconWidth || '100%'}}>
              {item.icon}
            </div>
          </div>
          <div className={classes.itemTitle}>
            {item.title}
          </div>
          <div className={classes.itemText}>
            {item.text}
          </div>
          <div className={classes.itemCount}>
            <span className={classes.itemCountCurrent}>
              {numeral(index+1).format('00')}
            </span>
            /
            <span className={classes.itemCountTotal}>
              {numeral(total).format('00')}
            </span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      classes,
      items = null,
    } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.headerColumn}>
            <div className={classes.headerSubtitle}>ценность сервиса</div>
            <div className={classes.headerTitle}>Проводим сделки выгодно для всех</div>
          </div>
          <div className={classes.headerSeparator}></div>
          <div className={classes.headerText}>Наша задача произвести сделку как можно выгоднее для пользователя и дилера. Мы исключаем из уравнения затраты на рекламу и большую часть работы менеджеров, так мы компенсируем скидку на автомобиль. Сервис наделяет клиента полномочиями заказчика, и дает ему возможность управлять покупкой. Больше никаких завышенных цен и уловок.</div>
        </div>
        {items !== null && items.map((item, index) => this.renderItem(item, index, items.length))}
      </div>
    );
  }
}

export default About;