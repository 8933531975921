import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'qs';

import { Container, Icon, Button } from '~app/components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './EmailConfirmScreenStyles';
import actions from '~app/redux/actions';

const mapStateToProps = state => ({
  user: state.auth.user,
  inProgress: state.user.emailConfirmInProgress,
  success: state.user.emailConfirmSuccess,
  error: state.user.emailConfirmError,
});

@connect(mapStateToProps)
@injectStyles(styles)
class EmailConfirmScreen extends Component {

  params = {
    token: null,
    userid: null
  }
  
  componentDidMount() {
    this.params = {
      ...this.params,
      ...qs.parse(this.props.location.search.replace('?', ''))
    };
    
    this.confirmRequest();
  }

  confirmRequest() {
    actions.user.emailConfirm(this.params);
  }

  render() {
    const {
      classes,
      success,
      error,
      inProgress,
      user,
    } = this.props;

    let actionLink = '/';
    let actionText = 'На главную';

    if (success && user !== null) {
      actionLink = user.isDealer ? '/dealer' : '/cabinet';
      actionText = 'В кабинет';
    }

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Avicar - Подтверждение почты</title>
        </Helmet>
        <Container>
          {!inProgress &&
            <div className={classes.content}>
              <div className={classes.icon}>
                {success && <Icon name={'tickCircle'}/>}
                {error !== null && <Icon name={'timesCircle'}/>}
              </div>
              <div className={classes.text}>
                {success && 'Поздравляем, email адрес успешно подтвержден!'}
                {error !== null && 'Ошибка!'}
              </div>
              <div className={classes.btn}>
                <Link to={actionLink}>
                  <Button
                    theme={'brand'}
                    text={actionText}
                    rightIcon={
                      <Icon name={'arrowRight'}/>
                    }
                  />
                </Link>
              </div>
            </div>
          }
        </Container>
      </div>
    );
  }
}

export default EmailConfirmScreen;