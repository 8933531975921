import React, { Component } from 'react';

import { Helmet } from 'react-helmet';


import { Container, Icon, Button, Spacer, ParentDomainLink } from '~app/components/ui';

import { connect } from 'react-redux';
import { injectStyles } from '~app/theme';
import { styles } from './NotFoundScreenStyles';
import actions from '~app/redux/actions';

@injectStyles(styles)

@connect()
class NotFoundScreen extends Component {

  supportOnClick = () => {
    actions.common.openModal({
      type: 'support'
    });
  }

  render = () => {
    const { classes } = this.props;

    return (
      <Container fullHeight>
        <Helmet>
          <title>Avicar - Страница не найдена</title>
        </Helmet>
        <div className={classes.container}>
          <div className={classes.icon}>
            <Icon name={'ooops'} />
          </div>
          <div className={classes.text}>{'Упс! Что-то пошло не так'}</div>
          <div className={classes.buttons}>
            <ParentDomainLink to={'/'}>
              <Button
                mobileBlock
                theme={'brand'}
                text={'На главную'}
                rightIcon={<Icon name={'arrowRight'} stroked />}
              />
            </ParentDomainLink>
            <Spacer h={12} v={12} />
            <Button
              mobileBlock
              onClick={this.supportOnClick}
              theme={'accent'}
              text={'Поддержка'} />
          </div>
        </div>
      </Container>
    );
  }
}

export default NotFoundScreen;
