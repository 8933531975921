export default (theme) => ({
  root: {
    width: '100%',
    height: 450,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    position: 'relative',
  },
  link: {
    width: '100%'
  },
  colText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
    position: 'relative',
    zIndex: 1,
    color: '#282828',
  },
  colTextInverted: {
    color: '#fff',
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
    lineHeight: 1.33,
    margin: '0 0 22px',
    maxWidth: 470,
    whiteSpace: 'pre-line',
  },
  subtitle: {
    fontSize: 24,
    lineHeight: 1.5,
    margin: '0 0 31px',
    maxWidth: 470,
    whiteSpace: 'pre-line',
  },
  text: {
    fontSize: 18,
    lineHeight: 1.56,
    margin: '0 0 10px',
    maxWidth: 470,
    whiteSpace: 'pre-line',
  },
  [theme.queries.mdDevicesDown]: {
    root: {
      '&:after': {
        zIndex: 0,
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        backgroundImage: 'linear-gradient(to left, rgba(60, 60, 80, 0.6), rgba(60, 60, 80, 0.88))',
        boxShadow: '0 2px 4px 0 rgba(60, 60, 60, 0.08)',
      }
    },
    title: {
      fontSize: 24,
      color: '#fac81e',
      margin: '0 0 14px'
    },
    subtitle: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 2,
      color: '#ffffff',
      margin: '0 0 13px'
    },
    text: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 2.33,
      color: '#ffffff',
      margin: '0 0 42px',
    }
  },

  [theme.queries.lgDevicesUp]: {
    colText: {
      maxWidth: '50%',
    },
    link: {
      display: 'none'
    }
  },

  [theme.queries.lgDevicesOnly]: {

  },

});
