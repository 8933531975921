import reducers from '~app/core/reducers';
import { mainClient } from '~app/network';
import { generateUUID } from '~app/utils/helpers';

export const changeLanguage = (lang) => reducers.common.setCurrentLanguage(lang);
export const openAuthModal = (tab, redirect = true, props = {}) => reducers.common.setAuthModalState({ tab, redirect, props });
export const closeAuthModal = () => reducers.common.setAuthModalState({ tab: null, redirect: null, props: {} });

export const openDealerAuthModal = (tab) => reducers.common.setDealerAuthModalState(tab);
export const closeDealerAuthModal = () => reducers.common.setDealerAuthModalState(null);

export const openGenericModal = (modal) => reducers.common.setGenericModal(modal);
export const closeGenericModal = () => reducers.common.resetGenericModal();

export const openModal = (data) => {
  const uuid = generateUUID();
  reducers.common.createModal({
    uuid,
    isOpen: true,
    ...data
  });
  return uuid;
};

export const closeModal = (uuid) => {
  reducers.common.updateModal({
    uuid,
    isOpen: false,
  });
  setTimeout(() => {
    reducers.common.removeModal({ uuid });
  }, 500);
};


export const sendSupportMessage = async (params) => {
  reducers.common.supportMessageRequest();
  const { data, error } = await mainClient.common.createSupportMessage(params);

  if (error !== null) {
    reducers.common.supportMessageFailure(error);
    return;
  }

  reducers.common.supportMessageSuccess(data);
};


export const subscribe = async (params) => {
  reducers.common.subscribeRequest();
  const { data, error } = await mainClient.common.subscribe(params);

  if (error !== null) {
    reducers.common.subscribeFailure(error);
    return;
  }

  reducers.common.subscribeSuccess(data);
};

