import React from 'react';
import { Icon } from '~app/components/ui';

const options = [
  {
    id: 'credit',
    name: 'Автокредит у дилера',
    icon: <Icon name={'optionCredit'} />
  },
  {
    id: 'insurance',
    name: 'КАСКО / ОСАГО у дилера',
    icon: <Icon name={'optionInsuranse'} />
  },
  {
    id: 'subsidy',
    name: 'Государственная субсидия',
    icon: <Icon name={'optionSubsidy'} />
  },
  {
    id: 'gibdd',
    name: 'Постановка на учет в ГАИ',
    icon: <Icon name={'optionGibdd'} />
  },
  {
    id: 'guaranty',
    name: 'Продление гарантии',
    icon: <Icon name={'optionGuaranty'} />
  },
  {
    id: 'helpOnRoads',
    name: 'Услуга «помощь на дорогах»',
    icon: <Icon name={'optionHelpOnRoads'} />
  }
];

export let schema = {};

for (let value of options) {
  schema[value.id] = {
    defaultValue: false,
    validation: {
      type: 'boolean',
    }
  };
}

export default options;
