import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectStyles } from '~app/theme';
import { formContainer, formCreate } from 'deforms';
import { push } from 'connected-react-router-exposed';
import cn from 'classnames';

import actions from '~app/redux/actions';
import { Button, TextInput, Checkbox, Icon, Spacer } from '~app/components/ui';

import styles from '../../DealerAuthModalStyles';

const mapStateToProps = (state) => ({
  loginResult: state.auth.dealerLoginResult,
  loginInProgress: state.auth.dealerLoginInProgress,
  loginError: state.auth.dealerLoginError,

});

const mapDispatchToProps = {
  push
};

@connect(mapStateToProps, mapDispatchToProps)
@injectStyles(styles)
@formContainer
class DealerAuthLogin extends Component {

  @formCreate()
  formLogin = {
    schema: {
      email: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
          minlength: 5
        }
      },
      password: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
          minlength: 5
        }
      },
      save: {
        defaultValue: true,
        validation: {
          type: 'boolean',
        }
      },
    },
    onSubmit: (formData) => {
      actions.auth.dealerLogin(formData);
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.loginResult !== null && nextProps.loginResult !== this.props.loginResult) {
      actions.auth.setAuthorized(nextProps.loginResult);
      this.onSuccess();
    }
  }

  onSuccess = () => {
    this.props.onSuccess();
  }

  renderLogin = () => {
    const formLogin = this.formLogin;
    const {
      classes,
      loginError,
    } = this.props;

    if (loginError === null) {
      return (<div className={classes.content}>
        <div className={classes.title}>
          Вход в кабинет дилера
        </div>
        <form noValidate className={classes.form}
          onSubmit={formLogin.submit}
        >
          <div className={classes.formInput}>
            <TextInput 
              name={'email'}
              type={'email'}
              placeholder={'Email'}
              onChange={formLogin.fields.email.change}
              value={formLogin.fields.email.value}
              error={formLogin.submitted && !formLogin.fields.email.valid}
            />
          </div>
          <div className={classes.formInput}>
            <TextInput 
              name={'password'}
              type={'password'}
              placeholder={'Пароль'}
              onChange={formLogin.fields.password.change}
              value={formLogin.fields.password.value}
              error={formLogin.submitted && !formLogin.fields.password.valid}
            />
          </div>
          <Spacer v={12}/>
          <div className={classes.formInput}>
            <Checkbox
              name={'agreement'}
              label={'Запомнить меня'}
              checked={formLogin.fields.save.value}
              onChangeValue={formLogin.fields.save.changeValue}
            />
          </div>
          <div className={classes.formAction}>
            <Button
              mobileBlock
              theme={'brand'}
              text={'Войти'}
              leftIcon={<Icon name={'enter'}/>}
            />
          </div>
        </form>
      </div>);
    }
    if (loginError !== null) {
      return (
        <div className={classes.content}>
          <div className={classes.text}>
            Ошибка!
          </div>
          <div className={classes.text}>
            {loginError.message}
          </div>
          <div className={classes.nav}
            onClick={actions.auth.dealerLoginReset}>
            <span className={cn(classes.navIcon, classes.navIconChange)}>
              <Icon name={'arrowRight'}/>
            </span>
            <span className={classes.navButton}>Вернуться</span>
          </div>
        </div>);
    }
  }

  

  render() {
    const formLogin = this.formLogin;
    const {
      classes,
      loginError,
    } = this.props;

    if (loginError === null) {
      return (<div className={classes.content}>
        <div className={classes.title}>
          Вход в кабинет дилера
        </div>
        <form noValidate className={classes.form}
          onSubmit={formLogin.submit}
        >
          <div className={classes.formInput}>
            <TextInput 
              name={'email'}
              type={'email'}
              placeholder={'Email'}
              onChange={formLogin.fields.email.change}
              value={formLogin.fields.email.value}
              error={formLogin.submitted && !formLogin.fields.email.valid}
            />
          </div>
          <div className={classes.formInput}>
            <TextInput 
              name={'password'}
              type={'password'}
              placeholder={'Пароль'}
              onChange={formLogin.fields.password.change}
              value={formLogin.fields.password.value}
              error={formLogin.submitted && !formLogin.fields.password.valid}
            />
          </div>
          <Spacer v={12}/>
          <div className={classes.formInput}>
            <Checkbox
              name={'agreement'}
              label={'Запомнить меня'}
              checked={formLogin.fields.save.value}
              onChangeValue={formLogin.fields.save.changeValue}
            />
          </div>
          <div className={classes.formAction}>
            <Button
              mobileBlock
              theme={'brand'}
              text={'Войти'}
              leftIcon={<Icon name={'enter'}/>}
            />
          </div>
        </form>
      </div>);
    }
    if (loginError !== null) {
      return (
        <div className={classes.content}>
          <div className={classes.text}>
            Ошибка!
          </div>
          <div className={classes.text}>
            {loginError.message}
          </div>
          <div className={classes.nav}
            onClick={actions.auth.dealerLoginReset}>
            <span className={cn(classes.navIcon, classes.navIconChange)}>
              <Icon name={'arrowRight'}/>
            </span>
            <span className={classes.navButton}>Вернуться</span>
          </div>
        </div>);
    }
  }
}

export default DealerAuthLogin;
