import sizes from './sizes';
import colors from './colors';
import queries from './queries';
import typography from './typography';
import styles from './styles';

const Theme = {
  sizes,
  colors,
  queries,
  typography,
  styles,
};

export default Theme;