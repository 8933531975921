import React, { Component } from 'react';
import cn from 'classnames';

import { PackageDetailsModal } from '~app/components';
import { Price, Hint } from '~app/components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './PackageStyles';


@injectStyles(styles)
class Package extends Component {

  state = {
    chosenPackage: null,
    modalOpened: false,
  }

  onChange = (name, id) => {
    this.setState({
      chosenPackage: id
    });
    this.props.onChangeOption(name);
  }

  detailsOnClick = name => () => {
    this.setState({
      modalOpened: name,
    });
  }

  closeModal = () => {
    this.setState({
      modalOpened: null,
    });
  }

  onOver = item => () => {
    this.setState({
      [`${item}_hover`]: true
    });
  }

  onLeave = item => () => {
    this.setState({
      [`${item}_hover`]: false
    });
  }

  renderItem = (item) => {
    const { classes } = this.props;
    const isChosen = this.state.chosenPackage !== null && this.state.chosenPackage == item.id;

    return (
      <div
        className={classes.row}
        key={item.id}
      >
        <div className={classes.rowMain}>
          <div className={classes.rowMainLink} onClick={() => this.onChange(item.slug, item.id)}/>
          <div className={cn(classes.cell, classes.cellRadio)}>
            <div className={cn(classes.radio, isChosen ? classes.radioActive : null)}></div>
          </div>
          <div className={classes.rowMainContent}>
            <div
              className={cn(classes.cell, classes.cellName, classes.relative)}>
              <span>{item.name}</span>
            </div>
            <div className={classes.rowMainContentBody}>
              <div className={cn(classes.cell, classes.cellEngine)}>
                <div className={classes.cellTitle}>{'Мотор:'}</div>
                {item.engineType}
              </div>
              <div className={cn(classes.cell, classes.cellHP)}>
                <div className={classes.cellTitle}>{'Л.с.:'}</div>
                {item.hp}
              </div>
              <div className={cn(classes.cell, classes.cellDrive)}>
                <div className={classes.cellTitle}>{'Привод:'}</div>
                {item.drive}
              </div>
              <div className={cn(classes.cell, classes.cellGear)}>
                <div className={classes.cellTitle}>{'Коробка:'}</div>
                {item.gear}
              </div>
              <div className={cn(classes.cell, classes.cellPrice)}>
                <div
                  onMouseOver={this.onOver(`header_${item.id}`)}
                  onMouseLeave={this.onLeave(`header_${item.id}`)}
                  className={cn(classes.cellTitle, classes.relative)}>
                  <span>{'Средняя цена*:'}</span>
                  <Hint isActive={this.state[`header_${item.id}_hover`]}>
                    {'Приведена ориентировочная стоимость автомобиля с выбранным набором опций'}
                  </Hint>
                </div>
                {item.price && <Price price={item.price} />}
              </div>
            </div>
          </div>
        </div>
        <div
          className={cn(classes.cell, classes.cellDetails)}
          onClick={this.detailsOnClick(item.slug)}
          onMouseOver={this.onOver(item.id)}
          onMouseLeave={this.onLeave(item.id)}
        >
          {'Детально >'} {'*'}
          <div className={classes.deskContent}>
            <Hint direction={'left'} place={'applicationTable'} isActive={this.state[`${item.id}_hover`]}>
              {'Ассортимент опций и цены могут быть другими. Некоторые типы дополнительного оборудования могут поставляться в пакете или же нет. Пожалуйста, уточняйте информацию у своего дилера при получении предложения в нашем сервисе'}
            </Hint>
          </div>
        </div>

        <PackageDetailsModal
          isOpen={this.state.modalOpened === item.slug}
          onRequestClose={this.closeModal}
          item={item}
        />

      </div>
    );
  }

  render() {
    const {
      classes,
      packages
    } = this.props;
    return (
      <div className={classes.list}>
        <div className={cn(classes.row, classes.rowHead)}>
          <div className={classes.rowMain}>
            <div className={cn(classes.cell, classes.cellRadio)} />
            <div className={classes.rowMainContent}>
              <div className={cn(classes.cell, classes.cellName)}>{'Название:'}</div>
              <div className={classes.rowMainContentBody}>
                <div className={cn(classes.cell, classes.cellEngine)}>{'Мотор:'}</div>
                <div className={cn(classes.cell, classes.cellHP)}>{'Л.с.:'}</div>
                <div className={cn(classes.cell, classes.cellDrive)}>{'Привод:'}</div>
                <div className={cn(classes.cell, classes.cellGear)}>{'Коробка:'}</div>
                <div
                  onMouseOver={this.onOver('header')}
                  onMouseLeave={this.onLeave('header')}
                  className={cn(classes.cell, classes.cellPrice, classes.relative)}>
                  {'Средняя цена*:'}
                  <Hint isActive={this.state.header_hover}>
                    {'Приведена ориентировочная стоимость автомобиля с выбранным набором опций'}
                  </Hint>
                </div>
              </div>
            </div>
          </div>
          <div className={cn(classes.cell, classes.cellDetails)}></div>
        </div>
        {packages !== null && packages.map((item) => {
          return (this.renderItem(item));
        })}
      </div>
    );
  }
}

export default Package;
