import React, { Component, Fragment } from 'react';
import actions from '~app/redux/actions';
import { injectStyles } from '~app/theme';
import moment from 'moment';

import { Card, CardContent, Icon, Button, Spacer } from '~app/components/ui';

import styles from './MessageCardStyles';

@injectStyles(styles)
class MessageCard extends Component {

  addReviewOnClick = () => {
    actions.common.openModal({
      type: 'createReview',
      params: {
        offerId: this.props.offer !== null ? this.props.offer.id : null,
        onSuccess: () => {
          if (this.props.onReviewCreated) {
            this.props.onReviewCreated();
          }
        }
      }
    });
  }
  sendMessageOnClick = () => {
    actions.common.openModal({
      type: 'createMessage',
      params: {
        asDealer: !!this.props.asDealer,
        offerId: this.props.offer !== null ? this.props.offer.id : null
      }
    });
  }
  supportOnClick = () => {
    actions.common.openModal({
      type: 'support',
      params: {
        offerId: this.props.offer !== null ? this.props.offer.id : null
      }
    });
  }

  render() {
    const {
      classes,
      item,
      offer,
      asDealer,
    } = this.props;

    return (
      <div className={classes.root}>
        <Card
          leftTick
          color={'#eef9f8'}
        >
          <CardContent>
            <div className={classes.content}>
              <div className={classes.name}>{item.name}</div>
              <div className={classes.text}>{item.text}</div>
              <div className={classes.date}>
                <div className={classes.dateIcon}>
                  <Icon name={'clock'} />
                </div>
                <div className={classes.dateText}>{moment(item.date).format('DD.MM.YY в HH:mm')}</div>
              </div>
            </div>
          </CardContent>
        </Card>
        {asDealer ?
          <div className={classes.buttonWrapper}>
            <div className={classes.button}>
              <Button
                theme={'general'}
                text={'Написать пользователю'}
                block
                onClick={this.sendMessageOnClick}
                mobileBlock
              />
            </div>
            <Spacer h={12} v={12} />
            <div className={classes.button}>
              <Button
                theme={'brand'}
                text={'Поддержка'}
                block
                onClick={this.supportOnClick}
                mobileBlock
              />
            </div>
          </div>
          :
          <div className={classes.buttonWrapper}>
            <div className={classes.button}>
              <Button
                theme={'general'}
                text={'Написать дилеру'}
                block
                onClick={this.sendMessageOnClick}
                mobileBlock
              />
            </div>
            {offer !== null && offer.allowReview &&
              <Fragment>
                <Spacer h={12} v={12} />
                <div className={classes.button}>
                  <Button
                    theme={'brand'}
                    text={'Оставить отзыв'}
                    block
                    onClick={this.addReviewOnClick}
                    mobileBlock
                  />
                </div>
              </Fragment>
            }
          </div>
        }
      </div>
    );
  }
}

export default MessageCard;
