import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
// import { I18nextProvider } from 'react-i18next';

import '@babel/polyfill';

// import i18n from '~app/core/i18n';
import store from '~app/core/store';
import Router from '~app/navigation/Router';

const serverStyles = document.querySelectorAll('[data-server-styles]');
if(Array.isArray(serverStyles)) {
  serverStyles.forEach(item => item.remove());
}


hydrate(
  <Provider store={store}>
    <Router />
  </Provider>,
  document.getElementById('root')
);
