import client from './_client';
import { PromoListModel, PromoItemModel } from './models';

// Get promos
export const getPromo = (params = {}) => {
  let {
    limit = 8,
    page = 1,
    id,
    ...otherParams
  } = params;

  return client.request({
    url: id ? `v1/actions/${id}` : 'v1/actions',
    method: 'GET',
    params: {
      limit,
      page,
      ...otherParams
    },
    transformData: data => new PromoListModel({
      items: data.data,
      paging: {
        count: data.count || 0,
        page: page,
        limit: limit,
      }
    })
  });
};

export const getPromoItem = ({ id }) => {
  return client.request({
    url: `/v1/action/${id}`,
    method: 'GET',
    transformData: data => new PromoItemModel(data),
  });
};
