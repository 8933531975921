export const styles = theme => ({
  root: {
    position: 'relative',

    color: theme.colors.gray.dark,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold'
  }
});