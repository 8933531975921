export default theme => ({
  modal: {
    display: 'flex',
    width: 'calc(100% - 80px)',

    position: 'relative',
    margin: 'auto',
    padding: '30px 10px 35px 50px',

    backgroundColor: '#ffffff',

    outline: 'none',
    boxShadow: '0 2px 4px 0 rgba(60,60,80,0.08)',
    borderRadius: 6,

    flexDirection: 'column',

    cursor: 'auto',
    opacity: 0,
    transform: 'scale(0.8)',

    transition: 'all 0.3s ease-in-out',
  },

  modalAfterOpen: {
    opacity: 1,
    transform: 'scale(1)',
  },
  modalBeforeClose: {
    opacity: 0,
    transform: 'scale(0.8)',
  },

  contentDisclaimer: {
    paddingBottom: '20px',
    fontSize: '12px',
  },

  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',

    backgroundColor: 'rgba(60,60,76,0.20)',

    outline: 'none',
    cursor: 'pointer',
    opacity: 0,

    transition: 'opacity 0.3s ease-in-out',
  },
  overlayAfterOpen: {
    opacity: 1,
  },
  overlayBeforeClose: {
    opacity: 0,
  },

  head: {
    display: 'flex',
    paddingBottom: 15,

    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headTitle: {
    width: 'calc(100% - 31px)',
    fontSize: 18,
  },
  close: {
    display: 'inline-flex',
    width: 50,
    height: 50,
    marginRight: -19,
    paddingRight: 40,

    fontSize: 24,

    transform: 'scale(1)',
    transformOrigin: 'center',
    transition: 'transform 0.3s',

    alignItems: 'center',
    justifyContent: 'center',

    cursor: 'pointer',

    '&:hover': {
      transform: 'scale(1.2)',
    },

    '&:before': {
      content: '"×"'
    },
  },

  content: {
    position: 'relative',
    overflowY: 'scroll',
  },

  footer: {
    display: 'flex',
    position: 'absolute',
    top: '100%',
    right: 0,
  },

  footerItem: {
    marginTop: 12,
    marginLeft: 15
  },

  [theme.queries.mdDevicesDown]: {
    modal: {
      width: 'calc(100% - 22px)',
      height: 'calc(100% - 22px)',

      padding: '25px 5px 25px 20px',
    },

    editable: {
      height: 'calc(100% - 91px)',
      transform: 'translateY(-34px)',
    },

    content: {
      height: '100%',
      paddingRight: 15,
    },

    head: {
      paddingRight: 15,
    },
    headTitle: {
      fontSize: 14,
    },
    close: {
      width: 40,
      height: 40,
      marginRight: -15,
      paddingRight: 0,

      fontSize: 28,
    },

    contentDesk: {
      display: 'none',
    },
    contentMobile: {
      display: 'block',
    },

    footer: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
    },

  },

  [theme.queries.lgDevicesUp]: {
    modal: {
      maxWidth: `calc(${theme.sizes.containerWidth}px - 2*${theme.sizes.containerPaddingH}px)`,
      minHeight: 300,
      height: 720,
      maxHeight: 'calc(100% - 80px)',
    },

    editable: {
      height: 660,
      maxHeight: 'calc(100% - 140px)',
      transform: 'translateY(-30px)',
    },

    content: {
      maxHeight: 600,
      paddingRight: 40,
    },
    contentDesk: {
      display: 'block',
    },
    contentMobile: {
      display: 'none',
    },
    footer: {
      justifyContent: 'flex-end'
    }
  },
});
