import ErrorModel from './ErrorModel';
import PagingModel from './PagingModel';

import UserBaseModel from './User/UserBaseModel';
import UserMeModel from './User/UserMeModel';

import BrandLanderModel from './Lander/BrandLanderModel';
import ModelLanderModel from './Lander/ModelLanderModel';
import LanderContentModel from './Lander/LanderContentModel';
import PreFooterModel from './Lander/PreFooterModel';

import BrandBaseModel from './Brand/BrandBaseModel';
import ModelBaseModel from './Model/ModelBaseModel';
import ModelShortModel from './Model/ModelShortModel';
import ColorBaseModel from './Color/ColorBaseModel';
import DetailBaseModel from './Detail/DetailBaseModel';
import DetailsGroupModel from './Detail/DetailsGroupModel';
import PackageBaseModel from './Package/PackageBaseModel';
import DeliveryOptionBaseModel from './DeliveryOption/DeliveryOptionBaseModel';

import ApplicationListModel from './Application/ApplicationListModel';
import ApplicationBaseModel from './Application/ApplicationBaseModel';
import ApplicationTradeInBaseModel from './Application/ApplicationTradeInBaseModel';

import OfferBaseModel from './Offer/OfferBaseModel';
import OfferListModel from './Offer/OfferListModel';

import ReviewBaseModel from './Review/ReviewBaseModel';
import ReviewListModel from './Review/ReviewListModel';

import DealBaseModel from './Deal/DealBaseModel';
import DealListModel from './Deal/DealListModel';

import PartnerBaseModel from './Partners/PartnerBaseModel';

import NewsListModel from './News/NewsListModel';
import NewsItemModel from './News/NewsItemModel';

import PromoListModel from './Promo/PromoListModel';
import PromoItemModel from './Promo/PromoItemModel';

import DealerModel from './Dealers/DealerModel';

import BannerModel from './Banners/BannerModel';

export {
  ErrorModel,
  PagingModel,
  UserBaseModel,
  UserMeModel,
  LanderContentModel,
  PreFooterModel,
  BrandLanderModel,
  ModelLanderModel,
  BrandBaseModel,
  ModelBaseModel,
  ModelShortModel,
  ColorBaseModel,
  DetailBaseModel,
  DetailsGroupModel,
  PackageBaseModel,
  DeliveryOptionBaseModel,
  ApplicationListModel,
  ApplicationBaseModel,
  ApplicationTradeInBaseModel,
  OfferBaseModel,
  OfferListModel,
  ReviewBaseModel,
  ReviewListModel,
  DealBaseModel,
  DealListModel,
  PartnerBaseModel,
  NewsListModel,
  NewsItemModel,
  PromoListModel,
  PromoItemModel,
  DealerModel,
  BannerModel,
};

export default {};
