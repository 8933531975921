export default theme => ({
  root: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#a0aab4',
    padding: '12px 0 20px',
    marginTop: -40
  },

  [theme.queries.lgDevicesUp]: {
    root: {
      display: 'none'
    }
  }
});
