export const styles = theme => ({
  list: {
    padding: '20px 0',
    display: 'flex',
    flexWrap: 'wrap',
  },
  option: {
    padding: '10px',

    borderRadius: '6px',
    border: `1px solid ${theme.colors.gray.lighter}`,
    boxShadow: '0 2px 1px 0 rgba(60, 60, 60, .04)',

    fontSize: '14px',
    textAlign: 'center',

    color: theme.colors.gray.darker,

    transition: 'border-color .5s ease, background-color .5s ease',

    alignItems: 'center',
    justifyContent: 'center',

    cursor: 'pointer',

    '&:hover': {
      borderColor: theme.colors.brand.main,
    }
  },
  optionActive: {
    backgroundColor: theme.colors.brand.lightest,

    '& $optionIcon': {
      color: theme.colors.brand.main,
    }
  },

  formInputCheckbox: {
    marginTop: 10,
  },

  optionIcon: {
    width: '24px',
    fontSize: '24px',
    margin: '0 auto',

    color: theme.colors.gray.lighter,

    transition: 'color .5s ease',
  },
  optionName: {
    paddingTop: '12px',

    lineHeight: '20px',
  },

  tradeIn: {
    
  },
  tradeInTitle: {
    display: 'inline-flex',
    padding: '15px 0',

    fontSize: '14px',
    fontWeight: '500',

    color: theme.colors.brand.main,

    alignItmes: 'center',

    cursor: 'pointer',
  },
  tradeInTitleIcon: {
    width: '16px',
  },


  [theme.queries.mdDevicesDown]: {
    head: {
      fontSize: '12px',
      lineHeight: '20px',
    },

    option: {
      display: 'flex',
      height: '88px',
      marginBottom: '8px',
      width: '100%',
    },

    credit: {
      order: 1,
    },

    formInput: {
      padding: '4px 0',
    },

    formAdditional: {
      paddingTop: '18px',
      paddingBottom: '9px',
    },
    formAdditionalTitle: {
      paddingBottom: '18px',

      fontSize: '12px',
      lineHeight: '20px',
    }
  },

  [theme.queries.lgDevicesUp]: {
    head: {
      fontSize: '14px',
    },

    list: {
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
    },
    option: {
      display: 'inline-flex',
      width: 'calc(100%/6 - 10px*5/6)',
      height: '120px',
    },

    tradeInTitle: {
      padding: '15px 0',
    },

    form: {
      display: 'flex',
      paddingTop: '3px',

      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },

    formInput: {
      width: 'calc(50% - 5px)',
      padding: '6px 0',
    },
    formInputHalf: {
      width: 'calc(25% - 30px/4)',
    },
    formInputThird: {
      width: 'calc(100%/3)',
    },

    formAdditional: {
      paddingTop: '25px',
      paddingBottom: '25px',
    },
    formAdditionalTitle: {
      paddingBottom: '21px',

      fontSize: '14px',
    },
    formAdditionalInput: {
      maxWidth: '530px',
    }

  },
});