export default theme => ({
  root: {
    position: 'relative',
  },
  banner: {
    position: 'relative',

    minHeight: 360,
    padding: '40px 60px 56px',
    marginBottom: 40,
    borderRadius: 6,
    boxShadow: '0 2px 4px 0 rgba(60, 60, 80, 0.08)',
    backgroundImage: 'radial-gradient(circle at 68% 50%, #fadc3c, #fabe1e)',
  },
  title: {
    maxWidth: 610,
    margin: '0 0 17px',
    color: theme.colors.gray.dark,
    fontSize: '36px',
    fontWeight: 'bold',
    lineHeight: 1.33,
  },
  descr: {
    maxWidth: 610,
    margin: '0 0 30px',
    color: theme.colors.gray.dark,
    fontSize: '14px',
    lineHeight: 2,
  },
  button: {
    width: 184,
  },
  [theme.queries.lgDevicesUp]: {
    hideDesk: {
      display: 'none',
    },
    title: {
      width: 'calc(100% - 280px)'
    },
    descr: {
      width: 'calc(100% - 280px)'
    },
    infoCard: {
      display: 'flex',
      width: '260px',
      position: 'absolute',
      top: '50px',
      right: '60px',
      padding: '20px 30px 30px',
      boxShadow: '0 6px 20px 0 rgba(20, 100, 220, 0.09)',
      borderRadius: '6px',
      
      backgroundColor: '#fff',

      flexDirection: 'column',
      alignItems: 'center',
    },
    logo: {
      maxHeight: '73px',
      maxWidth: '100%',
      marginBottom: '15px',
    },
    phone: {
      fontSize: '14px',
      lineHeight: '28px',
    },
    siteUrl: {
      fontSize: '14px',
      lineHeight: '20px',
      textDecoration: 'underline',
    },
  },
  [theme.queries.mdDevicesDown]: {
    backLink: {
      display: 'flex',
      alignItems: 'center',
    },
    backLinkIcon: {
      width: '10px',
      transform: 'rotate(180deg)',
    },
    backLinkText: {
      paddingLeft: '6px',
      fontSize: '10px',
      lineHeight: '20px',
    },
    title: {
      paddingTop: '20px',
    },

    phone: {
      display: 'flex',
      alignItems: 'center',
    },
    phoneIcon: {
      width: '15px',
    },
    phoneText: {
      paddingLeft: '5px',

      fontSize: '14px',
      lineHeight: '28px',
    },

    banner: {
      padding: '25px 20px 160px',
    },
    button: {
      position: 'absolute',
      bottom: 74,
      left: 20,
      zIndex: 3,

      maxWidth: 320,
      width: 'calc(100% - 40px)',
    },
    siteUrl: {
      display: 'block',
      width: '100%',
      position: 'absolute',
      bottom: 30,
      left: 20,

      fontSize: '14px',
      lineHeight: '20px',
      textDecoration: 'underline',
    },
    descr: {
      marginBottom: 20,
    },
    logo: {
      height: '40px',
      marginBottom: '20px',
    },
    fullDescr: {
      paddingBottom: '40px',
      fontSize: '14px',
      lineHeight: '28px',
    },

    hideMob: {
      display: 'none',
    },
  },
  [theme.queries.smDevicesDown]: {
    banner: {
      minHeight: 'calc(100vh - 100px)',
      padding: '26px 20px 160px',
    },
    title: {
      maxWidth: 260,
      marginBottom: 7,
      fontSize: '24px',
      lineHeight: 1.5,
    },
    button: {
      left: '50%',

      transform: 'translateX(-50%)',
    },
    siteUrl: {
      left: 0,
      textAlign: 'center',
    },

  },
  [theme.queries.xsDevicesDown]: {
  },
});