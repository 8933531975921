export default (theme) => ({
  root: {
    backgroundColor: '#14dc64',
    padding: '6px 10px 7px 5px',
    borderRadius: 4,
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 500,
    color: '#ffffff',
    flexShrink: 0,
    position: 'relative',
    '& span': {
      position: 'absolute',
      top: -2,
      left: -13,
      width: 17,
      height: 36,
      '& svg': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }
    }
  },
  absolute: {
    position: 'absolute',
    top: 10,
    right: 0,
    zIndex: 1
  },
  [theme.queries.mdDevicesDown]: {
    root: {
      fontSize: 12,
    },
  },
});
