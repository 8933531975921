import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { injectStyles } from '~app/theme';

import { DealerRating, ReviewCard } from '~app/components';
import { Icon } from '~app/components/ui';
import { freezeScroll } from '../../utils/helpers';

import styles from './ReviewsModalStyles';
import actions from '~app/redux/actions';

const mapStateToProps = (state, { dealerId }) => {
  const reduxKey = `ReviewsModal_${dealerId}`;

  let reviewsState = {
    items: null,
    paging: null,
    inProgress: null,
    error: null,
  };
  if (reduxKey in state.reviews.itemsByKey) {
    reviewsState = state.reviews.itemsByKey[reduxKey];
  }

  return {
    reduxKey,
    ...reviewsState
  };
};

@connect(mapStateToProps)
@injectStyles(styles)
class ReviewsModal extends Component {

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { dealerId } = this.props;
    actions.reviews.getItemsByKey(this.props.reduxKey, {
      dealerId
    });
  }

  onRequestClose = () => {
    this.props.onRequestClose();
    freezeScroll(true);
  }
  onOpen = () => {
    freezeScroll();
  }

  render() {
    const {
      classes,
      dealer,
      isOpen = true,
      items,
    } = this.props;

    return (
      <ReactModal
        key={dealer.id}
        isOpen={isOpen}
        onAfterOpen={this.onOpen}
        className={{
          base: classes.modal,
          afterOpen: classes.modalAfterOpen,
          beforeClose: classes.modalBeforeClose,
        }}
        overlayClassName={{
          base: classes.overlay,
          afterOpen: classes.overlayAfterOpen,
          beforeClose: classes.overlayBeforeClose,
        }}
        closeTimeoutMS={300}
        contentLabel={'title'}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick
      >
        <div className={classes.head}>
          <div className={classes.headTitle}>
            <DealerRating userDealer={dealer} />
          </div>
          <a className={classes.close} onClick={this.onRequestClose}>
            <Icon name={'times'} />
          </a>
        </div>

        <div className={classes.content}>
          {items !== null && items.map(item => (
            <div key={item.id} className={classes.reviewItem}>
              <ReviewCard item={item} showAuthorName />
            </div>
          ))}
        </div>
      </ReactModal>
    );
  }
}

export default ReviewsModal;
