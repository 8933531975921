import queries from '../queries';

export default {
  modal: {
    position: 'relative',

    width: 'calc(100% - 24px)',
    maxWidth: 430,
    // margin: 'auto',

    backgroundColor: '#ffffff',

    outline: 'none',
    boxShadow: '0 2px 4px 0 rgba(60,60,80,0.08)',
    borderRadius: '6px',
    cursor: 'auto',
    opacity: 0,
    transform: 'scale(0.8)',

    transition: 'all 0.3s ease-in-out',
  },

  modalAfterOpen: {
    opacity: 1,
    transform: 'scale(1)',
  },
  modalBeforeClose: {
    opacity: 0,
    transform: 'scale(0.8)',
  },

  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2000,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',

    backgroundColor: 'rgba(60,60,76,0.20)',

    outline: 'none',
    cursor: 'pointer',
    opacity: 0,

    transition: 'opacity 0.3s ease-in-out',
  },

  overlayAfterOpen: {
    opacity: 1,
  },
  overlayBeforeClose: {
    opacity: 0,
  },

  close: {
    position: 'absolute',
    top: '-37px',
    right: '7px',

    display: 'block',

    fontSize: '36px',
    fontWeight: '200',
    color: '#282828',

    cursor: 'pointer',

    transition: 'color 0.3s',

    '&:hover': {
      color: '#1464DC',
    },

    '&:before': {
      content: '"×"'
    },
  },


  [queries.smDevicesDown]: {
    overlay: {
      padding: '35px 11px 11px',
      alignItems: 'flex-start'
    },
    modal: {
      marginTop: 'auto',
      marginBottom: 'auto'
    },
    content: {
      padding: '30px 20px',
    },
  },
};
