import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectStyles } from '~app/theme';
import { formContainer, formCreate } from 'deforms';
import actions from '~app/redux/actions';

import { Select, TextInput, Button, /* Hint, */ Icon, HintHoverContainer } from '~app/components/ui';

import cn from 'classnames';

import { PackageDetailsModal } from '~app/components';

import styles from './OfferFormStyles';

const mapStateToProps = (state, ownProps) => {
  const {
    reduxKey = `offerForm_${ownProps.application.id}`,
    options
  } = ownProps;

  let {
    // price = null,
    deliveryOption = null,
    color = null,
    // package = null, // reserved
  } = options;
  let { model } = ownProps;

  let colorsState = null;
  if (model.id in state.dictionary.colorsByModel) {
    colorsState = state.dictionary.colorsByModel[model.id];
  }

  let packagesState = null;
  if (model.id in state.dictionary.packagesByModel) {
    packagesState = state.dictionary.packagesByModel[model.id];
  }

  let createState = {
    result: null,
    inProgress: false,
    error: null
  };
  if (reduxKey in state.offers.createByKey) {
    createState = state.offers.createByKey[reduxKey];
  }

  return {
    reduxKey,
    options: {
      deliveryOption,
      color,
      package: ownProps.options.package,
    },
    model: model,

    deliveryOptions: state.dictionary.deliveryOptions,
    deliveryOptionsInProgress: state.dictionary.deliveryOptionsInProgress,
    deliveryOptionsError: state.dictionary.deliveryOptionsError,

    colors: colorsState === null ? null : colorsState.items,
    colorsInProgress: packagesState === null ? false : colorsState.inProgress,
    colorsError: packagesState === null ? null : colorsState.error,

    packages: packagesState === null ? null : packagesState.items,
    packagesInProgress: packagesState === null ? false : packagesState.inProgress,
    packagesError: packagesState === null ? null : packagesState.error,

    createSuccess: createState.result !== null,
    createInProgress: createState.inProgress,
    createError: createState.error
  };
};

@connect(mapStateToProps)
@injectStyles(styles)
@formContainer

class OfferForm extends Component {
  state = {
    modalOpened: false,
    packageHovered: false,
    customPackage: {},
    customPackageFilled: false
  };

  @formCreate()
  formOffer = {
    schema: {
      price: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
        }
      },
      deliveryOption: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
        }
      },
      color: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
        }
      },
      package: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
        }
      }
    },
    onSubmit: (formData) => {
      this.onSubmit(formData);
    }
  }

  componentDidMount = () => {
    this.loadData();
    this.preselectForm();
  }

  componentWillReceiveProps = (nextProps) => {
    if (!this.props.createSuccess && nextProps.createSuccess) {
      this.onSuccess();
    } else if (this.props.createError === null && nextProps.createError !== null) {
      this.onError(nextProps.createError);
    }
  }

  preselectForm = (props = this.props) => {
    const options = props.options;
    let optionsKeys = Object.keys(props.options);

    for (var i = 0; i < optionsKeys.length; i++) {
      const optionName = optionsKeys[i];
      const option = options[optionName];

      if (option !== null && option !== undefined) {
        this.formOffer.fields[optionName].changeValue(option.id);
      }
    }
  }

  onSubmit = (formData) => {
    const price = parseInt(formData.price);
    const deliveryOptionId = formData.deliveryOption;
    const colorId = formData.color !== '' ? formData.color : undefined;
    const packageId = formData.package !== '' ? formData.package : undefined;

    const actionData = {
      applicationId: this.props.application.id,
      price,
      deliveryOptionId,
      colorId,
      packageId
    };

    let customPackage = {};

    if (this.state.customPackageFilled) {
      for (const key in this.state.customPackage) {
        if (this.state.customPackage[key] != undefined) {
          customPackage[key] = this.state.customPackage[key];
        }
      }
      actionData.customPackage = JSON.stringify(customPackage);
    }

    actions.offers.createByKey(this.props.reduxKey, actionData);
  }

  onSuccess = () => {
    if (this.props.onSuccess) {
      this.props.onSuccess();
    }
    actions.offers.resetCreateByKey(this.props.reduxKey);
  }

  onError(error) {
    actions.common.openModal({
      type: 'generic',
      params: {
        theme: 'rare',
        icon: 'timesCircle',
        title: 'Ошибка!',
        text: error.message || 'Оффер не создан.',
      }
    });
  }


  loadData = (props = this.props) => {
    if (props.deliveryOption !== null && props.deliveryOptions == null && !props.deliveryOptionsInProgress && props.deliveryOptionsError === null) {
      actions.dictionary.getDeliveryOptions();
    }
    if (props.colors == null && !props.colorsInProgress && props.colorsError === null) {
      actions.dictionary.getColorsByModel(props.model.id);
    }
    if (props.packages == null && !props.packagesInProgress && props.packagesError === null) {
      actions.dictionary.getPackagesByModel(props.model.id);
    }
  }

  closeModal = () => {
    this.setState({
      modalOpened: false,
    });
  }

  openModal = () => {
    this.setState({
      modalOpened: true,
    });
  }

  onSaveCustomPackage = (customPackage) => {
    let filled = false;
    if (customPackage) {
      for (const key in customPackage) {
        if (customPackage[key] !== undefined) {
          filled = true;
        }
      }
    }
    this.setState({
      customPackage,
      customPackageFilled: filled
    });
  }

  render() {
    const {
      classes,
      deliveryOptions,
      colors,
      packages,
      isOffered
    } = this.props;

    const { customPackageFilled } = this.state;

    const formOffer = this.formOffer;

    const currentPackage = packages != null ?
      packages.filter(item => item.id == formOffer.fields.package.value)[0]
      :
      null;
    return (
      <div className={classes.root}>
        <div className={classes.selects}>
          <form noValidate
            className={classes.form}
            onSubmit={formOffer.submit}
          >
            <div className={classes.select}>
              <TextInput
                name={'price'}
                type={'price'}
                placeholder={'Цена'}
                onChangeValue={formOffer.fields.price.changeValue}
                value={formOffer.fields.price.value}
                error={formOffer.submitted && !formOffer.fields.price.valid}
              />
            </div>
            <div className={classes.select}>
              <Select
                options={deliveryOptions || []}
                placeholder={'Наличие'}
                onChange={formOffer.fields.deliveryOption.changeValue}
                value={formOffer.fields.deliveryOption.value}
                valueField={'id'}
                textField={'name'}
                error={formOffer.submitted && !formOffer.fields.deliveryOption.valid}
              />
            </div>
            <div className={classes.select}>
              <Select
                options={colors || []}
                placeholder={'Цвет'}
                onChange={formOffer.fields.color.changeValue}
                value={formOffer.fields.color.value}
                valueField={'id'}
                textField={'name'}
                error={formOffer.submitted && !formOffer.fields.color.valid}
              />
            </div>
            <div className={classes.select}>
              {formOffer.fields.package.value != '' && customPackageFilled &&
                <div
                  onClick={this.openModal}
                  className={cn(classes.gearIcon, classes.enabled,
                    // {
                    //   [classes.disabled]: !customPackageFilled,
                    //   [classes.enabled]: customPackageFilled,
                    // }
                  )}>
                  <Icon name={'gear'} />
                </div>
              }
              <Select
                withPadding={formOffer.fields.package.value != '' && customPackageFilled}
                options={packages || []}
                placeholder={'Конфигурация'}
                onChange={formOffer.fields.package.changeValue}
                value={formOffer.fields.package.value}
                valueField={'id'}
                textField={'name'}
                error={formOffer.submitted && !formOffer.fields.package.valid}
              />
              <HintHoverContainer
                direction={'top'}
                html={'Самый важный элемент помимо цены - Расширяйте нестандартную&nbsp;комплектацию, предлагайте подарки и описывайте преимущества своего предложения - все как в автосалоне при визите!'}>
                <a className={classes.changeLink} onClick={this.openModal}>
                  {!customPackageFilled ? 'Улучшить комплектацию >' : 'Изменить комплектацию >'}
                </a>
              </HintHoverContainer>
            </div>
          </form>
        </div>
        <div className={classes.bottom}>
          <Button
            theme={'general'}
            text={isOffered !== null ? 'Сделать еще один оффер' : 'Сделать оффер'}
            onClick={formOffer.submit}
            mobileBlock
          />
        </div>
        {currentPackage !== null &&
          <PackageDetailsModal
            isOpen={this.state.modalOpened}
            onRequestClose={this.closeModal}
            onSaveCustomPackage={this.onSaveCustomPackage}
            customPackage={this.state.customPackage}
            readonly={false}
            item={currentPackage}
          />
        }

      </div>
    );
  }
}

export default OfferForm;
