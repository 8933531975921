import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { injectStyles } from '~app/theme';
import styles from './BreadcrumbsStyles';

@injectStyles(styles)
class Breadcrumbs extends Component {

  render = () => {
    const {
      classes,
      options,
    } = this.props;

    return (
      <div className={classes.root}>
        {options.map((item, index) => {
          return (
            <div key={index} className={classes.item}>
              {index > 0 && <div className={classes.itemDevider}>/</div>}
              {item.href ?
                <Link
                  to={item.href}
                  className={cn(classes.itemLink, !item.href ? classes.itemLinkEmpty : null)}
                >
                  {item.name}
                </Link>
                :
                <a className={cn(classes.itemLink, !item.href ? classes.itemLinkEmpty : null)}>{item.name}</a>
              }
            </div>
          );
        })}
      </div>
    );
  }
}

Breadcrumbs.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
};

Breadcrumbs.defaultProps = {
  options: [],
};

export default Breadcrumbs;