import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectStyles } from '~app/theme';
import { formContainer, formCreate } from 'deforms';
import { push } from 'connected-react-router-exposed';
import cn from 'classnames';

import actions from '~app/redux/actions';
import { Button, TextInput, Checkbox, Icon, Spacer } from '~app/components/ui';

import styles from '../../DealerAuthModalStyles';

const mapStateToProps = (state) => ({
  registrationResult: state.auth.dealerRegistrationResult,
  registrationInProgress: state.auth.dealerRegistrationInProgress,
  registrationError: state.auth.dealerRegistrationError,

});

const mapDispatchToProps = {
  push
};

@connect(mapStateToProps, mapDispatchToProps)
@injectStyles(styles)
@formContainer
class DealerAuthSignup extends Component {

  @formCreate()
  form = {
    schema: {
      name: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
        }
      },
      email: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
        }
      },
      phone: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
        }
      },
      agreement: {
        defaultValue: true,
        validation: {
          type: 'boolean',
        }
      },
    },
    onSubmit: (formData) => {
      actions.auth.dealerRegistration(formData);
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.registrationResult !== null && nextProps.registrationResult !== this.props.registrationResult) {
      this.onSuccess();
    }
  }

  onSuccess = () => {
    this.props.onSuccess();
  }

  render() {
    const form = this.form;
    const {
      classes,
      registrationError,
    } = this.props;

    if (registrationError === null) {
      return (<div className={classes.content}>
        <div className={cn(classes.title)}>
          Укажите контактные данные и мы вам перезвоним
        </div>
        <form noValidate 
          className={classes.form}
          onSubmit={form.submit}
        >
          <div className={classes.formInput}>
            <TextInput 
              name={'name'}
              type={'text'}
              placeholder={'Имя'}
              onChange={form.fields.name.change}
              value={form.fields.name.value}
              error={form.submitted && !form.fields.name.valid}
            />
          </div>
          <div className={classes.formInput}>
            <TextInput 
              name={'email'}
              type={'email'}
              placeholder={'Email'}
              onChange={form.fields.email.change}
              value={form.fields.email.value}
              error={form.submitted && !form.fields.email.valid}
            />
          </div>
          <div className={classes.formInput}>
            <TextInput 
              name={'phone'}
              type={'phone'}
              placeholder={'Телефон'}
              onChange={form.fields.phone.change}
              value={form.fields.phone.value}
              error={form.submitted && !form.fields.phone.valid}
              mask={'+7 999 999 99 99'}
            />
          </div>
          <Spacer v={12}/>
          <div className={classes.formInput}>
            <Checkbox
              name={'agreement'}
              checked={form.fields.agreement.value}
              label={'Я принимаю %LINK%'}
              labelStyles={classes.checkboxLabel}
              link={{
                placeholder: '%LINK%',
                text: 'условия пользовательского соглашения',
                url: '/static/files/agreement.pdf'
              }}
              onChangeValue={form.fields.agreement.changeValue}
              error={form.submitted && !form.fields.agreement.valid}
            />
          </div>
          <div className={classes.formAction}>
            <Button
              mobileBlock
              theme={'brand'}
              text={'Отправить'}
            />
          </div>
        </form>
      </div>);
    }
    if (registrationError !== null) {
      return (
        <div className={classes.content}>
          <div className={classes.text}>
            Ошибка!
          </div>
          <div className={classes.text}>
            {registrationError.message}
          </div>
          <div className={classes.nav}
            onClick={actions.auth.dealerLoginReset}>
            <span className={cn(classes.navIcon, classes.navIconChange)}>
              <Icon name={'arrowRight'}/>
            </span>
            <span className={classes.navButton}>Вернуться</span>
          </div>
        </div>);
    }
  }
}

export default DealerAuthSignup;
