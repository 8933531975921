import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

const mapStateToProps = (state, { to = null, exact = false}) => {
  const pathname = state.router.location.pathname;
  let isCurrentPath = false;

  if (to !== null) {
    isCurrentPath = exact ? pathname === to : pathname.indexOf(to) === 0;
  }

  return {
    isCurrentPath
  };
};

@connect(mapStateToProps)
class NavLinkCustom extends Component {
  render = () => {
    const {
      isCurrentPath,
      onClick,
      to,
      children,
      className,
      activeClassName,
      onMouseOver,
      onMouseLeave,
    } = this.props;

    const navLinkProps = {
      to, children, className, activeClassName,
      onMouseOver,
      onMouseLeave,
    };
    return (
      <NavLink
        onClick={onClick}
        {...navLinkProps}
        isActive={() => isCurrentPath}
      />
    );
  }
}

export default NavLinkCustom;
