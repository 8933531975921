import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import { styles } from './CardStyles';

@injectStyles(styles)
class Card extends Component {
  render () {
    const {
      classes,
      leftTick,
      rightTick,
      leftIcon,
      children,
      hover,
      shadow,
    } = this.props;

    return (
      <div className={cn(
        classes.root,
        hover ? classes.rootHoverable : null,
        shadow ? classes.rootShadow : null
      )}>
        {(leftTick || rightTick) &&
          <div className={cn(
            leftTick ? classes._tickShadowLeft : null,
            rightTick ? classes._tickShadowRight : null,
            classes.tickShadow, classes.tick
          )}/>
        }
        <div className={classes.card}>
          {(leftTick || rightTick) &&
            <div>
              <div className={cn(
                leftTick ? classes._tickLeft : null,
                rightTick ? classes._tickRight : null,
                classes.tick
              )}/>
            </div>
          }
          {leftIcon !== false &&
            leftIcon
          }
          {children}
        </div>
      </div>
    );
  }
}

Card.propTypes = {
  leftTick: PropTypes.bool,
  rightTick: PropTypes.bool,
  leftIcon: PropTypes.node,
  color: PropTypes.string,
  hover: PropTypes.bool,
  shadow: PropTypes.bool,
};

Card.defaultProps = {
  leftTick: false,
  rightTick: false,
  leftIcon: false,
  color: '#fff',
  hover: false,
  shadow: false,

  borderRadius: 6,
};

export default Card;
