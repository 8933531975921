import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Spacer extends Component {
  render () {
    const {
      h,
      v,
    } = this.props;

    return (
      <div style={{
        width: h,
        height: v
      }}/>
    );
  }
}

Spacer.propTypes = {
  h: PropTypes.number,
  v: PropTypes.number,
};

Spacer.defaultProps = {
  h: 0,
  v: 0,
};

export default Spacer;
