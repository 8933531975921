export const styles = theme => ({
  row: {
    fontWeight: '500',

    color: theme.colors.gray.main,
  },
  rowHead: {
    fontWeight: '400',

    color: theme.colors.gray.dark,
  },

  rowMain: {
    display: 'flex',
    position: 'relative',

    '&:hover': {
      '& $radio': {
        backgroundColor: theme.colors.gray.lightest,
        '&:after': {
          opacity: 1,
        },
      }
    },
  },

  rowMainLink: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    zIndex: 3,

    cursor: 'pointer',
  },

  relative: {
    position: 'relative'
  },

  cell: {

  },
  cellRadio: {

  },
  cellName: {
    color: theme.colors.gray.dark,
  },
  cellDetails: {
    position: 'relative',
    color: theme.colors.brand.main,

    cursor: 'pointer',
  },

  cellTitle: {
    width: '70px',
    paddingRight: '11px',
  },

  radio: {
    display: 'flex',
    width: '14px',
    height: '14px',

    borderRadius: '7px',
    border: `1px solid ${theme.colors.gray.lighter}`,
    boxShadow: '0 2px 1px 0 rgba(60,60,80,0.04)',

    backgroundColor: '#fff',

    transition: 'background-color .5s ease',

    alignItems: 'center',
    justifyContent: 'center',

    '&:after': {
      content: '""',
      display: 'block',
      width: '8px',
      height: '8px',
      opacity: 0,

      borderRadius: '4px',
      boxShadow: '0 2px 1px 0 rgba(60,60,80,0.04)',

      backgroundColor: theme.colors.brand.main,

      transition: 'opacity .5s ease',
    },
  },
  radioActive: {
    '&:after': {
      opacity: 1,
    },
  },

  mobileContent: {
    display: 'none',
  },


  [theme.queries.mdDevicesDown]: {
    row: {
      fontSize: '12px',
    },
    rowHead: {
      display: 'none',
    },

    cell: {
      display: 'flex',
      height: '24px',

      alignItems: 'center',
    },
    cellRadio: {
      display: 'inline-flex',
      width: '32px',
      height: '44px',
    },
    cellName: {
      fontSize: '14px',
      height: '44px',
    },
    cellDetails: {
      height: '32px',
      marginTop: '8px',
      marginBottom: '5px',
    },
    cellPrice: {
      position: 'relative',
      zIndex: 5,
    },

    mobileContent: {
      display: 'block'
    },
    deskContent: {
      display: 'none'
    }
  },

  [theme.queries.mdDevicesOnly]: {
    list: {
      display: 'flex',

      flexWrap: 'wrap',
    },
    row: {
      width: '50%',
    }
  },

  [theme.queries.lgDevicesUp]: {
    row: {
      display: 'flex',

      fontSize: '14px',

      '&:nth-child(2n)': {
        backgroundColor: theme.colors.brand.lightest,
      }
    },

    rowMain: {
      display: 'flex',
      width: 'calc(100% - 120px)',
      position: 'relative',
    },
    rowMainContent: {
      display: 'flex',
      width: 'calc(100% - 44px)',
      position: 'relative',
    },
    rowMainContentBody: {
      display: 'flex',
      width: '75%',
      minWidth: 'calc(100% - 205px)',
      position: 'relative',
    },

    cell: {
      display: 'inline-flex',
      width: '100%',
      minHeight: '44px',
      paddingTop: '10px',
      paddingRight: '10px',
      paddingBottom: '10px',

      alignItems: 'center',
    },
    cellRadio: {
      width: '44px',
      paddingRight: '0px',

      justifyContent: 'center',
    },
    cellName: {
      width: '20%',
      minWidth: '130px',
      paddingRight: '20px',
    },
    cellEngine: {
      width: '25%',
      minWidth: '135px',
    },
    cellHP: {
      width: '12%',
      minWidth: '45px',
    },
    cellDrive: {
      width: '18%',
      minWidth: '100px',
    },
    cellGear: {
      width: '27%',
      minWidth: '165px',
    },
    cellPrice: {
      width: '18%',
      minWidth: '105px',
    },
    cellDetails: {
      width: '120px',
      paddingRight: '10px',
    },

    cellTitle: {
      display: 'none',
    },

    radio: {
      width: '20px',
      height: '20px',

      borderRadius: '10px',
    }
  },

  [theme.queries.xlDevicesUp]: {
    cellName: {
      width: '25%',
      minWidth: '205px',
    }
  },
});
