export default (theme) => ({
  wrapper: {
    backgroundColor: '#fff'
  },
  root: {
    display: 'flex',
    width: '100%',
    height: 60,
    position: 'fixed',
    top: 0,
    left: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff'
  },

  content: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },


  brandLogo: {
    display: 'block',
    width: 110,
    height: 50,
    marginTop: 4,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0% 50%',
    marginRight: 60,
  },

  logo: {
    position: 'relative',
    marginLeft: 40,
  },

  logoLink: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
  },

  logoImg: {
    height: '100%',
    width: 150,
    fontSize: '36px',
  },

  left: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },

  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  city: {
    display: 'inline-flex',

    alignItems: 'center',
  },

  cityIcon: {
    width: 13,
    marginRight: 8,
    lineHeight: 0,

    color: theme.colors.accent.main,
  },
  cityActive: {
    fontSize: 14,
    color: theme.colors.gray.main,
  },

  phone: {
    display: 'inline-flex',
    position: 'relative',
    marginLeft: 40,

    alignItems: 'center',
  },

  phoneLink: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
  },

  phoneIcon: {
    width: 15,
    marginRight: 8,
    lineHeight: 0,

    color: theme.colors.accent.main,
  },
  phoneText: {
    fontSize: 16,
    fontWeight: '500',

    color: theme.colors.gray.dark,
  },

  mobileContacts: {
    display: 'none'
  },


  [theme.queries.mdDevicesDown]: {
    wrapper: {
      position: 'fixed',
      zIndex: 500,
    },
    root: {
      height: 60,
      padding: '2px 16px',
      boxShadow: 'none'
    },
    brandLogo: {
      width: 76,
      height: 40
    },
    right: {
      display: 'none'
    },
    content: {
      flexWrap: 'wrap'
    },
    mobileContacts: {
      display: 'block'
    },
    mobilePhone: {
      fontSize: 14,
      fontWeight: 500,
      textAlign: 'right',
      color: '#282828',
      margin: 0
    },
    mobileCity: {
      fontSize: 12,
      textAlign: 'right',
      color: '#a0a0a0',
      margin: 0
    }
  },

  [theme.queries.lgDevicesUp]: {
    wrapper: {
      width: '100%',
      height: 100,
      position: 'relative',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 210,
      boxShadow: '0 0 32px 0 rgba(60, 60, 60, 0.08)',
    },
    wrapperHome: {
      boxShadow: 'none',
    },

    root: {
      height: 100,
      maxWidth: 1440,
      position: 'relative',
      margin: '0 auto',
      padding: '8px 65px',
    },

    logo: {
      height: 36,
    },
  },

  [theme.queries.lgDevicesOnly]: {
    root: {
      padding: 30,
    },
    phone: {
      marginLeft: 20,
    },
  },

});
