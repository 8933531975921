export const initialState = {
  partnersByBrandsData: {},
  partnersByBrandsInProgress: false,
  partnersByBrandsSuccess: null,
  partnersByBrandsError: null,

  partnersData: {},
  partnersInProgress: false,
  partnersSuccess: null,
  partnersError: null,

};

export const getPartnersByBrandsRequest = (state) => ({
  ...state,
  partnersByBrandsInProgress: true,
  partnersByBrandsSuccess: null,
  partnersByBrandsError: null
});

export const getPartnersByBrandsSuccess = (state, { data }) => ({
  ...state,
  partnersByBrandsData: data,
  partnersByBrandsInProgress: false,
  partnersByBrandsSuccess: true,
  partnersByBrandsError: null
});

export const getPartnersByBrandsFailure = (state, { error }) => ({
  ...state,
  partnersByBrandsInProgress: false,
  partnersByBrandsSuccess: false,
  partnersByBrandsError: error
});


export const getPartnersRequest = (state) => ({
  ...state,
  partnersInProgress: true,
  partnersSuccess: null,
  partnersError: null
});

export const getPartnersSuccess = (state, { data }) => ({
  ...state,
  partnersData: data,
  partnersInProgress: false,
  partnersSuccess: true,
  partnersError: null
});

export const getPartnersFailure = (state, { error }) => ({
  ...state,
  partnersInProgress: false,
  partnersSuccess: false,
  partnersError: error
});

