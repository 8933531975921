export default (theme) => ({
  root: {
    padding: '20px 0 83px',
    margin: '0 -15px',
    width: 'calc(100% + 30px)',
  },

  head: {
    display: 'flex',
    padding: '0 15px 34px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  lander: {

  },

  title: {
    margin: 0,
    fontSize: 36,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#282828',
    '&$lander': {
      textAlign: 'center',
      fontSize: 30
    },
  },

  link: {
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: '0.2px',
    color: theme.colors.brand.main,
  },



  [theme.queries.xsDevicesDown]: {

  },

  [theme.queries.mdDevicesDown]: {
    hideMob: {
      display: 'none',
    },

    foot: {
      display: 'flex',
      padding: '0 16px',
      justifyContent: 'center',
    },

    root: {
      paddingTop: 14,
      paddingBottom: 68,
      margin: '0 -8px',
      width: 'calc(100% + 16px)',
    },
    head: {
      padding: '0 16px 12px',
    },
    title: {
      fontSize: 24,
      lineHeight: 1.5,
    },

  },

  [theme.queries.lgDevicesUp]: {
    hideDesk: {
      display: 'none',
    }
  },
  [theme.queries.lgDevicesOnly]: {
  },
});
