import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import actions from '~app/redux/actions';

import { Container, Carousel } from '~app/components/ui';
import PreFooter from '../HomeScreen/Sections/PreFooter/PreFooter';
import { injectStyles } from '~app/theme';
import { styles } from './DealerItemScreenStyles';
import { PromoItemCard } from '~app/components/Promo';
import { NewsItemCard } from '~app/components/News';
import { BrandsItemCard } from '~app/components';
import DealerBanner from '~app/components/DealerBanner/DealerBanner';


const mapStateToProps = ({ news, dealers, promo }, { reduxKey = 'dealerPage' }) => {
  let newsState = {
    items: null,
  };

  let promoState = {
    items: null,
  };

  if (reduxKey in news.newsByKey) {
    newsState = news.newsByKey[reduxKey];
  }

  if (reduxKey in promo.promoByKey) {
    promoState = promo.promoByKey[reduxKey];
  }

  return {
    data: dealers.dealer,
    inProgress: dealers.getDealerInProgress,
    success: promo.getDealerSuccess,
    error: promo.getDealerError,

    reduxKey,

    brandsByDealer: dealers.brandsByDealer,
    newsItems: newsState.items,
    promoItems: promoState.items,

  };
};

@connect(mapStateToProps)
@injectStyles(styles)
class DealerItemScreen extends Component {

  static async fetchData({ store, params }) {
    await Promise.all([
      actions.dealers.getDealer(params.id),
      actions.dealers.getBrandsByDealer(params.id),
      actions.promo.getPromoByKey('dealerPage', {
        limit: 20,
        id: params.id
      }),
      actions.news.getNewsByKey('dealerPage', {
        limit: 20,
        id: params.id,
        type: 'withDealer',
      })
    ]);
  }

  componentDidMount = () => {
    this.loadDealer();
    this.loadBrandsByDealer();
    this.loadPromoByDealer();
    this.loadNewsByDealer();
  }

  componentWillUnmount() {
    actions.dealers.getDealerReset();
  }

  loadDealer = () => {
    actions.dealers.getDealer(this.props.match.params.id);
  }

  loadBrandsByDealer = () => {
    actions.dealers.getBrandsByDealer(this.props.match.params.id);
  }

  loadPromoByDealer = () => {
    actions.promo.getPromoByKey(this.props.reduxKey, {
      limit: 20,
      page: 1,
      id: this.props.match.params.id
    });
  }

  loadNewsByDealer = () => {
    actions.news.getNewsByKey(this.props.reduxKey, {
      page: 1,
      limit: 20,
      id: this.props.match.params.id,
      type: 'withDealer',
    });
  }


  render() {
    const {
      classes,
      data,
      brandsByDealer,
      newsItems,
      promoItems,
    } = this.props;

    return (
      <div className={classes.root}>
        {data !== null &&
          <Helmet>
            <title>Avicar - {`Автомобили от дилера ${data.name} в Петербурге`}</title>
          </Helmet>
        }
        <Container background={'#ffffff'}>
          {data !== null && <DealerBanner data={data} />}
          <div className={classes.root}>
            {promoItems !== null &&
              Array.isArray(promoItems) &&
              promoItems.length > 0 &&
              <div className={classes.section}>
                <div className={classes.sectionTitle}>
                  {'Акции дилера'}
                </div>
                <div className={classes.carousel}>
                  <Carousel
                    name={'promo-carousel'}
                    desktopItems={2}
                    mobileItems={1}
                    items={promoItems.map(item =>
                      <PromoItemCard item={item} key={item.id} />
                    )}
                  />
                </div>
              </div>
            }
            {brandsByDealer !== null &&
              Array.isArray(brandsByDealer) &&
              brandsByDealer.length > 0 &&
              <div className={classes.section}>
                <div className={classes.sectionTitle}>
                  {'Бренды дилера'}
                </div>
                <div className={classes.carousel}>
                  <Carousel
                    name={'brands-carousel'}
                    desktopItems={8}
                    mobileItems={1}
                    items={brandsByDealer.map(item =>
                      <BrandsItemCard item={item} key={item.id} />
                    )}
                  />
                </div>
              </div>
            }
            {newsItems !== null &&
              Array.isArray(newsItems) &&
              newsItems.length > 0 &&
              <div className={classes.section}>
                <div className={classes.sectionTitle}>
                  {'Новости дилера'}
                </div>
                <div className={classes.carousel}>
                  <Carousel
                    name={'news-carousel'}
                    desktopItems={4}
                    mobileItems={1}
                    items={newsItems.map(item =>
                      <NewsItemCard item={item} key={item.id} />
                    )}
                  />
                </div>
              </div>
            }
          </div>
        </Container>
        <Container>
          <PreFooter />
        </Container>
      </div>
    );
  }
}

export default DealerItemScreen;