import * as common from './common/commonReducer';
import * as auth from './auth/authReducer';
import * as applications from './applications/applicationsReducer';
import * as notifications from './notifications/notificationsReducer';
import * as dictionary from './dictionary/dictionaryReducer';
import * as offers from './offers/offersReducer';
import * as reviews from './reviews/reviewsReducer';
import * as deals from './deals/dealsReducer';
import * as user from './user/userReducer';
import * as chat from './chat/chatReducer';
import * as lander from './lander/landerReducer';
import * as partners from './partners/partnersReducer';
import * as news from './news/newsReducer';
import * as dealers from './dealers/dealersReducer';
import * as promo from './promo/promoReducer';
import * as banners from './banners/bannersReducer';

export default {
  common,
  auth,
  applications,
  notifications,
  dictionary,
  offers,
  reviews,
  deals,
  user,
  chat,
  lander,
  partners,
  news,
  dealers,
  promo,
  banners,
};
