import React, { Component } from 'react';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import styles from './BrandsBannerStyles';
import { Container, Button, Icon, ParentDomainLink } from '~app/components/ui';

import PropTypes from 'prop-types';
import Triggers from '~app/components/Triggers/Triggers';

@injectStyles(styles)

class BrandsBanner extends Component {
  render = () => {
    const {
      classes,
      bgUrl = '',
      title,
      subtitle,
      text,
      iconText1,
      iconText2,
      // brandName,
      invertedTextColor,
    } = this.props;

    return (
      <div
        style={{ backgroundImage: bgUrl && `url(${bgUrl})` }}
        className={cn(classes.root)}>
        <Container fullHeight>
          <div className={cn(classes.colText, invertedTextColor ? classes.colTextInverted : null)}>
            <Triggers banner iconText1={iconText1} iconText2={iconText2} />
            {title && <h1 className={classes.title}>{`${title}`}</h1>}
            {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
            {text && <p className={classes.text}>{text}</p>}
            <ParentDomainLink className={classes.link} to={'/application'}>
              <Button
                mobileBlock
                block
                theme={'brand'}
                text={'Создать заявку'}
                rightIcon={<Icon name={'arrowRight'} stroked />}
              />
            </ParentDomainLink>
          </div>
        </Container>
      </div>
    );
  }
}

BrandsBanner.propTypes = {
  bgUrl: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string
};

export default BrandsBanner;
