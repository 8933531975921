import React, { Component } from 'react';

import { injectStyles } from '~app/theme';
import styles from './PromotionBannerStyles';
import cn from 'classnames';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Icon } from '../ui';

const mapStateToProps = ({ banners }) => {
  return {
    banners: banners.banners,
    inProgress: banners.bannersInProgress,
    error: banners.bannersError,
  };
};

@connect(mapStateToProps)
@injectStyles(styles)
class PromotionBannerStyles extends Component {

  state = {
    banner: null,
    opened: true,
  };

  static propTypes = {
    type: PropTypes.oneOf(['dealers', 'main']),
  };

  static defaultProps = {
    type: 'main',
  };

  static getDerivedStateFromProps(props, state) {
    if (state.banner == null && props.banners !== null && Array.isArray(props.banners)) {
      const bannersByType = props.banners.filter(item => item.type == props.type);
      if (bannersByType.length == 0) {
        return null;
      }
      const banner = bannersByType[Math.floor(Math.random() * bannersByType.length)];

      return {
        banner
      };
    }
    return null;
  }

  close = (event) => {
    event.preventDefault();
    this.setState({ opened: false });
  }

  render() {
    const {
      classes,
      type,
    } = this.props;

    const { banner, opened } = this.state;

    return (
      banner !== null &&
      <div className={cn(classes.root, classes[type], {
        [classes.closed]: !opened,
      })}>
        {banner.image !== null &&
          <div
            className={classes.image}
            style={{
              backgroundImage: `url(${banner.image})`
            }} />
        }
        {banner.name !== null &&
          <div className={classes.title}>{banner.name}</div>
        }
        {banner.link !== null &&
          <a
            href={banner.link}
            target={'_blank'}
            rel={'noreferrer noopener'}
            className={classes.button}>
            {banner.buttonText}
          </a>
        }
        <div className={classes.close} onClick={this.close}>
          <Icon block name={'times'} />
        </div>
      </div>
    );
  }
}

export default PromotionBannerStyles;
