import { mapper, fromInput } from 'demapper';

@mapper
export default class {

  @fromInput('_id')
  id = null

  @fromInput('name')
  name = null

  @fromInput('startDate', { model: Date })
  startDate = null

  @fromInput('endDate', { model: Date })
  endDate = null

  @fromInput('link')
  link = null

  @fromInput('buttonText')
  buttonText = null

  @fromInput('image.secure_url')
  image = null

  @fromInput('image.width')
  imageWidth = null

  @fromInput('image.height')
  imageHeight = null

  @fromInput('type')
  type = null

}
