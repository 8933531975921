import React, { Component } from 'react';

import { Header, Footer } from '../';
import { injectStyles } from '~app/theme';
import styles from './LayoutGeneralStyles';

@injectStyles(styles)
class LayoutGeneral extends Component {
  render = () => {
    const {
      classes,
      children,
    } = this.props;
    return (
      <div className={classes.root}>
        <Header />
        <div className={classes.content}>
          {children}
        </div>
        <Footer />
      </div>
    );
  }
}

export default LayoutGeneral;