import React, { Component } from 'react';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import styles from './PromoBannerStyles';
import { Container, Button, Icon, ParentDomainLink } from '~app/components/ui';

import PropTypes from 'prop-types';

@injectStyles(styles)

class PromoBanner extends Component {
  render = () => {
    const {
      classes,
      image,
      // header,
      buttonText,
      // description,
      brandName,
    } = this.props;

    return (
      <div
        className={cn(classes.root)}>
        <Container fullHeight>
          <div className={classes.container}>
            <div className={classes.left}>
              <h2 className={classes.title}>{`Avicar - авторизованный партнер всех официальных дилеров ${brandName}`}</h2>
              {/* {description && <p className={classes.text}>{description}</p>} */}
              <p className={classes.text}>{'Заполни заявку и получи индивидуальное предложение в личном кабинете. Покупка нового автомобиля - теперь это невероятно просто, ведь все трудности мы взяли на себя!'}</p>
              <ParentDomainLink className={classes.link} to={'/application'}>
                <Button
                  mobileBlock
                  block
                  theme={'accent'}
                  text={buttonText}
                  rightIcon={<Icon name={'arrowRight'} stroked />}
                />
              </ParentDomainLink>
            </div>
            <div className={classes.right}>
              <div
                className={classes.image}
                style={{ backgroundImage: `url(${image})` }} />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

PromoBanner.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string
};

export default PromoBanner;
