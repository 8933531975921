export const initialState = {
  items: []
};

export const addItem = (state, payload) => ({
  ...state,
  items: [
    ...state.items,
    payload
  ],
});

export const removeItem = (state, payload) => ({
  ...state,
  items: state.items.filter(x => x.id !== payload.id)
});

export const removeAll = () => initialState;