import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

class Price extends Component {
  render = () => {
    let {
      price,
      inline,
      additional,
    } = this.props;

    price = parseInt(price);

    return (
      <div style={inline ? { display: 'inline' } : {}}>
        <span dangerouslySetInnerHTML={{__html: price !== null && `${numeral(price).format('0(,)0').replace(/,/g, '&nbsp;')}`}}/>
        <span style={{ fontSize: '0.78em' }}>&nbsp;{'₽'}</span>
        {additional !== null && <span>{' *'}</span>}
      </div>
    );
  }
}

Price.propTypes = {
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  additional: PropTypes.string,
};

Price.defaultProps = {
  price: null,
  additional: null,
};

export default Price;
