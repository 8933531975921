import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '~app/redux/actions';

import { ReviewCard } from '../../../../components';
import { EmptyListPlaceholder, Spacer, Card } from '../../../../components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './ReviewsStyles';



const mapStateToProps = (state) => ({
  reviews: state.reviews.myReviews,
  reviewsPaging: state.reviews.myReviewsPaging,
  reviewsInProgress: state.reviews.myReviewsInProgress,
  reviewsError: state.reviews.myReviewsError,
});

@connect(mapStateToProps)
@injectStyles(styles)
class Reviews extends Component {

  componentDidMount = () => {
    this.loadData();
  }

  loadData = (props = this.props) => {
    actions.reviews.getMyReviews();
  }


  render() {
    const {
      classes,
      reviews,
    } = this.props;
    
    return (
      <div className={classes.root}>
        {reviews !== null && reviews.length > 0 &&
          reviews.map((item, index) => {
            return (
              <div key={index}>
                <Card borderRadius={6}>
                  <div className={classes.item}>
                    <ReviewCard item={item} showAuthorName />
                  </div>
                </Card>
                <Spacer v={12} />
              </div>
            );
          })
        }
        {reviews !== null && reviews.length === 0 &&
          <EmptyListPlaceholder
            iconName={'reviews'}
            text={'Нет отзывов о принятых сделках'}
          />
        }
      </div>
    );
  }
}

export default Reviews;