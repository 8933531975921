import { mapper, fromInput } from 'demapper';
import DetailBaseModel from '../Detail/DetailBaseModel';

@mapper
export default class {
  @fromInput('razdel')
  name = null;

  @fromInput('data', { model: DetailBaseModel })
  items = null;
}
