import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '~app/redux/actions';
import FlipMove from 'react-flip-move';

import { DealCard } from '../../../../components';
import { EmptyListPlaceholder, Spacer } from '../../../../components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './ArchiveStyles';

const mapStateToProps = (state, { reduxKey = 'UserArchiveScreen' }) => {
  let offersState = {
    items: null,
    paging: null,
    inProgress: null,
    error: null,
  };
  if (reduxKey in state.offers.itemsByKey) {
    offersState = state.offers.itemsByKey[reduxKey];
  }

  return {
    reduxKey,

    offers: offersState.items,
    offersPaging: offersState.paging,
    offersInProgress: offersState.inProgress,
    offersError: offersState.error,
  };
};


@connect(mapStateToProps)
@injectStyles(styles)
class Archive extends Component {

  componentDidMount = () => {
    this.loadData();
  }

  loadData = (props = this.props) => {
    actions.offers.getItemsByKey(this.props.reduxKey, {
      types: 'archive'
    });
  }

  render() {
    const {
      classes,
      offers,
    } = this.props;

    return (
      <div className={classes.root}>
        {offers !== null && offers.length > 0 ?
          <FlipMove>
            {offers.map((item) => {
              return (
                <div key={item.id} className={classes.item}>
                  <DealCard
                    item={item}
                    onChangeStatus={this.loadData}
                  />
                  <Spacer v={12}/>
                </div>
              );
            })}
          </FlipMove>
          :
          <EmptyListPlaceholder
            iconName={'archive'}
            text={'Нет данных в архиве'}
          />
        }
      </div>
    );
  }
}

export default Archive;
