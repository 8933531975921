export const styles = theme => ({
  root: {
    position: 'relative',
  },
  input: {
    width: '100%',
    height: 48,
    padding: '10px 15px',

    borderRadius: 3,
    border: `1px solid ${theme.colors.gray.lighter}`,
    boxShadow: '0 2px 1px 0 rgba(60,60,80,.04)',
    outline: 'none',

    fontSize: 14,

    backgroundColor: '#fff',
    color: theme.colors.gray.dark,

    transition: 'background-color 1s ease',

    appearance: 'none',

    '&::placeholder': {
      color: theme.colors.gray.main,
    },
    '&:focus': {
      backgroundColor: theme.colors.gray.lightest,
    },
  },

  withIcon: {
    paddingLeft: '40px',
  },

  inputWrapper: {
    position: 'relative',
  },
  inputIcon: {
    width: '40px',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
  },

  small: {
    height: 28,
    padding: '10px 5px',
  },

  textarea: {
    height: 160,

    resize: 'none',

    '&$small': {
      height: 62,
      resize: 'vertical',
      padding: '10px 15px',
    }
  },

  inputError: {
    borderColor: theme.colors.rare.main,
  },

  success: {
    borderColor: theme.colors.general.main,
  },

  code: {
    borderWidth: '0 0 1px 0',

    textAlign: 'center',
    '&:focus': {
      backgroundColor: 'transparent',
    }
  },

  errorMessage: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    paddingTop: 5,

    fontSize: 14,

    color: theme.colors.rare.main,

    transform: 'translateY(100%)',
  },

  [theme.queries.smDevicesDown]: {
    input: {
      fontSize: 16,
    },
    small: {

    },
    textarea: {
      '&$small': {
        height: 140
      }
    }
  },
});
