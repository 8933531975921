import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const baseDate = new Date(0, 0, 0, 0);

class Countdown extends Component {

  state = {
    diff: 0
  }
  interval = 1000
  intervalInstance = null

  componentWillMount = () => {
    this.checkDiff();
  }

  componentWillReceiveProps = (nextProps) => {
    this.checkDiff(nextProps);
  }

  componentWillUnmount = () => {
    this.endTimer();
  }

  checkDiff = (props = this.props) => {
    let diff = 0;
    if (props.date !== null) {
      diff = props.date - Date.now();
    }
    if (diff <= 0) {
      diff = 0;
    }

    if (diff > 0 && this.intervalInstance === null) {
      this.startTimer();
    } else if (diff === 0 && this.intervalInstance !== null) {
      this.endTimer();
    }

    this.setState({
      diff
    });
  }

  startTimer = () => {
    this.endTimer();
    this.intervalInstance = setInterval(() => {
      this.checkDiff();
    }, this.interval);
  }

  endTimer = () => {
    clearInterval(this.intervalInstance);
    this.intervalInstance = null;
  }

  render = () => {
    const diff = this.state.diff;
    const timeString = moment(baseDate).add(diff, 'millisecond').format(this.props.format);
    if (diff > 0) {
      return this.props.renderTimer({
        diff,
        timeString
      });
    } else {
      return this.props.renderAction();
    }
  }
}

Countdown.propTypes = {
  date: PropTypes.number.isRequired,
  format: PropTypes.string,
  renderTimer: PropTypes.func.isRequired,
  renderAction: PropTypes.func.isRequired
};

Countdown.defaultProps = {
  format: 'm мин ss сек'
};

export default Countdown;
