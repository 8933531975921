export default (theme) => ({
  root: {
    display: 'flex',
    width: 40,
    height: 40,

    borderRadius: 20,

    backgroundColor: theme.colors.gray.lighter,
    color: '#fff',

    transition: 'background-color .5s ease',

    alignItems: 'center',
    justifyContent: 'center',

    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.colors.gray.main,
    }
  },

  anchor: {
    position: 'fixed',
    top: 0,
  },

  icon: {
    width: 16,

    transform: 'rotate(180deg)',
  },

  [theme.queries.mdDevicesDown]: {
    root: {
      
    }
  }
});