export default (theme) => ({
  // wrapper: {
  //   position: 'relative',
  //   zIndex: 1000,
  // },

  root: {
    display: 'flex',
    width: '100%',
    height: 60,
    position: 'fixed',
    top: 0,
    left: 0,

    justifyContent: 'space-between',
    alignItems: 'center',
  },

  logo: {
    position: 'relative',
  },
  logoLink: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
  },
  logoImg: {
    height: '100%',
    width: 168,
    fontSize: '40px',
  },

  mobileMenu: {
    display: 'flex',
    maxWidth: '60%',
    position: 'relative',
    zIndex: 20,
    marginRight: -20,
  },

  mobileBtn: {
    display: 'inline-flex',
    width: 60,
    height: 60,
    position: 'relative',

    color: theme.colors.accent.main,

    transform: 'translateX(0)',
    transition: 'transform .7s ease, opacity .5s ease',

    alignItems: 'center',
    justifyContent: 'center',

    cursor: 'pointer',
  },
  mobileBtnLink: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  mobileBtnCall: {
    opacity: 1,
  },
  mobileBtnInfo: {
    opacity: 0,
  },
  mobileBtnIcon: {
    width: 20,
  },

  burger: {
    width: 60,
    height: 60,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,

    backgroundColor: '#fff',

    '&:before': {
      content: '""',
      display: 'block',
      width: 1,
      height: 44,
      position: 'absolute',
      left: 0,
      top: 8,
      opacity: .3,

      background: 'linear-gradient(to bottom, rgba(160,170,180,0) 0%,#a0aab4 50%,rgba(160,170,180,0) 100%)',
      filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr=\'#00a0aab4\', endColorstr=\'#00a0aab4\',GradientType=0 )',
    }
  },

  mobileMenuBottom: {
    position: 'absolute',
    bottom: 40,
    right: 25,
    left: 30,
  },

  city: {
    display: 'inline-flex',

    alignItems: 'center',
  },
  cityIcon: {
    width: 13,
    marginRight: 8,
    lineHeight: 0,

    color: theme.colors.accent.main,
  },
  cityActive: {
    fontSize: 14,

    color: theme.colors.gray.main,
  },

  socials: {
    display: 'flex',
    paddingTop: 15,
    marginLeft: -15,
  },
  socialItem: {
    display: 'flex',
    width: 40,
    height: 40,
    position: 'relative',

    color: theme.colors.brand.main,

    alignItems: 'center',
    justifyContent: 'center',
  },
  socialItemLink: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
  },

  contentLinks: {
    padding: '0 32px',
  },
  contentLinksButton: {
    display: 'inline-flex',
  },
  contentLink: {
    padding: '10px 15px',

    fontSize: 14,
    fontWeight: '500',
    letterSpacing: '.2px',
    textDecoration: 'none',

    color: theme.colors.gray.dark,

    transition: 'color .5s ease',

    '&:hover': {
      color: theme.colors.brand.main,
    }
  },
  contentLinkActive: {
    color: theme.colors.brand.main,
  },

  phone: {
    display: 'inline-flex',
    position: 'relative',
    marginLeft: 30,

    alignItems: 'center',
  },
  phoneLink: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
  },
  phoneIcon: {
    width: 15,
    marginRight: 8,
    lineHeight: 0,

    color: theme.colors.accent.main,
  },
  phoneText: {
    fontSize: 16,
    fontWeight: '500',

    color: theme.colors.gray.dark,
  },

  userLink: {
    position: 'relative',
  },
  userLinkLink: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
  },


  rootMenuOpened: {
    '& $mobileBtn': {
      transform: 'translateX(-60px)',
    },
    '& $mobileBtnCall': {
      opacity: 0,
    },
    '& $mobileBtnInfo': {
      opacity: 1,
    },
  },


  menuLink: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  menuLinkIcon: {
    position: 'absolute',
    left: '100%',
    display: 'inline-flex',
    width: 16,
    height: 16,
    marginLeft: '5px',

    borderRadius: '8px',

    fontSize: '8px',
    fontWeight: '700',

    backgroundColor: theme.colors.general.main,
    color: '#fff',

    alignItems: 'center',
    justifyContent: 'center',
  },


  [theme.queries.mdDevicesDown]: {
    wrapper: {
      position: 'fixed',
      zIndex: 500,
    },
    root: {
      height: 60,
      padding: '0 20px',

      background: '#fff',

      transform: 'translateY(0)',
      transition: 'transform .7s ease',

      '&:before': {
        content: '""',
        display: 'block',
        position: 'fixed',
        height: '100vh',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 5,
        opacity: 0,

        backgroundColor: 'rgba(60,60,80,.1)',

        transition: 'opacity .5s ease',

        pointerEvents: 'none',
      }
    },
    headerHidden: {
      transform: 'translateY(-100%)',
    },

    logo: {
      maxWidth: '40%',
      height: 28,
    },
    logoImg: {
      width: 118,
      fontSize: '28px',
    },

    desktopControls: {
      display: 'none',
    },

    content: {
      width: 235,
      height: '100vh',
      position: 'fixed',
      top: 0,
      right: 0,
      zIndex: 10,
      padding: '70px 0 40px',

      backgroundColor: '#fff',

      transform: 'translateX(100%)',
      transition: 'transform .7s ease',
    },
    contentLinks: {
      padding: '0 25px 12px 0',

      borderBottom: `1px solid ${theme.colors.gray.lighter}`,

      fontSize: 14,
      lineHeight: '28px',
    },
    contentLink: {
      display: 'block',
      padding: 0,
      paddingLeft: 30,

      lineHeight: '32px',
      textDecoration: 'none',

      '&:hover': {
        color: theme.colors.brand.main,
      }
    },
    contentLinksButton: {
      marginLeft: 30,
      marginBottom: 6,
    },

    user: {
      padding: '11px 25px 25px 0',
    },
    userLoginBtn: {
      paddingLeft: 30,

      fontSize: 14,
      fontWeight: '500',
      lineHeight: '28px',

      cursor: 'pointer',
    },

    userHead: {
      display: 'none',
    },
    userBody: {
      fontSize: 14,
      lineHeight: '28px',
    },
    userLink: {
      paddingLeft: 30,

      fontWeight: '500',
      lineHeight: '32px',
    },
    userLogoutBtn: {
      paddingLeft: 30,

      fontWeight: '500',
      lineHeight: '32px',

      color: theme.colors.gray.main,

      cursor: 'pointer',
    },


    rootMenuOpened: {
      '& $content': {
        transform: 'translateX(0)',
      },

      '&:before': {
        opacity: 1,

        pointerEvents: 'all',
      }
    }
  },

  [theme.queries.lgDevicesUp]: {
    wrapper: {
      width: '100%',
      height: 100,
      position: 'relative',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 210,

      boxShadow: '0 0 32px 0 rgba(60, 60, 60, 0.08)',
    },
    wrapperHome: {
      boxShadow: 'none',
    },

    root: {
      height: 100,
      maxWidth: 1440,
      position: 'relative',
      margin: '0 auto',
      padding: '30px 65px',
    },

    logo: {
      height: 40,
    },

    content: {
      display: 'flex',
      width: '100%',
      paddingTop: 6,

      justifyContent: 'space-between',
      alignItems: 'center',
    },

    contentLinks: {
      display: 'inline-flex',
      alignItems: 'center',
    },

    contentLink: {
      position: 'relative',
      
      '&:before': {
        content: '""',
        display: 'block',
        height: 2,
        width: 'calc(100% - 40px)',
        position: 'absolute',
        bottom: 6,
        left: 20,

        backgroundColor: theme.colors.brand.main,

        transformOrigin: 'left',
        transform: 'scaleX(0)',
        transition: 'transform .5s ease',
      },

      '&:hover': {
        '&:before': {
          transform: 'scaleX(1)'
        }
      }
    },

    contentControls: {
      display: 'inline-flex',
      alignItems: 'center',
    },

    desktopControls: {
      display: 'inline-flex',

      paddingRight: 30,
    },

    user: {
      position: 'relative',
      zIndex: 500,
    },

    userLoginBtn: {
      position: 'relative',

      fontSize: 14,
      fontWeight: '500',
      letterSpacing: '.2px',
      lineHeight: '20px',

      color: theme.colors.gray.dark,

      transition: 'color .5s ease',

      cursor: 'pointer',

      '&:before': {
        content: '""',
        display: 'block',
        height: 2,
        position: 'absolute',
        right: 0,
        bottom: -2,
        left: 0,

        backgroundColor: theme.colors.brand.main,

        transform: 'scaleX(0)',
        transformOrigin: 'left center',
        transition: 'transform .5s ease',
      },

      '&:hover': {
        color: theme.colors.brand.main,

        '&:before': {
          transform: 'scaleX(1)',
        },
      }
    },

    userContent: {
      position: 'relative',

      '&:hover': {
        '& $userBody': {
          opacity: 1,

          transform: 'translateY(0)',

          pointerEvents: 'all',
        }
      }
    },
    userHead: {
      display: 'inline-flex',
      position: 'relative',

      fontSize: 14,
      fontWeight: '500',
      letterSpacing: '.2px',
      lineHeight: '20px',

      color: theme.colors.gray.dark,

      transition: 'color .5s ease',

      alignItems: 'center',

      cursor: 'pointer',

      '&:before': {
        content: '""',
        display: 'block',
        height: 2,
        position: 'absolute',
        right: 0,
        bottom: -2,
        left: 0,

        backgroundColor: theme.colors.brand.main,

        transform: 'scaleX(0)',
        transformOrigin: 'left center',
        transition: 'transform .5s ease',
      },

      '&:hover': {
        color: theme.colors.brand.main,

        '&:before': {
          transform: 'scaleX(1)',
        },
      },
    },
    userHeadIcon: {
      width: 10,
      marginLeft: 4,
    },

    userBody: {
      width: 160,
      position: 'absolute',
      top: 35,
      right: 0,
      opacity: 0,
      zIndex: 5,

      borderRadius: 6,
      border: `1px solid ${theme.colors.gray.lighter}`,
      boxShadow: '0 2px 4px 0 rgba(60,60,80,.08)',

      backgroundColor: theme.colors.gray.lightest,

      transform: 'translateX(50%)',
      transition: 'transform .7s ease, opacity .7s ease',

      pointerEvents: 'none',

      '&:before': {
        content: '""',
        display: 'block',
        width: '100%',
        height: 15,
        position: 'absolute',
        top: -15,
        left: 0,
      }
    },
    userLinks: {
      minWidth: 160,
      padding: '13px 24px',

      borderBottom: `1px solid ${theme.colors.gray.lighter}`,

      fontSize: 14,
      fontWeight: '500',
      lineHeight: '28px',

      color: theme.colors.gray.dark,
    },
    userLink: {
      '&:hover': {
        '& $userLinkName': {
          color: theme.colors.brand.main,

          '&:before': {
            transform: 'scaleX(1)',
          },
        }
      }
    },
    userLinkName: {
      display: 'inline-block',
      position: 'relative',

      transition: 'color .5s ease',

      '&:before': {
        content: '""',
        display: 'block',
        height: 2,
        position: 'absolute',
        right: 0,
        bottom: 0,
        left: 0,

        backgroundColor: theme.colors.brand.main,

        transform: 'scaleX(0)',
        transformOrigin: 'left center',
        transition: 'transform .5s ease',
      },
    },
    userLogout: {
      padding: '8px 24px',

      fontSize: 14,
      fontWeight: '500',
      lineHeight: '32px',

      color: theme.colors.gray.main,
    },
    userLogoutBtn: {
      display: 'inline-block',
      position: 'relative',

      cursor: 'pointer',

      '&:before': {
        content: '""',
        display: 'block',
        height: 2,
        position: 'absolute',
        right: 0,
        bottom: 0,
        left: 0,

        backgroundColor: theme.colors.gray.main,

        transform: 'scaleX(0)',
        transformOrigin: 'left center',
        transition: 'transform .5s ease',
      },

      '&:hover': {
        '&:before': {
          transform: 'scaleX(1)',
        }
      }
    },


    mobileMenu: {
      display: 'none',
    },
    mobileMenuBottom: {
      display: 'none',
    }
  },

  [theme.queries.lgDevicesOnly]: {
    root: {
      padding: 30,
    },
    phone: {
      marginLeft: 20,
    },
    desktopControls: {
      paddingRight: 20,
    },

    logoImg: {
      width: '110px',
    },
    contentLinks: {
      padding: '0 20px',
    },
    contentLink: {
      padding: '15px 10px',
      fontSize: '12px',
    },
    contentLinksButton: {
      paddingRight: '10px',
    },
    phoneText: {
      fontSize: '14px'
    },
  },

});