import * as $models from './models';
import $client from './_client';
import * as auth from './auth';
import * as applications from './applications';
import * as user from './user';
import * as dictionary from './dictionary';
import * as offers from './offers';
import * as reviews from './reviews';
import * as deals from './deals';
import * as common from './common';
import * as chat from './chat';
import * as lander from './lander';
import * as partners from './partners';
import * as news from './news';
import * as promo from './promo';
import * as dealers from './dealers';
import * as banners from './banners';

export default {
  $models,
  $client,
  user,
  auth,
  applications,
  dictionary,
  offers,
  reviews,
  deals,
  common,
  chat,
  lander,
  partners,
  news,
  promo,
  dealers,
  banners,
};
