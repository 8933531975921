import React, { Component } from 'react';
import { connect } from 'react-redux';

import actions from '~app/redux/actions';
import { GenericModal } from '~app/components';
import { Button, Icon } from '~app/components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './RemoveOfferModalStyles';

const mapStateToProps = (state, { offerId = null }) => {
  
  let inProgress = false;
  let success = false;
  let error = null;

  if (offerId !== null && state.offers.removeId === offerId) {
    inProgress = state.offers.removeInProgress;
    success = state.offers.removeSuccess;
    error = state.offers.removeError;
  }

  return {
    inProgress,
    success,
    error,
    
    removeOfferInProgress: state.offers.removeInProgress,
  };
};


@connect(mapStateToProps)
@injectStyles(styles)
class RefuseOfferModal extends Component {
  
  componentWillReceiveProps(nextProps) {
    if (!this.props.success && nextProps.success) {
      this.onSuccess();
    }
  }

  onSuccess = () => {
    this.props.onSuccess();
  }

  removeOffer = () => {
    const {
      offerId = null,
    } = this.props;

    if (!this.props.inProgress && !this.props.refuseOfferInProgress) {
      actions.offers.removeItem(offerId);
    }
  }

  render() {
    const {
      classes,
      isOpen,
      onRequestClose,
    } = this.props;

    return (
      <GenericModal
        open={isOpen}
        title={'Удаление из архива'}
        text={'Вся контактная информация будет потеряна. Удалить сделку из архива?'}
        theme={'general'}
        onRequestClose={onRequestClose}
        additionalContent={
          <div className={classes.button}>
            <Button 
              mobileBlock
              theme={'rare'}
              text={'Удалить'}
              leftIcon={<Icon name={'timesThin'}/>}
              onClick={this.removeOffer}
              block
            />
          </div>
        }
      />
    );
  }
}

export default RefuseOfferModal;