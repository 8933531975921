import { mapper, fromInput } from 'demapper';
import DetailsGroupModel from '../Detail/DetailsGroupModel';
import { ModelBaseModel } from '~app/network/mainClient/models';

@mapper
export default class {
  @fromInput('_id')
  id = null;

  @fromInput('name')
  name = null;

  @fromInput('name', { transform: x => x.replace(/[ -.]/g, '').toLowerCase() })
  slug = null;

  @fromInput('price')
  price = null;

  @fromInput('drive')
  drive = null;

  @fromInput('engineType')
  engineType = null;

  @fromInput('gear')
  gear = null;

  @fromInput('hp')
  hp = null;

  @fromInput('params', { model: DetailsGroupModel })
  detailsGroup = null;

  @fromInput('discount.skidka')
  discountPercent = 0;

  @fromInput('discount.skidkaInRoubles')
  discountValue = 0;

  @fromInput('model', { transform: x => typeof x === 'string' ? null : new ModelBaseModel(x) })
  model = null;

}
