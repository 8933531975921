import React, { Component } from 'react';
import { injectStyles } from '~app/theme';
import cn from 'classnames';

import { Icon } from '~app/components/ui';
import styles from './PaginationStyles';

@injectStyles(styles)
class Pagination extends Component {
  
  render() {
    const {
      classes,
      onClick,
      paging = null,
    } = this.props;

    let current = paging !== null ? paging.page - 1 : 0;
    let total = paging !== null ? Math.ceil(paging.totalItems/paging.pageSize) : 1;

    if (paging !== null) {
      return (
        <div className={classes.pagination}>
          <div className={classes.paginationTitle}>{`Страница ${current + 1} из ${total}`}</div>
          {total > 1 && <div className={classes.paginationItem}
            onClick={() => {onClick(current >= 1 ? current - 1 : current);}}>
            <div className={cn(classes.paginationItemIcon, classes.paginationItemIconLeft)}>
              <Icon name={'pointRight'}/>
            </div>
          </div>}
          {total > 1 && [...new Array(total)].map((_,i) => {
            const edge = current === 0 || current === total - 1;
            const d = edge ? 3 : current === 1 || current === total - 2 ? 2 : 1;
            if (Math.abs(i - current) <= d || i === 0 || i === total - 1) {
              return (
                <div
                  className={cn(classes.paginationItem, {[classes.paginationItemActive]: i === current})}
                  onClick={() => {onClick(i);}}
                  key={i}
                >
                  {i + 1}
                </div>
              );
            } else if (Math.abs(i - current) <= d + 1) {
              return (
                <div
                  className={cn(classes.paginationItemPlaceholder)}
                  onClick={() => {onClick(i);}}
                  key={i}
                >
                  {'...'}
                </div>
              );
            } else {
              return;
            }

          })}
          {total > 1 && <div className={classes.paginationItem}
            onClick={() => {onClick(current < total - 1 ? current + 1 : current);}}>
            <div className={classes.paginationItemIcon}>
              <Icon name={'pointRight'}/>
            </div>
          </div>}
        </div>
      );
    }
    else {
      return null;
    }
  }
}

export default Pagination;
