export const initialState = {
  counts: null,
  countsInProgress: false,
  countsError: null,
  
  myBrands: [],

  updateUserInProgress: null,
  updateUserSuccess: null,
  updateUserError: null,

  updateDealerInProgress: null,
  updateDealerSuccess: null,
  updateDealerError: null,

  emailConfirmInProgress: null,
  emailConfirmSuccess: null,
  emailConfirmError: null,

  sendEmailConfirmationInProgress: null,
  sendEmailConfirmationSuccess: null,
  sendEmailConfirmationError: null,
};


// Counts
export const getCountsRequest = (state) => ({
  ...state,
  countsInProgress: true,
  countsError: null
});
export const getCountsSuccess = (state, payload) => ({
  ...state,
  counts: payload,
  countsInProgress: false,
  countsError: null
});
export const getCountsFailure = (state, payload) => ({
  ...state,
  countsInProgress: false,
  countsError: payload
});

// My brands
export const toggleMyBrand = (state, { id }) => {
  const myBrands = [
    ...state.myBrands
  ];
  if (myBrands.indexOf(id) > -1) {
    myBrands.splice(myBrands.indexOf(id), 1);
  } else {
    myBrands.push(id);
  }

  return {
    ...state,
    myBrands
  };
};


// Update user
export const updateUserRequest = (state) => ({
  ...state,
  updateUserInProgress: true,
  updateUserSuccess: null,
  updateUserError: null
});
export const updateUserSuccess = (state, payload) => ({
  ...state,
  updateUserInProgress: false,
  updateUserSuccess: true,
  updateUserError: null
});
export const updateUserFailure = (state, payload) => ({
  ...state,
  updateUserInProgress: false,
  updateUserSuccess: false,
  updateUserError: payload
});


// Update dealer
export const updateDealerRequest = (state) => ({
  ...state,
  updateDealerInProgress: true,
  updateDealerSuccess: null,
  updateDealerError: null
});
export const updateDealerSuccess = (state, payload) => ({
  ...state,
  updateDealerInProgress: false,
  updateDealerSuccess: true,
  updateDealerError: null
});
export const updateDealerFailure = (state, payload) => ({
  ...state,
  updateDealerInProgress: false,
  updateDealerSuccess: false,
  updateDealerError: payload
});

// Email confirm
export const emailConfirmRequest = (state) => ({
  ...state,
  emailConfirmInProgress: true,
  emailConfirmSuccess: null,
  emailConfirmError: null
});
export const emailConfirmSuccess = (state, payload) => ({
  ...state,
  emailConfirmInProgress: false,
  emailConfirmSuccess: true,
  emailConfirmError: null
});
export const emailConfirmFailure = (state, payload) => ({
  ...state,
  emailConfirmInProgress: false,
  emailConfirmSuccess: false,
  emailConfirmError: payload
});

// Send email confirmation
export const sendEmailConfirmationRequest = (state) => ({
  ...state,
  sendEmailConfirmationInProgress: true,
  sendEmailConfirmationSuccess: null,
  sendEmailConfirmationError: null
});
export const sendEmailConfirmationSuccess = (state, payload) => ({
  ...state,
  sendEmailConfirmationInProgress: false,
  sendEmailConfirmationSuccess: true,
  sendEmailConfirmationError: null
});
export const sendEmailConfirmationFailure = (state, payload) => ({
  ...state,
  sendEmailConfirmationInProgress: false,
  sendEmailConfirmationSuccess: false,
  sendEmailConfirmationError: payload
});