import client from './_client';
import { UserMeModel } from './models';

// Registration
export const register = (data) => {
  const params = {};

  params['name'] = data.name;

  if (data.surname) {
    params['surname'] = data.surname;
  }

  if (data.email) {
    params['email'] = data.email;
  }
  params['phone'] = data.phone;

  return client.request({
    url: '/v1/register',
    method: 'POST',
    data: params
  });
};


// Login
export const login = (data) => {
  const params = {};

  if (data.email) {
    params['email'] = data.email;
  }

  params['phone'] = data.phone;

  return client.request({
    url: '/v1/login',
    method: 'POST',
    data: params
  });
};

// Code
export const checkCode = (data) => {
  const params = {};

  if (data.email) {
    params['email'] = data.email;
  }

  params['phone'] = data.phone;

  params['smscode'] = data.code;

  return client.request({
    url: '/v1/checksms',
    method: 'POST',
    data: params,
    transformData: data => ({
      token: data.token,
      user: new UserMeModel(data.user)
    })
  });
};

// Login dealer
export const dealerLogin = (data) => {
  const params = {};

  params['email'] = data.email;
  params['password'] = data.password;

  return client.request({
    url: '/v1/dealer_login',
    method: 'POST',
    data: params,
    transformData: data => ({
      token: data.token,
      user: new UserMeModel(data.dealer)
    })
  });
};

// Login registration
export const dealerRegistration = (params) => {

  return client.request({
    url: '/v1/dealer_registration',
    method: 'POST',
    data: params,
  });
};
