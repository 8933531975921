export default (theme) => ({
  wrapper: {
    minHeight: 108,
  },
  head: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    opacity: 0,
    zIndex: 0,

    // backgroundColor: '#fff',

    transform: 'translateY(40px)',
    transition: 'opacity .75s ease, transform .75s ease .75s',
  },
  headActive: {
    opacity: 1,
    zIndex: 3,

    transform: 'translateY(0)',
    transition: 'opacity .75s ease .25s, transform .75s ease .25s',
  },

  body: {
    opacity: 0,

    transition: 'opacity .75s ease',
  },
  bodyActive: {
    opacity: 1,
  },

  bodyHead: {
    display: 'flex',
    position: 'relative',
    padding: '20px 0',

    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bodyHeadMain: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  bodyHeadBackground: {
    marginBottom: 35,
    paddingBottom: 43,

    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: -23,
      right: -50,
      bottom: 0,
      left: -50,
      zIndex: 0,

      borderRadius: '6px 6px 0 0',

      backgroundColor: theme.colors.accent.light,
    }
  },

  bodyHeadTitle: {
    paddingRight: 32,
    fontSize: 18,
  },
  bodyHeadNumber: {
    fontSize: 14,

    color: theme.colors.gray.main,
  },

  [theme.queries.mdDevicesDown]: {
    bodyHead: {
      alignItems: 'flex-start',
    },
    bodyHeadBackground: {
      paddingTop: 2,
      paddingBottom: 7,
      '&:before': {
        left: '-20px',
        right: '-20px',
      }
    },

    bodyHeadMain: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    bodyHeadTitle: {
      paddingRight: 20,
      paddingBottom: 18,
      fontSize: 14,
    },
  }
});