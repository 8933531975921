export const styles = theme => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  item: {
    width: '100%',
    marginBottom: '23px',

    cursor: 'pointer',

    '&:hover': {
      '& $itemName': {
        color: theme.colors.brand.main,
      }
    }
  },
  itemIcon: {
    width: '100%',
    position: 'relative',
  },
  itemIconImg: {
    width: '100%',
  },
  itemName: {
    paddingTop: '5px',

    fontSize: '14px',
    fontWeight: '500',
    textAlign: 'center',

    transition: 'color .5s ease',
  },


  [theme.queries.mdDevicesDown]: {
    list: {
      justifyContent: 'space-between',
    },
    item: {
      width: 'calc(50% - 18px)',
    }
  },

  [theme.queries.xsDevicesDown]: {
    item: {
      width: '100%',
    }
  },

  [theme.queries.lgDevicesUp]: {
    item: {
      width: 'calc((100% - 18px*4)/5 + 18px)',
      paddingRight: '18px',

      '&:nth-child(5n)': {
        width: 'calc((100% - 18px*4)/5)',
        paddingRight: 0,
      }
    }
  },
});