export const styles = theme => ({
  root: {
    display: 'flex',
    minHeight: 'calc(100vh - 100px)',

    alignItems: 'center',
    justifyContent: 'center',
  },

  icon: {
    width: 48,
    margin: '0 auto',
  },

  content: {
    textAlign: 'center',
  },

  text: {
    maxWidth: 330,
    padding: '16px 0 24px',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '28px',
  },


  [theme.queries.lgDevicesUp]: {
  },

  [theme.queries.mdDevicesDown]: {
  },
});