import React, { Component } from 'react';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import styles from './LanderIconsBlockStyles';
import { Icon } from '~app/components/ui';


import PropTypes from 'prop-types';

@injectStyles(styles)

class LanderIconsBlock extends Component {
  render = () => {
    const {
      classes,
      dividers = true,
      variant = 'center',
      texts = [],
      icons = []
    } = this.props;

    return (
      <div
        className={cn(classes.root, { [classes.left]: variant == 'left' })}>
        {icons.map((icon, index) => {
          const text = texts[index];
          if (!text) {
            return null;
          }
          return [<div key={icon} className={classes.item}>
            <div className={classes.icon}>
              <Icon block name={icon} />
            </div>
            {text.header && <h4 className={classes.title}>{text.header}</h4>}
            {text.description && <p className={classes.text}>{text.description}</p>}
          </div>,
          dividers && index < (icons.length - 1) &&
          <div key={'divider'} className={classes.divider} />
          ];
        }
        )}
      </div>
    );
  }
}

LanderIconsBlock.propTypes = {
  dividers: PropTypes.bool,
  variant: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    title: PropTypes.node,
    text: PropTypes.node
  }))
};

export default LanderIconsBlock;
