import reducers from '~app/core/reducers';
import { mainClient } from '~app/network';

export const sendMessage = async ({ offerId, ...params }) => {
  reducers.chat.sendMessageRequest();
  const { data, error } = await mainClient.chat.sendMessage({ offerId, ...params });
  
  if (error !== null) {
    reducers.chat.sendMessageFailure(error);
    return;
  }

  reducers.chat.sendMessageSuccess(data);
};

export const resetChatState = () => reducers.chat.resetChatState();