import React, { Component } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import actions from '~app/redux/actions';
import { NavLink, Link } from 'react-router-dom';

import { IS_BROWSER } from '~app/config';
import { Icon, Burger, Button } from '../ui';
import { freezeScroll } from '../../utils/helpers';
// import { AuthModal } from '~app/components';
import { injectStyles } from '~app/theme';
import styles from './HeaderStyles';
import Constants from 'Constants';

const mapStateToProps = state => ({
  user: state.auth.user,
  counts: state.user.counts,
  location: state.router.location.pathname,
});


@connect(mapStateToProps)
@injectStyles(styles)
class Header extends Component {

  state = {
    menuOpened: false,
    lastScrollY: 0,
    headerHidden: false,
    scrollTop: 0,
  }


  componentWillMount = () => {
    if (IS_BROWSER) {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount = () => {
    if (IS_BROWSER) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    if (!this.state.menuOpened) {
      let newScrollY = window.scrollY;
      const lastScrollY = this.state.lastScrollY;

      if (lastScrollY >= 60) {
        if (lastScrollY >= newScrollY) {
          this.setState({
            headerHidden: false,
          });
        }
        else {
          this.setState({
            headerHidden: true,
          });
        }
      }

      this.setState({
        lastScrollY: newScrollY,
      });
    }
  }


  triggerMobileMenu = () => {
    let newMenuState = !this.state.menuOpened;

    this.setState({
      menuOpened: newMenuState
    });

    if (newMenuState) {
      freezeScroll();
    }
    else {
      freezeScroll(true);
    }
  }
  closeMobileMenu = () => {
    this.setState({
      menuOpened: false
    });
  }


  openAuthModal = (modal) => {
    actions.common.openAuthModal('auth');
  }

  openSupportModal = () => {
    actions.common.openModal({
      type: 'support'
    });
  }

  burgerOnPress = () => {
    this.triggerMobileMenu();
  }
  loginOnPress = () => {
    this.openAuthModal();
    this.closeMobileMenu();
  }
  logoutOnPress = () => {
    actions.auth.logout();
    this.closeMobileMenu();
  }

  getNavLinkContent = (text, countField = null) => {
    const {
      counts,
      classes
    } = this.props;
    let count = 0;

    if (counts !== null && countField in counts ) {
      count = counts[countField];
    }
    return (
      <span className={classes.menuLink}>
        {text}
        {count > 0 &&
          <span className={classes.menuLinkIcon}>
            {/* {count} */}
            {'!'}
          </span>
        }
      </span>
    );
  }

  render = () => {
    const {
      classes,
      user,
    } = this.props;

    let isHome = this.props.location === '/';

    return (
      <div className={cn(classes.wrapper, isHome ? classes.wrapperHome : null)}>
        <div
          className={cn(
            classes.root,
            this.state.headerHidden ? classes.headerHidden : null,
            this.state.menuOpened ? classes.rootMenuOpened : null,
          )}
        >
          {/* <AuthModal
            open={this.state.modal === 'auth'}
            onRequestClose={() => {this.closeModal('auth');}}
          /> */}
          <div className={classes.logo}>
            <Link
              to='/'
              className={classes.logoLink}
            />
            <div className={classes.logoImg}>
              <Icon name={'logo'}/>
            </div>
          </div>
          <div className={classes.mobileMenu}>
            <div className={cn(classes.mobileBtn, classes.mobileBtnCall)}>
              <a href='tel:+78005117340' className={classes.mobileBtnLink}/>
              <div className={classes.mobileBtnIcon}>
                <Icon name={'phone'} />
              </div>
            </div>
            <div
              onClick={this.openSupportModal}
              className={cn(classes.mobileBtn, classes.mobileBtnInfo)}
            >
              <div className={classes.mobileBtnIcon}>
                <Icon name={'supportQuestion'} />
              </div>
            </div>
            <div className={classes.burger}>
              <Burger size={60} padding={20} active={this.state.menuOpened} onPress={this.burgerOnPress} />
            </div>
          </div>
          <div className={classes.content}>
            <div className={classes.contentLinks}>
              <NavLink
                exact
                to='/application'
                className={classes.contentLinksButton}
                onClick={this.closeMobileMenu}
              >
                <Button
                  // onClick={this.onReset}
                  size={'sm'}
                  theme={'brand'}
                  text={'Создать заявку'}
                />
              </NavLink>
              <NavLink
                exact
                to='/promo'
                className={classes.contentLink}
                activeClassName={classes.contentLinkActive}
                onClick={this.closeMobileMenu}
              >
                {'Акции'}
              </NavLink>
              <NavLink
                exact
                to='/news'
                className={classes.contentLink}
                activeClassName={classes.contentLinkActive}
                onClick={this.closeMobileMenu}
              >
                {'Новости'}
              </NavLink>
              <NavLink
                exact
                to='/dealers'
                className={classes.contentLink}
                activeClassName={classes.contentLinkActive}
                onClick={this.closeMobileMenu}
              >
                {'Дилеры'}
              </NavLink>
              {/* <NavLink
                to='/dealer/applications'
                className={classes.contentLink}
                activeClassName={classes.contentLinkActive}
              >
                {'Дилеры'}
              </NavLink> */}
              <NavLink
                to='/contacts'
                className={classes.contentLink}
                activeClassName={classes.contentLinkActive}
                onClick={this.closeMobileMenu}
              >
                {'Контакты'}
              </NavLink>
            </div>
            <div className={classes.contentControls}>
              <div className={classes.desktopControls}>
                <div className={classes.city}>
                  <div className={classes.cityIcon}>
                    <Icon name={'pin'}/>
                  </div>
                  <div className={classes.cityActive}>{'Санкт-Петербург'}</div>
                </div>
                <div className={classes.phone}>
                  <a href={`tel:${Constants.PHONE_NUMBER_PLAIN}`} className={classes.phoneLink}/>
                  <div className={classes.phoneIcon}>
                    <Icon name={'phone'}/>
                  </div>
                  <div className={classes.phoneText}>{Constants.PHONE_NUMBER_BEAUTY}</div>
                </div>
              </div>
              <div className={classes.user}>
                {user === null &&
                  <div onClick={this.loginOnPress} className={classes.userLoginBtn}>{'Вход'}</div>
                }
                {user && !user.isDealer &&
                  <div className={classes.userContent}>
                    <div className={classes.userHead}>
                      <div className={classes.userHeadText}>{user.firstName}</div>
                      <div className={classes.userHeadIcon}>
                        <Icon name={'point2Down'} />
                      </div>
                    </div>
                    <div className={classes.userBody}>
                      <div className={classes.userLinks}>
                        <div className={classes.userLink}>
                          <Link
                            to='/cabinet'
                            className={classes.userLinkLink}
                            onClick={this.closeMobileMenu}
                          />
                          <div className={classes.userLinkName}>
                            {this.getNavLinkContent('Мои заявки', 'applicationsNew')}
                          </div>
                        </div>
                        <div className={classes.userLink}>
                          <Link
                            to='/cabinet/deals'
                            className={classes.userLinkLink}
                            onClick={this.closeMobileMenu}
                          />
                          <div className={classes.userLinkName}>
                            {this.getNavLinkContent('Мои сделки', 'dealsNew')}
                          </div>
                        </div>
                        <div className={classes.userLink}>
                          <Link
                            to='/cabinet/reviews'
                            className={classes.userLinkLink}
                            onClick={this.closeMobileMenu}
                          />
                          <div className={classes.userLinkName}>{'Мои отзывы'}</div>
                        </div>
                      </div>
                      <div className={classes.userLogout}>
                        <div onClick={this.logoutOnPress} className={classes.userLogoutBtn}>{'Выйти'}</div>
                      </div>
                    </div>
                  </div>
                }
                {user && user.isDealer &&
                  <div className={classes.userContent}>
                    <div className={classes.userHead}>
                      <div className={classes.userHeadText}>{user.firstName}</div>
                      <div className={classes.userHeadIcon}>
                        <Icon name={'point2Down'} />
                      </div>
                    </div>
                    <div className={classes.userBody}>
                      <div className={classes.userLinks}>
                        <div className={classes.userLink}>
                          <Link
                            to='/dealer'
                            className={classes.userLinkLink}
                          />
                          <div className={classes.userLinkName}>
                            {this.getNavLinkContent('Все заявки', 'dealer')}
                          </div>
                        </div>
                        <div className={classes.userLink}>
                          <Link
                            to='/dealer/offers'
                            className={classes.userLinkLink}
                          />
                          <div className={classes.userLinkName}>
                            {this.getNavLinkContent('Мои офферы', 'offersNew')}
                          </div>
                        </div>
                        <div className={classes.userLink}>
                          <Link
                            to='/dealer/deals'
                            className={classes.userLinkLink}
                          />
                          <div className={classes.userLinkName}>
                            {this.getNavLinkContent('Мои сделки', 'dealsNew')}
                          </div>
                        </div>
                        <div className={classes.userLink}>
                          <Link
                            to='/dealer/reviews'
                            className={classes.userLinkLink}
                          />
                          <div className={classes.userLinkName}>
                            {this.getNavLinkContent('Мои отзывы', 'reviewsNew')}
                          </div>
                        </div>
                      </div>
                      <div className={classes.userLogout}>
                        <div onClick={this.logoutOnPress} className={classes.userLogoutBtn}>{'Выйти'}</div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className={classes.mobileMenuBottom}>
              <div className={classes.city}>
                <div className={classes.cityIcon}>
                  <Icon name={'pin'}/>
                </div>
                <div className={classes.cityActive}>{'Санкт-Петербург'}</div>
              </div>
              <div className={classes.socials}>
                <div className={classes.socialItem}>
                  <a href='www.facebook.com/avicar.ru/' target={'_blank'} className={classes.socialItemLink} />
                  <div style={{ width: 20 }}>
                    <Icon name={'socialFb'} />
                  </div>
                </div>
                <div className={classes.socialItem}>
                  <a href='https://vk.com/avicar' target={'_blank'} className={classes.socialItemLink} />
                  <div style={{ width: 20 }}>
                    <Icon name={'socialVk'} />
                  </div>
                </div>
                <div className={classes.socialItem}>
                  <a href='https://ok.ru/avicar' target={'_blank'} className={classes.socialItemLink} />
                  <div style={{ width: 10 }}>
                    <Icon name={'socialOk'} />
                  </div>
                </div>
                <div className={classes.socialItem}>
                  <a href='https://www.instagram.com/avicar.ru/' target={'_blank'} className={classes.socialItemLink} />
                  <div style={{ width: 16 }}>
                    <Icon name={'socialIg'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
