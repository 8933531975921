import { mapper, fromInput } from 'demapper';

@mapper
export default class {
  @fromInput('_id')
  id = null;

  @fromInput('name')
  firstName = null;

  @fromInput('surname')
  lastName = null;

  @fromInput('name', { transform: (field, input) => `${input.name}${input.surname ? ' ' + input.surname : ''}` })
  fullName = null
  
  @fromInput('email')
  email = null;

  @fromInput('emailVerified')
  emailVerified = null;
  
  @fromInput('phone')
  phone = null;
  
  @fromInput('phoneVerified')
  phoneVerified = null;

  @fromInput('createdAt')
  createdAt = null;

  @fromInput('address')
  address = null; // пока не знаю, как будет это поле называться, но оно нужно

  @fromInput('score')
  ratingScore = null;
  @fromInput('positive')
  positiveReviewsCount = null;
  @fromInput('negative')
  negativeReviewsCount = null;
}
