export const styles = theme => ({
  root: {
    width: '100%',
    position: 'relative',

    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 10,
      bottom: 0,
      left: 10,
      zIndex: 0,
      opacity: 0,

      borderRadius: props => props.borderRadius ? props.borderRadius : 20,
      boxShadow: '0 8px 14px 0 rgba(60, 60, 80, 0.08)',

      transition: 'opacity .5s ease',
    },
  },
  rootHoverable: {
    '&:hover': {
      '&:before': {
        opacity: 1,
      }
    }
  },
  rootShadow: {
    '&:before': {
      opacity: 1,
    }
  },

  card: {
    width: '100%',
    position: 'relative',
    zIndex: 2,

    borderRadius: props => props.borderRadius ? props.borderRadius : 20,
    boxShadow: '0 2px 4px 0 rgba(60,60,80,.08)',

    backgroundColor:  props => props.color ? props.color : '#fff',
  },

  tick: {
    width: 0,
    height: 0,
    position: 'absolute',
    bottom: 0,
    zIndex: 1,

    borderStyle: 'solid',
  },
  tickShadow: {
    bottom: -2,
    zIndex: 0,
  },

  _tickLeft: {
    left: 0,

    borderWidth: '10px 0 10px 10px',
    borderColor: props => props.color ? `transparent transparent transparent ${props.color}` : 'transparent transparent transparent #fff',
  },
  _tickShadowLeft: {
    left: -1,

    borderWidth: '11px 0 11px 11px',
    borderColor: 'transparent transparent transparent rgba(60,60,80,.04)',
  },
  _tickRight: {
    right: 0,
    
    borderWidth: '10px 10px 10px 0',
    borderColor: props => props.color ? `transparent ${props.color} transparent transparent` : 'transparent #fff transparent transparent',
  },
  _tickShadowRight: {
    right: -1,

    borderWidth: '11px 11px 11px 0',
    borderColor: 'transparent rgba(60,60,80,.04) transparent transparent',
  },
});