import React, { Component } from 'react';
import { animateScroll as scroll } from 'react-scroll';

import { Icon } from '../';
import { injectStyles } from '~app/theme';
import styles from './ScrollToTopStyles';

@injectStyles(styles)
class ScrollToTop extends Component {

  render = () => {
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.root} onClick={() => { scroll.scrollToTop(); }}>
        <div className={classes.icon}>
          <Icon name={'point2Down'}/>
        </div>
      </div>
    );
  }
}

export default ScrollToTop;