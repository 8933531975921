import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import { injectStyles } from '~app/theme';
import styles from './ContainerStyles';

@injectStyles(styles)
class Container extends Component {
  render = () => {
    const {
      classes,
      background,
      fullHeight
    } = this.props;

    return (
      <div
        className={cn(classes.wrapper, { [classes.fullHeight]: fullHeight })}
        style={{ backgroundColor: background }}>
        <div className={cn(classes.root, { [classes.fullHeight]: fullHeight })}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

Container.propTypes = {
  centerPage: PropTypes.bool,
  fullHeight: PropTypes.bool,
  background: PropTypes.string,
};

Container.defaultProps = {
  centerPage: false,
  fullHeight: false,
  background: 'transparent',
};

export default Container;
