export default theme => ({
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '20px 2px 20px 0',
  },
  paginationTitle: {
    marginRight: '18px',
    fontSize:'12px',
  },
  paginationItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    margin: '0 2px',

    background: '#FFFFFF',

    fontSize:'12px',

    boxShadow: '0 2px 1px 0 rgba(60,60,80,0.04)',
    borderRadius: '3px',
    cursor: 'pointer',
    userSelect: 'none',

    transition: 'color 0.3s ease-in-out, background-color 0.3s ease-in-out',
    '&:hover': {
      color: theme.colors.gray.dark,

      background: theme.colors.brand.lightest,
    }
  },
  paginationItemIcon: {
    width: 4,
    lineHeight: 0,
  },
  paginationItemIconLeft: {
    transform: 'rotate(180deg)',
  },
  paginationItemActive: {
    color: '#FFFFFF',

    background: theme.colors.brand.main,
  },
  paginationItemPlaceholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    margin: '0 2px',

    color: theme.colors.gray.dark,
  },

  [theme.queries.smDevicesDown]: {
    paginationTitle: {
      display:'none',
    },
  },
});