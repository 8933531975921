import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import styles from './HintStyles';

@injectStyles(styles)
class Hint extends Component {
  render = () => {
    const {
      classes,
      onClick,
      isActive,
      place
    } = this.props;

    return (
      <div
        onClick={onClick}
        className={cn(classes.root, {
          [classes.rootActive]: isActive,
          [classes.offerBubble]: place == 'offerBubble',
          [classes.applicationTable]: place == 'applicationTable',
          [classes.menuItems]: place == 'menuItems'
        })}>
        <div className={classes.content}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

Hint.propTypes = {
  direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  margin: PropTypes.number,
  place: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

Hint.defaultProps = {
  direction: 'bottom',
  margin: 0,
  place: null,
  onClick: undefined,
  isActive: false,
};

export default Hint;
