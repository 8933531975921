import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '~app/redux/actions';
import { injectStyles } from '~app/theme';
import { push } from 'connected-react-router-exposed';

import { Container, Icon, Breadcrumbs, Spacer } from '../../../components/ui';
import { UserProfileCard, DealCard, MessageCard } from '../../../components';

import { styles } from './CabinetDealerDealScreenStyles';


const messages = [];


const mapStateToProps = (state, ownProps) => {
  const offerId = ownProps.match.params.id;

  let itemState = {
    item: null,
    inProgress: false,
    error: null
  };
  if (offerId in state.offers.itemById) {
    itemState = state.offers.itemById[offerId];
  }

  return {
    offerId,
    ...itemState
  };
};

const mapDispatchToProps = {
  push
};

@connect(mapStateToProps, mapDispatchToProps)
@injectStyles(styles)
class CabinetUserDealScreen extends Component {

  componentDidMount = () => {
    this.loadData();
  }

  loadData = (props = this.props) => {
    actions.offers.getItemById(this.props.offerId);
    // if (props.applications === null && !props.applicationsInProgress && props.applicationsError === null) {
    //   actions.applications.getApplications();
    // }
  }

  archiveOnClick = () => {
    const { offerId } = this.props;
    actions.common.openModal({
      type: 'archive',
      params: {
        offerId,
        title: 'Убрать в архив',
        text: 'Сделка и контактная информация будут храниться в архиве до момента удаления.',
        btnText: 'В архив',
        onSuccess: () => {
          this.props.push('/dealer/deals');
        }
      }
    });
    // const { classes } = this.props;


    // actions.common.openGenericModal({
    //   theme: 'general',
    //   title: 'Убрать в архив',
    //   text: 'Сделка отправится в архив и потеряет все предложения дилеров. Продолжить?',
    //   additionalContent: (
    //     <div className={classes.modalArchiveButton}>
    //       <Button
    //         theme={'rare'}
    //         text={'В архив'}
    //         leftIcon={<Icon name={'times'}/>}
    //         block
    //       />
    //     </div>
    //   ),
    // });
  }

  getDefaultMessage = () => {
    let date = null;
    if (this.props.item !== null) {
      date = this.props.item.updatedDate;
    }
    return {
      name: 'Avicar',
      text: 'Поздравляем, покупатель принял ваше предложение! Не заставляйте его долго ждать и свяжитесь в ближайшее время по телефону или электронной почте.',
      date,
    };
  }

  renderMessages = () => {
    return(
      <div>
        <MessageCard asDealer item={this.getDefaultMessage()} offer={this.props.item} />
        {messages.map((item, index) => {
          return (
            <div key={index}>
              <MessageCard asDealer item={item} />
              <Spacer v={12}/>
            </div>
          );
        })}
      </div>
    );
  }

  render = () => {
    const {
      classes,
      item,
    } = this.props;

    return (
      <div className={classes.root}>
        <Container>
          <div className={classes.head}>
            <Breadcrumbs
              options={[
                {
                  href: '/',
                  name: 'Главная',
                },
                {
                  href: '/dealer',
                  name: 'Кабинет дилера',
                },
                {
                  href: '/dealer/deals',
                  name: 'Мои сделки',
                }
              ]}
            />
            {item !== null && !item.archivedByDealer &&
              <div
                onClick={this.archiveOnClick}
                className={classes.headLink}
              >
                <div className={classes.headLinkIcon}>
                  <Icon name={'timesThinest'}/>
                </div>
                {'В архив'}
              </div>
            }
          </div>

          {item !== null &&
            <div className={classes.headCard}>
              <UserProfileCard dealScreen user={item.user}/>
              <div className={classes.headDevider}/>
              <DealCard item={item} borderRadius={'0 0 6px 6px'} showActions={false} />
            </div>
          }

          <div className={classes.body}>
            {this.renderMessages()}
          </div>

        </Container>
      </div>
    );
  }
}

export default CabinetUserDealScreen;
