import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router';
import { ThemeProvider } from 'react-jss';

import actions from '~app/redux/actions';
import { MainTheme } from '~app/theme';
import { IS_BROWSER } from '~app/config';
import { ConditionalRoute, Preloader, AuthModal, DealerAuthModal, GenericModal } from '~app/components';
import Notifications from '~app/modules/Notifications/Notifications';
import DataHooks from '~app/modules/DataHooks/DataHooks';
import ModalsContainer from '~app/modules/ModalsContainer/ModalsContainer';
import ContainerMain from './ContainerMain';
import ContainerDealer from './ContainerDealer';
import ContainerUser from './ContainerUser';
import ContainerLander from './ContainerLander';

import 'normalize.css/normalize.css';

const mapStateToProps = state => ({
  auth: state.auth,
  authModalState: state.common.authModalState,
  dealerAuthModalState: state.common.dealerAuthModalState,
  genericModal: state.common.genericModal,
});

@connect(mapStateToProps)
class ContainerRoot extends Component {

  state = {
    restoreHolding: true,
  }

  componentWillMount = () => {
    if (IS_BROWSER) {
      this.restoreAuth();
      this.getBanners();
    }
  }

  getBanners = () => {
    actions.banners.getBanners();
  }

  restoreAuth = () => {
    if (!this.props.auth.restoreSuccess && !this.props.auth.restoreInProgress) {
      actions.auth.restoreAuth();
    }
    setTimeout(() => {
      this.setState({
        restoreHolding: false
      });
    }, 1500);
  }

  render = () => {
    const {
      user,
      restoreInProgress,
      restoreSuccess,
    } = this.props.auth;
    const {
      authModalState,
      dealerAuthModalState,
      genericModal,
    } = this.props;

    const realRestoring = IS_BROWSER && (restoreInProgress || restoreSuccess === null);
    const showPreloader = IS_BROWSER && (realRestoring || this.state.restoreHolding);
    const isDealer = user !== null && user.isDealer;
    const isUser = user !== null && !user.isDealer;
    return (
      <ThemeProvider theme={MainTheme}>
        <Fragment>
          <Preloader show={showPreloader} />
          {!realRestoring &&
            <Fragment>
              <Switch>
                <ConditionalRoute path={'/dealer'} component={ContainerDealer} allow={isDealer} redirect={'/'} />
                <ConditionalRoute path={'/cabinet'} component={ContainerUser} allow={isUser} redirect={'/'} />
                <Route path={'/brands'} component={ContainerLander} />
                <Route path={'/auto'} component={ContainerLander} />
                <Route path={'/'} component={ContainerMain} />
              </Switch>
            </Fragment>
          }
          {IS_BROWSER && (
            <Fragment>
              <Notifications />
              <DataHooks />
              <AuthModal
                open={authModalState !== null}
                tab={authModalState}
                onRequestClose={() => {actions.common.closeAuthModal();}}
              />
              <DealerAuthModal
                open={dealerAuthModalState !== null}
                tab={dealerAuthModalState}
                onRequestClose={() => {actions.common.closeDealerAuthModal();}}
              />
              <ModalsContainer />
              <GenericModal
                open={genericModal.open}
                title={genericModal.title}
                text={genericModal.text}
                icon={genericModal.icon}
                theme={genericModal.theme}
                additionalContent={genericModal.additionalContent}
                thinPaddings={genericModal.theme}
                onRequestClose={() => {
                  if (genericModal.onClose) {
                    genericModal.onClose();
                  }
                  actions.common.closeGenericModal();
                }}
              />
            </Fragment>
          )}
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default ContainerRoot;
