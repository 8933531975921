import client from './_client';
import { PartnerBaseModel } from './models';
import { transformListModel } from './_helpers';


export const getPartnersByBrands = (brands = []) => {
  return client.request({
    // url: '/v1/companies',
    url: '/v1/company_groups',
    method: 'GET',
    params: { universal: true },
    // params: { brands: brands.join(',') },
    transformData: data => ({ items: transformListModel(PartnerBaseModel)(data) })
  });
};

export const getPartners = () => {
  return client.request({
    url: '/v1/company_groups',
    method: 'GET',
    transformData: data => ({ items: transformListModel(PartnerBaseModel)(data) })
  });
};
