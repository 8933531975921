import reducers from '~app/core/reducers';
import { generateUUID } from '~app/utils/helpers';

export const addItem = (options) => {
  reducers.notifications.addItem({
    id: generateUUID(),
    options
  });
};

export const removeItem = (id) => {
  reducers.notifications.removeItem({ id });
};

export const removeAll = () => {
  reducers.notifications.removeAll();
};