import React, { Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';

import { injectStyles } from '~app/theme';
import { Loader } from '~app/components/ui';
import styles from './PreloaderStyles';

@injectStyles(styles)
class Preloader extends Component {
  render = () => {
    const {
      classes,
      show = true
    } = this.props;
    return (
      <CSSTransitionGroup
        transitionEnterTimeout={0}
        transitionLeaveTimeout={600}
        transitionName={{
          leave: classes._leave,
          leaveActive: classes._leaveActive,
          enter: '',
          enterActive: '',
        }}
      >
        {show &&
          <div className={classes.root}>
            <div className={classes.loader}>
              <Loader theme={'white'} />
            </div>
          </div>
        }
      </CSSTransitionGroup>
      // <div/>
    );
  }
}

export default Preloader;