import React, { Component } from 'react';

import { Hint } from '../../../../components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './ColorStyles';


@injectStyles(styles)
class ColorItem extends Component {

  state = {
    isActive: false,
  };

  render() {
    const { classes, item } = this.props;

    return (
      <div className={classes.item}>
        <div
          className={classes.itemCircle}
          style={{backgroundImage: `url(${item.image})`}}
          onMouseOver={() => { this.setState({ isActive: true }); }}
          onMouseLeave={() => { this.setState({ isActive: false }); }}
        />
        <Hint isActive={this.state.isActive}>
          <div className={classes.itemNameText}>{item.name}</div>
        </Hint>
      </div>
    );
  }
}

@injectStyles(styles)
class Color extends Component {

  onChange = (name) => {
    this.props.onChangeOption(name);
  }

  renderItem = (item) => {
    return (
      <div
        onClick={() => this.onChange(item.slug)}
        key={item.id}
      >
        <ColorItem item={item} />
      </div>
    );
  }

  render() {
    const {
      classes,
      colors,
    } = this.props;
    return (
      <div className={classes.list}>
        {colors !== null && colors.map((item) => {
          return (this.renderItem(item));
        })}
      </div>
    );
  }
}

export default Color;