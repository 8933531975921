import React, { Component } from 'react';
import { injectStyles } from '~app/theme';

import { Container } from '~app/components/ui';
import { styles } from './AuthDealerScreenStyles';


@injectStyles(styles)
class AuthDealerScreen extends Component {

  render = () => {
    const {
      classes,
    } = this.props;

    return (
      <Container centerPage>
        <div className={classes.root}>
          AuthDealerScreen
        </div>
      </Container>
    );
  }
}

export default AuthDealerScreen;