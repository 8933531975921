import { mapper, fromInput } from 'demapper';

import { transformImageUrl } from '../../_helpers';

@mapper
export default class {
  @fromInput('_id')
  id = null;

  @fromInput('slug')
  slug = null;

  @fromInput('name')
  name = null;

  @fromInput('heroImage.secure_url', { transform: transformImageUrl })
  heroUrl = null;

  @fromInput('logo_icon.secure_url', { transform: transformImageUrl })
  logoUrl = null;

  @fromInput('landingImage.secure_url', { transform: transformImageUrl })
  landingLogoUrl = null;

  @fromInput('invertedTextColor')
  invertedTextColor = false;
}
