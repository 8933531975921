import React, { Component } from 'react';
import ReactModal from 'react-modal';
import cn from 'classnames';
import { injectStyles } from '~app/theme';
import { connect } from 'react-redux';
import { formContainer, formCreate } from 'deforms';
// import actions from '~app/redux/actions';

import { Button, TextInput, Select } from '~app/components/ui';
import { freezeScroll } from '../../utils/helpers';

import styles from './TradeInModalStyles';

import { schema as tradeInSchema } from '~app/config/tradeInOptions';

const mapStateToProps = (state) => {
  return {
    driveWheelTypes: state.dictionary.driveWheelTypes,
    transmissionTypes: state.dictionary.transmissionTypes,
    productionYears: state.dictionary.productionYears,
  };
};

@connect(mapStateToProps)
@injectStyles(styles)
@formContainer
class TradeInModal extends Component {

  @formCreate()
  form = {
    schema: tradeInSchema,
    onSubmit: (formData) => {
      this.onSuccess(formData);
    }
  }

  componentDidMount() {
    if (this.props.data && Object.keys(this.props.data).length > 0) {
      for (const key in this.props.data) {
        this.form.fields[key].setValue(this.props.data[key]);
      }
    }
  }

  onSuccess = (formData) => {
    this.props.onSaveTradeInForm({ data: formData, filled: true });
    this.props.onRequestClose();
  }

  onReset = event => {
    event.preventDefault();
    this.props.onSaveTradeInForm({ filled: false });
    this.props.onRequestClose();
  }

  onRequestClose = () => {
    this.props.onRequestClose();
  }

  onOpen = () => {
    freezeScroll();
  }

  render() {
    const {
      classes,
      isOpen,
      driveWheelTypes,
      transmissionTypes,
      productionYears,
    } = this.props;

    const form = this.form;

    return (
      <ReactModal
        className={{
          base: classes.modal,
          afterOpen: classes.modalAfterOpen,
          beforeClose: classes.modalBeforeClose,
        }}
        overlayClassName={{
          base: classes.overlay,
          afterOpen: classes.overlayAfterOpen,
          beforeClose: classes.overlayBeforeClose,
        }}
        isOpen={isOpen}
        onAfterOpen={this.onOpen}
        closeTimeoutMS={300}
        key={this.props.isOpen}
        contentLabel={'modal'}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick>
        <a
          className={classes.close}
          onClick={this.onRequestClose} />
        <div className={classes.content}>
          <h3 className={classes.title}>{'Программа Trade-in'}</h3>
          <p className={classes.subtitle}>{'Укажи данные своего автомобиля'}</p>
          <form
            noValidate
            className={classes.form}
            onSubmit={form.submit}>
            <div className={cn(classes.formInput, classes.formInputHalf)}>
              <TextInput
                name={'brand'}
                placeholder={'Марка авто'}
                onChange={form.fields.brand.change}
                value={form.fields.brand.value}
                error={form.submitted && !form.fields.brand.valid}
              />
            </div>
            <div className={cn(classes.formInput, classes.formInputHalf)}>
              <TextInput
                name={'model'}
                placeholder={'Модель авто'}
                onChange={form.fields.model.change}
                value={form.fields.model.value}
                error={form.submitted && !form.fields.model.valid}
              />
            </div>
            <div className={cn(classes.formInput, classes.formInputHalf)}>
              <TextInput
                name={'engineVolume'}
                placeholder={'Объем двигателя'}
                onChange={form.fields.engineVolume.change}
                value={form.fields.engineVolume.value}
                error={form.submitted && !form.fields.engineVolume.valid}
              />
            </div>
            <div className={cn(classes.formInput, classes.formInputHalf)}>
              <Select
                name={'drive'}
                placeholder={'Привод'}
                onChange={form.fields.drive.changeValue}
                value={form.fields.drive.value}
                error={form.submitted && !form.fields.drive.valid}
                options={driveWheelTypes}
                textField={'name'}
                valueField={'id'}
              />
            </div>
            <div className={cn(classes.formInput, classes.formInputHalf)}>
              <Select
                name={'gear'}
                placeholder={'Коробка'}
                onChange={form.fields.gear.changeValue}
                value={form.fields.gear.value}
                error={form.submitted && !form.fields.gear.valid}
                options={transmissionTypes}
                textField={'name'}
                valueField={'id'}
              />
            </div>
            <div className={cn(classes.formInput, classes.formInputHalf)}>
              <Select
                name={'year'}
                placeholder={'Год выпуска'}
                onChange={form.fields.year.changeValue}
                value={form.fields.year.value}
                error={form.submitted && !form.fields.year.valid}
                options={productionYears}
                textField={'name'}
                valueField={'id'}
              />
            </div>
            <p className={classes.text}>{'Идентификационный номер транспортного средства позволит дилеру сделать самое точное и выгодное предложение:'}</p>
            <div className={classes.formInput}>
              <TextInput
                name={'vin'}
                placeholder={'VIN (17 символов)'}
                onChange={form.fields.vin.change}
                value={form.fields.vin.value}
                error={form.submitted && !form.fields.vin.valid}
              />
            </div>
            <div className={classes.formAction}>
              <Button
                onClick={this.onReset}
                variant={'transparent'}
                theme={'brand'}
                text={'Сбросить'}
              />
              <Button
                mobileBlock
                theme={'brand'}
                text={'Продолжить'}
              />
            </div>
          </form>
        </div>

      </ReactModal>
    );
  }
}

export default TradeInModal;
