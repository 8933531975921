export default (theme) => ({
  root: {
    paddingTop: 30,

    backgroundColor: theme.colors.brand.main,
    color: '#fff',
  },

  content: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  logo: {
    width: 126,
    fontSize: '30px',
  },

  logoText: {
    fontSize: 12,
    lineHeight: '24px',

    color: theme.colors.accent.main,
  },

  colTitle: {
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  colList: {
    display: 'flex',

    fontSize: 14,
    lineHeight: '32px',

    flexDirection: 'column',
  },
  colLink: {
    opacity: .6,

    fontWeight: '400',
    textDecoration: 'none',

    color: '#fff',

    transition: 'opacity .5s ease',
    cursor: 'pointer',

    '&:hover': {
      opacity: 1,
    }
  },

  socials: {
    display: 'flex',
  },
  socialItem: {
    display: 'inline-flex',
    width: 48,
    height: 48,
    position: 'relative',
    marginRight: 12,

    borderRadius: 6,

    backgroundColor: 'rgba(255,255,255,.08)',
    color: '#fff',

    transition: 'background-color .5s ease',

    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: 'rgba(255,255,255,.16)',
    },
  },
  socialItemLink: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
  },
  socialItemIcon: {
    width: 20,
    fontSize: '20px',
    lineHeight: 0,
  },

  copyright: {
    opacity: .6,

    fontSize: 12,

    color: '#fff',
  },
  rules: {
    opacity: .6,
    fontSize: 12,
    fontWeight: '400',

    color: '#fff',

    '&:hover': {
      textDecoration: 'underline',
    }
  },

  madeBy: {
    fontWeight: '600',
    textDecoration: 'none',

    color: '#fff',
  },

  developed: {
    margin: 0,
  },

  developedLink: {
    fontWeight: '600',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline'
    }
  },

  inputBtn: {
    position: 'relative',
  },
  inputBtnBtn: {
    display: 'flex',
    width: 48,
    height: 48,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,

    borderRadius: '0 2px 2px 0',

    backgroundColor: 'transparent',
    color: theme.colors.gray.lighter,

    transition: 'background-color .5s ease, color .5s ease',

    justifyContent: 'center',
    alignItems: 'center',

    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.colors.accent.main,
      color: '#fff',
    },
    '&:active': {
      backgroundColor: theme.colors.accent.active,
    }
  },
  inputBtnBtnIcon: {
    width: 4,
    lineHeight: 0,
  },


  [theme.queries.mdDevicesDown]: {
    root: {
      padding: '30px 8px 0',
    },

    content: {
      flexDirection: 'column',
    },

    logo: {
      height: 28,
    },
    logoText: {
      maxWidth: '70%',
      paddingTop: 12,
    },

    col: {
      paddingBottom: 40,
    },
    colDesk: {
      display: 'none'
    },
    colSubscribe: {
      order: 0,
    },
    colDealers: {
      order: 1,
    },

    colTitle: {
      paddingBottom: 11,
    },

    inputBtn: {
      marginTop: 13,
    },

    bottom: {
      display: 'inline-flex',
      position: 'relative',
      paddingBottom: 40,

      order: 2,
      justifyContent: 'space-between',
      alignItems: 'center',

      '& $col': {
        paddingBottom: 21,
      }
    },

    colMadeby: {
      display: 'flex',
      height: 40,
      position: 'absolute',
      bottom: 0,
      left: -20,
      right: -20,
      padding: '0 20px !important',

      fontSize: 12,
      textAlign: 'center',

      backgroundColor: theme.colors.gray.darker,

      justifyContent: 'center',
      alignItems: 'center',
    },
    madeBy: {
      paddingLeft: '.5em',
      fontSize: 12,
    },
    developed: {
      marginLeft: 5
    }
  },

  [theme.queries.lgDevicesUp]: {

    root: {
      paddingTop: 66,
    },

    logo: {
      height: 30,
    },
    logoText: {
      maxWidth: 160,
      paddingTop: 19,
    },

    devider: {
      width: '100%',
      height: 1,
      opacity: .1,

      marginBottom: 65,

      backgroundColor: '#fff',
    },

    col: {
      width: 'calc(25% + 10px)',
      paddingRight: 40,
      paddingBottom: 50,
    },

    colLogo: {
      marginTop: -11,
    },

    colSubscribe: {
      width: 'calc(25% - 30px)',
      paddingRight: 0,
    },
    colDealers: {
      width: 'calc(25% - 30px)',
      paddingRight: 0,
    },

    colRules: {
      width: 'calc(50% + 20px)',
    },
    colMadeby: {
      width: 'calc(25% - 30px)',
      paddingRight: 0,
      fontSize: 12,
      textAlign: 'right',
      verticalAlign: 'baseline',
    },

    colTitle: {
      paddingBottom: 15,
    },

    inputBtn: {
      marginTop: 8,
    },

    bottom: {
      display: 'flex',
      width: '100%',
      paddingTop: 5,
      paddingBottom: 50,

      alignItems: 'center',

      '& $col': {
        paddingBottom: 0,
      }
    },

    socials: {
      paddingTop: 6,
    },

    madeBy: {
      fontSize: 16,
      textAlign: 'right',
    },

    developed: {
      marginTop: 10
    }
  },

});
