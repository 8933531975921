export const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    margin: '-15px 0 42px',
  },

  item: {
    width: 'calc(25% - 90px/4)',
    // height: 212,
    padding: '28px',
    margin: '15px 0',

    borderRadius: 6,
    backgroundColor: theme.colors.brand.lightest,
  },
  itemTitle: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: 1.56,
    whiteSpace: 'pre',
    color: theme.colors.brand.main,
    '&:after': {
      content: '""',

      display: 'block',
      width: 39,
      height: 2,
      marginTop: 12,
      marginBottom: 13,

      backgroundColor: theme.colors.accent.main,
    }
  },
  itemText: {
    fontSize: '14px',
    lineHeight: 1.75,
    color: theme.colors.gray.dark,
  },

  [theme.queries.mdDevicesDown]: {
    item: {
      width: 'calc(50% - 15px)',
    }
  },

  [theme.queries.smDevicesDown]: {
    root: {
      margin: '-6px -15px 70px',
    },
    item: {
      width: '100%',
      height: 'auto',
      padding: '20px 24px 25px',
      margin: '6px 15px',
    },
  },
});