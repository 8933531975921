import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { injectStyles } from '~app/theme';
import { connect } from 'react-redux';
import actions from '~app/redux/actions';

import { freezeScroll } from '../../utils/helpers';

import styles from './ChangeUserModalStyles';

import ChangeUserStep from './Steps/ChangeUserStep';
import TwoFactorStep from './Steps/TwoFactorStep';

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,

    inProgress: state.user.updateUserInProgress,
    success: state.user.updateUserSuccess,
    error: state.user.updateUserError,
  };
};

@connect(mapStateToProps)
@injectStyles(styles)
class ChangeUserModal extends Component {

  state = {
    step: 0,
    newData: null,
  }

  changeStep = step => {
    this.setState({
      step: step
    });
  }

  componentWillReceiveProps = (nextProps) => {
    if (!this.props.success && nextProps.success) {
      this.onSuccess();
    }
  }

  onSuccess = () => {
    this.props.onRequestClose();
    actions.common.openGenericModal({
      icon: 'tickCircle',
      title: 'Данные изменены',
      text: 'Информация в профиле пользователя успешно обновлена.',
    });
    actions.auth.refreshUser();
  }

  onSubmitInfo = (formData) => {
    if (this.props.inProgress) {
      return;
    }
    actions.user.updateUser(formData);
    // this.setState({
    //   step: 1,
    //   newData: formData
    // });
  }

  onRequestClose = () => {
    this.props.onRequestClose();
    freezeScroll(true);
  }
  onOpen = () => {
    freezeScroll();
  }

  render() {
    const {
      classes,
      isOpen,
      user,
    } = this.props;

    return (
      <ReactModal
        className={{
          base: classes.modal,
          afterOpen: classes.modalAfterOpen,
          beforeClose: classes.modalBeforeClose,
        }}
        overlayClassName={{
          base: classes.overlay,
          afterOpen: classes.overlayAfterOpen,
          beforeClose: classes.overlayBeforeClose,
        }}
        isOpen={isOpen}
        onAfterOpen={this.onOpen}
        closeTimeoutMS={300}
        key={this.props.isOpen}
        contentLabel={'modal'}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick>

        <a className={classes.close} onClick={this.onRequestClose} />
        <div className={classes.content}>
          {this.state.step === 0 ?
            <ChangeUserStep
              user={user}
              data={this.state.newData}
              onSubmit={this.onSubmitInfo}
            />
            :
            <TwoFactorStep
              user={user}
              newData={this.state.newData}
              onBack={() => {
                this.setState({
                  step: 0
                });
              }}
            />
          }
        </div>

      </ReactModal>
    );
  }
}

export default ChangeUserModal;
