export default theme => ({

  title: {
    paddingBottom: 6,
    color: theme.colors.gray.dark,
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: '25px',
    textAlign: 'center',
  },
  
  form: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: 'calc(100% + 10px)',
    margin: '19px -5px -6px',
  },
  formInput: {
    width: '100%',
    margin: '6px 5px',
    textAlign: 'left',
  },
  checkboxLabel: {
    fontWeight: '400',
    letterSpacing: 0,
    '& a': {
      fontWeight: '400',
    }
  },
  formInput_half: {
    width: 'calc(50% - 10px)',
  },
  formAction: {
    width: '100%',
    paddingTop: '17px',
    textAlign: 'center',
  },

  [theme.queries.smDevicesDown]: {
    formInput_half: {
      width: '100%',
    },
  },
  

});