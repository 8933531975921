import { mapper, fromInput } from 'demapper';

// import ColorBaseModel from '../Color/ColorBaseModel';
// import PackageBaseModel from '../Package/PackageBaseModel';
// import DeliveryBaseModel from '../Delivery/DeliveryBaseModel';

@mapper
export default class {

  @fromInput('_id')
  id = null;
}
