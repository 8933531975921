import client from './_client';
import { ReviewListModel } from './models';

// Get
export const getReviews = (params = {}) => {
  let {
    limit = 5,
    page = 1,
    dealerId = null,
    ...otherParams
  } = params;

  return client.request({
    url: dealerId === null ? '/v1/reviews' : `/v1/dealer/${dealerId}/reviews`,
    method: 'GET',
    params: {
      limit,
      page,
      ...otherParams
    },
    transformData: data => new ReviewListModel({
      items: data.data,
      paging: {
        count: data.count || 0,
        page: page,
        limit: limit,
      }
    })
  });
};


// Create review 
export const createReview = (params = {}) => {
  let {
    offerId,
    review,
    positive
  } = params;

  return client.request({
    url: `/v1/offer/${offerId}/review`,
    method: 'POST',
    data: {
      review,
      positive
    }
  });
};