import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { injectStyles } from '~app/theme';
import { formContainer } from 'deforms';
import { push } from 'connected-react-router-exposed';
import cn from 'classnames';

import { freezeScroll } from '../../utils/helpers';
import actions from '~app/redux/actions';
// import { Button, TextInput, Checkbox, Icon, Spacer } from '~app/components/ui';
import DealerAuthLogin from './Sections/Login/DealerAuthLogin';
import DealerAuthSignup from './Sections/Signup/DealerAuthSignup';


import styles from './DealerAuthModalStyles';

const mapStateToProps = (state) => ({
  loginResult: state.auth.dealerLoginResult,
  loginInProgress: state.auth.dealerLoginInProgress,
  loginError: state.auth.dealerLoginError,

});

const mapDispatchToProps = {
  push
};

@connect(mapStateToProps, mapDispatchToProps)
@injectStyles(styles)
@formContainer
class DealerAuthModal extends Component {

  state = {
    activeTab: 0,
  }

  changeTab = tab => {
    this.setState({
      activeTab: tab
    });
  }

  onSuccessSignin = () => {
    this.props.push('/dealer');
    this.onRequestClose();
  }
  onSuccessSignup = () => {
    actions.common.openGenericModal({
      icon: 'tickCircle',
      title: 'Запрос отправлен!',
      text: 'Наша служба поддержки обработает запрос на регистрацию профиля в ближайшее время и свяжется с Вами.',
    });
    this.onRequestClose();
  }

  onOpen = () => {
    freezeScroll();

    const tab = this.props.tab;

    if (tab !== null) {
      if (tab === 'auth' || tab === 0) {
        this.setState({
          activeTab: 0,
        });
      }
      else if (tab === 'register' || tab === 1) {
        this.setState({
          activeTab: 1,
        });
      }
    }
  }

  onRequestClose = () => {
    this.props.onRequestClose();
    freezeScroll(true);
  }

  renderLogin = () => {
    return (
      <DealerAuthLogin
        onSuccess={this.onSuccessSignin}
      />
    );
  }
  renderRegister = () => {
    return (
      <DealerAuthSignup
        onSuccess={this.onSuccessSignup}
      />
    );
  }



  render() {
    const {
      classes,
      open,
    } = this.props;

    const {
      activeTab,
    } = this.state;

    return (
      <ReactModal
        className={{
          base: classes.modal,
          afterOpen: classes.modalAfterOpen,
          beforeClose: classes.modalBeforeClose,
        }}
        overlayClassName={{
          base: classes.overlay,
          afterOpen: classes.overlayAfterOpen,
          beforeClose: classes.overlayBeforeClose,
        }}
        isOpen={open}
        onAfterOpen={this.onOpen}
        closeTimeoutMS={300}
        key={this.props.isOpen}
        contentLabel={'modal'}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick>

        <a className={classes.close} onClick={this.onRequestClose} />
        <div className={classes.tabs}>
          <div className={cn(classes.tab, { [classes.tab_active]: activeTab === 0 })}
            onClick={() => {
              this.changeTab(0);
            }}
          >
            {'Авторизация'}
          </div>
          <div className={cn(classes.tab, { [classes.tab_active]: activeTab === 1 })}
            onClick={() => {
              this.changeTab(1);
            }}
          >
            {'Регистрация'}
          </div>
        </div>
        <div>
          {activeTab === 0 && this.renderLogin()}
          {activeTab === 1 && this.renderRegister()}
        </div>
      </ReactModal>
    );
  }
}

export default DealerAuthModal;
