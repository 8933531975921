export const schema = {
  brand: {
    defaultValue: '',
    validation: {
      type: 'string',
      allowEmpty: false,
      minlength: 3
    }
  },
  model: {
    defaultValue: '',
    validation: {
      type: 'string',
      allowEmpty: false,
      minlength: 2
    }
  },
  engineVolume: {
    defaultValue: '',
    validation: {
      type: 'string',
      allowEmpty: false,
    }
  },
  drive: {
    defaultValue: '',
    validation: {
      type: 'string',
      allowEmpty: false,
    }
  },
  gear: {
    defaultValue: '',
    validation: {
      type: 'string',
      allowEmpty: false,
    }
  },
  year: {
    defaultValue: '',
    validation: {
      type: 'string',
      allowEmpty: false,
      minlength: 4
    }
  },
  vin: {
    defaultValue: '',
    validation: {
      type: 'string',
      allowEmpty: true,
      minlength: 17
    }
  }
};
