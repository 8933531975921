export const styles = theme => ({
  listDesk: {
    display: 'flex',

    flexWrap: 'wrap',
  },
  listMobile: {
    display: 'none',
  },

  item: {
    display: 'inline-flex',
    width: '20%',
    height: '35px',

    alignItems: 'center',

    cursor: 'pointer',

    '&:hover': {
      '& $itemName': {
        color: theme.colors.brand.main,
      }
    }
  },
  itemIcon: {
    width: '26px',
    position: 'relative',
    marginRight: '12px',
    lineHeight: 0,
  },
  itemIconImg: {
    width: '100%',
  },
  itemName: {
    fontSize: '14px',

    transition: 'color .5s ease',
  },


  [theme.queries.mdDevicesDown]: {
    listMobile: {
      display: 'block',
    },
    listDesk: {
      display: 'none',
    }
  },
});