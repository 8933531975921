export default theme => ({

  ...theme.styles.modal,
  customModal: {
    maxWidth: 1080
  },
  title: {
    width: 330,
    paddingTop: 14,
    paddingBottom: 26,

    fontSize: 18,
    fontWeight: '500',
    textAlign: 'center',
  },
  video: {
    width: '100%',
    height: 0,
    position: 'relative',
    paddingBottom: 'calc(100% / 16 * 9)'
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  [theme.queries.smDevicesDown]: {
    modal: {
      width: 'calc(100% - 22px)',
    },

    title: {
      width: '100%',
    },

    content: {
      padding: '34px 20px 40px',
    },
  },


});
