import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { injectStyles } from '~app/theme';
import { formContainer, formCreate } from 'deforms';
import { connect } from 'react-redux';

import { freezeScroll } from '../../utils/helpers';
import { Button, TextInput, Card, CardContent } from '~app/components/ui';

import styles from './MessageModalStyles';
import actions from '~app/redux/actions';

const mapStateToProps = (state, { applicationId = null, offerId = null }) => {
  return {
    inProgress: state.chat.sendProgress,
    success: state.chat.sendSuccess,
    error: state.chat.sendError,
  };
};


@connect(mapStateToProps)
@injectStyles(styles)
@formContainer
class MessageModal extends Component {

  @formCreate()
  form = {
    schema: {
      message: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
        }
      }
    },
    onSubmit: (formData) => {
      this.submit(formData);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.success && nextProps.success) {
      this.onSuccess();
    }
  }

  componentWillUnmount() {
    actions.chat.resetChatState();
  }

  onSuccess = () => {
    actions.common.openGenericModal({
      theme: 'general',
      icon: 'tickCircle',
      title: 'Сообщение отправлено',
      text: `${this.props.asDealer ? 'Клиент' : 'Дилер'} получит уведомление на электронную почту.`,
    });
    if (this.props.onSuccess) {
      this.props.onSuccess();
    } else {
      this.onRequestClose();
    }
  }

  onRequestClose = () => {
    this.props.onRequestClose();
    freezeScroll(true);
  }
  onOpen = () => {
    freezeScroll();
  }

  submit = (formData) => {
    if (this.props.inProgress) {
      return;
    }
    actions.chat.sendMessage({
      offerId: this.props.offerId,
      ...formData
    });
  }

  render() {
    const form = this.form;
    const {
      classes,
      isOpen,
      asDealer,
    } = this.props;

    return (
      <ReactModal
        className={{
          base: classes.modal,
          afterOpen: classes.modalAfterOpen,
          beforeClose: classes.modalBeforeClose,
        }}
        overlayClassName={{
          base: classes.overlay,
          afterOpen: classes.overlayAfterOpen,
          beforeClose: classes.overlayBeforeClose,
        }}
        isOpen={isOpen}
        onAfterOpen={this.onOpen}
        closeTimeoutMS={300}
        key={this.props.isOpen}
        contentLabel={'modal'}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick>

        <a className={classes.close} onClick={this.onRequestClose} />

        <div className={classes.modalWrapper}>

          <Card>
            <CardContent>

              {asDealer ?
                <div className={classes.title}>{'Написать клиенту'}</div>
                :
                <div className={classes.title}>{'Написать дилеру'}</div>
              }


              <div className={classes.formInput}>
                <TextInput
                  name={'message'}
                  type={'textarea'}
                  placeholder={'Сообщение'}
                  value={form.fields.message.value}
                  onChange={form.fields.message.change}
                  error={form.submitted && form.fields.message.error}
                />
              </div>

              <div className={classes.formButton}>
                <Button
                  mobileBlock
                  theme={'brand'}
                  text={'Отправить'}
                  onClick={form.submit}
                />
              </div>

            </CardContent>
          </Card>

        </div>

      </ReactModal>
    );
  }
}

export default MessageModal;
