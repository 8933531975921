import React, { Component } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import FlipMove from 'react-flip-move';

import actions from '~app/redux/actions';
import { ApplicationCard, Pagination, OfferForm } from '../../../../components';
import { Button, Icon, EmptyListPlaceholder } from '../../../../components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './ApplicationsStyles';



const mapStateToProps = (state, { reduxKey = 'forDealer' }) => {
  let applicationsState = {
    items: null,
    paging: null,
    inProgress: false,
    error: null
  };

  if (reduxKey in state.applications.itemsByKey) {
    applicationsState = state.applications.itemsByKey[reduxKey];
  }

  return {
    reduxKey,
    items: applicationsState.items,
    itemsPaging: applicationsState.paging,
    itemsInProgress: applicationsState.inProgress,
    itemsError: applicationsState.error,
    myBrands: state.user.myBrands,
  };
};


@connect(mapStateToProps)
@injectStyles(styles)
class Applications extends Component {

  watchIntevalInstance = null;
  watchInteval = 5000;

  state = {
    currentPage: 1,
    // openedItem: null,
  }

  componentDidMount = () => {
    this.loadData();
    this.startWatchData();
  }

  componentWillUnmount = () => {
    this.stopWatchData();
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.myBrands !== nextProps.myBrands) {
      this.onChangeFilters();
    }
  }

  startWatchData = () => {
    this.watchIntevalInstance = setInterval(() => {
      this.loadData();
    }, this.watchInteval);
  }

  stopWatchData = () => {
    clearInterval(this.watchIntevalInstance);
    this.watchIntevalInstance = null;
  }

  reloadData = () => {
    this.stopWatchData();
    this.loadData();
    this.startWatchData();
  }

  loadData = (props = this.props) => {
    actions.applications.getItemsByKey(this.props.reduxKey, {
      page: this.state.currentPage,
      brands: props.myBrands.join(',')
    });
  }

  setPage = (page) => {
    this.setState({
      currentPage: page,
    }, () => {
      this.reloadData();
    });
  }

  onChangeFilters = () => {
    this.setPage(1);
  }

  archiveOnClick = () => {

    const { classes } = this.props;

    actions.common.openGenericModal({
      theme: 'general',
      title: 'Убрать в архив',
      text: 'Заявка отправится в архив и потеряет все предложения дилеров. Продолжить?',
      additionalContent: (
        <div className={classes.modalArchiveButton}>
          <Button
            theme={'rare'}
            text={'В архив'}
            leftIcon={<Icon name={'timesThin'} />}
            block
          />
        </div>
      ),
    });
  }

  // itemOnClick = id => () => {
  //   this.setState({
  //     openedItem: id,
  //   });
  // }

  onSuccessCreateOffer = () => () => {
    actions.common.openGenericModal({
      theme: 'general',
      icon: 'tickCircle',
      title: 'Оффер сделан!',
      text: 'Покупатель получит уведомление о вашем предложении.',
    });
    // this.setState({
    //   openedItem: null,
    // }, () => {
    this.reloadData();
    // });
  }

  render() {
    const {
      classes,
      items,
      itemsPaging,
    } = this.props;

    return (
      <div className={classes.root}>
        {items !== null && items.length > 0 ?
          <div>
            <div className={classes.paging}>
              <Pagination
                paging={itemsPaging !== null ? itemsPaging : null}
                onClick={(x) => this.setPage(x + 1)}
              />
            </div>
            <FlipMove>
              {items.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={cn(classes.item, {
                      // [classes.itemActive]: this.state.openedItem == item.id
                    })}
                  // onClick={this.itemOnClick(item.id)}
                  >
                    {item.isOffered &&
                      <div className={classes.itemOffered}>
                        <Icon name={'offered'} />
                      </div>
                    }
                    <div
                      className={cn(classes.itemWrapper, {
                        // [classes.itemWrapperActive]: this.state.openedItem == item.id
                      })}>
                      <ApplicationCard
                        item={item} />
                    </div>
                    <div className={classes.itemBottom}>
                      <OfferForm
                        application={item}
                        options={{
                          color: item.color,
                          package: item.package
                        }}
                        model={item.model}
                        isOffered={item.isOffered}
                        onSuccess={this.onSuccessCreateOffer(item)}
                      />
                    </div>
                  </div>
                );
              })}
            </FlipMove>
            <div className={classes.paging}>
              <Pagination
                paging={itemsPaging !== null ? itemsPaging : null}
                onClick={(x) => this.setPage(x + 1)}
              />
            </div>
          </div>
          :
          <EmptyListPlaceholder
            iconName={'car'}
            text={'Нет активных заявок на покупку автомобиля'}
          />
        }
      </div>
    );
  }
}

export default Applications;
