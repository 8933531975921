export default (theme) => ({
  root: {
    display: 'flex',
    height: 15,

    fontSize: 12,

    alignItems: 'center',
  },

  item: {
    display: 'inline-flex',
    paddingRight: 3,

    color: theme.colors.brand.main,

    alignItems: 'center',
    '& a': {
      fontWeight: '400',
    }
  },
  itemDevider: {
    paddingRight: 3,
  },
  itemLink: {
    textDecoration: 'none',

    color: theme.colors.brand.main,

    cursor: 'pointer',
  },
  itemLinkEmpty: {
    cursor: 'default',
  },

  [theme.queries.mdDevicesDown]: {
    root: {
      fontSize: 10,
    }
  }
});