const options = [
  {
    id: 'carterProtection',
    name: 'защита картера',
  },
  {
    id: 'tire',
    name: 'резина',
  },
  {
    id: 'carpets',
    name: 'ковры',
  },
  {
    id: 'signaling',
    name: 'сигнализация',
  },
  {
    id: 'parkingSensors',
    name: 'парктроники',
  },
  {
    id: 'tuningStyling',
    name: 'тюнинг/стайлинг',
  },
  {
    id: 'addSoundInsulation',
    name: 'дополнительная шумоизоляция',
  },
];

export let schema = {
  comment: {
    defaultValue: '',
    validation: {
      type: 'string',
      allowEmpty: true,
      maxlength: 100,
    }
  }
};

for (let value of options) {
  schema[value.id] = {
    defaultValue: false,
    validation: {
      type: 'boolean',
    }
  };
}

export default options;
