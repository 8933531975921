import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import { Switch, Route } from 'react-router';

import { LayoutGeneral } from '~app/components';
import AuthDealerScreen from '~app/modules/AuthDealerScreen/AuthDealerScreen';
import CabinetDealerScreen from '~app/modules/CabinetDealerScreen/CabinetDealerScreen';
import CabinetDealerDealScreen from '~app/modules/CabinetDealerScreen/CabinetDealerDealScreen/CabinetDealerDealScreen';
import NotFoundScreen from '~app/modules/NotFoundScreen/NotFoundScreen';

class ContainerDealer extends Component {
  render = () => {
    return (
      <LayoutGeneral>
        <Helmet>
          <title>Avicar - Кабинет дилера</title>
        </Helmet>
        <Switch>
          <Route path={'/dealer/auth'} component={AuthDealerScreen} />
          <Route path={'/dealer/deal/:id'} component={CabinetDealerDealScreen} />
          <Route path={'/'} component={CabinetDealerScreen} />
          <Route path='*' component={NotFoundScreen} />
        </Switch>
      </LayoutGeneral>
    );
  }
}

export default ContainerDealer;
