export const generateUUID = () => {
  let d = Date.now();
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
};

export const inflection = (q, words) => {
  let cases = [2, 0, 1, 1, 1, 2];
  return words[(q % 100 > 4 && q % 100 < 20) ? 2 : cases[(q % 10 < 5) ? q % 10 : 5]];
};

export const freezeScroll = (revert = false) => {
  const body = document.querySelector('body');
  const root = document.querySelector('#root');

  if (revert) {
    let currentScroll = root.scrollTop;
  
    body.classList.remove('fixed-scroll');
    window.scrollTo(0, currentScroll);
  }
  else {
    if (!body.classList.contains('fixed-scroll')) {
      let currentScroll = window.pageYOffset;
    
      body.classList.add('fixed-scroll');
      root.scrollTop = currentScroll;
    }
  }
};