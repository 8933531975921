import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from '~app/theme';
import cn from 'classnames';
import { connect } from 'react-redux';
import moment from 'moment';
import { Collapse } from 'react-collapse';

import actions from '~app/redux/actions';

import { Icon, Checkbox, Hint } from '~app/components/ui';
import { DealerRating, NavLink } from '~app/components';

import styles from './UserProfileCardStyles';

const mapStateToProps = (state, { showFilters = false }) => {
  const pathname = state.router.location.pathname;
  let allowFilters = showFilters;
  if (allowFilters && pathname !== '/dealer') {
    allowFilters = false;
  }

  return {
    allowFilters,
    counts: state.user.counts,
    brands: state.dictionary.brands,
    brandsInProgress: state.dictionary.brandsInProgress,
    brandsError: state.dictionary.brandsError,
    myBrands: state.user.myBrands
  };
};

@connect(mapStateToProps)
@injectStyles(styles)
class UserProfileCard extends Component {

  state = {
    filtersOpen: false,
  };

  componentDidMount = () => {
    this.loadData();
  }

  loadData = () => {
    if (this.props.showFilters) {
      this.loadBrands();
    }
  }

  loadBrands = (props = this.props) => {
    if (props.brands === null && !props.brandsInProgress && props.brandsError === null) {
      actions.dictionary.getBrands();
    }
  }

  triggerFiltersOpenState = () => {
    const newState = !this.state.filtersOpen;

    this.setState({
      filtersOpen: newState,
    });
  }

  brandOnClick = (id) => {
    actions.user.toggleMyBrand(id);
  }

  emailWarningOnClick = () => {
    actions.user.sendEmailConfirmation();
    actions.common.openGenericModal({
      theme: 'general',
      icon: 'email',
      title: 'Подтверждение почты',
      text: `На email ${this.props.user.email} отправлено письмо. Что бы получать уведомления о поступающих предложениях дилеров на заявки перейди по ссылке в письме.`,
    });
  }

  changeDealerOnClick = () => {
    actions.common.openModal({
      type: 'changeDealer'
    });
  }
  changeUserOnClick = () => {
    actions.common.openModal({
      type: 'changeUser'
    });
  }

  showDealerRating = () => {
    actions.common.openModal({
      type: 'dealerReviews',
      params: {
        dealer: this.props.dealer,
        dealerId: this.props.dealer.id
      }
    });
  }

  renderUserInfo = () => {
    const {
      classes,
      user,
      dealScreen,
      editable,
    } = this.props;

    let phoneArr = user.phone.split('');
    phoneArr.splice(2, 0, ' ');
    phoneArr.splice(6, 0, ' ');
    phoneArr.splice(10, 0, ' ');
    phoneArr.splice(13, 0, ' ');

    const phoneFormatted = phoneArr.join('');

    return (
      <div className={classes.info}>

        <div className={classes.infoItem}>
          <div className={classes.infoItemTitle}>
            {'Профиль'}:
          </div>
          <div className={cn(classes.infoItemValue, classes.infoItemValue_name,
            { [classes.reduced]: dealScreen })}>
            {user.fullName}
          </div>
        </div>

        <div className={classes.infoItem}>
          <div className={classes.infoItemTitle}>
            {'Почта'}:
          </div>
          <div className={cn(classes.infoItemValue, { [classes.enlarged]: dealScreen })}>
            {user.email || '-'}
            {editable && !user.emailVerified && user.email &&
              <div
                className={classes.infoItemValueWarning}
                onClick={this.emailWarningOnClick}
              >
                <Icon name={'warning'} />
              </div>
            }
          </div>
        </div>

        <div className={classes.infoItem}>
          <div className={classes.infoItemTitle}>
            {'Телефон'}:
          </div>
          <div className={cn(classes.infoItemValue, { [classes.enlarged]: dealScreen })}>
            {phoneFormatted}
          </div>
        </div>

        <div className={cn(classes.infoItem, classes.infoItemDecreased)}>
          <div className={classes.infoItemTitle}>
            {'Регистрация'}:
          </div>
          <div className={classes.infoItemValue}>
            {moment(user.registrationDate).format('DD.MM.YY')}
          </div>
        </div>

        {editable &&
          <div
            className={cn(classes.infoItem, classes.infoItemEdit)}
            onClick={this.changeUserOnClick}
          >
            {'Изменить'}
          </div>
        }
      </div>
    );
  }

  renderDealerInfo = () => {
    const {
      classes,
      dealer,
      editable,
    } = this.props;

    return (
      <div className={classes.info}>

        <div className={classes.infoItem}>
          <div className={classes.infoDealerName}>{dealer.firstName}</div>
          <div onClick={this.showDealerRating} className={classes.raitingWrapper}>
            <DealerRating userDealer={dealer} />
          </div>
        </div>

        <div className={classes.infoItem}>
          <div className={classes.infoItemTitle}>
            {'Адрес салона'}:
          </div>
          <div className={classes.infoItemValue}>
            {dealer.address}
          </div>
        </div>

        <div className={classes.infoItem}>
          <div className={classes.infoItemTitle}>
            {'Почта'}:
          </div>
          <div className={classes.infoItemValue}>
            {dealer.email}
            {/* {editable && !dealer.emailVerified &&
              <div
                className={classes.infoItemValueWarning}
                onClick={this.emailWarningOnClick}
              >
                <Icon name={'warning'}/>
              </div>
            } */}
          </div>
        </div>

        <div className={cn(classes.infoItem, classes.infoItemDecreased)}>
          <div className={classes.infoItemTitle}>
            {'Телефон'}:
          </div>
          <div className={classes.infoItemValue}>
            {dealer.phone}
          </div>
        </div>

        {editable &&
          <div
            className={cn(classes.infoItem, classes.infoItemEdit)}
            onClick={this.changeDealerOnClick}
          >
            {'Изменить'}
          </div>
        }
      </div>
    );
  }

  getNavLinkText = (text, countField = null) => {
    const {
      counts
    } = this.props;

    if (counts !== null && countField in counts) {
      return `${text} (${counts[countField]})`;
    }
    return text;
  }

  showMenuHint = item => () => {
    this.setState({ [`hint_${item}_active`]: true });
  }

  hideMenuHint = item => () => {
    this.setState({ [`hint_${item}_active`]: false });
  }

  renderUserNavigation = () => {
    const {
      classes,
    } = this.props;


    return (
      <div className={classes.links}>
        <div className={classes.linkContainer}>
          <NavLink
            exact
            to='/cabinet'
            className={classes.linksItem}
            activeClassName={classes.linksItemActive}
            onMouseOver={this.showMenuHint('applications')}
            onMouseLeave={this.hideMenuHint(('applications'))}>
            {this.getNavLinkText('Мои заявки', 'applications')}
          </NavLink>
          <span className={classes.hint}>
            <Hint isActive={this.state.hint_applications_active} place={'menuItems'}>
              {'Здесь хранятся все Ваши заявки на автомобили'}
            </Hint>
          </span>
        </div>
        <div className={classes.linkContainer}>
          <NavLink
            to='/cabinet/deals'
            className={classes.linksItem}
            activeClassName={classes.linksItemActive}
            onMouseOver={this.showMenuHint('deals')}
            onMouseLeave={this.hideMenuHint(('deals'))}
          >
            {this.getNavLinkText('Мои сделки', 'deals')}
          </NavLink>
          <span className={classes.hint}>
            <Hint isActive={this.state.hint_deals_active} place={'menuItems'}>
              {'Здесь хранятся все Ваши сделки'}
            </Hint>
          </span>
        </div>
        <div className={classes.linkContainer}>
          <NavLink
            to='/cabinet/reviews'
            className={classes.linksItem}
            activeClassName={classes.linksItemActive}
            onMouseOver={this.showMenuHint('reviews')}
            onMouseLeave={this.hideMenuHint(('reviews'))}>
            {this.getNavLinkText('Мои отзывы', 'reviews')}
          </NavLink>
          <span className={classes.hint}>
            <Hint isActive={this.state.hint_reviews_active} place={'menuItems'}>
              {'Здесь хранятся все отзывы от Вас'}
            </Hint>
          </span>
        </div>
        <div className={classes.linkContainer}>
          <NavLink
            to='/cabinet/archive'
            className={classes.linksItem}
            activeClassName={classes.linksItemActive}
            onMouseOver={this.showMenuHint('archived')}
            onMouseLeave={this.hideMenuHint(('archived'))}>
            {this.getNavLinkText('Архив', 'archived')}
          </NavLink>
          <span className={classes.hint}>
            <Hint isActive={this.state.hint_archived_active} place={'menuItems'}>
              {'Здесь хранятся все заявки, которые Вы отправили в архив'}
            </Hint>
          </span>
        </div>
      </div>
    );
  }

  renderDealerNavigation = () => {
    const {
      classes,
      brands,
      dealer,
      showFilters,
      allowFilters,
      myBrands,
    } = this.props;

    return (
      <div>
        <div className={cn(classes.links, classes.linksDealer)}>
          <div className={classes.linkContainer}>
            <NavLink
              exact
              to='/dealer'
              className={classes.linksItem}
              activeClassName={classes.linksItemActive}
              onMouseOver={this.showMenuHint('orders')}
              onMouseLeave={this.hideMenuHint(('orders'))}>
              {'Все заявки'}
            </NavLink>
            <span className={classes.hint}>
              <Hint isActive={this.state.hint_orders_active} place={'menuItems'}>
                {'Здесь хранятся заявки клиента на ваш Бренд'}
              </Hint>
            </span>
          </div>
          <div className={classes.linkContainer}>
            <NavLink
              to='/dealer/offers'
              className={classes.linksItem}
              activeClassName={classes.linksItemActive}
              onMouseOver={this.showMenuHint('offers')}
              onMouseLeave={this.hideMenuHint(('offers'))}>
              {this.getNavLinkText('Мои офферы', 'offers')}
            </NavLink>
            <span className={classes.hint}>
              <Hint isActive={this.state.hint_offers_active} place={'menuItems'}>
                {'Здесь хранятся все предложения от вас клиентам'}
              </Hint>
            </span>
          </div>
          <div className={classes.linkContainer}>
            <NavLink
              to='/dealer/deals'
              className={classes.linksItem}
              activeClassName={classes.linksItemActive}
              onMouseOver={this.showMenuHint('dealer_deals')}
              onMouseLeave={this.hideMenuHint(('dealer_deals'))}>
              {this.getNavLinkText('Мои сделки', 'deals')}
            </NavLink>
            <span className={classes.hint}>
              <Hint isActive={this.state.hint_dealer_deals_active} place={'menuItems'}>
                {'Здесь отражены принятые клиентом предложения'}
              </Hint>
            </span>
          </div>
          <div className={classes.linkContainer}>

            <NavLink
              to='/dealer/reviews'
              className={classes.linksItem}
              activeClassName={classes.linksItemActive}
            >
              {this.getNavLinkText('Мои отзывы', 'reviews')}
            </NavLink>
          </div>
          <div className={classes.linkContainer}>

            <NavLink
              to='/dealer/archive'
              className={classes.linksItem}
              activeClassName={classes.linksItemActive}
            >
              {this.getNavLinkText('Архив', 'archived')}
            </NavLink>
          </div>
          <div
            className={cn(classes.linksItem, classes.linkContainer, classes.linksItemFilters, {
              [classes.linksItemActive]: showFilters && allowFilters && this.state.filtersOpen
            })}
            onClick={(showFilters && allowFilters) ? this.triggerFiltersOpenState : null}
          >
            {showFilters && allowFilters &&
              <Fragment>
                {'Фильтры'}
                <div className={cn(classes.linksItemIcon, this.state.filtersOpen ? classes.listItemIconActive : null)}>
                  <Icon name={'point2Down'} />
                </div>
              </Fragment>
            }
          </div>
        </div>
        {showFilters &&
          <div className={classes.filtersWrapperContainer}>
            <Collapse isOpened={allowFilters && this.state.filtersOpen}>
              <div className={classes.filtersWrapper}>
                {brands !== null && brands.map((item, index) => {
                  if (dealer.brands.indexOf(item.id) > -1) {
                    return (
                      <div key={index} className={classes.filtersItem}>
                        <Checkbox
                          name={item.id}
                          label={
                            <div style={{ fontSize: 14, fontWeight: '500', letterSpacing: 0 }}>{item.name}</div>
                          }
                          checked={myBrands.indexOf(item.id) > -1}
                          onChangeValue={() => this.brandOnClick(item.id)}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </Collapse>
          </div>
        }
      </div>
    );
  }

  render() {
    const {
      classes,
      user,
      dealer,
      showNavigation,
    } = this.props;

    return (
      <div className={classes.root}>

        {user !== null && this.renderUserInfo()}
        {dealer !== null && this.renderDealerInfo()}

        {showNavigation && user !== null && this.renderUserNavigation()}
        {showNavigation && dealer !== null && this.renderDealerNavigation()}

      </div>
    );
  }
}

UserProfileCard.propTypes = {
  user: PropTypes.object,
  dealer: PropTypes.object,
  editable: PropTypes.bool,
  showNavigation: PropTypes.bool,
  showFilters: PropTypes.bool,
  dealScreen: PropTypes.bool,
};

UserProfileCard.defaultProps = {
  user: null,
  dealer: null,
  editable: false,
  showNavigation: false,
  showFilters: false,
  dealScreen: false,
};

export default UserProfileCard;
