export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 3000,
    backgroundColor: theme.colors.brand.main
  },
  loader: {
    position: 'relative',
    width: 110,
    margin: 20,
  },
  _leave: {
    opacity: 1,
  },
  _leaveActive: {
    opacity: 0,
    transition: 'opacity .5s ease-out',
  },
});