import { updateStateItemByKey } from '~app/utils/reduxHelpers';

export const initialState = {
  itemsByKey: {},
};

const initialStateItemByKey = {
  items: null,
  paging: null,
  inProgress: false,
  error: null,
};

export const getItemsByKeyRequest = (state, { key }) => {
  return updateStateItemByKey(state, 'itemsByKey', initialStateItemByKey, key, {
    inProgress: true,
    error: null
  });
};

export const getItemsByKeySuccess = (state, { key, data }) => {
  const {
    items,
    paging,
  } = data;

  return updateStateItemByKey(state, 'itemsByKey', initialStateItemByKey, key, {
    items,
    paging,
    inProgress: false,
    error: null
  });
};
export const getItemsByKeyFailure = (state, { key, error }) => {
  return updateStateItemByKey(state, 'itemsByKey', initialStateItemByKey, key, {
    inProgress: false,
    error
  });
};