export const styles = theme => ({
  timeIcon: {
    '& svg': {
      width: 14,
      height: 14,
    },
    marginRight: 8
  },
  time: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    color: '#a0aab4',
    margin: 0,
    position: 'relative',
    zIndex: 1,
  },
});