import { mapper, fromInput } from 'demapper';
import ApplicationTradeInBaseModel from '../Application/ApplicationTradeInBaseModel';
import ApplicationAdditionalKitModel from '../Application/ApplicationAdditionalKitModel';

import BrandBaseModel from '../Brand/BrandBaseModel';
import OfferBaseModel from '../Offer/OfferBaseModel';
import ModelBaseModel from '../Model/ModelBaseModel';
import ColorBaseModel from '../Color/ColorBaseModel';
import PackageBaseModel from '../Package/PackageBaseModel';

@mapper
export default class {
  @fromInput('_id')
  id = null;

  @fromInput('user')
  user = null;

  @fromInput('archived')
  archived = null;

  @fromInput('offersCount')
  offersCount = 0;

  @fromInput('newOffers')
  newOffersCount = 0;

  @fromInput('acceptedOffer', { transform: x => {
    if (!x) {
      return null;
    }
    return typeof x === 'string' ? null : new OfferBaseModel(x);
  }})
  acceptedOffer = null;

  @fromInput('brand', { transform: x => typeof x === 'string' ? null : new BrandBaseModel(x) })
  brand = null;

  @fromInput('model', { transform: x => typeof x === 'string' ? null : new ModelBaseModel(x) })
  model = null;

  @fromInput('color', { transform: x => typeof x === 'string' ? null : new ColorBaseModel(x) })
  color = null;
  @fromInput('color', { transform: x => {
    if (!x) {
      return false;
    }
    return true;
  }})
  colorPicked = false;

  @fromInput('package', { transform: x => typeof x === 'string' ? null : new PackageBaseModel(x) })
  package = null;
  @fromInput('package', { transform: x => {
    if (!x) {
      return false;
    }
    return true;
  }})
  packagePicked = false;


  @fromInput('tradeIn')
  tradeIn = null;

  @fromInput('oldCar', { model: ApplicationTradeInBaseModel })
  tradeInData = null;

  @fromInput('_id', { transform: (field, input) => {
    let data = [];

    if (input.credit)
      data.push('credit');
    if (input.insurance)
      data.push('insurance');
    if (input.subsidy)
      data.push('subsidy');
    if (input.gibdd)
      data.push('gibdd');
    if (input.guaranty)
      data.push('guaranty');
    if (input.helpOnRoads)
      data.push('helpOnRoads');

    return data;
  }})
  options = null;


  // @fromInput('package.price')
  price = null;

  @fromInput('createdAt', { model: Date })
  createdDate = null;

  @fromInput('isOffered')
  isOffered = null;

  @fromInput('loanOption')
  loanOption = null;

  @fromInput('payWithCash')
  payWithCash = null;

  @fromInput('additionalKit', { model: ApplicationAdditionalKitModel })
  additionalKit = null;

}
