import { updateStateItemByKey } from '~app/utils/reduxHelpers';

export const initialState = {
  createApplicationInProgress: false,
  createApplicationSuccess: null,
  createApplicationError: null,

  applications: null,
  applicationsInProgress: false,
  applicationsError: null,

  archiveId: null,
  archiveInProgress: false,
  archiveSuccess: false,
  archiveError: null,

  paging: null,

  itemsByKey: {},

  itemById: {}
};

const initialItemsByKey = {
  items: null,
  paging: null,
  inProgress: false,
  error: null
};

const initialItemById = {
  item: null,
  inProgress: false,
  error: null
};

// Create
export const createApplicationRequest = (state) => ({
  ...state,
  createApplicationInProgress: true,
  createApplicationSuccess: null,
  createApplicationError: null
});
export const createApplicationSuccess = (state) => ({
  ...state,
  
  createApplicationInProgress: false,
  createApplicationSuccess: true,
  createApplicationError: null
});
export const createApplicationFailure = (state, payload) => ({
  ...state,

  createApplicationInProgress: false,
  createApplicationSuccess: false,
  createApplicationError: payload
});

// Get Applications
export const getApplicationsRequest = (state) => ({
  ...state,
  applicationsInProgress: true,
  applicationsError: null
});
export const getApplicationsSuccess = (state, { items, paging }) => ({
  ...state,
  applications: items,
  paging: paging,
  applicationsInProgress: false,
  applicationsError: null
});
export const getApplicationsFailure = (state, payload) => ({
  ...state,
  applicationsInProgress: false,
  applicationsError: payload
});


// Get Items By Key
export const getItemsByKeyRequest = (state, { key }) => {
  return updateStateItemByKey(state, 'itemsByKey', initialItemsByKey, key, {
    inProgress: true,
    error: null
  });
};

export const getItemsByKeySuccess = (state, { key, data }) => {
  const {
    items,
    paging,
  } = data;

  return updateStateItemByKey(state, 'itemsByKey', initialItemsByKey, key, {
    items,
    paging,
    inProgress: false,
    error: null
  });
};
export const getItemsByKeyFailure = (state, { key, error }) => {
  return updateStateItemByKey(state, 'itemsByKey', initialItemsByKey, key, {
    inProgress: false,
    error
  });
};


// Get Item By ID
export const getItemByIdRequest = (state, { id }) => {
  return updateStateItemByKey(state, 'itemById', initialItemById, id, {
    inProgress: true,
    error: null
  });
};

export const getItemByIdSuccess = (state, { id, data }) => {
  return updateStateItemByKey(state, 'itemById', initialItemById, id, {
    item: data,
    inProgress: false,
    error: null
  });
};
export const getItemByIdFailure = (state, { id, error }) => {
  return updateStateItemByKey(state, 'itemById', initialItemById, id, {
    inProgress: false,
    error
  });
};


// Archive application
export const archiveApplicationRequest = (state, { id }) => ({
  ...state,
  archiveId: id,
  archiveInProgress: true,
  archiveSuccess: false,
  archiveError: null,
});
export const archiveApplicationSuccess = (state, { id }) => ({
  ...state,
  archiveId: id,
  archiveInProgress: false,
  archiveSuccess: true,
});
export const archiveApplicationFailure = (state, { id, error }) => ({
  ...state,
  archiveId: id,
  archiveInProgress: false,
  archiveError: error,
});