export default theme => ({
  sectionTitle: {
    minHeight: 31,

    fontSize: 14,
    fontWeight: '500',
    lineHeight: '18px',
  },

  sectionItem: {
    padding: '9px 0',
  },
  sectionTitleComment: {
    paddingTop: '16px',

    color: theme.colors.brand.main
  },

  full: {
    paddingTop: '15px',
    paddingBottom: '18px',
  },
  commentBorder: {
    height: '1px',
    width: '100%',

    backgroundColor: theme.colors.gray.light,
  },

  sectionItemValue: {
    maxWidth: '100%',
    
    fontWeight: '500',

    color: theme.colors.gray.main,
  },
  sectionItemValuePositive: {
    color: theme.colors.general.main,
  },
  sectionItemValueNegative: {
    color: theme.colors.rare.light,
  },
  sectionItemEdit: {
  },
  sectionItemState: {
    position: 'relative',
    width: 14,
    height: 14,
    color: '#fac81e',
    marginRight: 10
  },
  sectionItemLimit: {
    marginTop: 7
  },
  [theme.queries.mdDevicesDown]: {
    section: {
      paddingBottom: 8,
    },
    sectionTitle: {
      display: 'flex',
      cursor: 'pointer',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    sectionArrow: {
      width: 10,
      height: 5,
      position: 'relative',

      transform: 'rotate(0)',
      transition: 'transform .5s ease',

      '&:before, &:after': {
        content: '""',
        display: 'block',
        height: 2,
        width: 9,
        position: 'absolute',
        top: 0,

        borderRadius: 1,

        backgroundColor: theme.colors.gray.dark,
      },
      '&:before': {
        left: 0,
        transformOrigin: 'left top',
        transform: 'rotate(45deg)',
      },
      '&:after': {
        right: 0,
        transformOrigin: 'right top',
        transform: 'rotate(-45deg)',
      }
    },
    sectionArrowActive: {
      transform: 'rotate(180deg)',
    },

    sectionBody: {
      paddingTop: 10,

      fontSize: 12,
      fontWeight: '500',
    },

    sectionItemName: {
      paddingBottom: 4,
    },

    sectionItemEdit: {
      position: 'absolute',
      top: 12,
      right: 0,
      zIndex: 1,
      maxWidth: 50,
    },

    sectionItem: {
      position: 'relative',
      paddingRight: 50
    },
    full: {
      paddingRight: 0,
      width: '100%',
      '& $sectionItemEdit': {
        maxWidth: '100%',
        position: 'static',
        width: '100%',
        padding: 0,
      }
    },
    sectionItemState: {
      position: 'absolute',
      right: 0,
      top: 15,
      marginRight: 0,
    }
  },

  [theme.queries.lgDevicesUp]: {
    section: {
      paddingBottom: 26,
      position: 'relative'
    },
    sectionBody: {
      display: 'flex',

      fontSize: 14,

      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    sectionItem: {
      display: 'inline-flex',
      width: 'calc(50% - 25px)',

      justifyContent: 'space-between',
      alignItems: 'center',
    },

    sectionItemName: {
      width: '100%',
      paddingRight: 15,
    },

    sectionItemValue: {
      flexShrink: 0
    },
    sectionItemEdit: {
      maxWidth: 50,
      paddingLeft: 10,
      flexShrink: 0
    },
    sectionItemLimit: {
      position: 'absolute',
      right: 0,
      top: 4,
      marginTop: 0
    },
    full: {
      width: '100%',
      lineHeight: '36px',
  
      '&:after': {
        content: '""',
        display: 'block',
        width: '100%',
        height: '1px',
        position: 'absolute',
        left: 0,
        bottom: '25px',
  
        backgroundColor: theme.colors.gray.lighter,
      },
      '& $sectionItemEdit': {
        maxWidth: '100%',
        width: '100%',
        padding: 0,
      }
    }
  },
});
