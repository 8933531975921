import reducers from '~app/core/reducers';
import { mainClient } from '~app/network';

export const getBrands = async (params) => {
  reducers.dictionary.getBrandsRequest();
  const { data, error } = await mainClient.dictionary.getBrands(params);
  
  if (error !== null) {
    reducers.dictionary.getBrandsFailure(error);
    return;
  }

  reducers.dictionary.getBrandsSuccess(data);
};

export const getBrandsReset = () => {
  reducers.dictionary.getBrandsReset();
};

export const getModelsByBrand = async (brandId) => {
  reducers.dictionary.getModelsByBrandRequest({ brandId });
  const { data, error } = await mainClient.dictionary.getModels(brandId);
  
  if (error !== null) {
    reducers.dictionary.getModelsByBrandFailure({ brandId, error });
    return;
  }

  reducers.dictionary.getModelsByBrandSuccess({ brandId, data });
};


export const getColorsByModel = async (modelId) => {
  reducers.dictionary.getColorsByModelRequest({ modelId });
  const { data, error } = await mainClient.dictionary.getColors(modelId);
  
  if (error !== null) {
    reducers.dictionary.getColorsByModelFailure({ modelId, error });
    return;
  }

  reducers.dictionary.getColorsByModelSuccess({ modelId, data });
};


export const getPackagesByModel = async (modelId) => {
  reducers.dictionary.getPackagesByModelRequest({ modelId });
  const { data, error } = await mainClient.dictionary.getPackages(modelId);
  
  if (error !== null) {
    reducers.dictionary.getPackagesByModelFailure({ modelId, error });
    return;
  }

  reducers.dictionary.getPackagesByModelSuccess({ modelId, data });
};

export const getDeliveryOptions = async () => {
  reducers.dictionary.getDeliveryOptionsRequest();
  const { data, error } = await mainClient.dictionary.getDeliveryOptions();
  
  if (error !== null) {
    reducers.dictionary.getDeliveryOptionsFailure(error);
    return;
  }

  reducers.dictionary.getDeliveryOptionsSuccess(data);
};