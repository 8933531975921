export const styles = theme => ({

  root: {
    paddingTop: 40,
    paddingBottom: 75,

    background: theme.colors.brand.lightest,
  },
  head: {
    display: 'flex',
    paddingBottom: 21,

    justifyContent: 'space-between',
    alignItems: 'center',
  },
  
  headLink: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    fontSize: 12,
    textDecoration: 'none',

    color: theme.colors.brand.main,

    cursor: 'pointer'
  },
  headDevider: {
    borderBottom: `1px solid ${theme.colors.gray.lighter}`
  },
  headLinkIcon: {
    display: 'inline-block',
    width: 10,
    marginRight: 5,
    lineHeight: 0,
  },

  headCard: {
    paddingBottom: 21,
  },

  modalArchiveButton: {
    display: 'flex',
    width: 130,
    margin: '0 auto',
    padding: '21px 0 10px',

    justifyContent: 'center',
  },



  [theme.queries.lgDevicesUp]: {
  },

  [theme.queries.mdDevicesDown]: {
    modalArchiveButton: {
      width: '100%',
    }
  },
});