import storage from 'local-storage-fallback';

const applicationFormDataKey = 'applicationFormData';

class MainStorage {

  setApplicationFormData = async(data) => {
    try {
      let dataString = JSON.stringify(data);
      storage.setItem(applicationFormDataKey, dataString);
    } catch (error) {}

    return true;
  }

  getApplicationFormData = async() => {
    let extractedData = null;
    try {
      let dataString = storage.getItem(applicationFormDataKey);
      if (dataString) {
        extractedData = JSON.parse(dataString);
      }
    } catch (error) {
      this.cleanStorage();
    }

    return extractedData;
  }

  removeApplicationFormData = () => storage.removeItem(applicationFormDataKey);
}

export default new MainStorage();