export default theme => ({

  wrapper: {
    width: '100%',
    position: 'relative',

    '&:hover': {
      zIndex: 100,
    }
  },

  column: {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: '28px',
  },

  brand: {
    display: 'flex',

    fontWeight: '700',

    alignItems: 'center',
  },
  brandImage: {
    width: 26,
    marginLeft: 12,

    lineHeight: 0,
  },

  package: {
    color: theme.colors.brand.main,

    cursor: 'pointer',
  },

  delivery: {
    display: 'inline-flex',

    fontSize: 14,
    lineHeight: '22px',

    alignItems: 'center',
  },
  deliveryIcon: {
    width: 14,
    marginRight: 8,

    lineHeight: 0,
  },
  deliveryMobile: {
    display: 'none',
  },

  valueAny: {
    color: theme.colors.gray.main,
  },

  detailsItem: {
    display: 'flex',

    alignItmes: 'center',
  },
  detailsItemName: {
    width: 83,
  },
  detailsValue: {
    width: 'calc(100% - 83px)',
    fontWeight: '400',
  },

  tradeInWrapper: {
    position: 'relative',
  },

  additionalKitWrapper: {
    position: 'relative',
  },

  tradeIn: {
    display: 'flex',

    color: theme.colors.gray.lighter,

    alignItems: 'center',
  },
  tradeInActive: {
    color: theme.colors.brand.main,

    cursor: 'pointer',
  },

  tradeInIcon: {
    width: 10,
    marginRight: 8,
    lineHeight: 0,
  },

  additionalKitIcon: {
    width: 13,
    marginRight: 6,
    lineHeight: 0,
  },

  tradeInPopup: {
    whiteSpace: 'nowrap'
  },

  additionalKitPopup: {
    whiteSpace: 'normal',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#282828',
  },

  additionalKitPopupTitle: {
    fontWeight: 500,
    margin: '4px 0 6px',
  },

  additionalKitPopupValues: {
    marginBottom: 20,
  },

  additionalKitPopupComment: {
    maxWidth: 236,
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },

  tradeInPopupItem: {
    display: 'flex',
    padding: '0 17px',

    fontSize: 12,
    lineHeight: '28px',

    alignItems: 'center',
  },

  tradeInPopupItemName: {
    width: 115,
    marginRight: 15,

    fontWeight: '500',
    textAlign: 'left',
  },

  options: {
    display: 'flex',
    height: 17,
    marginTop: 9,

    alignItems: 'center',
  },

  option: {
    position: 'relative',
    marginRight: 16,

    color: theme.colors.gray.lighter,

    cursor: 'pointer',
  },
  optionActive: {
    color: theme.colors.brand.main,
  },

  optionIcon: {
    width: 17,
    fontSize: '17px',
  },

  optionHint: {
    color: theme.colors.gray.dark,
  },

  additionalBottom: {
    display: 'flex',
    paddingTop: 4,

    lineHeight: '26px',

    alignItems: 'center',
    justifyContent: 'space-between',
  },

  price: {
    paddingRight: 10,

    fontSize: 18,
    fontWeight: '700',
    lineHeight: '25px',

    color: theme.colors.general.main,
  },

  offers: {
    display: 'inline-flex',

    fontSize: 14,
    fontWeight: 400,

    alignItems: 'center',
  },

  offersIcon: {
    width: 14,
    marginRight: 8,
    marginBottom: -2,

    lineHeight: 0,

    color: theme.colors.accent.main,
  },

  date: {
    display: 'inline-flex',
    minWidth: '50%',

    fontSize: 14,
    fontWeight: 400,

    alignItems: 'center',
  },
  dateIcon: {
    width: 14,
    marginRight: 8,
    lineHeight: 0,

    color: theme.colors.accent.main,
  },

  gear: {
    position: 'relative',
    width: 14,
    height: 14,
    color: '#fac81e',
    marginLeft: 5,
    lineHeight: 1,
    flexShrink: 0,
  },
  valueCustom: {
    display: 'flex',
    alignItems: 'center'
  },

  [theme.queries.lgDevicesUp]: {
    root: {
      display: 'flex',
      height: 110,

      alignItems: 'flex-start',
    },

    column: {
      width: '25%',
      paddingRight: 40,
      '& + $column': {
        // paddingLeft: 10,
      }
    },
    columnAdditional: {
      minWidth: '260px',
      paddingRight: 0,
    },

    value: {
      overflowX: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    valueText: {
      textOverflow: 'ellipsis',
      maxWidth: 'calc(100% - 20px)',
      overflowX: 'hidden',
    },

    valueCustom: {
      overflowX: 'initial'
    },

    detailsValue: {
      overflowX: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    image: {
      display: 'inline-flex',
      width: '25%',
      height: '100%',
      paddingRight: 40,

      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',

      '& img': {
        maxWidth: 200,
        maxHeight: 100,
      }
    },
    imageWitDelivery: {
      height: 'calc(100% + 15px)',
      marginTop: '-15px',
    },

    tradeInPopupMob: {
      display: 'none',
    },

    tradeInPopupDesk: {
      display: 'block',
    },

    additionalKitPopupMob: {
      display: 'none',
    },

    additionalKitPopupDesk: {
      display: 'block',
    }
  },

  [theme.queries.lgDevicesOnly]: {
    root: {
      marginLeft: '-25px',
      marginRight: '-25px',
    },
    column: {
      width: '22%',
      paddingRight: '20px',
    },
    columnAdditional: {
      width: '31%',
    },
  },

  [theme.queries.mdDevicesDown]: {
    image: {
      display: 'flex',
      width: '100%',
      padding: '0 0 21px',

      flexDirection: 'column',
      alignItems: 'center',

      '& img': {
        width: 200,
      }
    },

    delivery: {
      display: 'none',
      width: '100%',
      paddingTop: 7,
    },
    deliveryMobile: {
      display: 'flex',
    },

    columnMain: {
      paddingBottom: 10,
    },

    tradeIn: {
      padding: '10px 0',
      lineHeight: 1,
    },

    tradeInPopupMob: {
      display: 'block',
    },
    tradeInPopupDesk: {
      display: 'none',
    },

    tradeInWrapper: {
      marginTop: 7,
    },

    additionalKitPopupMob: {
      display: 'block',
    },
    additionalKitPopupDesk: {
      display: 'none',
    },

    options: {
      height: 'auto',
      margin: '10px 0 18px',
    },
    option: {
      lineHeight: 0,
    },
    optionIcon: {
      width: 24,
      fontSize: 24,
    },
    additionalBottom: {
      paddingTop: 0,
      lineHeight: '22px',
    },
    offers: {
      lineHeight: 1,
    },
  },
});
