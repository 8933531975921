import reducers from '~app/core/reducers';
import { mainClient } from '~app/network';

// Get items by key
export const getItemsByKey = async (key, params) => {
  reducers.deals.getItemsByKeyRequest({ key });
  const { data, error } = await mainClient.deals.getDeals({ ...params });
  
  if (error !== null) {
    reducers.deals.getItemsByKeyFailure({ key, error });
    return;
  }

  reducers.deals.getItemsByKeySuccess({ key, data });
};
