import React from 'react';
import { injectStyles } from '~app/theme';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { Button, Icon } from '~app/components/ui';

import styles from './DealerBannerStyles';

@injectStyles(styles)
class DealerBanner extends React.Component {

  renderPhone = (string) => {
    return string.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 ($2) $3-$4-$5');
  }

  render() {
    const {
      classes,
      style,
      data,
      ...rest
    } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.banner} {...rest}>
          <Link to={'/dealers'} className={cn(classes.backLink, classes.hideDesk)}>
            <span className={classes.backLinkIcon}>
              <Icon name={'arrowRight'} />
            </span>
            <span className={classes.backLinkText}>{'Назад к списку дилеров'}</span>
          </Link>
          {data.name !== null &&
            <h2 className={classes.title}>
              {data.name}
            </h2>
          }
          {data.shortDescription !== null &&
            <p className={cn(classes.descr)}>
              {data.shortDescription}
            </p>
          }
          {data.phone !== null &&
            <a className={cn(classes.hideDesk, classes.phone)} href={`tel:${data.phone}`}>
              <span className={classes.phoneIcon}>
                <Icon name={'phone'} />
              </span>
              <span className={classes.phoneText}>{this.renderPhone(data.phone)}</span>
            </a>
          }
          <div className={classes.button}>
            <Link to={'/application'}>
              <Button
                mobileBlock
                block
                theme={'brand'}
                text={'Создать заявку'}
                rightIcon={<Icon name={'arrowRight'} stroked />}
              />
            </Link>
          </div>
          {data.url !== null &&
            <a
              target={'_blank'}
              rel={'noreferrer noopener'}
              className={cn(classes.hideDesk, classes.siteUrl)}
              href={data.url}>
              {'Перейти на сайт дилера'}
            </a>
          }
        </div>
        <div className={classes.infoCard}>
          <img src={data.pageImage} className={classes.logo} />
          <div className={cn(classes.fullDescr, classes.hideDesk)}>{data.fullDescription}</div>
          {data.phone !== null &&
            <a
              href={`tel:${data.phone}`}
              className={cn(classes.hideMob, classes.phone)}>
              {this.renderPhone(data.phone)}
            </a>
          }
          {data.url !== null &&
            <a
              target={'_blank'}
              rel={'noreferrer noopener'}
              href={data.url}
              className={cn(classes.hideMob, classes.siteUrl)}>
              {'Перейти на сайт'}
            </a>
          }
        </div>
      </div>
    );
  }
}

export default DealerBanner;
