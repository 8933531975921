import client from './_client';
import { OfferListModel, OfferBaseModel } from './models';
// import { transformListModel } from './_helpers';



// Get
export const getOffers = (params = {}) => {
  let {
    limit = 5,
    page = 1,
    applicationId = null,
    ...otherParams
  } = params;

  return client.request({
    url: applicationId === null ? '/v1/offers' : `/v1/application/${applicationId}/offers`,
    method: 'GET',
    params: {
      limit,
      page,
      ...otherParams
    },
    transformData: data => new OfferListModel({
      items: data.data || data.offers,
      paging: {
        count: data.count || 0,
        page: page,
        limit: limit,
      },
      ...data
    })
  });
};

// Get offer
export const getOffer = (params = {}) => {
  let {
    id
  } = params;

  return client.request({
    url: `/v1/offer/${id}`,
    transformData: data => new OfferBaseModel(data)
  });
};

// Accept offer
export const createOffer = (params = {}) => {
  let {
    price,
    applicationId,
    deliveryOptionId,
    colorId,
    customPackage,
    packageId
  } = params;

  return client.request({
    url: '/v1/offer',
    method: 'POST',
    data: {
      price,
      application: applicationId,
      delivery: deliveryOptionId,
      color: colorId,
      customPackage,
      package: packageId
    }
  });
};

// Accept offer
export const acceptOffer = (params = {}) => {
  let {
    id
  } = params;

  return client.request({
    url: `/v1/offer/${id}/accept`,
    method: 'POST',
  });
};


// Cancel offer
export const cancelOffer = ({ id }) => {
  return client.request({
    url: `/v1/offer/${id}`,
    method: 'DELETE',
  });
};

// Confirm offer
export const confirmOffer = (params = {}) => {
  let {
    id
  } = params;

  return client.request({
    url: `/v1/offer/${id}/confirm`,
    method: 'POST',
  });
};

// Archive offer
export const archiveOffer = ({ id }) => {
  return client.request({
    url: `/v1/offer/${id}`,
    method: 'POST',
    data: {
      action: 'archive'
    }
  });
};

// Refuse offer
export const refuseOffer = ({ id }) => {
  return client.request({
    url: `/v1/offer/${id}/cancel`,
    method: 'POST',
  });
};

// Remove offer
export const removeOffer = ({ id }) => {
  return client.request({
    url: `/v1/offer/${id}`,
    method: 'POST',
    data: {
      action: 'delete'
    }
  });
};
