import React, { Component } from 'react';

import { injectStyles } from '~app/theme';
import styles from './PromoItemCardStyles';

import { Link } from 'react-router-dom';

@injectStyles(styles)

class PromoItemCard extends Component {

  render = () => {
    const {
      classes,
      item,
      ...rest
    } = this.props;

    return (
      <Link
        to={`/promo/${item.id}`}
        className={classes.item}
        {...rest}>
        {item.image !== null &&
          <div
            className={classes.img}
            style={{
              backgroundImage: `url(${item.image})`
            }} />
        }
        <div className={classes.itemContent}>
          {item.dealer !== null && item.dealer.name !== null &&
            <div className={classes.itemDealer}>
              {`от ${item.dealer.name}`}
            </div>
          }
          {item.name !== null &&
            <div className={classes.itemName}>{item.name}</div>
          }
          {item.shortDescription !== null &&
            <div className={classes.itemDescription}>{item.shortDescription}</div>
          }
        </div>
      </Link>
    );
  }
}

export default PromoItemCard;
