import React, { Component } from 'react';
import actions from '~app/redux/actions';
import { injectStyles } from '~app/theme';
import { connect } from 'react-redux';
import cn from 'classnames';
import moment from 'moment';
// import { Collapse } from 'react-collapse';
import { push } from 'connected-react-router-exposed';

import { DealerRating, PackageDetailsModal } from '~app/components';
import { Card, CardContent, Icon, Button, Spacer, Price, HintHoverContainer, Hint } from '~app/components/ui';

import styles from './OfferCardStyles';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  push
};

@connect(mapStateToProps, mapDispatchToProps)
@injectStyles(styles)
class OfferCard extends Component {

  state = {
    packageModalOpened: false,
  };

  showDealerReviews = () => {
    actions.common.openModal({
      type: 'dealerReviews',
      params: {
        dealer: this.props.item.dealer,
        dealerId: this.props.item.dealer.id
      }
    });
  }

  acceptOfferOnClick = () => {
    const {
      id,
      applicationId,
    } = this.props.item;
    actions.offers.acceptByApplicationId(applicationId, { id });

    setTimeout(() => { // TODO сделать нормально
      actions.common.openGenericModal({
        theme: 'general',
        icon: 'tickCircle',
        title: 'Оффер принят!',
        text: 'Заявка перешла в стадию сделки. Ожидай подтверждения дилером!',
      });
      this.props.push('/cabinet/deals');
    }, 500);
    // actions.common.openGenericModal({
    //   theme: 'general',
    //   icon: 'tickCircle',
    //   title: 'Оффер принят!',
    //   text: 'Заявка перешла в стадию сделки. Ожидай подтверждения дилером!',
    // });
  }

  closePackageModal = () => {
    this.setState({
      packageModalOpened: false,
    });
  }

  openPackageModal = () => {
    this.setState({
      packageModalOpened: true,
    });
  }


  render() {
    const {
      classes,
      item
    } = this.props;

    const isCustomPackage = item && item.customPackage;

    return [
      item.application.packagePicked !== null && isCustomPackage &&
      <Hint
        onClick={item.application.packagePicked !== null ? this.openPackageModal : null}
        key={'hint'}
        direction={'right'}
        place={'offerBubble'}
        isActive>
        {'Дилер предлагает вам \nчто-то интересное'}
      </Hint>,
      <div key={'card'} className={classes.root}>
        <Card
          leftTick
          color={!item.readByUser ? '#eef9f8' : '#fff'}
          borderRadius={24}
        >
          <CardContent>
            <div className={classes.content}>
              <div className={cn(classes.col, classes.colMain)}>
                <div className={classes.price}>
                  {item.application.tradeIn ?
                    <HintHoverContainer
                      direction={'right'}
                      hint={'Стоимость предварительная и зависит от оценки состояния вашего автомобиля специалистом Тrade-In дилера'}

                    >
                      <Price price={item.price} additional={'*'} />
                    </HintHoverContainer>
                    :
                    <Price price={item.price} />
                  }
                </div>
                <div className={classes.info}>{item.delivery.name}</div>
              </div>
              <div className={cn(classes.col, classes.colInfo)}>
                <div className={cn(classes.color, item.application.colorPicked ? classes.textLight : null)}>{item.color.name}</div>
                <div
                  onClick={item.application.packagePicked !== null ? this.openPackageModal : null}
                  className={cn(classes.package, {
                    [classes.textLight]: item.application.packagePicked,
                    [classes.custom]: item.application.packagePicked !== null
                  })}>
                  <span>{item.package.name}</span>
                  {item.application.packagePicked !== null && isCustomPackage &&
                    <div
                      onMouseOver={() => { this.setState({ customPackageHovered: true }); }}
                      onMouseLeave={() => { this.setState({ customPackageHovered: false }); }}
                      className={classes.gear}>
                      <Icon name={'gear'} />
                      <Hint isActive={this.state.customPackageHovered}>
                        {'Нестандартная комплектация'}
                      </Hint>
                    </div>
                  }
                </div>
              </div>
              <div className={cn(classes.col, classes.colAdditional)}>

                <div onClick={this.showDealerReviews} className={classes.reviewsWrapper}>
                  <DealerRating userDealer={item.dealer} />
                </div>

                <div className={classes.date}>
                  <div className={classes.dateIcon}>
                    <Icon name={'clock'} />
                  </div>
                  {moment(item.createdDate).format('DD.MM.YY в HH:mm')}
                </div>

              </div>
            </div>
          </CardContent>
        </Card>
        <div className={classes.buttonWrapper}>
          <div className={classes.button}>
            <Button
              theme={'general'}
              leftIcon={
                <div className={classes.buttonIcon}>
                  <Icon name={'tick'} />
                  <Spacer h={8} />
                </div>
              }
              text={'Принять оффер'}
              block
              onClick={this.acceptOfferOnClick}
              mobileBlock
            />
          </div>
        </div>
        {item.package != null &&
          <PackageDetailsModal
            isOpen={this.state.packageModalOpened}
            onRequestClose={this.closePackageModal}
            item={item.package}
            customPackage={item.customPackage}
          />
        }
      </div>
    ];
  }
}

export default OfferCard;
