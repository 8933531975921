import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import actions from '~app/redux/actions';
import { Link } from 'react-router-dom';
import FlipMove from 'react-flip-move';

import { throttle } from 'throttle-debounce';

import {
  Container, Price, Select, TextInput, Icon, EmptyListPlaceholder
} from '~app/components/ui';
import { PromotionBanner } from '~app/components';
import PreFooter from '../HomeScreen/Sections/PreFooter/PreFooter';
import Banner from '../HomeScreen/Sections/Banner/Banner';
import { injectStyles } from '~app/theme';
import { styles } from './DealersScreenStyles';


const mapStateToProps = ({ dealers, dictionary }) => {
  return {
    items: dealers.dealerItems,
    inProgress: dealers.getDealersInProgress,
    error: dealers.getDealersError,

    brands: dictionary.brands,
  };
};

@connect(mapStateToProps)
@injectStyles(styles)
class DealersScreen extends Component {

  state = {
    // currentPage: 1,
    options: {
      brand: null,
      search: null,
    }
  }

  static async fetchData({ store, params }) {
    await actions.dealers.getDealers();
  }

  componentDidMount = () => {
    actions.dictionary.getBrands({ withdealers: true });
    this.loadData();
  }

  componentWillUnmount() {
    actions.dictionary.getBrandsReset();
    actions.dealers.getDealersReset();
  }


  loadData = throttle(300, () => {
    actions.dealers.getDealers({
      brand_id: this.state.options.brand,
      search: this.state.options.search,
    });
  });

  onFilterChange = (options) => {
    this.setState(prevState => ({
      options: { ...prevState.options, ...options }
    }), () => {
      this.loadData();
    });
  }

  onChangeSearch = event => {
    this.onFilterChange({ search: event.target.value });
  }

  onChangeBrand = value => {
    this.onFilterChange({ brand: value });
  }

  renderDealers = () => {
    const {
      items,
      classes
    } = this.props;

    return (
      <div>
        {items !== null &&
          items.length > 0 ?
          <FlipMove>
            {items.map((item) => {
              return (
                <div key={item.id}>
                  <div className={classes.item}>
                    <Link to={`/dealers/${item.id}`} className={classes.itemLink} />
                    <div className={classes.itemImgWrapper}>
                      {item.pageImage !== null &&
                        <img className={classes.itemImg} src={item.pageImage} />
                      }
                    </div>
                    <div className={classes.itemContent}>
                      {item.name !== null &&
                        <div className={classes.itemName}>{item.name}</div>
                      }
                      {item.brands !== null &&
                        Array.isArray(item.brands) &&
                        item.brands.length > 0 &&
                        <div className={classes.itemBrands}>
                          {`Бренды: ${item.brands.join(', ')}`}
                        </div>
                      }
                      {item.shortDescription !== null &&
                        <div className={classes.itemText}>
                          {item.shortDescription}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              );
            })}
          </FlipMove>
          :
          <EmptyListPlaceholder
            iconName={'target'}
            text={'Ничего не найдено. Попробуйте изменить запрос'}
          />
        }
      </div>
    );
  }

  render() {
    const {
      classes,
      brands,
    } = this.props;

    const { options } = this.state;

    const brandEmptyOption = {
      id: null,
      name: 'Все марки',
    };
    const brandsOptions = brands ? [brandEmptyOption].concat(brands) : [brandEmptyOption];

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Avicar - Автодилеры</title>
        </Helmet>
        <Container background={'#ffffff'}>
          <Banner
            style={'brand'}
            title={'Все автодилеры Петербурга'}
            bubbles={[
              { price: <Price price={'1930000'} inline />, comment: 'Есть в наличии' },
              { price: <Price price={'1915000'} inline />, comment: 'Под заказ от 30 дней' },
              { price: <Price price={'1920000'} inline />, comment: 'Есть в наличии' },
              { price: <Price price={'1900000'} inline />, comment: 'Поступление до 7 дней' },
              { price: <Price price={'1895000'} inline />, comment: 'Поступление до 14 дней' },
            ]}
            updateInterval={3000}
          />
          <div className={classes.root}>
            <div className={classes.title}>{'Лучшие автодилеры Петербурга'}</div>
            <div className={classes.content}>
              <div className={classes.list}>
                <div className={classes.filters}>
                  <div className={classes.filtersItem}>
                    <Select
                      options={brandsOptions}
                      placeholder={'Все марки'}
                      onChange={this.onChangeBrand}
                      value={options.brand}
                      valueField={'id'}
                      textField={'name'}
                    />
                  </div>
                  <div className={classes.filtersItem}>
                    <TextInput
                      name={'search'}
                      placeholder={'Поиск по дилерам'}
                      onChange={this.onChangeSearch}
                      value={options.search}
                      leftIcon={<div className={classes.searchIcon}><Icon name={'search'} /></div>}
                    />
                  </div>
                </div>
                <div className={classes.items}>
                  {this.renderDealers()}
                </div>
              </div>
              <div className={classes.banner}>
                <PromotionBanner type={'dealers'} />
              </div>
            </div>
          </div>
          <PreFooter />
        </Container>
      </div>
    );
  }
}

export default DealersScreen;