const themes = {
  primary: (theme) => ({
    bar: {
      backgroundColor: theme.colors.accent.main,
    },
  }),
  white: (theme) => ({
    bar: {
      backgroundColor: '#fff',
    },
  }),
};

export default (theme) => ({
  root: (props) => ({
    display: 'block',
    height: 6,
    width: '100%',
    position: 'relative',
    overflow: 'hidden',

    borderRadius: 3,

    ...themes[props.theme](theme, props).root,
  }),
  bar: (props) => ({
    display: 'block',
    width: '50%',
    height: 6,
    position: 'absolute',
    left: '-50%',
    top: 0,

    borderRadius: 3,
    transformOrigin: 'left center',

    ...themes[props.theme](theme, props).bar,
  }),
  barFirst: {
    animation: 'bar 2s ease-in-out infinite',
  },
  barSecond: {
    animation: 'bar 2s ease-in-out infinite',
    animationDelay: '1s',
  },

  '@keyframes bar': {
    '0%': { transform: 'translateX(0) scaleX(1)', opacity: 1 },
    '99%': { transform: 'translateX(300%) scaleX(2)', opacity: 1 },
    '99.5%': { transform: 'translateX(300%) scaleX(2)', opacity: 0 },
    '100%': { transform: 'translateX(0) scaleX(1)', opacity: 0 },
  },
});