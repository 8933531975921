export const styles = theme => ({

  root: {
    paddingTop: 40,
    paddingBottom: 75,

    background: theme.colors.brand.lightest,
  },
  container: {
    position: 'relative'
  },

  firstOfferCommentContainer: {
    position: 'relative',
    maxWidth: 900,
  },

  firstOfferCommentText: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    color: '#282828',
  },

  firstOfferIcon: {
    position: 'absolute',
    left: 50,
    top: 38,
    width: 40,
    height: 42,
  },

  head: {
    display: 'flex',
    paddingBottom: 21,

    justifyContent: 'space-between',
    alignItems: 'center',
  },

  headLink: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    fontSize: 12,
    textDecoration: 'none',

    color: theme.colors.brand.main,

    cursor: 'pointer',
  },
  headLinkIcon: {
    display: 'inline-block',
    width: 10,
    marginRight: 5,
  },
  [theme.queries.mdDevicesDown]: {
    firstOfferIcon: {
      left: 20,
      top: 40,
    }
  },
});
