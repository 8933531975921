export const styles = theme => ({
  root: {
    
  },

  filters: {
    position: 'relative',
    zIndex: 10,
  },

  head: {
    display: 'flex',
    paddingBottom: 21,

    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headLink: {
    display: 'inline-flex',

    fontSize: 12,
    fontWeight: '400',
    textDecoration: 'none',

    color: theme.colors.brand.main,

    alignItems: 'center',
  },
  headLinkIcon: {
    width: 10,
    marginLeft: 4,
  },

  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  bodyHead: {
    display: 'flex',
    padding: '20px 0 4px',
  },
  bodyBottom: {
    display: 'flex',
    position: 'relative',
    padding: '4px 0 96px',
  },

  scrollToTop: {
    position: 'absolute',
    right: -70,
    bottom: 116,
  },


  [theme.queries.lgDevicesUp]: {
    head: {
      paddingTop: 40,
    },

    bodyHead: {
      justifyContent: 'flex-end',
    },
    bodyBottom: {
      justifyContent: 'flex-end',
    },
  },

  [theme.queries.mdDevicesDown]: {
    head: {
      paddingTop: 34,
    },
    headLink: {
      fontSize: 10,
    },

    bodyHead: {
      justifyContent: 'center',
    },
    bodyBottom: {
      justifyContent: 'center',
    },
    scrollToTop: {
      display: 'none',
    }
  },
});