export default theme => ({
  tab_active: {
    backgroundColor: '#fff',
  },
  content: {
    padding: '34px 50px 40px',
  },

  ...theme.styles.modal,

  [theme.queries.smDevicesDown]: {
    content: {
      padding: '34px 20px 40px',
    },
  },
  

});