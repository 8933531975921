export default (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    padding: '66px 0',
  },
  left: {
    '& $item': {
      alignItems: 'flex-start',
    },
    '& $title': {
      textAlign: 'left',
      color: '#1464dc',
      lineHeight: 1.56,
      margin: '0 0 7px',
    },
    '& $text': {
      textAlign: 'left',
      fontSize: 14,
      lineHeight: 1.71
    },
    '& $icon': {
      width: 30,
      height: 26,
      justifyContent: 'flex-start'
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 35px',
    width: '100%',
  },
  icon: {
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    margin: '0 0 19px'
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.11,
    textAlign: 'center',
    color: '#282828',
    width: '100%',
    margin: '0 0 12px'
  },
  text: {
    fontSize: 12,
    lineHeight: 2,
    width: '100%',
    textAlign: 'center',
    color: '#282828',
    margin: '0 0 10px',
  },
  divider: {
    width: 1,
    height: 180,
    opacity: 0.3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderImageSource: 'linear-gradient(to bottom, rgba(137, 142, 162, 0.0), #898ea2 50%, rgba(137, 142, 162, 0.0))',
    borderImageSlice: 1,
  },
  [theme.queries.mdDevicesDown]: {
    item: {
      marginBottom: 35,
    },
    left: {
      '& $item': {
        maxWidth: 420
      },
    },
    divider: {
      display: 'none'
    }
  },
  [theme.queries.lgDevicesUp]: {
    item: {
      width: 'calc(25% - 2px)',
    }
  },
  [theme.queries.lgDevicesOnly]: {
  },

});
