import * as common from './common/commonActions';
import * as auth from './auth/authActions';
import * as applications from './applications/applicationsActions';
import * as notifications from './notifications/notificationsActions';
import * as dictionary from './dictionary/dictionaryActions';
import * as offers from './offers/offersActions';
import * as reviews from './reviews/reviewsActions';
import * as deals from './deals/dealsActions';
import * as user from './user/userActions';
import * as chat from './chat/chatActions';
import * as lander from './lander/landerActions';
import * as partners from './partners/partnersActions';
import * as news from './news/newsActions';
import * as dealers from './dealers/dealersActions';
import * as promo from './promo/promoActions';
import * as banners from './banners/bannersActions';


export default {
  common,
  auth,
  applications,
  notifications,
  dictionary,
  offers,
  reviews,
  deals,
  user,
  chat,
  lander,
  partners,
  news,
  dealers,
  promo,
  banners,
};
