import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { injectStyles } from '~app/theme';
import { styles } from './BrandsItemCardStyles';

@injectStyles(styles)

class BrandsItemCard extends Component {

  render = () => {
    const {
      classes,
      item,
      ...rest
    } = this.props;

    return (
      <Link to={`/application/${item.slug}`} className={classes.brand} {...rest}>
        <span
          style={{ backgroundImage: `url(${item.logoUrl})` }}
          className={classes.brandImage} />
        {item.name !== null &&
          <span className={classes.brandTitle}>{item.name}</span>
        }
      </Link>

    );
  }
}

export default BrandsItemCard;
