export const styles = theme => ({
  root: {
    
  },

  title: {
    padding: '20px 0 30px',
    fontSize: '24px',
    fontWeight: '700',
  },

  banner: {
    width: '270px',
    // paddingBottom: '50%',
    // background: '#dadada',
  },

  searchIcon: {
    display: 'flex',
    height: '100%',
    width: '16px',
    margin: '0 auto',

    color: theme.colors.gray.main,

    alignItems: 'center',
    justifyContent: 'center',
  },

  items: {
    position: 'relative',
    zIndex: 1,
    paddingTop: '30px',
    paddingBottom: '60px',
  },

  item: {
    marginBottom: '30px',
    padding: '30px 40px',
    position: 'relative',
    cursor: 'pointer',
    
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 0,
      top: 0,
      left: 4,
      width: 'calc(100% - 8px)',
      height: '100%',
      borderRadius: 6,
      boxShadow: '0 8px 14px 0 rgba(60, 60, 60, 0.08)',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 6,
      backgroundColor: '#ffffff',
      boxShadow: '0 6px 20px 0 rgba(20, 100, 220, 0.09)',
    },
  },
  itemLink: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: '4',
    textDecoration: 'none',
  },

  itemImgWrapper: {
    width: '35%',
    position: 'relative',
    zIndex: 2,
    paddingRight: '40px',
  },
  itemImg: {
    width: '100%',
  },

  itemContent: {
    width: '65%',
    position: 'relative',
    zIndex: 2,
  },
  itemName: {
    fontSize: '24px',
    lineHeight: '40px',
    color: theme.colors.brand.main,
  },
  itemBrands: {
    fontSize: '14px',
    lineHeight: 2,
    color: theme.colors.gray.main,
    maxHeight: 'calc(14px * 2 * 1)',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',

  },
  itemText: {
    paddingTop: '30px',
    fontSize: '14px',
    lineHeight: '28px',
  },

  filters: {
    position: 'relative',
    zIndex: 4,
  },

  [theme.queries.lgDevicesUp]: {
    content: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    list: {
      width: 'calc(100% - 300px)',
    },

    filters: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  
    filtersItem: {
      width: 'calc(50% - 5px)',
    },

    item: {
      display: 'flex',
      alignItems: 'center',
    },
  },

  [theme.queries.mdDevicesDown]: {
    filtersItem: {
      marginBottom: '8px',
    },

    item: {
      padding: '20px',
    },
    itemImgWrapper: {
      width: '100%',
      height: '30px',
      paddingRight: 0,
    },
    itemImg: {
      width: 'auto',
      maxWidth: '100%',
      maxHeight: '100%'
    },
    itemContent: {
      width: '100%',
      paddingTop: '12px',
    },
    itemName: {
      fontSize: '18px',
      lineHeight: '28px',
    },
    banner: {
      width: '100%',
    }
  },
});