import client from './_client';
import { transformListModel } from './_helpers';
import { BrandBaseModel, DealerModel } from './models';

// Get dealers list (brand_id, search)
export const getDealersList = (params = {}) => {
  return client.request({
    url: '/v1/groups/list',
    method: 'GET',
    params,
    transformData: data => ({ items: transformListModel(DealerModel)(data) }),
  });
};

// Get dealer by id
export const getDealer = (id = null) => {
  return client.request({
    url: `/v1/group/${id}`,
    method: 'GET',
    transformData: data => new DealerModel(data),
  });
};

// Get brands by dealer
export const getBrandsByDealer = (id = null) => {
  return client.request({
    url: `/v1/group/${id}/brands`,
    method: 'GET',
    transformData: data => ({ items: transformListModel(BrandBaseModel)(data) }),
  });
};
