import React, { Component } from 'react';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import styles from './CarCarouselStyles';
import { Price, Button } from '~app/components/ui';

import { connect } from 'react-redux';
import actions from '~app/redux/actions';

import Swiper from 'swiper/dist/js/swiper.js';

// import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import queries from '~app/theme/main/queries';
import SalePercent from '~app/components/ui/SalePecrent/SalePercent';



const mapStateToProps = ({ auth }) => {

  return {
    isAuthorized: auth.isAuthorized,
  };
};


@connect(mapStateToProps)

@injectStyles(styles)

class CarCarousel extends Component {

  componentDidMount() {
    const { classes } = this.props;

    const swiperConfig = {
      loop: false,
      slidesPerView: 3,
      spaceBetween: 0,
      pagination: {
        el: '.swiper-pagination',
        bulletClass: cn('swiper-pagination-bullet'),
        bulletActiveClass: cn('swiper-pagination-bullet-active', classes.bulletActive),
      },
      breakpoints: {
        [queries.MD]: {
          slidesPerView: 2,
        },
        [queries.SM]: {
          slidesPerView: 1,
        }
      }
    };

    new Swiper('.cars-swiper', swiperConfig);
  }

  createApplication = (data, afterAuth = false) => {
    const { isAuthorized } = this.props;

    // if (this.state.tradeInFilled) {
    //   combinedData.tradeIn = true;
    //   combinedData.oldCar = this.state.tradeInFormData;
    // }

    if (!isAuthorized && !afterAuth) {
      actions.common.openAuthModal('register', false, { type: 'landing' });
    } else {
      actions.applications.createApplication(data);
    }
  }

  render = () => {
    const {
      classes,
      title,
      content = []
    } = this.props;

    return (
      <div className={cn(classes.root)}>
        <h3 className={classes.title}>{title}</h3>
        <div className={classes.content}>
          <div className="swiper-container cars-swiper">
            <div className="swiper-wrapper">
              {content.map((item, index) =>
                <div key={index} className={'swiper-slide'}>
                  <div className={classes.item}>
                    <div className={classes.top}>
                      <div className={classes.info}>
                        <div className={classes.brandInfo}>
                          {item.brand && <div className={classes.brand}>{item.brand}</div>}
                          {item.brandImg &&
                            <div className={classes.brandImgContainer}>
                              <img src={item.brandImg} className={classes.brandImg} />
                            </div>}
                        </div>
                        {item.model && <div className={classes.model}>{item.model}</div>}
                      </div>
                      {item.discountPercent && item.discountPercent > 0 &&
                        <SalePercent value={item.discountPercent} />
                      }
                    </div>
                    <div className={classes.img} style={{
                      backgroundImage: `url(${item.img})`
                    }} />
                    {item.color &&
                      <div className={classes.prop}>
                        <div className={classes.propTitle}>{'Цвет:'}</div>
                        <div className={classes.propValue}>{item.color}</div>
                      </div>
                    }
                    {item.package &&
                      <div className={classes.prop}>
                        <div className={classes.propTitle}>{'Комплектация:'}</div>
                        <div className={classes.propValue}>{item.package}</div>
                      </div>
                    }
                    {item.discountValue &&
                      <div className={classes.price}>
                        {'Скидка: '}
                        <Price inline price={item.discountValue} />
                      </div>
                    }

                    {item.modelId && item.colorId && item.packageId &&
                      <div className={classes.createApplication}>
                        <Button
                          mobileBlock
                          theme={'brand'}
                          text={'Создать заявку'}
                          onClick={() => this.createApplication({
                            model: item.modelId,
                            color: item.colorId,
                            package: item.packageId,
                          })}
                          block
                        />
                      </div>
                    }
                  </div>
                </div>
              )}

            </div>
            <div className="swiper-pagination" />
          </div>
        </div>
      </div>
    );
  }
}

CarCarousel.propTypes = {
  title: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    brand: PropTypes.string,
    brandImg: PropTypes.string,
    model: PropTypes.string,
    img: PropTypes.string.isRequired,
    color: PropTypes.string,
    package: PropTypes.string,
    discountPercent: PropTypes.number,
    discountValue: PropTypes.number,
  }))
};

export default CarCarousel;
