export default theme => ({

  item: {
    position: 'relative',
    zIndex: 1,
  },
  
  itemButtons: {
    display: 'flex',
    // height: 84,
    position: 'relative',
    zIndex: 2,
    padding: '12px 0',

    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  itemButton: {
    display: 'inline-flex',
    height: 48,
    padding: '5px 10px',

    fontSize: 14,
    fontWeight: '700',

    color: theme.colors.brand.main,

    alignItems: 'center',

    cursor: 'pointer',
  },

  contentMobile: {
    display: 'none',
  },

  itemButtonIcon: {
    width: 12,
    height: 12,
    position: 'relative',
    marginRight: 9,

    '&:before, &:after': {
      content: '""',
      display: 'block',
      width: 14,
      height: 1.5,
      position: 'absolute',
      top: 5,
      left: -1,

      backgroundColor: 'currentColor',

      transformOrigin: 'center',
    },
    '&:before': {
      transform: 'rotate(-45deg)',
    },
    '&:after': {
      transform: 'rotate(45deg)',
    },
  },

  [theme.queries.mdDevicesDown]: {
    itemButtons: {
      height: 'auto',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    itemButton: {
      padding: '0',
      justifyContent: 'center',
    },
    itemButtonSecond: {
      order: -1,
    },
    contentMobile: {
      display: 'block',
    },
    contentDesktop: {
      display: 'none',
    },
  }
});