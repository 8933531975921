export default theme => ({

  tabs: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    overflow: 'hidden',

    borderRadius: '6px 6px 0 0',
  },

  tab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    minWidth: '150px',
    height: '48px',
    
    color: theme.colors.gray.main,
    fontSize: '12px',
    // fontWeight: '200',
    letterSpacing: '1px',
    textAlign: 'center',
    textTransform: 'uppercase',

    backgroundColor: theme.colors.brand.lightest,

    cursor: 'pointer',

    transition: 'color 0.3s',
    '&:hover': {
      color: theme.colors.brand.main,
    }
  },
  tab_active: {
    backgroundColor: '#fff',
  },
  content: {
    padding: '34px 50px 40px',
  },

  title: {
    paddingBottom: 6,
    color: theme.colors.gray.dark,
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: '25px',
    textAlign: 'center',
  },
  titleSmaller: {
    maxWidth: 220,
    margin: '0 auto',
    
    fontSize: 14,
  },
  text: {
    marginTop: '20px',
    marginBottom: '20px',
    
    fontSize: '14px',
    color: theme.colors.gray.dark,
    textAlign: 'center',
    lineHeight: '24px',
  },
  
  form: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: 'calc(100% + 10px)',
    margin: '19px -5px -6px',
  },
  formInput: {
    width: '100%',
    margin: '6px 5px',
    textAlign: 'left',
  },
  formInput_half: {
    width: 'calc(50% - 10px)',
  },
  formAction: {
    width: '100%',
    paddingTop: '17px',
    textAlign: 'center',
  },
  formInputLabel: {
    display: 'block',
    fontSize: '12px',
    color: theme.colors.gray.main,
    textAlign: 'center',
  },
  formInputLabelError: {
    color: theme.colors.rare.light,
  },
  formInputLabelSuccess: {
    color: theme.colors.general.main,
  },

  nav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: theme.colors.brand.main,
    fontSize: '12px',
    textAlign: 'center',
    
    cursor: 'pointer',

    transition: 'color 0.3s',
    '&:hover': {
      color: theme.colors.brand.active,
    }
  },
  navIcon: {
    display: 'inline-block',
    width: '16px',
  },
  navIconChange: {
    width: 10,
    marginRight: 4,
    transform: 'rotate(180deg)',
  },

  timer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: theme.colors.gray.main,
    fontSize: '12px',
    textAlign: 'center',
  },
  timerIcon: {
    display: 'inline-block',
    width: '14px',
    marginRight: 8,
  },

  codeTitle: {
    marginTop: '40px',

    color: theme.colors.gray.main,
    fontSize: '12px',
  },


  ...theme.styles.modal,

  [theme.queries.smDevicesDown]: {
    tab: {
      fontSize: '10px',
    },
    formInput_half: {
      width: '100%',
    },
    formInputLabel: {
      paddingTop: '20px',
    },
    content: {
      padding: '34px 20px 40px',
    },
  },
  

});