export default theme => ({
  root: {
    boxShadow: '0 2px 4px 0 rgba(60,60,80,0.08)',

    borderRadius: '6px',
  },

  info: {
    display: 'flex',
    padding: '32px 50px 28px',

    background: '#FFF',

    borderRadius: '6px 6px 0 0',
  },
  infoItem: {
    width: '25%',
  },
  infoItemDecreased: {
    width: '20%',
  },
  infoItemEdit: {
    display: 'inline-flex',
    width: '5%',
    paddingTop: 25,

    fontSize: '12px',

    color: theme.colors.brand.main,

    justifyContent: 'flex-end',

    cursor: 'pointer',
  },
  infoDealerName: {
    paddingBottom: 5,

    fontSize: 18,
    fontWeight: '500',
  },
  raitingWrapper: {
    cursor: 'pointer',
  },
  infoItemTitle: {
    marginBottom: '7px',

    color: theme.colors.gray.main,
    fontSize: '12px',
    lineHeight: '15px',
  },

  infoItemValue: {
    display: 'flex',

    fontSize: 14,
    fontWeight: '500',
    lineHeight: '18px',

    color: theme.colors.gray.dark,

    justifyContent: 'space-between',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  enlarged: {
    fontSize: 18
  },

  infoItemValue_name: {
    marginTop: '-1px',

    color: theme.colors.gray.dark,
    fontSize: '18px',
    lineHeight: '18px',
  },

  reduced: {
    fontSize: 14
  },

  infoItemValueWarning: {
    width: '14px',
    height: '14px',
    marginLeft: '10px',
    marginRight: 'auto',

    color: theme.colors.rare.main,

    cursor: 'pointer',
  },

  links: {
    display: 'flex',
    height: 40,
    position: 'relative',
    zIndex: 3,
    padding: '10px 50px 12px',

    backgroundColor: theme.colors.accent.main,

    borderRadius: '0 0 6px 6px',

    alignItems: 'center',
  },
  linksDealer: {
    backgroundColor: theme.colors.brand.main,
  },

  linksItem: {
    color: '#FFF',
    opacity: .6,

    fontSize: '14px',

    transition: 'opacity .5s ease',
    cursor: 'pointer',
  },

  linkContainer: {
    position: 'relative',
    '& ~ &': {
      marginLeft: '32px',
    },
  },

  hint: {

  },

  linksItemActive: {
    opacity: 1,

    '& $linksItemIcon': {
      transform: 'rotate(180deg)',
    }
  },
  linksItem_empty: {
    opacity: 0.6,

    cursor: 'auto',
  },
  linksItemFilters: {
    display: 'inline-flex',

    fontWeight: '500',

    alignItems: 'center',
  },
  linksItemIcon: {
    width: 12,
    marginLeft: 8,

    transform: 'rotate(0)',
    transition: 'transform .5s ease',
  },

  filtersWrapperContainer: {
    marginTop: -10,
  },

  filtersWrapper: {
    position: 'relative',
    zIndex: 1,
    paddingTop: 10,

    borderRadius: '0 0 6px 6px',

    backgroundColor: '#fff',

    '&:before': {
      content: '""',
      display: 'block',
      height: 10,
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,

      backgroundColor: theme.colors.brand.main,
    },
  },
  filtersItem: {
    padding: '6px 0',
  },

  [theme.queries.lgDevicesUp]: {

    info: {
      minHeight: 100,
    },

    infoDealerName: {
      marginTop: -4,
    },

    linksItemFilters: {
      marginLeft: 'auto !important',
    },

    filtersWrapper: {
      display: 'flex',
      padding: '28px 50px',

      flexWrap: 'wrap',
    },
    filtersItem: {
      width: '20%',
      paddingRight: 10,
    }
  },

  [theme.queries.mdDevicesDown]: {
    hint: {
      display: 'none'
    },
    info: {
      position: 'relative',
      padding: '28px 20px',

      flexDirection: 'column',
    },
    infoItem: {
      width: '100%',
      '& ~ &': {
        marginTop: '16px',
      }
    },

    infoItemEdit: {
      width: '50%',
      position: 'absolute',
      bottom: 28,
      right: 20,
      paddingTop: 0,
    },

    filtersWrapper: {
      padding: '14px 20px',
    },

    links: {
      height: 'auto',
      padding: '5px 10px 7px',

      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    linksItem: {
      padding: '0 5px',
    },
    linkContainer: {
      width: '50%',
      margin: '5px 0',
      '& ~ &': {
        margin: '5px 0',
      }
    }
  },
});
