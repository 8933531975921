import { mapper, fromInput } from 'demapper';

import ApplicationBaseModel from '../Application/ApplicationBaseModel';
import BrandBaseModel from '../Brand/BrandBaseModel';
import ModelBaseModel from '../Model/ModelBaseModel';
import ColorBaseModel from '../Color/ColorBaseModel';
import PackageBaseModel from '../Package/PackageBaseModel';
import DeliveryBaseModel from '../Delivery/DeliveryBaseModel';
import UserBaseModel from '../User/UserBaseModel';

@mapper
export default class {

  @fromInput('_id')
  id = null;

  @fromInput('application', { transform: x => typeof x === 'string' ? null : new ApplicationBaseModel(x) })
  application = null;

  @fromInput('application', { transform: x => typeof x === 'string' ? x : x._id })
  applicationId = null;

  @fromInput('price')
  price = null;

  @fromInput('type')
  type = null;

  @fromInput('readByUser')
  readByUser = false;

  @fromInput('archivedByUser')
  archivedByUser = false;

  @fromInput('archivedByDealer')
  archivedByDealer = false;

  @fromInput('createdAt', { model: Date })
  createdDate = null;

  @fromInput('updatedAt', { model: Date })
  updatedDate = null;

  @fromInput('allowReview')
  allowReview = true;

  @fromInput('brand', { transform: x => new BrandBaseModel(typeof x === 'string' ? { '_id': x } : x) })
  brand = null;

  @fromInput('model', { transform: x => new ModelBaseModel(typeof x === 'string' ? { '_id': x } : x) })
  model = null;

  @fromInput('color', { transform: x => new ColorBaseModel(typeof x === 'string' ? { '_id': x } : x) })
  color = null;

  @fromInput('package', { transform: x => new PackageBaseModel(typeof x === 'string' ? { '_id': x } : x) })
  package = null;

  @fromInput('customPackage', { transform: x => x ? JSON.parse(x) : null })
  customPackage = null;

  @fromInput('delivery', { transform: x => new DeliveryBaseModel(typeof x === 'string' ? { '_id': x } : x) })
  delivery = null;

  @fromInput('dealer', { transform: x => new UserBaseModel(typeof x === 'string' ? { '_id': x } : x) })
  dealer = null;

  @fromInput('user', { transform: x => new UserBaseModel(typeof x === 'string' ? { '_id': x } : x) })
  user = null;
}
