import Constants from 'Constants';

export const IS_SERVER = process.env.SERVER === 'true';
export const IS_BROWSER = !IS_SERVER;

const Config = {
  constants: Constants,
  urls: {
    apiBase: Constants.BASE_URL,
  },
  regex: {
    email: /^[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/
  },
  languages: ['en'],
  languageDefault: 'en'
};

export default Config;