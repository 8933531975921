import { mapper, fromInput } from 'demapper';
import { transformImageUrl } from '../../_helpers';

@mapper
export default class {
  @fromInput('buttonText')
  buttonText = null;

  @fromInput('description')
  description = null;

  @fromInput('header')
  header = null;

  @fromInput('image.secure_url', { transform: transformImageUrl })
  image = null;

}
