import { updateStateItemByKey } from '~app/utils/reduxHelpers';

export const initialState = {
  newsByKey: {},

  newsItem: null,
  newsItemInProgress: false,
  newsItemError: null,

};

const initialStateNewsByKey = {
  items: null,
  paging: null,
  inProgress: false,
  error: null,
};

// get news by key
export const getNewsByKeyRequest = (state, { key }) => {
  return updateStateItemByKey(state, 'newsByKey', initialStateNewsByKey, key, {
    inProgress: true,
    error: null
  });
};

export const getNewsByKeySuccess = (state, { key, data }) => {
  const {
    items,
    paging,
  } = data;

  return updateStateItemByKey(state, 'newsByKey', initialStateNewsByKey, key, {
    items,
    paging,
    inProgress: false,
    error: null
  });
};
export const getNewsByKeyFailure = (state, { key, error }) => {
  return updateStateItemByKey(state, 'newsByKey', initialStateNewsByKey, key, {
    inProgress: false,
    error
  });
};

// get news item by id
export const getNewsItemRequest = (state) => ({
  ...state,
  newsItemInProgress: true
});

export const getNewsItemSuccess = (state, payload) => ({
  ...state,
  newsItem: payload,
  newsItemInProgress: false,
  newsItemError: null,
});

export const getNewsItemFailure = (state, payload) => ({
  ...state,
  newsItemInProgress: false,
  newsItemError: null,
});


export const getNewsItemReset = (state) => ({
  ...state,

  newsByKey: {},

  newsItem: null,
  newsItemInProgress: false,
  newsItemError: null,
});