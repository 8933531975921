export const styles = theme => ({
  root: {

  },
  section: {
    paddingBottom: 40,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#282828',
    margin: '0 0 30px',
  },
  carousel: {
    margin: '0 -15px',
  },
  [theme.queries.lgDevicesUp]: {
  },

  [theme.queries.mdDevicesDown]: {
  },
});