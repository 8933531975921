import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Button, Icon } from '~app/components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './PreFooterStyles';

@injectStyles(styles)
class PreFooter extends Component {

  render() {
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.title}>Avicar – cервис,<br />где ценой управляешь ты</div>
        <div className={classes.subtitle}>Cкидки и выгодные предложения на покупку нового авто</div>
        <div className={classes.button}>
          <Link to={'/application'}>
            <Button
              block
              mobileBlock
              theme={'accent'}
              text={'Создать заявку'}
              rightIcon={<Icon name={'arrowRight'} stroked />}
            />
          </Link>
        </div>
      </div>
    );
  }
}

export default PreFooter;
