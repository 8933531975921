import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../';

import { injectStyles } from '~app/theme';
import styles from './EmptyListPlaceholderStyles';

@injectStyles(styles)
class EmptyListPlaceholder extends Component {
  render = () => {
    const {
      classes,
      iconName,
      text,
      additional
    } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.icon}>
          <Icon name={iconName} />
        </div>
        <div className={classes.text}>{text}</div>
        {additional !== null && additional}
      </div>
    );
  }
}

EmptyListPlaceholder.propTypes = {
  centerPage: PropTypes.bool,
  background: PropTypes.string,
  additional: PropTypes.object,
};

EmptyListPlaceholder.defaultProps = {
  centerPage: false,
  background: 'transparent',
  additional: null,
};

export default EmptyListPlaceholder;