import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '~app/redux/actions';
import { injectStyles } from '~app/theme';
import { push } from 'connected-react-router-exposed';

import { Container, Icon, Breadcrumbs, Spacer, Card, CardContent } from '../../../components/ui';
import { ApplicationCard, OfferCard } from '../../../components';

import { styles } from './CabinetUserApplicationScreenStyles';


const mapStateToProps = (state, ownProps) => {
  const applicationId = ownProps.match.params.id;

  let itemState = {
    item: null,
    inProgress: false,
    error: null
  };
  if (applicationId in state.applications.itemById) {
    itemState = state.applications.itemById[applicationId];
  }

  let offersState = {
    items: null,
    paging: null,
    inProgress: false,
    error: null,
  };
  if (applicationId in state.offers.itemsByApplicationId) {
    offersState = state.offers.itemsByApplicationId[applicationId];
  }

  return {
    applicationId,
    ...itemState,

    offers: offersState.items,
    offersPaging: offersState.paging,
    offersInProgress: offersState.inProgress,
    offersError: offersState.error,
  };
};

const mapDispatchToProps = {
  push
};

@connect(mapStateToProps, mapDispatchToProps)
@injectStyles(styles)
class CabinetUserApplicationScreen extends Component {

  componentDidMount = () => {
    this.loadData();
  }

  loadData = (props = this.props) => {
    actions.applications.getItemById(this.props.applicationId);
    actions.offers.getItemsByApplicationId(this.props.applicationId);
  }

  archiveOnClick = () => {
    const { item } = this.props;
    actions.common.openModal({
      type: 'archive',
      params: {
        applicationId: item.id,
        title: 'Удалить заявку',
        text: 'Заявка потеряет все предложения дилеров. Ты уверен, что хочешь удалить заявку?',
        onSuccess: () => {
          this.props.push('/cabinet');
        }
      }
    });
  }


  renderOffers = () => {
    const {
      offers,
      classes
    } = this.props;

    return (
      <div>
        {offers !== null && offers.map((item, index) => {
          return (
            <div key={index} className={classes.container}>
              <OfferCard item={item} />
              <Spacer v={12} />
            </div>
          );
        })}
        {offers !== null && offers.length == 1 &&
          <div className={classes.firstOfferCommentContainer}>
            <Card
              leftTick
              color={'#fff'}
              borderRadius={24}
              leftIcon={<div className={classes.firstOfferIcon}>
                <Icon name={'firstOffer'} />
              </div>
              }>
              <CardContent leftIcon>
                <div className={classes.firstOfferCommentText}>
                  {'Дождитесь нескольких предложений, не соглашайтесь на первое - важно иметь возможность сравнить'}
                </div>
              </CardContent>
            </Card>
          </div>
        }
      </div>
    );
  }

  render = () => {
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.root}>
        <Container>
          <div className={classes.head}>
            <Breadcrumbs
              options={[
                {
                  href: '/',
                  name: 'Главная',
                },
                {
                  href: '/cabinet',
                  name: 'Мой кабинет',
                },
                {
                  href: '/cabinet',
                  name: 'Мои заявки',
                }
              ]}
            />
            <div
              onClick={this.archiveOnClick}
              className={classes.headLink}
            >
              <div className={classes.headLinkIcon}>
                <Icon name={'timesThinest'} />
              </div>
              {'Удалить'}
            </div>
          </div>

          <div className={classes.head}>
            {this.props.item !== null &&
              <ApplicationCard
                item={this.props.item} />
            }
          </div>

          <div className={classes.body}>
            {this.renderOffers()}
          </div>

        </Container>
      </div>
    );
  }
}

export default CabinetUserApplicationScreen;
