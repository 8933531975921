import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import { Switch, Route } from 'react-router';

import { LayoutGeneral } from '~app/components';
import CabinetUserScreen from '~app/modules/CabinetUserScreen/CabinetUserScreen';
import CabinetUserApplicationScreen from '~app/modules/CabinetUserScreen/CabinetUserApplicationScreen/CabinetUserApplicationScreen';
import CabinetUserDealScreen from '~app/modules/CabinetUserScreen/CabinetUserDealScreen/CabinetUserDealScreen';
import NotFoundScreen from '~app/modules/NotFoundScreen/NotFoundScreen';

class ContainerUser extends Component {
  render = () => {
    return (
      <LayoutGeneral>
        <Helmet>
          <title>Avicar - Личный кабинет</title>
        </Helmet>
        <Switch>
          <Route path={'/cabinet/application/:id'} component={CabinetUserApplicationScreen} />
          <Route path={'/cabinet/deal/:id'} component={CabinetUserDealScreen} />
          <Route path={'/'} component={CabinetUserScreen} />
          <Route path='*' component={NotFoundScreen} />
        </Switch>
      </LayoutGeneral>
    );
  }
}

export default ContainerUser;
