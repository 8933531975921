export default (theme) => ({
  root: {
    padding: '46px 0 86px'
  },
  bullet: {
    backgroundColor: '#e1e6eb',
    opacity: 1,
  },
  bulletActive: {
    backgroundColor: '#fac81e'
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 2,
    textAlign: 'center',
    color: '#282828',
    margin: '0 0 25px'
  },
  content: {
    // display: 'flex',
    // justifyContent: 'space-between',
  },
  item: {
    // width: 'calc(100%/3 - 15px)',
    display: 'flex',
    color: '#000',
    textDecoration: 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '28px 28px 34px',
    borderRadius: 6,
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 4px 0 rgba(60, 60, 80, 0.08)',
    position: 'relative',
    margin: '20px 15px 66px',
    '&:after': {
      zIndex: 0,
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      borderRadius: 6,
      backgroundColor: '#ffffff',
      boxShadow: '0 8px 28px 0 rgba(10, 80, 180, 0.12)',
    }
  },
  top: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    position: 'relative',
    zIndex: 1,
  },
  brand: {
    margin: '0 8px 0 0',
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.78,
    color: '#282828',
  },
  model: {
    margin: 0,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 2,
    color: '#282828',
  },
  brandInfo: {
    display: 'flex'
  },
  brandImgContainer: {
    width: 50,
    height: 32
  },
  brandImg: {
    maxWidth: 50,
    maxHeight: 32
  },
  img: {
    width: '100%',
    height: '156px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    margin: '0 0 10px',
    position: 'relative',
    zIndex: 1,
  },
  prop: {
    margin: '0 0 5px',
    textAlign: 'left',
    width: '100%',
    position: 'relative',
    zIndex: 1,
  },
  propTitle: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 2.33,
    color: '#a0aab4',
    margin: '0 0 -5px'
  },
  propValue: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 2,
    color: '#282828',
    margin: 0
  },
  price: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#14dc64',
    width: '100%',
    textAlign: 'left',
    position: 'relative',
    zIndex: 1,
    '& span': {
      fontSize: 24,
    }
  },
  createApplication: {
    width: '100%',
    position: 'relative',
    zIndex: 1,
    paddingTop: '20px',
  },

  [theme.queries.mdDevicesDown]: {
    content: {
      flexWrap: 'wrap',
    },
    title: {
      lineHeight: 1.33,
    }
  },
  [theme.queries.lgDevicesUp]: {
  },
  [theme.queries.lgDevicesOnly]: {
  },

});
