import { updateStateItemByKey } from '~app/utils/reduxHelpers';

export const initialState = {
  myReviews: null,
  myReviewsPaging: null,
  myReviewsInProgress: false,
  myReviewsError: null,

  itemsByKey: {},
  
  createByOfferId: {},
};

const initialStateItemByKey = {
  items: null,
  paging: null,
  inProgress: false,
  error: null,
};

const initialStateCreateByOfferId = {
  result: null,
  inProgress: false,
  error: null,
};



export const getMyReviewsRequest = (state) => ({
  ...state,
  myReviewsInProgress: true
});
export const getMyReviewsSuccess = (state, { items, paging }) => ({
  ...state,
  myReviews: items,
  myReviewsPaging: paging,
  myReviewsInProgress: false,
  myReviewsError: null,
});
export const getMyReviewsFailure = (state, payload) => ({
  ...state,
  myReviewsInProgress: false,
  myReviewsError: null,
});


// Models
export const getItemsByKeyRequest = (state, { key }) => {
  return updateStateItemByKey(state, 'itemsByKey', initialStateItemByKey, key, {
    inProgress: true,
    error: null
  });
};

export const getItemsByKeySuccess = (state, { key, data }) => {
  const {
    items,
    paging,
  } = data;

  return updateStateItemByKey(state, 'itemsByKey', initialStateItemByKey, key, {
    items,
    paging,
    inProgress: false,
    error: null
  });
};
export const getItemsByKeyFailure = (state, { key, error }) => {
  return updateStateItemByKey(state, 'itemsByKey', initialStateItemByKey, key, {
    inProgress: false,
    error
  });
};

// Create
export const createByOfferIdRequest = (state, { offerId }) => {
  return updateStateItemByKey(state, 'createByOfferId', initialStateCreateByOfferId, offerId, {
    inProgress: true,
    error: null
  });
};

export const createByOfferIdSuccess = (state, { offerId, data }) => {
  return updateStateItemByKey(state, 'createByOfferId', initialStateCreateByOfferId, offerId, {
    result: data,
    inProgress: false,
    error: null
  });
};
export const createByOfferIdFailure = (state, { offerId, error }) => {
  return updateStateItemByKey(state, 'createByOfferId', initialStateCreateByOfferId, offerId, {
    inProgress: false,
    error
  });
};