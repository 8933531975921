export const transformListModel = Model => data => {
  if (Array.isArray(data)) {
    return data.map(x => new Model(x));
  }
  if (Array.isArray(data.data)) {
    return data.data.map(x => new Model(x));
  } 
  return [];
};

export const transformImageUrl = (url) => {
  if (typeof url !== 'string') {
    return url;
  }
  return url.replace(/\/image\/upload\/(\w+)\//, '/image/upload/fl_lossy,f_auto/');
};