import { mapper, fromInput } from 'demapper';
import { transformImageUrl } from '../../_helpers';

@mapper
export default class {

  @fromInput('name')
  name = null;

  @fromInput('modelId')
  modelId = null;

  @fromInput('packageName')
  package = null;

  @fromInput('packageId')
  packageId = null;

  @fromInput('brandName')
  brand = null;

  @fromInput('brand')
  brandId = null;

  @fromInput('brandLogo.secure_url')
  brandLogo = null;

  @fromInput('discount.skidka')
  discountPercent = 0;

  @fromInput('discount.skidkaInRoubles')
  discountValue = 0;

  @fromInput('image.secure_url', { transform: transformImageUrl })
  image = null;

  @fromInput('name', { transform: x => x.replace(/[ -.]/g, '').toLowerCase() })
  slug = null;


  @fromInput('color._id')
  colorId = null;

  @fromInput('color.name')
  colorName = null;

}
