import React, { Component } from 'react';

import { injectStyles } from '~app/theme';
import { styles } from './AdvantagesStyles';

@injectStyles(styles)
class Advantages extends Component {

  renderItem = (item, index) => {
    const { classes } = this.props;

    return (
      <div key={index} className={classes.item}>
        <div className={classes.itemTitle}>{item.title}</div>
        <div className={classes.itemText}>{item.text}</div>
      </div>
    );
  }

  render() {
    const {
      classes,
      items = null,
    } = this.props;

    return (
      <div className={classes.root}>
        {items !== null && items.map((item, index) => this.renderItem(item, index))}
      </div>
    );
  }
}

export default Advantages;