import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from '~app/theme';
import cn from 'classnames';
import moment from 'moment';

import { Icon } from '~app/components/ui';

import styles from './ReviewCardStyles';


@injectStyles(styles)
class ReviewCard extends Component {
  render() {
    const {
      classes,
      item,
      showAuthorName
    } = this.props;

    const {
      positive,
      authorName,
      modelName,
      text,
      createdDate,
    } = item;

    const title = showAuthorName ? authorName : modelName;

    return (
      <div className={cn(classes.root, !positive ? classes.rootNegative : null, positive ? classes.rootPositive : null)}>
        <div className={cn(
          classes.rating,
          !positive ? classes.ratingNegative : null,
          positive ? classes.ratingPositive : null
        )}>
          {positive ? '+1' : '-1'}
        </div>
        <div className={classes.name}>{title}</div>
        <div className={classes.message}>{text}</div>
        <div className={classes.bottom}>
          <div className={classes.date}>
            <div className={classes.dateIcon}>
              <Icon name={'clock'}/>
            </div>
            <div className={classes.dateText}>{moment(createdDate).format('DD.MM.YY в HH:mm')}</div>
          </div>
          {showAuthorName &&
            <div className={classes.package}>{modelName}</div>
          }
        </div>
      </div>
    );
  }
}

ReviewCard.propTypes = {
  showAuthorName: PropTypes.bool,
};

ReviewCard.defaultProps = {
  showAuthorName: false,
};

export default ReviewCard;
