import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import actions from '~app/redux/actions';
import { Link } from 'react-router-dom';
import FlipMove from 'react-flip-move';

import { Container, Breadcrumbs, Spacer, Icon, ScrollToTop, EmptyListPlaceholder } from '~app/components/ui';
import { Filters, Pagination, DealCard } from '~app/components';
import { injectStyles } from '~app/theme';
import { styles } from './DealsScreenStyles';


const mapStateToProps = (state, { reduxKey = 'DealsScreen '}) => {
  let dealsState = {
    items: null,
    paging: null,
    inProgress: false,
    error: null
  };

  if (reduxKey in state.deals.itemsByKey) {
    dealsState = state.deals.itemsByKey[reduxKey];
  }

  return {
    reduxKey,
    user: state.auth.user,
    items: dealsState.items,
    itemsPaging: dealsState.paging,
    itemsInProgress: dealsState.inProgress,
    itemsError: dealsState.error,
  };
};

@connect(mapStateToProps)
@injectStyles(styles)
class DealsScreen extends Component {

  state = {
    currentPage: 1,
    options: {
      brand: null,
      model: null,
      color: null,
      package: null
    }
  }

  componentDidMount = () => {
    this.loadData();
  }

  loadData = (props = this.props) => {
    actions.deals.getItemsByKey(this.props.reduxKey, {
      page: this.state.currentPage,
      options: this.state.options,
    });
    // if (props.applications === null && !props.applicationsInProgress && props.applicationsError === null) {
    //   actions.applications.getApplications({
    //     page: this.state.currentPage,
    //     options: this.state.options,
    //   });
    // }
  }

  // updateData = (props = this.props) => {
  //   if (!props.applicationsInProgress && props.applicationsError === null) {
  //     actions.applications.getApplications({
  //       page: this.state.currentPage,
  //       options: this.state.options,
  //     });
  //   }
  // }

  setPage = (page) => {
    this.setState({
      currentPage: page,
    }, () => {
      this.loadData();
    });
  }

  onFilterChange = (options) => {
    this.setState({
      currentPage: 1,
      options: options
    }, () => {
      this.loadData();
    });
  }

  renderDeals = () => {
    const {
      items
    } = this.props;

    return (
      <div>
        {items !== null &&
          items.length > 0 ?
          <FlipMove>
            {items.map((item) => {
              return (
                <div key={item.id}>
                  <DealCard item={item} showActions={false} />
                  <Spacer v={12}/>
                </div>
              );
            })}
          </FlipMove>
          :
          <EmptyListPlaceholder
            iconName={'car'}
            text={'По выбранным параметрам не удалось найти ни одной сделки'}
          />
        }
      </div>
    );
  }

  render() {
    const {
      classes,
      user,
      items,
    } = this.props;
    
    const isUser = user !== null && !user.isDealer;
    const isNotDealer = user !== null ? !user.isDealer : true;

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Avicar - Сделки</title>
        </Helmet>
        <Container background={'#f6fafe'}>
          <div className={classes.head}>
            <Breadcrumbs
              options={[
                {
                  href: '/',
                  name: 'Главная',
                },
                {
                  name: 'Сделки',
                }
              ]}
            />
            {isUser &&
              <Link to={'/cabinet'} className={classes.headLink}>
                {'Мой кабинет'}
                <div className={classes.headLinkIcon}>
                  <Icon name={'arrowRight'}/>
                </div>
              </Link>
            }
          </div>
          <div className={classes.filters}>
            <Filters
              options={this.state.options}
              onChange={(options) => this.onFilterChange(options)}
              isNotDealer={isNotDealer}
            />
          </div>
          <div className={classes.bodyHead}>
            {items !== null && items.length > 0 &&
              <Pagination
                paging={this.props.itemsPaging !== null ? this.props.itemsPaging : null}
                onClick={(x) => this.setPage(x+1)}
              />
            }
          </div>

          <div className={classes.list}>
            {this.renderDeals()}
          </div>

          <div className={classes.bodyBottom}>   
            {items !== null && items.length > 0 &&  
              <div>
                <Pagination
                  paging={this.props.itemsPaging !== null ? this.props.itemsPaging : null}
                  onClick={(x) => this.setPage(x+1)}
                />
                <div className={classes.scrollToTop}>
                  <ScrollToTop/>
                </div>
              </div>
            }
          </div>
        </Container>
      </div>
    );
  }
}

export default DealsScreen;