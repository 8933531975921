export const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 200
  },
  icon: {
    width: 40,
    height: 40,
    margin: '0 0 15px',
  },
  text: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#a0aab4',
    margin: '0 0 25px',
  },
  [theme.queries.lgDevicesUp]: {
    buttons: {
      display: 'flex'
    }
  },

  [theme.queries.mdDevicesDown]: {
  },
});
