import { mapper, fromInput } from 'demapper';
import { BrandBaseModel, PackageBaseModel, LanderContentModel, ModelBaseModel, ModelShortModel } from '~app/network/mainClient/models';

@mapper
export default class {
  @fromInput('content', { transform: x => typeof x === 'string' ? null : new LanderContentModel(x) })
  content = null;

  @fromInput('brand', { transform: x => typeof x === 'string' ? null : new BrandBaseModel(x) })
  brand = null;

  @fromInput('model', { transform: x => typeof x === 'string' ? null : new ModelBaseModel(x) })
  model = null;

  @fromInput('package', { transform: x => typeof x === 'string' ? null : new PackageBaseModel(x) })
  package = null;

  @fromInput('advantages')
  advantages = null;

  @fromInput('similarModels', { each: item => new ModelShortModel(item) })
  similarModels = null;

}
