import client from './_client';
import { transformListModel } from './_helpers';
import { BrandBaseModel, ModelBaseModel, PackageBaseModel, ColorBaseModel, DeliveryOptionBaseModel } from './models';

// Get brands
export const getBrands = params => {
  return client.request({
    url: '/v1/brands',
    method: 'GET',
    params,
    transformData: data => ({ items: transformListModel(BrandBaseModel)(data) })
  });
};

export const getModels = (brandId) => {
  return client.request({
    url: `/v1/brand/${brandId}/models`,
    method: 'GET',
    transformData: data => ({ items: transformListModel(ModelBaseModel)(data) })
  });
};


export const getColors = (modelId) => {
  return client.request({
    url: `/v1/model/${modelId}`,
    method: 'GET',
    transformData: data => ({ items: transformListModel(ColorBaseModel)(data.colors) })
  });
};



export const getPackages = (modelId) => {
  return client.request({
    url: `/v1/model/${modelId}/packages`,
    method: 'GET',
    transformData: data => ({ items: transformListModel(PackageBaseModel)(data) })
  });
};


export const getDeliveryOptions = () => {
  return client.request({
    url: '/v1/deliveryOptions',
    method: 'GET',
    transformData: data => ({ items: transformListModel(DeliveryOptionBaseModel)(data.deliveryOptions) })
  });
};
