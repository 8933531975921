import reducers from '~app/core/reducers';
import { mainClient } from '~app/network';

export const getContentByBrand = async (slug) => {

  reducers.lander.getContentByBrandRequest();
  const { data, error } = await mainClient.lander.getContentByBrand(slug);

  if (error !== null) {
    reducers.lander.getContentByBrandFailure({ error });
    return;
  }

  reducers.lander.getContentByBrandSuccess({ data });
};

export const getContentByModel = async (params) => {

  reducers.lander.getContentByModelRequest();
  const { data, error } = await mainClient.lander.getContentByModel(params);

  if (error !== null) {
    reducers.lander.getContentByModelFailure({ error });
    return;
  }

  reducers.lander.getContentByModelSuccess({ data });
};
