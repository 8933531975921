import React, { Component } from 'react';
import { connect } from 'react-redux';

import actions from '~app/redux/actions';
import { GenericModal } from '~app/components';
import { Button, Icon } from '~app/components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './ArchiveModalStyles';

const mapStateToProps = (state, { applicationId = null, offerId = null }) => {
  
  let inProgress = false;
  let success = false;
  let error = null;

  if (applicationId !== null && state.applications.archiveId === applicationId) {
    inProgress = state.applications.archiveInProgress;
    success = state.applications.archiveSuccess;
    error = state.applications.archiveError;
  } else if (offerId !== null && state.offers.archiveId === offerId) {
    inProgress = state.offers.archiveInProgress;
    success = state.offers.archiveSuccess;
    error = state.offers.archiveError;
  }

  return {
    inProgress,
    success,
    error,
    
    archiveApplicationInProgress: state.applications.archiveInProgress,
    archiveOfferInProgress: state.offers.archiveInProgress,
  };
};


@connect(mapStateToProps)
@injectStyles(styles)
class Applications extends Component {
  
  componentWillReceiveProps(nextProps) {
    if (!this.props.success && nextProps.success) {
      this.onSuccess();
    }
  }

  onSuccess = () => {
    this.props.onSuccess();
  }

  archive = () => {
    const {
      applicationId = null,
      offerId = null,
    } = this.props;
    if (this.props.inProgress) {
      return;
    }
    if (applicationId !== null && !this.props.archiveApplicationInProgress) {
      actions.applications.archiveItem(applicationId);
    } else if (offerId !== null && !this.props.archiveOfferInProgress) {
      actions.offers.archiveItem(offerId);
    }
  }

  render() {
    const {
      classes,
      isOpen,
      onRequestClose,
      title,
      text,
      btnText,
      offersCount,
    } = this.props;
    
    const applicationText = offersCount === 0 ?
      'На данный момент нет предложений от дилеров. Ты уверен, что хочешь удалить заявку?' :
      'Заявка потеряет все предложения дилеров. Ты уверен, что хочешь удалить заявку?';

    return (
      <GenericModal
        open={isOpen}
        title={title ? title : 'Удалить заявку?'}
        text={text ? text : applicationText}
        theme={'general'}
        onRequestClose={onRequestClose}
        additionalContent={
          <div className={classes.button}>
            <Button 
              mobileBlock
              theme={'rare'}
              text={btnText ? btnText : 'Удалить'}
              leftIcon={<Icon name={'timesThin'}/>}
              onClick={this.archive}
              block
            />
          </div>
        }
      />
    );
  }
}

export default Applications;