export default theme => ({
  
  root: {
    display: 'flex',

    alignItems: 'baseline',
  },

  textContent: {
    display: 'inline-flex',
    alignItems: 'baseline',
  },

  score: {
    paddingRight: 16,

    fontSize: 18,
    fontWeight: '500',

    color: theme.colors.general.main,
  },
  scoreNegative: {
    color: theme.colors.rare.main,
  },

  scoreIcon: {
    width: 14,
    marginRight: 8,
    lineHeight: 0,
  },

  reviews: {
    display: 'inline-flex',

    fontSize: 14,
    fontWeight: '500',

    alignItems: 'center',
  },
  reviewsItem: {
    paddingLeft: 6,

    color: '#e1e6eb',
  },
  reviewsItemPositive: {
    color: theme.colors.general.main,
  },
  reviewsItemNegative: {
    color: theme.colors.rare.main,
  },
});