export const styles = theme => ({
  itemButtons: {
    display: 'flex',
    height: 84,
    padding: '12px 0 24px',

    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  withComment: {
    justifyContent: 'space-between'
  },

  comment: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    color: '#a0aab4',
  },

  itemButton: {
    display: 'inline-flex',
    height: 48,
    padding: '5px 10px',

    fontSize: 14,
    fontWeight: '700',

    color: theme.colors.brand.main,

    alignItems: 'center',

    cursor: 'pointer',
  },

  itemButtonIcon: {
    width: 12,
    marginRight: 9,

    lineHeight: 0,
  },

  modalArchiveButton: {
    display: 'flex',
    width: 130,
    margin: '0 auto',
    padding: '21px 0 10px',

    justifyContent: 'center',
  },

  emptyBtnIcon: {
    width: 16,

    lineHeight: 0,

    color: '#fff',
  },


  [theme.queries.mdDevicesDown]: {
    withComment: {
      justifyContent: 'center',
      flexWrap: 'wrap',
      height: 'auto'
    },
    comment: {
      textAlign: 'center'
    },
    modalArchiveButton: {
      width: '100%',
    },
    itemButtonFull: {
      width: '100%',
      justifyContent: 'center',
    }
  },
});
