export default theme => ({


  checkbox: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '&:hover': {
      '& $ui': {
        backgroundColor: theme.colors.gray.lightest,
      }
    }
  },

  input: {
    display: 'none',
  },

  ui: {
    flexShrink: 0,
    position: 'relative',
    display: 'inline-block',
    width: '20px',
    height: '20px',
    borderRadius: '3px',
    transition: 'border-color 0.3s ease, background-color 0.3s ease',

    backgroundColor: '#FFFFFF',
    border: '1px solid #E1E6EB',
    boxShadow: '0 2px 1px 0 rgba(60,60,80,0.04)',
    cursor: 'pointer',
  },
  ui_error: {
    borderColor: theme.colors.rare.main,
  },

  check: {
    display: 'block',
    height: '7px',
    width: '13px',
    position: 'absolute',
    right: '1px',
    bottom: '3px',
    opacity: 0,
    transformOrigin: 'bottom left',
    transform: 'rotate(-45deg) translate(4px, 2px)',
    transition: 'opacity 0.3s ease',
    
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      right: '3px',

      display: 'block',
      height: '2px',

      borderRadius: '2px',
      
      background: '#1464DC',
      
      transition: 'background-color 0.3s ease',
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,

      display: 'block',
      width: '2px',

      background: '#1464DC',
      
      borderRadius: '2px',

      transition: 'background-color 0.3s ease',
    },
  },
  check_active: {
    opacity: 1,
  },

  label: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '12px',
  },
  labelSmaller: {
      
  },

  labelLink: {
    color: '#1464DC',
    textDecoration: 'none',

    transition: 'color 0.3s',
    '&:hover': {
      color: '#1478F0',
    }
  },

  labelIcon: {
    marginRight: '5px',
    fontSize: '20px',
  },

  labelText: {
    color: '#282828',
    fontSize: '12px',
    // fontWeight: '200',
    textAlign: 'left',
    lineHeight: '20px',
    // letterSpacing: '0.5px',
    cursor: 'pointer',

    '& a': {
      fontWeight: '400',
    }
  },

  labelHint: {
    display: 'block',
    color: '#787878',
  },
  ui_disabled: {
    pointerEvents: 'none',
  },
  '.input:checked + .ui .check': {
    opacity: 1,
  },
  '.input:disabled': {
    pointerEvents: 'none',
  },

  '.checkbox:hover .ui': {
    borderColor: '#f38775',
  },
  '.checkbox:hover .input:checked + .ui': {
    backgroundColor: '#f38775',
    borderColor: '#f38775',
  },


  '._handle-input': {
    '& .ui': {
      pointerEvents: 'all',
    },
  },
  '._handle-label': {
    '.ui, .labelText': {
      pointerEvents: 'all',
    },
  },
  
});