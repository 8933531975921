import { mapper, fromInput } from 'demapper';

@mapper
export default class {
  @fromInput('count')
  totalItems = 0;

  @fromInput('page')
  page = 1;

  @fromInput('limit')
  pageSize = 0;
}
