export const initialState = {
  currentLanguage: null,
  authModalState: null,
  authModalUseRedirect: null,
  authModalProps: {},
  dealerAuthModalState: null,

  modals: [],

  genericModal: {
    open: false,
    title: null,
    text: null,
    icon: null,
    theme: null,
    additionalContent: null,
    thinPaddings: false,
    onClose: null,
  },

  supportMessageInProgress: false,
  supportMessageSuccess: null,
  supportMessageError: null,

  subscribeInProgress: false,
  subscribeSuccess: null,
  subscribeError: null,
};

export const setCurrentLanguage = (state, payload) => ({
  ...state,
  currentLanguage: payload
});

export const setAuthModalState = (state, { tab, redirect, props }) => ({
  ...state,
  authModalState: tab,
  authModalUseRedirect: redirect,
  authModalProps: props
});
export const setDealerAuthModalState = (state, payload) => ({
  ...state,
  dealerAuthModalState: payload
});

export const setGenericModal = (state, payload) => ({
  ...state,
  genericModal: {
    open: true,
    ...payload
  }
});

export const resetGenericModal = (state) => ({
  ...state,
  genericModal: {
    ...state.genericModal,
    open: false,
  }
});

export const createModal = (state, payload) => ({
  ...state,
  modals: [
    ...state.modals,
    payload
  ]
});
export const updateModal = (state, { uuid, ...data }) => ({
  ...state,
  modals: state.modals.map(x => {
    if (x.uuid === uuid) {
      return {
        ...x,
        ...data,
      };
    }
    return x;
  })
});
export const removeModal = (state, { uuid }) => ({
  ...state,
  modals: state.modals.filter(x => x.uuid !== uuid)
});


export const supportMessageRequest = (state) => ({
  ...state,
  supportMessageInProgress: true,
  supportMessageSuccess: null,
  supportMessageError: null
});
export const supportMessageSuccess = (state) => ({
  ...state,
  supportMessageInProgress: false,
  supportMessageSuccess: true,
  supportMessageError: null
});
export const supportMessageFailure = (state, payload) => ({
  ...state,
  supportMessageInProgress: false,
  supportMessageSuccess: null,
  supportMessageError: payload
});


export const subscribeRequest = (state) => ({
  ...state,
  subscribeInProgress: true,
  subscribeSuccess: null,
  subscribeError: null
});
export const subscribeSuccess = (state) => ({
  ...state,
  subscribeInProgress: false,
  subscribeSuccess: true,
  subscribeError: null
});
export const subscribeFailure = (state, payload) => ({
  ...state,
  subscribeInProgress: false,
  subscribeSuccess: null,
  subscribeError: payload
});
