export const styles = theme => ({
  root: {
    width: props => props.size,
    height: props => props.size,
    position: 'relative',
    padding: props => props.padding,

    cursor: 'pointer',
  },

  burger: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },

  line: {
    width: '100%',
    height: '20%',
    position: 'absolute',
    left: 0,

    backgroundColor: props => props.color ? props.color : theme.colors.accent.main,

    '&:nth-child(1)': {
      top: 0,

      animation: 'crossToBurgerFirst .7s ease forwards',
    },
    '&:nth-child(2)': {
      top: '40%',

      animation: 'crossToBurgerSecond .7s ease forwards',
    },
    '&:nth-child(3)': {
      bottom: 0,

      animation: 'crossToBurgerThird .7s ease forwards',
    },
  },

  active: {
    '& $line': {
      '&:nth-child(1)': {
        animation: 'burgerToCrossFirst .7s ease forwards',
      },
      '&:nth-child(2)': {
        animation: 'burgerToCrossSecond .7s ease forwards',
      },
      '&:nth-child(3)': {
        animation: 'burgerToCrossThird .7s ease forwards',
      }
    }
  },


  '@keyframes burgerToCrossFirst': {
    '0%': {
      transform: 'translateY(0)'
    },
    '50%': {
      transform: 'translateY(200%)'
    },
    '100%': {
      transform: 'translateY(200%) rotate(45deg)'
    },
  },
  '@keyframes burgerToCrossSecond': {
    '0%': {
      transform: 'translateY(0)'
    },
    '50%': {
      transform: 'translateY(0)'
    },
    '100%': {
      transform: 'translateY(0) rotate(45deg)'
    },
  },
  '@keyframes burgerToCrossThird': {
    '0%': {
      transform: 'translateY(0)'
    },
    '50%': {
      transform: 'translateY(-200%)'
    },
    '100%': {
      transform: 'translateY(-200%) rotate(-45deg)'
    },
  },

  '@keyframes crossToBurgerFirst': {
    '0%': {
      transform: 'translateY(200%) rotate(45deg)'
    },
    '50%': {
      transform: 'translateY(200%) rotate(0)'
    },
    '100%': {
      transform: 'translateY(0)'
    },
  },
  '@keyframes crossToBurgerSecond': {
    '0%': {
      transform: 'translateY(0) rotate(45deg)'
    },
    '50%': {
      transform: 'translateY(0) rotate(0)'
    },
    '100%': {
      transform: 'translateY(0)'
    },
  },
  '@keyframes crossToBurgerThird': {
    '0%': {
      transform: 'translateY(-200%) rotate(-45deg)'
    },
    '50%': {
      transform: 'translateY(-200%) rotate(0)'
    },
    '100%': {
      transform: 'translateY(0)'
    },
  },

});