import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectStyles } from '~app/theme';
import cn from 'classnames';
import actions from '~app/redux/actions';

import { Select } from '~app/components/ui';

import styles from './FiltersStyles';

const mapStateToProps = (state, ownProps) => {
  let {
    brand = null,
    model = null,
    color = null,
    // package = null, // reserved
  } = ownProps.options;


  let currentBrand = null;
  if (brand !== null && state.dictionary.brands !== null) {
    currentBrand = state.dictionary.brands.find(x => x.id === brand);
  }

  let modelsState = null;
  if (currentBrand !== null && currentBrand.id in state.dictionary.modelsByBrand) {
    modelsState = state.dictionary.modelsByBrand[currentBrand.id];
  }
  let currentModel = null;
  if (model !== null && modelsState !== null && modelsState.items !== null) {
    currentModel = modelsState.items.find(x => x.id === model);
  }

  let colorsState = null;
  if (currentModel !== null && currentModel.id in state.dictionary.colorsByModel) {
    colorsState = state.dictionary.colorsByModel[currentModel.id];
  }
  let currentColor = null;
  if (color !== null && color.toLowerCase() !== 'any' && colorsState !== null && colorsState.items !== null) {
    currentColor = colorsState.items.find(x => x.id === color);
  }

  let packagesState = null;
  if (currentModel !== null && currentModel.id in state.dictionary.packagesByModel) {
    packagesState = state.dictionary.packagesByModel[currentModel.id];
  }
  let currentPackage = null;
  if (ownProps.options.package !== null && ownProps.options.package.toLowerCase() !== 'any' && packagesState !== null && packagesState.items !== null) {
    currentPackage = packagesState.items.find(x => x.id === ownProps.options.package);
  }

  return {
    options: {
      brand, model, color,
      package: ownProps.options.package,
    },
    currentBrand,
    currentModel,
    currentColor,
    currentPackage,

    brands: state.dictionary.brands,
    brandsInProgress: state.dictionary.brandsInProgress,
    brandsError: state.dictionary.brandsError,

    models: modelsState === null ? null : modelsState.items,
    modelsInProgress: modelsState === null ? false : modelsState.inProgress,
    modelsError: modelsState === null ? null : modelsState.error,

    colors: colorsState === null ? null : colorsState.items,
    colorsInProgress: colorsState === null ? false : colorsState.inProgress,
    colorsError: colorsState === null ? null : colorsState.error,

    packages: packagesState === null ? null : packagesState.items,
    packagesInProgress: packagesState === null ? false : packagesState.inProgress,
    packagesError: packagesState === null ? null : packagesState.error,
  };
};

@connect(mapStateToProps)
@injectStyles(styles)
class Filters extends Component {
  componentDidMount = () => {
    this.loadData();
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.options !== nextProps.options) {
      this.loadData(nextProps);
    }
  }


  loadData = (props = this.props) => {
    if (props.brands === null && !props.brandsInProgress && props.brandsError === null) {
      actions.dictionary.getBrands();
    }
    if (props.currentBrand !== null && props.models == null && !props.modelsInProgress && props.modelsError === null) {
      actions.dictionary.getModelsByBrand(props.currentBrand.id);
    }
    if (props.currentModel !== null && props.colors == null && !props.colorsInProgress && props.colorsError === null) {
      actions.dictionary.getColorsByModel(props.currentModel.id);
    }
    if (props.currentModel !== null && props.packages == null && !props.packagesInProgress && props.packagesError === null) {
      actions.dictionary.getPackagesByModel(props.currentModel.id);
    }
  }

  onChangeBrand = (value) => {
    const currentOptions = this.props.options;
    const options = {
      ...currentOptions,
      brand: value,
      model: null,
      color: null,
      package: null
    };
    this.props.onChange(options);
    // console.log(options);
    // if (value === null) {

    // }
  }

  onChangeModel = (value) => {
    const currentOptions = this.props.options;
    const options = {
      ...currentOptions,
      model: value,
      color: null,
      package: null
    };
    this.props.onChange(options);
    // console.log(options);
  }

  onChangeColor = (value) => {
    const currentOptions = this.props.options;
    const options = {
      ...currentOptions,
      color: value,
    };
    this.props.onChange(options);
  }

  onChangePackage = (value) => {
    const currentOptions = this.props.options;
    const options = {
      ...currentOptions,
      package: value,
    };
    this.props.onChange(options);
  }

  render() {
    const {
      classes,
      simple,

      options = {},
      brands,
      models,
      colors,
      packages,

      isNotDealer
    } = this.props;

    const brandEmptyOption = {
      id: null,
      name: 'Все марки',
      slug: null
    };
    const modelEmptyOption = {
      id: null,
      name: 'Все модели',
      slug: null
    };
    const colorEmptyOption = {
      id: null,
      name: 'Все цвета',
      slug: null
    };
    const packageEmptyOption = {
      id: null,
      name: 'Все комплектации',
      slug: null
    };

    const brandsOptions = brands ? [brandEmptyOption].concat(brands) : [brandEmptyOption];
    const modelsOptions = models ? [modelEmptyOption].concat(models) : [modelEmptyOption];
    const colorsOptions = colors ? [colorEmptyOption].concat(colors) : [colorEmptyOption];
    const packagesOptions = packages ? [packageEmptyOption].concat(packages) : [packageEmptyOption];

    return (
      <div className={cn(classes.filters, simple ? classes.filtersSimple : null)}>
        {!simple &&
          <div className={classes.buttons}>
            <div className={classes.deals}>
              <div className={cn(classes.dealsButton, classes.dealsButtonActive)}>
                {'Все сделки'}
              </div>
            </div>
            {isNotDealer &&
              <div className={classes.createButton}>
                <span className={classes.createButtonIcon}>{'+'}</span>
                <Link
                  to={'/application'}
                >
                  {'Создать заявку'}
                </Link>
              </div>
            }
          </div>
        }
        <form noValidate
          className={cn(
            classes.selects,
            options.brand !== null ? classes.selectsSeveral : null,
          )}
        >
          <div className={classes.select}>
            <Select
              options={brandsOptions}
              placeholder={'Все марки'}
              onChange={this.onChangeBrand}
              value={options.brand}
              valueField={'id'}
              textField={'name'}
            />
          </div>
          <div className={cn(classes.select, options.brand === null ? classes.selectHidden : null)}>
            <Select
              options={modelsOptions}
              placeholder={'Все модели'}
              onChange={this.onChangeModel}
              value={options.model}
              valueField={'id'}
              textField={'name'}
            />
          </div>
          <div className={cn(classes.select, options.model === null ? classes.selectHidden : null)}>
            <Select
              options={colorsOptions}
              placeholder={'Все цвета'}
              onChange={this.onChangeColor}
              value={options.color}
              valueField={'id'}
              textField={'name'}
            />
          </div>
          <div className={cn(classes.select, options.model === null ? classes.selectHidden : null)}>
            <Select
              options={packagesOptions}
              placeholder={'Все комплектации'}
              onChange={this.onChangePackage}
              value={options.package}
              valueField={'id'}
              textField={'name'}
            />
          </div>
        </form>
      </div>
    );
  }
}

Filters.propTypes = {
  simple: PropTypes.bool,
};

Filters.defaultProps = {
  simple: false,
};

export default Filters;
