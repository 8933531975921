import reducers from '~app/core/reducers';
import { mainClient } from '~app/network';

export const getPartners = async () => {

  reducers.partners.getPartnersRequest();
  const { data, error } = await mainClient.partners.getPartners();

  if (error !== null) {
    reducers.partners.getPartnersFailure({ error });
    return;
  }

  reducers.partners.getPartnersSuccess({ data });
};

export const getPartnersByBrands = async (brands) => {

  reducers.partners.getPartnersByBrandsRequest();
  const { data, error } = await mainClient.partners.getPartnersByBrands(brands);

  if (error !== null) {
    reducers.partners.getPartnersByBrandsFailure({ error });
    return;
  }

  reducers.partners.getPartnersByBrandsSuccess({ data });
};
