export default (theme) => ({
  root: {
    padding: '20px 0 83px',
    margin: '0 -15px',
    width: 'calc(100% + 30px)',
  },

  head: {
    display: 'flex',
    padding: '0 15px 34px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  lander: {

  },


  title: {
    margin: 0,
    fontSize: 36,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#282828',
    '&$lander': {
      textAlign: 'center',
      fontSize: 30
    },
  },

  link: {
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: '0.2px',
    color: theme.colors.brand.main,
  },


  item: {
    display: 'flex',
    margin: '0 15px 30px',
    width: 'calc(50% - 30px)',
    transition: 'filter .5s ease',
    filter: 'grayscale(100%)',
    position: 'relative',
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 0,
      top: 0,
      left: 4,
      width: 'calc(100% - 8px)',
      height: '100%',
      borderRadius: 6,
      boxShadow: '0 8px 14px 0 rgba(60, 60, 60, 0.08)',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 6,
      backgroundColor: '#ffffff',
      boxShadow: '0 6px 20px 0 rgba(20, 100, 220, 0.09)',
    },
    '&:hover': {
      filter: 'grayscale(0%)',
    },
    '&:hover $img': {
      opacity: 1,
    },
  },
  img: {
    width: 'calc(50% - 30px)',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transition: 'opacity .5s ease',
    opacity: .6,
    position: 'relative',
    zIndex: 2,
    borderRadius: '6px 0 0 6px',
  },

  itemContent: {
    width: 'calc(50% + 30px)',
    position: 'relative',
    zIndex: 2,
    padding: '20px 30px',
  },

  itemDealer: {
    fontSize: 14,
    lineHeight: '28px',
    color: theme.colors.gray.main,
  },

  itemName: {
    paddingBottom: '15px',
    fontSize: 24,
    lineHeight: '36px',
    fontWeight: '500',
  },

  itemDescription: {
    fontSize: 14,
    lineHeight: '28px',
    color: '#282828',
  },

  [theme.queries.xsDevicesDown]: {
    itemContent: {
      display: 'flex',
      minHeight: '140px',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    itemDescription: {
      display: 'none',
    },
    itemName: {
      paddingBottom: 0,
      fontSize: '14px',
      lineHeight: '24px',
    },
  },

  [theme.queries.mdDevicesDown]: {
    hideMob: {
      display: 'none',
    },

    foot: {
      display: 'flex',
      padding: '0 16px',
      justifyContent: 'center',
    },

    root: {
      paddingTop: 14,
      paddingBottom: 68,
      margin: '0 -8px',
      width: 'calc(100% + 16px)',
    },
    head: {
      padding: '0 16px 12px',
    },
    title: {
      fontSize: 24,
      lineHeight: 1.5,
    },
    item: {
      margin: '0 8px 12px',
      width: '100%',
    },
    itemContent: {
      padding: '20px 16px',
    },
  },

  [theme.queries.lgDevicesUp]: {
    hideDesk: {
      display: 'none',
    }
  },
  [theme.queries.lgDevicesOnly]: {
  },
});
