export const styles = theme => ({
  root: {

  },
  lander: {

  },
  section: {
    paddingBottom: 40,
  },
  carousel: {
    margin: '0 -15px',
  },
  content: {
    maxWidth: 970,
    width: '100%',
    margin: '0 auto 60px',
  },
  html: {
    marginBottom: 22,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: '#282828',
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#282828',
    margin: '0 0 30px',
  },
  [theme.queries.smDevicesDown]: {
  },
});
