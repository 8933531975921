import { updateStateItemByKey } from '~app/utils/reduxHelpers';

import options from './options.json';

export const initialState = {
  brands: null,
  brandsInProgress: false,
  brandsError: null,

  deliveryOptions: null,
  deliveryOptionsInProgress: false,
  deliveryOptionsError: null,

  driveWheelTypes: options.driveWheelTypes,
  transmissionTypes: options.transmissionTypes,
  productionYears: options.productionYears,
  // loanOptions: options.loanOptions,

  modelsByBrand: {},

  colorsByModel: {},

  packagesByModel: {},
};

const initialStateItemByKey = {
  items: null,
  inProgress: false,
  error: null
};

// Brands
export const getBrandsRequest = (state) => ({
  ...state,
  brandsInProgress: true,
  brandsError: null
});
export const getBrandsSuccess = (state, { items }) => ({
  ...state,
  brands: items,
  brandsInProgress: false,
  brandsError: null
});
export const getBrandsFailure = (state, payload) => ({
  ...state,
  brandsInProgress: false,
  brandsError: payload
});

export const getBrandsReset = (state) => ({
  ...state,
  brands: null,
  brandsInProgress: false,
  brandsError: null,
});

// DeliveryOptions
export const getDeliveryOptionsRequest = (state) => ({
  ...state,
  deliveryOptionsInProgress: true,
  deliveryOptionsError: null
});
export const getDeliveryOptionsSuccess = (state, { items }) => ({
  ...state,
  deliveryOptions: items,
  deliveryOptionsInProgress: false,
  deliveryOptionsError: null
});
export const getDeliveryOptionsFailure = (state, payload) => ({
  ...state,
  deliveryOptionsInProgress: false,
  deliveryOptionsError: payload
});

// Models
export const getModelsByBrandRequest = (state, { brandId }) => {
  return updateStateItemByKey(state, 'modelsByBrand', initialStateItemByKey, brandId, {
    inProgress: true,
    error: null
  });
};

export const getModelsByBrandSuccess = (state, { brandId, data }) => {
  const {
    items
  } = data;

  let modelsByBrand = updateStateItemByKey(state, 'modelsByBrand', initialStateItemByKey, brandId, {
    items,
    inProgress: false,
    error: null
  });

  let colorsByModel = state.colorsByModel;

  data.items.forEach(model => {
    colorsByModel[model.id] = {
      items: model.colors
    };
  });

  return {
    ...modelsByBrand,
    colorsByModel,
  };
};
export const getModelsByBrandFailure = (state, { brandId, error }) => {
  return updateStateItemByKey(state, 'modelsByBrand', initialStateItemByKey, brandId, {
    inProgress: false,
    error
  });
};


// Colors
export const getColorsByModelRequest = (state, { modelId }) => {
  return updateStateItemByKey(state, 'colorsByModel', initialStateItemByKey, modelId, {
    inProgress: true,
    error: null
  });
};

export const getColorsByModelSuccess = (state, { modelId, data }) => {
  const {
    items
  } = data;

  return updateStateItemByKey(state, 'colorsByModel', initialStateItemByKey, modelId, {
    items,
    inProgress: false,
    error: null
  });
};
export const getColorsByModelFailure = (state, { modelId, error }) => {
  return updateStateItemByKey(state, 'colorsByModel', initialStateItemByKey, modelId, {
    inProgress: false,
    error
  });
};

// Packages
export const getPackagesByModelRequest = (state, { modelId }) => {
  return updateStateItemByKey(state, 'packagesByModel', initialStateItemByKey, modelId, {
    inProgress: true,
    error: null
  });
};

export const getPackagesByModelSuccess = (state, { modelId, data }) => {
  const {
    items
  } = data;

  return updateStateItemByKey(state, 'packagesByModel', initialStateItemByKey, modelId, {
    items,
    inProgress: false,
    error: null
  });
};
export const getPackagesByModelFailure = (state, { modelId, error }) => {
  return updateStateItemByKey(state, 'packagesByModel', initialStateItemByKey, modelId, {
    inProgress: false,
    error
  });
};
