import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import { replace, push } from 'connected-react-router-exposed';
// import { MainStorage } from '~app/storage';
import { animateScroll as scroll } from 'react-scroll';
import cn from 'classnames';

import actions from '~app/redux/actions';
import { Container, Typography, Spacer, CollapseSection, Button, Breadcrumbs, HintHoverContainer } from '~app/components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './ApplicationScreenStyles';

import Brand from './Sections/Brand/Brand';
import Model from './Sections/Model/Model';
import Color from './Sections/Color/Color';
import Package from './Sections/Package/Package';
import Options from './Sections/Options/Options';

const mapStateToProps = (state, ownProps) => {
  let {
    brand = null,
    model = null,
    color = null,
    configuration = null,
    options = null,
  } = ownProps.match.params;

  let currentBrand = null;
  if (brand !== null && state.dictionary.brands !== null) {
    currentBrand = state.dictionary.brands.find(x => x.slug === brand);
  }

  let modelsState = null;
  if (currentBrand !== null && currentBrand.id in state.dictionary.modelsByBrand) {
    modelsState = state.dictionary.modelsByBrand[currentBrand.id];
  }
  let currentModel = null;
  if (model !== null && modelsState !== null && modelsState.items !== null) {
    currentModel = modelsState.items.find(x => x.slug === model);
  }

  let colorsState = null;
  if (currentModel !== null && currentModel.id in state.dictionary.colorsByModel) {
    colorsState = state.dictionary.colorsByModel[currentModel.id];
  }
  let currentColor = null;
  if (color !== null && color.toLowerCase() !== 'any' && colorsState !== null && colorsState.items !== null) {
    currentColor = colorsState.items.find(x => x.slug === color);
  }

  let packagesState = null;
  if (currentModel !== null && currentModel.id in state.dictionary.packagesByModel) {
    packagesState = state.dictionary.packagesByModel[currentModel.id];
  }
  let currentPackage = null;
  if (configuration !== null && configuration.toLowerCase() !== 'any' && packagesState !== null && packagesState.items !== null) {
    currentPackage = packagesState.items.find(x => x.slug === configuration);
  }

  if (options !== null) {
    options = options.split('-');
  }

  return {
    options: {
      brand, model, color, configuration, options,
    },
    brands: state.dictionary.brands,
    brandsInProgress: state.dictionary.brandsInProgress,
    brandsError: state.dictionary.brandsError,
    currentBrand,
    currentModel,
    currentColor,
    currentPackage,
    models: modelsState === null ? null : modelsState.items,
    modelsInProgress: modelsState === null ? false : modelsState.inProgress,
    modelsError: modelsState === null ? null : modelsState.error,

    colors: colorsState === null ? null : colorsState.items,
    colorsInProgress: colorsState === null ? false : colorsState.inProgress,
    colorsError: colorsState === null ? null : colorsState.error,

    packages: packagesState === null ? null : packagesState.items,
    packagesInProgress: packagesState === null ? false : packagesState.inProgress,
    packagesError: packagesState === null ? null : packagesState.error,

    isAuthorized: state.auth.isAuthorized,

    createApplicationSuccess: state.applications.createApplicationSuccess,
    createApplicationError: state.applications.createApplicationError,
  };
};
const mapDispatchToProps = {
  replace,
  push
};

@connect(mapStateToProps, mapDispatchToProps)
@injectStyles(styles)
class ApplicationScreen extends Component {

  waitingAuthorization = false;

  state = {
    options: this.props.match.params,
    activeSection: 'brand',
    trySubmit: false,
    tradeInFormValid: true,
    tradeInFormData: null,

    loanOption: null,
    payWithCash: null,
    loadOptionValid: true,

    additionalKitFormData: {},
    additionalKitFilled: false,
  }

  sections = {
    brand: {
      component: Brand,
      getProps: () => ({
        brands: this.props.brands
      }),
      propName: 'brands',
      title: 'Выбери марку',
      number: 'шаг 1 из 4',
    },
    model: {
      component: Model,
      getProps: () => ({
        models: this.props.models
      }),
      propName: 'models',
      title: 'Выбери модель',
      number: 'шаг 2 из 4',
    },
    color: {
      component: Color,
      getProps: () => ({
        colors: this.props.colors
      }),
      propName: 'colors',
      title: 'Выбери цвет',
      number: 'шаг 3 из 4',

      optionAny: 'Любой цвет',
    },
    configuration: {
      component: Package,
      getProps: () => ({
        packages: this.props.packages
      }),
      propName: 'packages',
      title: 'Выбери комплектацию',
      number: 'шаг 4 из 4',

      optionAny: 'Любая комплектация',
    },
    options: {
      component: Options,
      getProps: () => ({
        options: this.props.options.options || [],
        onChangeTradeInForm: this.onChangeTradeInForm,
        onChangeLoanOptions: this.onChangeLoanOptions,
        submitted: this.state.trySubmit
      }),
      propName: 'options',
      title: 'Уменьшение стоимости сделки',
      background: true,
    },
  }

  // tradeInFieldUpdate = (field, newValue) => {
  //   console.log(field, newValue)
  // }

  componentDidMount = () => {
    this.setState({
      activeSection: this.getActiveSection(),
    });
    this.restoreFormData();
    this.loadData();
  }

  componentWillReceiveProps = (nextProps) => {

    const currentOptions = this.state.options;
    const currentKeys = Object.keys(currentOptions);
    let options = { ...nextProps.match.params };
    let optionsDidChange = false;

    if (currentKeys.length !== Object.keys(options).length)
      optionsDidChange = true;
    for (var i = currentKeys.length; i--;) {
      if (currentOptions[currentKeys[i]] !== options[currentKeys[i]])
        optionsDidChange = true;
    }

    if (optionsDidChange) {
      this.setState({
        options: options,
      });

      this.setActiveSection(this.getActiveSection(nextProps.match.params));
    }

    if (!this.props.isAuthorized && nextProps.isAuthorized && this.waitingAuthorization) {
      setTimeout(() => {
        this.createApplication();
      }, 100);
    }
    if (nextProps.createApplicationSuccess && nextProps.createApplicationSuccess !== this.props.createApplicationSuccess) {
      this.props.push('/cabinet');
      actions.common.openGenericModal({
        theme: 'general',
        icon: 'tickCircle',
        text: 'Заявка успешно создана!'
      });
    }
    if (nextProps.createApplicationError !== null && nextProps.createApplicationError !== this.props.createApplicationError) {
      actions.common.openGenericModal({
        theme: 'rare',
        icon: 'timesCircle',
        title: 'Ошибка!',
        text: nextProps.createApplicationError.message,
      });
    }
  }

  componentDidUpdate = () => {
    this.loadData();
  }

  onChangeTradeInForm = ({ valid, data }) => {
    this.setState({
      tradeInFormData: data,
      tradeInFormValid: valid
    });
  }

  onChangeLoanOptions = ({ valid, data }) => {
    this.setState({
      loanOption: data !== null ? data.loanOption : null,
      payWithCash: data !== null ? data.payWithCash : null,
      loadOptionValid: valid
    });
  }

  getActiveSection = (optionsList) => {
    const options = optionsList ? optionsList : this.state.options;
    const sectionKeys = Object.keys(this.sections);
    let activeSection;

    for (var i = 0; i < sectionKeys.length; i++) {

      if (!options[sectionKeys[i]] || i == sectionKeys.length - 1) {
        activeSection = sectionKeys[i];
        break;
      }
    }



    return (activeSection);
  }

  scrollToActiveSection = (name) => {

    // collapsed section height with margin
    const height = 120;

    // first section scroll top value
    const baseSectionTop = document.getElementById('section-brand').getBoundingClientRect().top + window.scrollY;

    // vertical offset for scroll result
    const offset = -130;

    let index;
    switch (name) {
    case 'brand':
      index = 0;
      break;
    case 'model':
      index = 1;
      break;
    case 'color':
      index = 2;
      break;
    case 'configuration':
      index = 3;
      break;
    case 'options':
      index = 4;
      break;

    default:
      break;
    }

    scroll.scrollTo(baseSectionTop + index * height + offset);

  }

  setActiveSection = (name) => {
    const scrollToSection = this.state.activeSection !== name;
    this.setState({
      activeSection: name,
    }, () => {
      if (scrollToSection) {
        this.scrollToActiveSection(name);
      }
    });
  }

  setOption = (name, option) => {
    const options = this.state.options;
    const optionKeys = Object.keys(options);
    let newPath = '/application';

    for (var i = 0; i < optionKeys.length; i++) {
      if (optionKeys[i] == name) {
        if (option.options) {
          newPath += `/${option.options.join('-')}`;
        } else {
          newPath += `/${option}`;
        }
        break;
      }
      else {
        newPath += `/${options[optionKeys[i]]}`;
      }
    }

    this.props.replace(newPath);
  }

  loadData = (props = this.props) => {
    if (props.brands === null && !props.brandsInProgress && props.brandsError === null) {
      actions.dictionary.getBrands();
    }
    if (props.currentBrand !== null && props.models == null && !props.modelsInProgress && props.modelsError === null) {
      actions.dictionary.getModelsByBrand(props.currentBrand.id);
    }
    if (props.currentModel !== null && props.packages == null && !props.packagesInProgress && props.packagesError === null) {
      actions.dictionary.getPackagesByModel(props.currentModel.id);
    }
  }

  createApplication = () => {

    if (!this.state.tradeInFormValid || !this.state.loadOptionValid) {
      this.setState({
        trySubmit: true
      });
      return;
    }

    const {
      isAuthorized,
      currentModel,
      currentColor,
      currentPackage,
      options,
    } = this.props;
    let combinedData = {};

    if (currentModel !== null) {
      combinedData.model = currentModel.id;
    }
    if (currentColor !== null) {
      combinedData.color = currentColor.id;
    }
    if (currentPackage !== null) {
      combinedData.package = currentPackage.id;
    }

    if (options !== null && options.options !== null) {
      options.options.map(option => {
        combinedData[option] = true;
      });
    }

    if (combinedData.credit && this.state.loanOption !== null) {
      combinedData.loanOption = this.state.loanOption;
    }

    if (combinedData.credit && this.state.payWithCash !== null) {
      combinedData.payWithCash = this.state.payWithCash;
    }

    if (this.state.tradeInFormData !== null) {
      combinedData.tradeIn = true;
      combinedData.oldCar = this.state.tradeInFormData;
    }

    if (this.state.additionalKitFilled) {
      combinedData.additionalKit = this.state.additionalKitFormData;
    }

    if (!isAuthorized) {
      this.storeFormData(options);
      actions.common.openAuthModal('register', false);
      this.waitingAuthorization = true;
    } else {
      if (combinedData.model === null) {
        return actions.common.openGenericModal({
          title: 'Ошибка!',
          text: 'Модель не выбрана!',
        });
      }
      actions.applications.createApplication(combinedData);
    }
  }

  storeFormData = (data) => {
    // MainStorage.setApplicationFormData(data);
  }

  restoreFormData = async () => {
    // let data = await MainStorage.getApplicationFormData();

    // if (data !== null) {
    //   let dataKeys = Object.keys(data);
    //   for (var i = 0; i < dataKeys.length; i++) {
    //     this.setOption(dataKeys[i], data[dataKeys[i]]);
    //   }
    //   MainStorage.removeApplicationFormData();
    // }
  }


  openAdditionalKitModal = event => {
    if (event) {
      event.preventDefault();
    }
    actions.common.openModal({
      type: 'additionalKit',
      params: {
        data: this.state.additionalKitFormData,
        onSaveAdditionalKitForm: this.onSaveAdditionalKitForm,
      }
    });
  };

  onSaveAdditionalKitForm = ({ data = {}, filled = true }) => {
    this.setState({ additionalKitFormData: data, additionalKitFilled: filled });
  };

  renderSection = (name) => {
    const { classes } = this.props;

    const isShown = this.state.options[name] !== undefined && name !== 'options';
    const isActive = this.state.activeSection == name;
    const section = this.sections[name];
    let sectionProps = {};
    if (section.getProps) {
      sectionProps = section.getProps();
    }

    let sectionName = this.state.options[name];
    let sectionLogo;

    if (sectionProps[section.propName]) {
      let sectionItem = sectionProps[section.propName].filter(
        item => {
          return item.slug == this.state.options[name];
        }
      )[0];
      if (sectionItem) {
        sectionName = sectionItem.name;
        sectionLogo = sectionItem.logoUrl ? sectionItem.logoUrl : null;
      }
    }

    if (sectionName === 'Any') {
      sectionName = this.sections[name].optionAny;
    }



    if (isShown || isActive) {
      return (
        <div key={name} id={`section-${name}`} className={cn(classes.section, isActive ? classes.sectionActive : null)}>
          <CollapseSection
            head={
              <div
                className={classes.sectionHead}
                onClick={() => this.setActiveSection(name)}
              >
                <div className={classes.sectionHeadOption}>
                  {sectionName}
                  {sectionLogo !== null && <img src={sectionLogo} className={classes.sectionHeadOptionLogo} />}
                </div>
                <div className={classes.sectionHeadChange}>
                  {'Изменить'}
                </div>
              </div>
            }
            title={section.title}
            optionAny={
              section.optionAny !== undefined ?
                <div
                  className={classes.sectionOptionAny}
                  onClick={() => this.setOption(name, 'Any')}
                >
                  {section.optionAny}
                </div>
                :
                null
            }
            notCollapse={name == 'options' ? true : false}
            number={section.number ? section.number : ''}
            body={
              <section.component
                onChangeOption={(option) => this.setOption(name, option)} {...sectionProps} />
            }

            expanded={isActive}
            background={section.background ? section.background : false}
          />
          <Spacer v={12} />
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      classes,
    } = this.props;

    const { additionalKitFilled } = this.state;

    return (
      <Container background={'#f6fafe'}>
        <Helmet>
          <title>Avicar - Создание заявки</title>
        </Helmet>
        <div className={classes.head}>
          <Breadcrumbs
            options={[
              {
                href: '/',
                name: 'Главная',
              },
              {
                name: 'Создать заявку',
              }
            ]}
          />
          <Spacer v={6} />
          <Typography type={'title'}>{'Создание заявки'}</Typography>
        </div>
        {Object.keys(this.sections).map((name) => {
          return (this.renderSection(name));
        })}
        <Spacer v={12} />
        {this.state.activeSection == 'options' &&
          <div className={classes.bottom}>
            <HintHoverContainer
              mobileFullWidth
              direction={'bottom'}
              html={additionalKitFilled ?
                'Вы уже выбрали дополнительное&nbsp;оборудование, но всегда можете дополнить этот список'
                :
                'Предоставьте дилеру возможность&nbsp;делать&nbsp;бонусы и подарки.'}>
              <Button
                mobileBlock
                theme={additionalKitFilled ? 'general' : 'brand'}
                text={additionalKitFilled ? 'Изменить доп. оборудование' : [
                  <span key={'desktop'} className={classes.desktop}>{'Хочу дополнительное оборудование'}</span>,
                  <span key={'mobile'} className={classes.mobile}>{'Хочу доп. оборудование'}</span>,
                ]}
                onClick={this.openAdditionalKitModal}
              />
            </HintHoverContainer>
            <Spacer v={12} h={12} />
            <Button
              mobileBlock
              theme={'accent'}
              text={'Создать заявку'}
              onClick={this.createApplication}
              leftIcon={<div style={{ fontSize: 20, lineHeight: '20px' }}>{'+'}</div>}
            />
          </div>
        }
        <Spacer v={120} />
      </Container>
    );
  }
}

export default ApplicationScreen;
