import React, { Component } from 'react';

import { Helmet } from 'react-helmet';


import { Container, Card, CardContent } from '~app/components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './ContactsScreenStyles';
import Constants from 'Constants';


@injectStyles(styles)
class ContactsScreen extends Component {

  render() {
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Avicar - Контакты</title>
        </Helmet>
        <div className={classes.map}>
          <iframe src={'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31951.78627938492!2d30.284693412033132!3d59.96554504912635!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4696315b15fd14c7%3A0x1b526e1955d27669!2z0YPQuy4g0JvRjNCy0LAg0KLQvtC70YHRgtC-0LPQviwgMSwg0KHQsNC90LrRgi3Qn9C10YLQtdGA0LHRg9GA0LMsIDE5NzEwMQ!5e0!3m2!1sru!2sru!4v1535452771692'}/>
        </div>
        <div className={classes.card}>
          <Container>
            <Card>
              <CardContent>
                <div className={classes.head}>
                  <div className={classes.title}>{'Контакты'}</div>
                  <div className={classes.time}>
                    <span>{'9'}</span>
                    <span><sup>{'00'}</sup></span>
                    <span>{'–'}</span>
                    <span>{'19'}</span>
                    <span><sup>{'00'}</sup></span>
                  </div>
                </div>
                <div className={classes.body}>
                  <div className={classes.item}>
                    <div className={classes.itemTitle}>{'Телефон:'}</div>
                    <div className={classes.itemContent}>
                      <a href={`tel:${Constants.PHONE_NUMBER_PLAIN}`}>{Constants.PHONE_NUMBER_BEAUTY}</a>
                    </div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.itemTitle}>{'Поддержка:'}</div>
                    <div className={classes.itemContent}>
                      <a href={'mailto:support@avicar.ru'}>{'support@avicar.ru'}</a>
                    </div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.itemTitle}>{'Сотрудничество:'}</div>
                    <div className={classes.itemContent}>
                      <a href={'mailto:partners@avicar.ru'}>{'partners@avicar.ru'}</a>
                    </div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.itemTitle}>{'Адрес:'}</div>
                    <div className={classes.itemContent}>
                      {'Санкт–Петербург, Льва Толстого 1-3'}
                    </div>
                  </div>
                </div>
                <div className={classes.bottom}>{'Услуги предоставляются ООО "Авикар" ОГРН 1187847192741'}</div>
              </CardContent>
            </Card>
          </Container>
        </div>
      </div>
    );
  }
}

export default ContactsScreen;
