import client from './_client';
import { ApplicationListModel, ApplicationBaseModel } from './models';

// Create

export const createApplication = (data) => {
  return client.request({
    url: '/v1/application',
    method: 'POST',
    data
  });
};

// Get
export const getApplications = (params = {}) => {
  let {
    limit = 5,
    page = 1,
    options,
    ...otherParams
  } = params;
  // let {
  //   brand = null,
  //   model = null,
  //   color = null,
  //   configuration = null,
  // } = options.options;

  return client.request({
    url: '/v1/applications',
    method: 'GET',
    params: {
      limit,
      page,
      ...options,
      ...otherParams
    },
    transformData: data => new ApplicationListModel({
      items: data.data,
      paging: {
        count: data.count || 0,
        page: page,
        limit: limit,
      },
      ...data
    })
  });
};


export const getApplication = async ({ id }) => {
  return client.request({
    url: `/v1/application/${id}`,
    method: 'GET',
    transformData: data => new ApplicationBaseModel(data)
  });
};


// Archive application 
export const archiveApplication = ({ id }) => {
  return client.request({
    url: `/v1/application/${id}`,
    method: 'DELETE',
  });
};
