import React, { Component } from 'react';
import ReactModal from 'react-modal';
import cn from 'classnames';
import { injectStyles } from '~app/theme';
import { formContainer, formCreate } from 'deforms';

import { Button, TextInput, Checkbox } from '~app/components/ui';
import { freezeScroll } from '../../utils/helpers';

import styles from './AdditionalKitModalStyles';

import options, { schema as additionalKitSchema } from '~app/config/additionalKitOptions';

@injectStyles(styles)
@formContainer
class AdditionalKitModal extends Component {

  @formCreate()
  form = {
    schema: additionalKitSchema,
    onSubmit: (formData) => {
      this.onSuccess(formData);
    }
  }

  componentDidMount() {
    if (this.props.data && Object.keys(this.props.data).length > 0) {
      for (const key in this.props.data) {
        this.form.fields[key].setValue(this.props.data[key]);
      }
    }
  }

  onSuccess = (formData) => {
    let filled = false;
    for (let key in formData) {
      if (key == 'comment' && formData[key] != '') {
        filled = true;
        break;
      } else if (formData[key] != false) {
        filled = true;
        break;
      }
    }
    this.props.onSaveAdditionalKitForm({ data: formData, filled });
    this.props.onRequestClose();
  }

  onReset = event => {
    event.preventDefault();
    this.props.onSaveAdditionalKitForm({ filled: false });
    this.props.onRequestClose();
  }

  onRequestClose = () => {
    this.props.onRequestClose();
  }

  onOpen = () => {
    freezeScroll();
  }

  render() {
    const {
      classes,
      isOpen,
    } = this.props;

    const form = this.form;

    return (
      <ReactModal
        className={{
          base: classes.modal,
          afterOpen: classes.modalAfterOpen,
          beforeClose: classes.modalBeforeClose,
        }}
        overlayClassName={{
          base: classes.overlay,
          afterOpen: classes.overlayAfterOpen,
          beforeClose: classes.overlayBeforeClose,
        }}
        isOpen={isOpen}
        onAfterOpen={this.onOpen}
        closeTimeoutMS={300}
        key={this.props.isOpen}
        contentLabel={'modal'}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick>
        <a
          className={classes.close}
          onClick={this.onRequestClose} />
        <div className={classes.content}>
          <h3 className={cn(classes.title, classes.desktop)}>{'Выбери дополнительное оборудование:'}</h3>
          <h3 className={cn(classes.title, classes.mobile)}>{'Выбери доп. оборудование:'}</h3>
          <form
            noValidate
            className={classes.form}
            onSubmit={form.submit}>

            {options.map(option =>
              <div key={option.id} className={cn(classes.formInput, classes.formInputHalf)}>
                <Checkbox
                  name={option.id}
                  label={option.name}
                  checked={form.fields[option.id].value}
                  onChangeValue={form.fields[option.id].changeValue}
                />
              </div>
            )}
            <div className={classes.formInput}>
              <TextInput
                type={'textarea'}
                small
                maxLength={100}
                name={'comment'}
                placeholder={'Сообщение (не более 100 символов)'}
                onChange={form.fields.comment.change}
                value={form.fields.comment.value}
                error={form.submitted && !form.fields.comment.valid}
              />
            </div>
            <div className={classes.formAction}>
              <Button
                onClick={this.onReset}
                variant={'transparent'}
                theme={'brand'}
                text={'Сбросить'}
              />
              <Button
                mobileBlock
                theme={'brand'}
                text={'Продолжить'}
              />
            </div>
          </form>
        </div>

      </ReactModal>
    );
  }
}

export default AdditionalKitModal;
