import { mapper, fromInput } from 'demapper';

import ReviewBaseModel from './ReviewBaseModel';
import PagingModel from '../PagingModel';

@mapper
export default class {
  @fromInput('items', { model: ReviewBaseModel })
  items = []

  @fromInput('paging', { model: PagingModel })
  paging = null
}
