import React, { Component } from 'react';
import { Switch, Route } from 'react-router';

import { LayoutLander } from '~app/components';

import BrandsLanderScreen from '~app/modules/BrandsLanderScreen/BrandsLanderScreen';
import ModelsLanderScreen from '~app/modules/ModelsLanderScreen/ModelsLanderScreen';

import NotFoundScreen from '~app/modules/NotFoundScreen/NotFoundScreen';

class ContainerLander extends Component {
  render = () => {
    return (
      <LayoutLander>
        <Switch>
          <Route
            exact
            path={'/auto/:class/:type/:brand/:model'}
            component={ModelsLanderScreen} />

          {/*
          <Route
            exact
            path={'/auto/:class/:type/:brand'}
            component={BrandsLanderScreen} />
          */}

          <Route
            exact
            path={'/brands/:type/:brand'}
            component={BrandsLanderScreen} />

          <Route path='*' component={NotFoundScreen} />
        </Switch>
      </LayoutLander>
    );
  }
}

export default ContainerLander;
