import { mapper, fromInput } from 'demapper';

@mapper
export default class {
  @fromInput('brand')
  brand = null;

  @fromInput('model')
  model = null;

  @fromInput('engineVolume')
  engineVolume = null;

  @fromInput('year')
  year = null;

  @fromInput('drive')
  drive = null;

  @fromInput('gear')
  gear = null;

  @fromInput('vin')
  vin = null;
}