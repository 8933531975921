import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from '~app/theme';
import cn from 'classnames';
import { Collapse } from 'react-collapse';
import { Checkbox, TextInput, Hint, Icon } from '~app/components/ui';

import styles from './PackageDetailsSectionStyles';


@injectStyles(styles)
class PackageDetailsSection extends Component {

  state = {
    isOpened: false
  };

  renderSectionBody = (section) => {
    const {
      classes,
      handleInputChange,
      handleCheckboxChange,
      customPackage,
      readonly,
      mobile,
    } = this.props;

    return (
      <div className={classes.sectionBody}>
        {section.items.map((item) => {

          const value = customPackage && customPackage[item.name] !== undefined ?
            customPackage[item.name]
            :
            item.value;

          let checked = false;
          const fieldType =
            (item.name != 'comment' &&
              value &&
              ['опционально', 'есть', 'недоступно'].indexOf(value) !== -1) ?
              'checkbox'
              :
              'text';

          if (fieldType == 'checkbox' && value == 'есть') {
            checked = true;
          }
          return (
            <div
              key={item.name}
              className={cn(classes.sectionItem, {
                [classes.full]: item.name == 'comment'
              })}>
              {item.name != 'comment' &&
                <div className={classes.sectionItemName}>{item.name}</div>
              }
              {readonly && value != item.value && item.name != 'comment' &&
                <div
                  onMouseOver={() => { this.setState({ [`${item.name}_hovered`]: true }); }}
                  onMouseLeave={() => { this.setState({ [`${item.name}_hovered`]: false }); }}
                  className={classes.sectionItemState}>
                  <Icon name={'gear'} />
                  <Hint direction={mobile ? 'left' : 'bottom'} isActive={this.state[[`${item.name}_hovered`]]}>
                    {'Нестандартная комплектация'}
                  </Hint>
                </div>
              }
              {(item.name != 'comment' || readonly) &&
                <div
                  className={cn(
                    classes.sectionItemValue,
                    (
                      value === 'опционально' || value === 'есть'
                        ?
                        classes.sectionItemValuePositive
                        :
                        (
                          value === 'недоступно'
                            ?
                            classes.sectionItemValueNegative
                            :
                            ''
                        )
                    )
                  )}
                >
                  {value}
                </div>
              }
              {!readonly &&
                <div className={classes.sectionItemEdit}>
                  {fieldType == 'text' &&
                    <TextInput
                      small
                      name={item.name}
                      type={item.name == 'comment' ? 'textarea' : 'text'}
                      maxLength={140}
                      value={value}
                      onChange={handleInputChange(item.name != 'comment' ? item.value : '')}
                      placeholder={item.name == 'comment' ? 'Не более 140 символов' : item.value}
                    />
                  }
                  {item.name == 'comment' &&
                    <div className={classes.sectionItemLimit}>
                      {`${value.length || 0}/140`}
                    </div>
                  }
                  {fieldType == 'checkbox' && item.value != 'есть' &&
                    <Checkbox
                      name={item.name}
                      checked={checked}
                      onChange={handleCheckboxChange(item.value)}
                    />
                  }
                </div>
              }
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const {
      classes,
      section,
      mobile
    } = this.props;

    const comment = section.name == 'Дополнительные условия'; // TODO: исправить HARDCODE

    return (
      <div className={classes.section}>
        <div
          className={cn(classes.sectionTitle, {
            [classes.sectionTitleComment]: comment
          })}
          onClick={() => {
            const newOpenedState = !this.state.isOpened;
            this.setState({
              isOpened: newOpenedState
            });
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {section.name}
            {comment &&
              <div style={{ width: '12px', marginLeft: '5px', lineHeight: 0 }}><Icon name={'gift'}/></div>
            }
          </div>
          <div className={cn(classes.sectionArrow, this.state.isOpened ? classes.sectionArrowActive : null)}></div>
          
        </div>
        {mobile ? <Collapse isOpened={this.state.isOpened}>{this.renderSectionBody(section)}</Collapse> : this.renderSectionBody(section)}
      </div>
    );
  }
}

PackageDetailsSection.propTypes = {
  section: PropTypes.object.isRequired,
  onSaveCustomPackage: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  mobile: PropTypes.bool,
  readonly: PropTypes.bool
};

PackageDetailsSection.defaultProps = {
  onSaveCustomPackage: null,
  mobile: false,
  readonly: true
};

export default PackageDetailsSection;
