import React, { Component } from 'react';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import styles from './PartnersStyles';
import { IS_BROWSER } from '~app/config';
import { Link } from 'react-router-dom';


import Swiper from 'swiper/dist/js/swiper.js';

import queries from '~app/theme/main/queries';


const MOBILE_ITEMS = 6;
const DESKTOP_ITEMS = 12;

@injectStyles(styles)

class Partners extends Component {
  swiper = null;

  state = {
    swiperInited: false
  };

  componentDidMount() {
    this.initSwiper();
  }

  initSwiper() {
    const { classes, data } = this.props;

    const perPage = this.getPerPage();

    const swiperConfig = {
      loop: false,
      slidesPerView: 1,
      autoHeight: true,
      pagination: {
        el: `.${classes.pagination}`,
        // bulletClass: cn('swiper-pagination-bullet', classes.bullet),
        bulletActiveClass: cn('swiper-pagination-bullet-active', classes.bulletActive),
      },
    };
    if (data && data.items && data.items.length > perPage) {
      this.swiper = new Swiper('.partners-swiper', swiperConfig);
      this.setState({ swiperInited: true });
    }
  }

  getPerPage = () => IS_BROWSER ? window.innerWidth < queries.MD ? MOBILE_ITEMS : DESKTOP_ITEMS : DESKTOP_ITEMS;

  componentDidUpdate(prevProps) {
    if (prevProps.data != this.props.data) {
      setTimeout(() => {
        if (this.swiper !== null) {
          this.swiper.update();
        } else {
          this.initSwiper();
        }
      }, 500);
    }
  }

  render = () => {
    const {
      classes,
      data,
      lander,
    } = this.props;

    const { swiperInited } = this.state;

    const content = data.items || [];

    const perPage = this.getPerPage();

    const pages = new Array(Math.ceil(content.length / perPage)).fill(0);

    return (
      <div className={cn(classes.root, {
        [classes.lander]: lander,
        [classes.withoutCarousel]: !swiperInited,
      })}>
        <h3 className={cn(classes.title, {
          [classes.lander]: lander
        })}>
          {'Наши партнеры'}
        </h3>
        <div className={classes.content}>
          <div className="swiper-container partners-swiper">
            <div className="swiper-wrapper">
              {pages.map((page, pageIndex) => {
                const items = content.slice(pageIndex * perPage, (pageIndex + 1) * perPage);
                return (
                  <div key={pageIndex} className={'swiper-slide'}>
                    <div className={cn(classes.page, {
                      [classes.lander]: lander,
                    })}>
                      {items.map((item, index) =>
                        <div key={index} className={classes.item}>
                          <Link to={`/dealers/${item.id}`} className={classes.itemLink} />
                          <div
                            className={classes.img}
                            style={{
                              backgroundImage: `url(${item.image})`
                            }} />
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
              )}
            </div>
            <div className={cn('swiper-pagination', classes.pagination)} />
          </div>
        </div>
      </div>
    );
  }
}

export default Partners;
