export default (theme) => ({
  root: {
    paddingTop: 30,
    backgroundColor: theme.colors.brand.main,
    color: '#fff',
  },

  content: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  logo: {
    width: 90,
    height: 90,
  },

  copyright: {
    opacity: .6,
    fontSize: 12,
    color: '#fff',
  },

  col: {

  },

  rules: {
    opacity: .6,
    fontSize: 12,
    fontWeight: '400',

    color: '#fff',

    '&:hover': {
      textDecoration: 'underline',
    }
  },

  madeBy: {
    fontWeight: '600',
    textDecoration: 'none',

    color: '#fff',
  },

  developed: {
    margin: 0,
  },

  developedLink: {
    fontWeight: '600',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline'
    }
  },

  text: {
    fontSize: 14,
    lineHeight: 1.71,
    letterSpacing: 0,
    color: '#ffffff',
    margin: '0 0 9px',
    fontWeight: 'normal'
  },

  'mobile': {
    display: 'none'
  },

  transparent: {
    opacity: 0.6,
    lineHeight: 2,
  },

  bottom: {
    display: 'flex',
    width: '100%',
    paddingBottom: 50,
    alignItems: 'center',
    '& $col': {
      paddingBottom: 0,
    }
  },


  [theme.queries.mdDevicesDown]: {
    root: {
      padding: '44px 4px 0',
    },
    content: {
      flexDirection: 'column',
    },

    desktop: {
      display: 'none'
    },

    mobile: {
      display: 'block'
    },

    col: {
      paddingBottom: 34,
    },

    logo: {
      width: 60,
      height: 60,
      marginRight: 24,
      flexShrink: 0
    },

    colLogo: {
      display: 'none'
    },

    colText: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center'
    },

    text: {
      width: 'calc(100% - 84px)',
      margin: 0
    },

    transparent: {
      width: '100%',
      fontSize: 10,
      marginTop: 34
    },

    colMadeby: {
      display: 'flex',
      height: 40,
      position: 'absolute',
      bottom: 0,
      left: -20,
      right: -20,
      padding: '0 20px !important',

      fontSize: 12,
      textAlign: 'center',

      backgroundColor: theme.colors.gray.darker,

      justifyContent: 'center',
      alignItems: 'center',
    },
    madeBy: {
      paddingLeft: '.5em',
      fontSize: 12,
    },
    developed: {
      marginLeft: 5
    },
    bottom: {
      justifyContent: 'space-between',
      paddingBottom: 60
    }
  },

  [theme.queries.lgDevicesUp]: {

    root: {
      paddingTop: 55,
    },

    col: {
      width: 'calc(25% + 10px)',
      paddingRight: 40,
      paddingBottom: 36,
    },

    colLogo: {
      width: 200,
      paddingRight: 0,
      marginTop: 7,
    },

    colText: {
      width: 'calc(100% - 200px)',
      paddingRight: 0
    },

    colCopyright: {
      width: 200,
      paddingRight: 0,
    },

    colRules: {
      width: 'calc(75% - 200px)',
    },

    colMadeby: {
      width: 'calc(25%)',
      paddingRight: 0,
      fontSize: 12,
      textAlign: 'right',
      verticalAlign: 'baseline',
    },

    madeBy: {
      fontSize: 16,
      textAlign: 'right',
    },

    developed: {
      marginTop: 10
    },

    bottom: {
      '& $col': {
        paddingBottom: 0,
      }
    },
  }

});
