import reducers from '~app/core/reducers';
import { mainClient } from '~app/network';


// Get promos by key
export const getPromoByKey = async (key, params) => {
  reducers.promo.getPromoByKeyRequest({ key });
  const { data, error } = await mainClient.promo.getPromo({ ...params });

  if (error !== null) {
    reducers.promo.getPromoByKeyFailure({ key, error });
    return;
  }
  reducers.promo.getPromoByKeySuccess({ key, data });
};

// Get promo item by id
export const getPromoItem = async (params) => {
  reducers.promo.getPromoItemRequest();
  const { data, error } = await mainClient.promo.getPromoItem(params);

  if (error !== null) {
    reducers.promo.getPromoItemFailure(error);
    return;
  }

  reducers.promo.getPromoItemSuccess(data);
};

export const getPromoItemReset = () => {
  reducers.promo.getPromoItemReset();
};