import { updateStateItemByKey } from '~app/utils/reduxHelpers';

export const initialState = {
  itemsByKey: {},
  
  itemById: {},
  
  itemsByApplicationId: {},
  
  acceptByApplicationId: {},

  createByKey: {},

  archiveId: null,
  archiveInProgress: false,
  archiveSuccess: false,
  archiveError: null,

  cancelId: null,
  cancelInProgress: false,
  cancelSuccess: false,
  cancelError: null,

  refuseId: null,
  refuseInProgress: false,
  refuseSuccess: false,
  refuseError: null,

  removeId: null,
  removeInProgress: false,
  removeSuccess: false,
  removeError: null,
};

const initialStateItemById = {
  item: null,
  inProgress: false,
  error: null,
};

const initialStateItemByKey = {
  items: null,
  paging: null,
  inProgress: false,
  error: null,
};

const initialStateCreateByKey = {
  result: null,
  inProgress: false,
  error: null,
};

const initialStateItemByApplicationId = initialStateItemByKey;

const initialStateAcceptItemByApplicationId = {
  id: null,
  inProgress: false,
  result: null,
  error: null,
};




export const getItemByIdRequest = (state, { id }) => {
  return updateStateItemByKey(state, 'itemById', initialStateItemById, id, {
    inProgress: true,
    error: null
  });
};
export const getItemByIdSuccess = (state, { id, data }) => {
  return updateStateItemByKey(state, 'itemById', initialStateItemById, id, {
    item: data,
    inProgress: false,
    error: null
  });
};
export const getItemByIdFailure = (state, { id, error }) => {
  return updateStateItemByKey(state, 'itemById', initialStateItemById, id, {
    inProgress: false,
    error
  });
};



export const getItemsByKeyRequest = (state, { key }) => {
  return updateStateItemByKey(state, 'itemsByKey', initialStateItemByKey, key, {
    inProgress: true,
    error: null
  });
};

export const getItemsByKeySuccess = (state, { key, data }) => {
  const {
    items,
    paging,
  } = data;

  return updateStateItemByKey(state, 'itemsByKey', initialStateItemByKey, key, {
    items,
    paging,
    inProgress: false,
    error: null
  });
};
export const getItemsByKeyFailure = (state, { key, error }) => {
  return updateStateItemByKey(state, 'itemsByKey', initialStateItemByKey, key, {
    inProgress: false,
    error
  });
};


export const getItemsByApplicationIdRequest = (state, { applicationId }) => {
  return updateStateItemByKey(state, 'itemsByApplicationId', initialStateItemByApplicationId, applicationId, {
    inProgress: true,
    error: null
  });
};

export const getItemsByApplicationIdSuccess = (state, { applicationId, data }) => {
  const {
    items,
    paging,
  } = data;

  return updateStateItemByKey(state, 'itemsByApplicationId', initialStateItemByApplicationId, applicationId, {
    items,
    paging,
    inProgress: false,
    error: null
  });
};
export const getItemsByApplicationIdFailure = (state, { applicationId, error }) => {
  return updateStateItemByKey(state, 'itemsByApplicationId', initialStateItemByApplicationId, applicationId, {
    inProgress: false,
    error
  });
};

// Accept
export const acceptByApplicationIdRequest = (state, { applicationId, id }) => {
  return updateStateItemByKey(state, 'acceptByApplicationId', initialStateAcceptItemByApplicationId, applicationId, {
    id,
    inProgress: true,
    error: null
  });
};

export const acceptByApplicationIdSuccess = (state, { applicationId, id, data }) => {
  return updateStateItemByKey(state, 'acceptByApplicationId', initialStateAcceptItemByApplicationId, applicationId, {
    id,
    result: data,
    inProgress: false,
    error: null
  });
};
export const acceptByApplicationIdFailure = (state, { applicationId, id, error }) => {
  return updateStateItemByKey(state, 'acceptByApplicationId', initialStateAcceptItemByApplicationId, applicationId, {
    id,
    inProgress: false,
    error
  });
};

// Create
export const createByKeyRequest = (state, { key }) => {
  return updateStateItemByKey(state, 'createByKey', initialStateCreateByKey, key, {
    inProgress: true,
    error: null
  });
};

export const createByKeySuccess = (state, { key, data }) => {
  return updateStateItemByKey(state, 'createByKey', initialStateCreateByKey, key, {
    result: data,
    inProgress: false,
    error: null
  });
};
export const createByKeyFailure = (state, { key, error }) => {
  return updateStateItemByKey(state, 'createByKey', initialStateCreateByKey, key, {
    inProgress: false,
    error
  });
};
export const createByKeyReset = (state, key = null) => {
  let createByKey = state.createByKey;
  if (key in createByKey) {
    delete createByKey[key];
  } else if (key === null) {
    createByKey = {};
  }
  
  return {
    ...state,
    createByKey
  };
};

// Archive offer
export const archiveOfferRequest = (state, { id }) => ({
  ...state,
  archiveId: id,
  archiveInProgress: true,
  archiveSuccess: false,
  archiveError: null,
});
export const archiveOfferSuccess = (state, { id }) => ({
  ...state,
  archiveId: id,
  archiveInProgress: false,
  archiveSuccess: true,
});
export const archiveOfferFailure = (state, { id, error }) => ({
  ...state,
  archiveId: id,
  archiveInProgress: false,
  archiveError: error,
});

// Cancel offer
export const cancelOfferRequest = (state, { id }) => ({
  ...state,
  cancelId: id,
  cancelInProgress: true,
  cancelSuccess: false,
  cancelError: null,
});
export const cancelOfferSuccess = (state, { id }) => ({
  ...state,
  cancelId: id,
  cancelInProgress: false,
  cancelSuccess: true,
});
export const cancelOfferFailure = (state, { id, error }) => ({
  ...state,
  cancelId: id,
  cancelInProgress: false,
  cancelError: error,
});

// Refuse offer
export const refuseOfferRequest = (state, { id }) => ({
  ...state,
  refuseId: id,
  refuseInProgress: true,
  refuseSuccess: false,
  refuseError: null,
});
export const refuseOfferSuccess = (state, { id }) => ({
  ...state,
  refuseId: id,
  refuseInProgress: false,
  refuseSuccess: true,
});
export const refuseOfferFailure = (state, { id, error }) => ({
  ...state,
  refuseId: id,
  refuseInProgress: false,
  refuseError: error,
});

// Remvoe offer
export const removeOfferRequest = (state, { id }) => ({
  ...state,
  removeId: id,
  removeInProgress: true,
  removeSuccess: false,
  removeError: null,
});
export const removeOfferSuccess = (state, { id }) => ({
  ...state,
  removeId: id,
  removeInProgress: false,
  removeSuccess: true,
});
export const removeOfferFailure = (state, { id, error }) => ({
  ...state,
  removeId: id,
  removeInProgress: false,
  removeError: error,
});