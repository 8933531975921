import { mapper, fromInput } from 'demapper';

@mapper
export default class {
  @fromInput('_id')
  id = null;

  @fromInput('slug')
  slug = null;

  @fromInput('name')
  name = null;
}
