import React, { Component } from 'react';
import { connect } from 'react-redux';

import actions from '~app/redux/actions';
import { Notification } from '~app/components/ui';

const mapStateToProps = state => ({
  items: state.notifications.items
});

@connect(mapStateToProps)
class Notifications extends Component {
  optionsByTypes = {
    error: {
      place: 'br',
      color: 'danger',
      close: true
    },
    success: {
      place: 'tr',
      color: 'success',
      close: true
    }
  }

  onCloseItem = (id, options) => {
    if ('onClose' in options && options.onClose !== null) {
      options.onClose();
    } else {
      actions.notifications.removeItem(id);
    }
  }

  renderNotification = ({ id, options }) => {
    let {
      type = null,
      ...props
    } = options;  
    
    if (type !== null && type in this.optionsByTypes) {
      props = {
        ...this.optionsByTypes[type],
        ...props
      };
    }
    return (
      <Notification
        key={id} 
        {...props}
        onClose={() => this.onCloseItem(id, options)}
        open
      />
    );
  }

  render = () => {
    const {
      items
    } = this.props;

    return items.map(this.renderNotification);
  }
}

export default Notifications;