export default theme => ({

  root: {
    maxWidth: 900,
    position: 'relative',
  },
  link: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 0,

    cursor: 'pointer',
  },

  price: {
    display: 'flex',
    paddingBottom: 9,

    fontSize: 18,
    fontWeight: '700',

    color: theme.colors.general.main,
  },
  info: {
    fontSize: 14,
    lineHeight: '22px',
    fontWeight: '500',
  },
  color: {
    fontSize: 14,
    lineHeight: '22px',
    fontWeight: '500',
  },
  package: {
    paddingTop: 10,

    fontSize: 14,
    fontWeight: '500',
  },

  textLight: {
    color: theme.colors.gray.main,
  },

  custom: {
    display: 'flex',
    color: '#1464DC',
    alignItems: 'center',
    cursor: 'pointer',
  },

  reviewsWrapper: {
    position: 'relative',
    zIndex: 2,

    cursor: 'pointer',
  },

  date: {
    display: 'flex',
    paddingTop: 12,

    fontSize: 14,

    alignItems: 'center',
  },
  dateIcon: {
    width: 14,
    marginRight: 8,
    lineHeight: 0,

    color: theme.colors.accent.main,
  },

  buttonWrapper: {
    display: 'flex',
    padding: '12px 0',

    justifyContent: 'flex-end',
  },
  buttonIcon: {
    width: 16,
    fontSize: '16px',
  },

  gear: {
    position: 'relative',
    width: 14,
    height: 14,
    color: '#fac81e',
    marginLeft: 5,
    lineHeight: 1,
    zIndex: 3
  },

  [theme.queries.lgDevicesUp]: {
    content: {
      display: 'flex',

      justifyContent: 'space-between',
    },
    col: {
      width: 'calc(100%/3)',
    },
  },

  [theme.queries.mdDevicesDown]: {
    colAdditional: {
      paddingTop: 11,
    },
    price: {
      paddingBottom: 8,
    },
    info: {
      lineHeight: 'normal',
    },
    col: {
      paddingBottom: 17,
    },

    button: {
      width: '100%',
    },
  },
});
