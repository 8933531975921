export default (theme) => ({
  triggers: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  trigger: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 48,
    '&:last-child': {
      marginRight: 0,
    }
  },

  triggerIcon: {
    width: 24,
    height: 24,
    marginRight: 12,
    color: '#1464DC'
  },
  triggersDivider: {
    marginRight: 30,
    width: 2,
    height: 60,
    opacity: 0.3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderImageSource: 'linear-gradient(to bottom, rgba(160, 170, 180, 0.0), #a0aab4 50%, rgba(160, 170, 180, 0.0))',
    borderImageSlice: 1,
  },
  triggerText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: 0.2,
    maxWidth: 86,
    color: '#282828',
    whiteSpace: 'pre-line'
  },
  banner: {
    '& $trigger': {
      marginRight: 23,
    },
    '& $triggersDivider': {
      display: 'none'
    },
    '& $triggerIcon': {
      color: '#fac81e'
    },
    '& $triggerText': {
      color: '#ffffff',
    },
  },
  [theme.queries.mdDevicesDown]: {
    triggers: {
      display: 'none'
    },
    banner: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 34,
      width: '100%',
    },
  },
  [theme.queries.lgDevicesUp]: {
    'banner': {
      display: 'none'
    }
  }

});
