export const styles = theme => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  item: {
    width: '44px',
    height: '44px',
    position: 'relative',
    marginRight: '16px',
    marginBottom: '16px',
    zIndex: 1,

    cursor: 'pointer',

    '&:hover': {
      zIndex: 2,
      '& $itemName': {
        opacity: 1,

        transition: 'opacity .5s ease .25s, transform .5s ease .25s',
        transform: 'translate(-50%, 0)',
      }
    }
  },
  itemCircle: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',

    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  itemName: {
    minWidth: '140px',
    position: 'absolute',
    left: '50%',
    zIndex: 2,
    padding: '12px 7px',
    opacity: 0,

    borderRadius: '6px',
    border: `1px solid ${theme.colors.gray.lighter}`,
    boxShadow: '0 2px 4px 0 rgba(60,60,80,0.08)',

    fontSize: '14px',
    textAlign: 'center',

    backgroundColor: '#fff',

    transition: 'opacity .5s ease, transform .5s ease',
    transform: 'translate(-50%, 20%)',

    '&:before': {
      content: '""',
      display: 'block',
      width: '0px',
      height: '0px',
      position: 'absolute',
      zIndex: 3,
      top: '-8px',
      left: '50%',

      borderStyle: 'solid',
      borderWidth: '0 8px 9px 8px',
      borderColor: 'transparent transparent #fff transparent',

      transform: 'translateX(-50%)',
    }
  },
  itemNameText: {
  },


  [theme.queries.mdDevicesDown]: {
    item: {
      marginRight: 9,
      marginBottom: 12,
    }
  },
});