import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { injectStyles } from '~app/theme';
import cn from 'classnames';

import { freezeScroll } from '../../utils/helpers';
import { Icon } from '~app/components/ui';

import styles from './GenericModalStyles';

@injectStyles(styles)
class GenericModal extends Component {

  onRequestClose = () => {
    this.props.onRequestClose();
    freezeScroll(true);
  }
  onOpen = () => {
    freezeScroll();
  }

  componentWillMount() {
    ReactModal.setAppElement('body');
  }

  render() {
    const {
      classes,
      open,
      icon = null,
      theme,
      title = null,
      text = null,
      additionalContent = null,
      thinPaddings = false,
    } = this.props;

    return (
      <ReactModal
        className={{
          base: classes.modal,
          afterOpen: classes.modalAfterOpen,
          beforeClose: classes.modalBeforeClose,
        }}
        overlayClassName={{
          base: classes.overlay,
          afterOpen: classes.overlayAfterOpen,
          beforeClose: classes.overlayBeforeClose,
        }}
        isOpen={open}
        onAfterOpen={this.onOpen}
        closeTimeoutMS={300}
        key={this.props.isOpen}
        contentLabel={'modal'}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick>

        <a className={classes.close} onClick={this.onRequestClose} />
        <div className={cn(classes.content, { [classes.contentThinPaddings]: thinPaddings })}>
          {icon !== null && <div className={cn(
            classes.icon, {
              [classes.iconRare]: theme === 'rare',
              [classes.iconBrand]: theme === 'brand',
              [classes.iconGeneral]: theme === 'general',
              [classes.iconAccent]: theme === 'accent',
              [classes.iconLighter]: theme === 'lighter',
            })}>
            <Icon name={icon} />
          </div>}
          {title !== null && <div className={classes.title}>
            {title}
          </div>}
          {text !== null && <div className={classes.text}>
            {text}
          </div>}
          {additionalContent !== null &&
            <div>
              {additionalContent}
            </div>
          }
        </div>
      </ReactModal>
    );
  }
}

export default GenericModal;
