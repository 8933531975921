import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { injectStyles } from '~app/theme';
import { freezeScroll } from '../../../utils/helpers';

import { Button } from '~app/components/ui';

import cn from 'classnames';

import { PackageDetailsSection } from '../../';
import styles from './PackageDetailsModalStyles';

import PropTypes from 'prop-types';


@injectStyles(styles)
class PackageDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customPackage: props.customPackage
    };
  }

  onRequestClose = () => {
    this.props.onRequestClose();
    freezeScroll(true);
  }

  onOpen = () => {
    this.setState({
      customPackage: this.props.customPackage
    });
    freezeScroll();
  }

  handleCheckboxChange = originalValue => event => {
    const name = event.target.name;
    const value = event.target.checked ? 'есть' : 'недоступно';

    this.setState(prevState => ({
      customPackage: {
        ...prevState.customPackage,
        [name]: value != originalValue ? value : undefined
      }
    }));

  }

  handleInputChange = originalValue => event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(prevState => ({
      customPackage: {
        ...prevState.customPackage,
        [name]: value != originalValue ? value : undefined
      }
    }));
  }

  saveCustomPackage = () => {
    if (this.props.onSaveCustomPackage !== null) {
      this.props.onSaveCustomPackage(this.state.customPackage);
      this.onRequestClose();
    }
  }

  clearCustomPackage = () => {
    if (this.props.onSaveCustomPackage !== null) {
      this.props.onSaveCustomPackage({});
      this.onRequestClose();
    }
  }

  render() {
    const {
      classes,
      item,
      isOpen,
      readonly
    } = this.props;

    const { customPackage } = this.state;
    const textareaSection = {
      name: 'Дополнительные условия',
      items: [{
        name: 'comment',
        value: ''
      }]
    };
    return (
      <ReactModal
        key={item.name}
        isOpen={isOpen}
        onAfterOpen={this.onOpen}
        className={{
          base: cn(classes.modal, {
            [classes.editable]: !readonly
          }),
          afterOpen: classes.modalAfterOpen,
          beforeClose: classes.modalBeforeClose,
        }}
        overlayClassName={{
          base: classes.overlay,
          afterOpen: classes.overlayAfterOpen,
          beforeClose: classes.overlayBeforeClose,
        }}
        closeTimeoutMS={300}
        contentLabel={'title'}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick>

        <div className={classes.head}>
          <div className={classes.headTitle}>{item.name}</div>
          <a className={classes.close} onClick={this.onRequestClose} />
        </div>

        {item && item.detailsGroup &&
          <div className={classes.content}>
            <div className={classes.contentMobile}>
              {readonly &&
                <div className={classes.contentDisclaimer}>{'* Ассортимент опций и цены могут быть другими. Некоторые типы дополнительного оборудования могут поставляться в пакете или же нет. Пожалуйста, уточняйте информацию у своего дилера при получении предложения в нашем сервисе'}</div>
              }
              {/* если коммент нельзя менять и он там есть - выводим наверху */}
              {readonly && customPackage && customPackage.comment && customPackage.comment != '' &&
                <div>
                  <PackageDetailsSection
                    section={textareaSection}
                    customPackage={customPackage}
                    handleInputChange={this.handleInputChange}
                    handleCheckboxChange={this.handleCheckboxChange}
                    onSaveCustomPackage={this.onSaveCustomPackage}
                    readonly={readonly}
                    mobile />
                </div>
              }
              {/* если можно менять - выводим внизу */}
              {!readonly &&
                <div>
                  <PackageDetailsSection
                    section={textareaSection}
                    customPackage={customPackage}
                    handleInputChange={this.handleInputChange}
                    handleCheckboxChange={this.handleCheckboxChange}
                    onSaveCustomPackage={this.onSaveCustomPackage}
                    readonly={readonly}
                    mobile />
                </div>
              }
              {item.detailsGroup.map((section, index) => {
                return (<div key={section.name}>
                  <PackageDetailsSection
                    section={section}
                    customPackage={customPackage}
                    handleInputChange={this.handleInputChange}
                    handleCheckboxChange={this.handleCheckboxChange}
                    readonly={readonly || index == 0 || index == 1}
                    mobile />
                </div>);
              })}
            </div>
            <div className={classes.contentDesk}>
              {/* если коммент нельзя менять и он там есть - выводим наверху */}
              {readonly && customPackage && customPackage.comment && customPackage.comment != '' &&
                <div>
                  <PackageDetailsSection
                    section={textareaSection}
                    customPackage={customPackage}
                    handleInputChange={this.handleInputChange}
                    handleCheckboxChange={this.handleCheckboxChange}
                    onSaveCustomPackage={this.onSaveCustomPackage}
                    readonly={readonly} />
                </div>
              }
              {/* если можно менять - выводим внизу */}
              {!readonly &&
                <div>
                  <PackageDetailsSection
                    section={textareaSection}
                    customPackage={customPackage}
                    handleInputChange={this.handleInputChange}
                    handleCheckboxChange={this.handleCheckboxChange}
                    onSaveCustomPackage={this.onSaveCustomPackage}
                    readonly={readonly} />
                </div>
              }
              {item.detailsGroup.map((section, index) => {
                return (<div key={section.name}>
                  <PackageDetailsSection
                    section={section}
                    customPackage={customPackage}
                    handleInputChange={this.handleInputChange}
                    handleCheckboxChange={this.handleCheckboxChange}
                    onSaveCustomPackage={this.onSaveCustomPackage}
                    readonly={readonly || index == 0 || index == 1} />
                </div>);
              })}
            </div>
          </div>
        }
        {!readonly &&
          <div className={classes.footer}>
            <div className={classes.footerItem}>
              <Button
                onClick={this.clearCustomPackage}
                mobileBlock
                theme={'rare'}
                text={'Сбросить'}
              />
            </div>
            <div className={classes.footerItem}>
              <Button
                onClick={this.saveCustomPackage}
                mobileBlock
                theme={'general'}
                text={'Подтвердить'}
              />
            </div>
          </div>
        }
      </ReactModal>
    );
  }
}

PackageDetailsModal.propTypes = {
  item: PropTypes.object,
  customPackage: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  readonly: PropTypes.bool,
  onSaveCustomPackage: PropTypes.func
};

PackageDetailsModal.defaultProps = {
  item: {},
  customPackage: {},
  isOpen: false,
  readonly: true,
  onSaveCustomPackage: null
};

export default PackageDetailsModal;
