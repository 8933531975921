
import NewsPreviews from './NewsPreviews/NewsPreviews';
import NewsItemCard from './NewsItemCard/NewsItemCard';
import HeroSlider from './HeroSlider/HeroSlider';
import HeroImage from './HeroImage/HeroImage';

export {
  NewsPreviews,
  NewsItemCard,
  HeroSlider,
  HeroImage,
};