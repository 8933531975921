export default theme => ({
  ...theme.styles.modal,

  modal: {
    ...theme.styles.modal.modal,
    maxWidth: 630
  },

  title: {
    color: theme.colors.gray.dark,
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: 'normal',
    textAlign: 'left',
    margin: '0 0 30px'
  },

  form: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: 'calc(100% + 30px)',
    margin: '0 -15px',
  },
  formInput: {
    width: '100%',
    margin: '8px 15px',
    textAlign: 'left',
  },
  text: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#282828',
    margin: '26px auto 14px',
    maxWidth: 440
  },

  formAction: {
    width: '100%',
    paddingTop: '30px',
    textAlign: 'center',
  },

  formInputLabel: {
    display: 'block',
    fontSize: '12px',
    color: theme.colors.gray.main,
    textAlign: 'center',
  },

  formInputLabelError: {
    color: theme.colors.rare.light,
  },

  formInputLabelSuccess: {
    color: theme.colors.general.main,
  },

  [theme.queries.lgDevicesUp]: {
    ...theme.styles.modal[theme.queries.lgDevicesUp],

    content: {
      padding: '30px 50px 30px',
    },
  
    formInputHalf: {
      width: 'calc(50% - 30px)',
    },
    mobile: {
      display: 'none',
    },
  },
  [theme.queries.smDevicesDown]: {

    ...theme.styles.modal[theme.queries.smDevicesDown],

    formInputHalf: {
      width: '100%',
    },
    formInputLabel: {
      paddingTop: '20px',
    },
    text: {
      maxWidth: '100%'
    },
    content: {
      padding: '30px 20px 30px',
    },
    desktop: {
      display: 'none',
    },

    formAction: {
      paddingLeft: 10,
      paddingRight: 10,
    }

  },


});
