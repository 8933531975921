import reducers from '~app/core/reducers';
import { mainClient } from '~app/network';

export const getCounts = async () => {
  reducers.user.getCountsRequest();
  const { data, error } = await mainClient.user.getCounts();
  
  if (error !== null) {
    reducers.user.getCountsFailure(error);
    return;
  }

  reducers.user.getCountsSuccess(data);
};

// Toggle my brand
export const toggleMyBrand = (id) => reducers.user.toggleMyBrand({ id });


// Update user
export const updateUser = async (params) => {
  reducers.user.updateUserRequest();
  const { data, error } = await mainClient.user.updateUser(params);
  
  if (error !== null) {
    reducers.user.updateUserFailure(error);
    return;
  }

  reducers.user.updateUserSuccess(data);
};

// Update dealer
export const updateDealer = async (params) => {
  reducers.user.updateDealerRequest();
  const { data, error } = await mainClient.user.updateDealer(params);
  
  if (error !== null) {
    reducers.user.updateDealerFailure(error);
    return;
  }

  reducers.user.updateDealerSuccess(data);
};

// Email confirm
export const emailConfirm = async (params) => {
  reducers.user.emailConfirmRequest();
  const { data, error } = await mainClient.user.emailConfirm(params);
  
  if (error !== null) {
    reducers.user.emailConfirmFailure(error);
    return;
  }

  reducers.user.emailConfirmSuccess(data);
};

// Send email confirmation
export const sendEmailConfirmation = async () => {
  reducers.user.sendEmailConfirmationRequest();
  const { data, error } = await mainClient.user.sendEmailConfirmation();
  
  if (error !== null) {
    reducers.user.sendEmailConfirmationFailure(error);
    return;
  }

  reducers.user.sendEmailConfirmationSuccess(data);
};