import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { injectStyles } from '~app/theme';
import actions from '~app/redux/actions';
import { connect } from 'react-redux';
import { push } from 'connected-react-router-exposed';

import { mainClient } from '~app/network'; // TODO надо через редакс

import { Spacer, Button, Icon, HintHoverContainer } from '../ui';
import { ApplicationCard } from '../';

import styles from './DealCardStyles';

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  push
};

@connect(mapStateToProps, mapDispatchToProps)
@injectStyles(styles)
class DealCard extends Component {

  showNote = () => {
    this.setState({
      noteShown: true
    });
  }

  onChangeStatus = () => {
    if (this.props.onChangeStatus) {
      this.props.onChangeStatus();
    }
  }

  cancelDeal = async () => {
    const {
      item
    } = this.props;

    actions.common.openModal({
      type: 'cancelOffer',
      params: {
        offerId: item.id,
        onSuccess: this.onChangeStatus
      }
    });
  }

  refuseOffer = () => {
    const {
      item
    } = this.props;

    actions.common.openModal({
      type: 'refuseOffer',
      params: {
        offerId: item.id,
        onSuccess: this.onChangeStatus
      }
    });
  }

  deleteDeal = async () => {
    const {
      item
    } = this.props;

    actions.common.openModal({
      type: 'removeOffer',
      params: {
        offerId: item.id,
        onSuccess: this.onChangeStatus
      }
    });
  }

  confirmOffer = async () => {
    const {
      item
    } = this.props;

    await mainClient.offers.confirmOffer({ id: item.id });

    setTimeout(() => { // TODO сделать нормально
      actions.common.openGenericModal({
        theme: 'general',
        icon: 'tickCircle',
        title: 'Оффер подвержден!',
        text: 'Заявка перешла в стадию сделки!',
      });
      this.props.push(`/dealer/deal/${item.id}`);
    }, 500);
  }

  renderUserActions = () => {
    const {
      item,
      classes,
    } = this.props;

    if (item.type === 'deal') {
      return (
        <div className={classes.itemButtons}>
          {item.archivedByUser &&
            <Fragment>
              <div
                onClick={this.deleteDeal}
                className={classes.itemButton}
              >
                <div className={classes.itemButtonIcon}></div>
                {'Удалить'}
              </div>
              <Spacer h={12} v={12} />
            </Fragment>
          }
          <Link
            to={`/cabinet/deal/${item.id}`}
            className={classes.itemButtonSecond}
          >
            <Button
              mobileBlock
              theme={'brand'}
              text={'Открыть сделку'}
            />
          </Link>
        </div>
      );
    }
    return (
      <div className={classes.itemButtons}>
        <div
          onClick={this.refuseOffer}
          className={classes.itemButton}
        >
          <div className={classes.itemButtonIcon}></div>
          {'Отменить сделку'}
        </div>
        <Spacer h={12} v={12} />
        <div className={classes.itemButtonSecond}>
          <HintHoverContainer
            direction={'bottom'}
            hint={'Дождись подтверждения сделки от дилера'}

          >
            <Button
              theme={'brand'}
              text={'Открыть сделку'}
              unactive
              mobileBlock
            />
          </HintHoverContainer>
        </div>
      </div>
    );
  }

  renderDealerActions = () => {
    const {
      item,
      classes,
    } = this.props;

    if (item.type === 'deal') {
      return (
        <div className={classes.itemButtons}>
          {item.archivedByDealer &&
            <Fragment>
              <div
                onClick={this.deleteDeal}
                className={classes.itemButton}
              >
                <div className={classes.itemButtonIcon}></div>
                {'Удалить'}
              </div>
              <Spacer h={12} v={12} />
            </Fragment>
          }
          <Link
            to={`/dealer/deal/${item.id}`}
            className={classes.itemButtonSecond}
          >
            <Button
              mobileBlock
              theme={'brand'}
              text={'Открыть сделку'}
            />
          </Link>
        </div>
      );
    }

    if (item.type === 'accepted') {
      return (
        <div className={classes.itemButtons}>
          <Button
            theme={'rare'}
            onClick={this.cancelDeal}
            leftIcon={
              <div style={{ width: 12 }}><Icon name={'times'} /></div>
            }
            text={'Отменить оффер'}
            mobileBlock
          />
          <Spacer v={12} h={12} />
          <Button
            theme={'general'}
            onClick={this.confirmOffer}
            leftIcon={
              <div style={{ width: 16 }}><Icon name={'tick'} /></div>
            }
            text={'Подтвердить сделку'}
            mobileBlock
          />
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      item,
      classes,
      asDealer,
      readonly, // костыль для совершенной сделки
      showActions = true // пока так получаю инфу, что это карточка для кабинета дилера, мб стоит переделать
    } = this.props;

    const application = {
      ...item.application,
      color: item.color,
      brand: item.brand,
      model: item.model,
      package: item.package,
      customPackage: item.customPackage,
    };

    return (
      <div className={classes.root}>
        <div className={classes.item}>
          <ApplicationCard
            item={application}
            deal={item}
            readonly={showActions || readonly} />
        </div>
        {showActions && (asDealer ? this.renderDealerActions() : this.renderUserActions())}
      </div>
    );
  }
}

export default DealCard;
