import React, { Component } from 'react';
import cn from 'classnames';

import { Icon } from '~app/components/ui';

import { injectStyles } from '~app/theme';
import styles from './TriggersStyles.js';

@injectStyles(styles)

class Triggers extends Component {
  render = () => {
    const {
      classes,
      iconText1,
      iconText2,
      banner = false
    } = this.props;

    return (
      <div className={cn(classes.triggers, { [classes.banner]: banner })}>
        <div className={classes.trigger}>
          <div className={classes.triggerIcon}>
            <Icon block name={'sale'} />
          </div>
          {iconText1 &&
            <div className={classes.triggerText}>
              {iconText1}
            </div>
          }
        </div>
        <div className={classes.triggersDivider} />
        <div className={classes.trigger}>
          <div className={classes.triggerIcon}>
            <Icon block name={'userCar'} />
          </div>
          {iconText2 &&
            <div className={classes.triggerText}>
              {iconText2}
            </div>
          }
        </div>
      </div>
    );
  }
}

export default Triggers;
