import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import SelectReact from 'react-select';
import 'react-select/dist/react-select.css';

import { injectStyles } from '~app/theme';
import { generateUUID } from '~app/utils/helpers';
import { styles } from './SelectStyles';

@injectStyles(styles)
class Select extends Component {
  uuid = generateUUID()

  getOptions = (arr) => {
    let options = [];

    arr.map((item) => {
      if (typeof item === 'string') {
        options.push({ value: item, label: item });
      } else {
        options.push({
          value: item[this.props.valueField],
          label: item[this.props.textField]
        });
      }
    });

    return options;
  }

  onChange = (option) => {
    if (this.props.onChange) {
      this.props.onChange(option.value);
    }
    document.getElementById(this.uuid).blur();
  }

  render() {
    const {
      classes,
      placeholder,
      options,
      name,
      withPadding,
      error,
    } = this.props;

    return (
      <div className={classes.root}>
        <SelectReact
          id={this.uuid}
          options={this.getOptions(options)}
          placeholder={placeholder}
          value={this.props.value}
          onChange={this.onChange}
          clearable={false}

          name={name}

          className={cn(classes.select, {
            [classes.selectError]: error,
            [classes.withPadding]: withPadding
          })}
        />
      </div>
    );
  }
}

Select.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  onChange: PropTypes.func,
  error: PropTypes.bool,
  isSearchable: PropTypes.bool,
  withPadding: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  textField: PropTypes.string,
  valueField: PropTypes.string,
};

Select.defaultProps = {
  placeholder: '',
  options: [],
  onChange: null,
  error: false,
  withPadding: false,
  isSearchable: false,

  name: '',
  value: '',
  textField: 'label',
  valueField: 'value',
};

export default Select;
