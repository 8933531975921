import { mapper, fromInput } from 'demapper';
import { transformImageUrl } from '../../_helpers';

@mapper
export default class {
  @fromInput('_id')
  id = null;

  @fromInput('name')
  name = null;

  @fromInput('logo.secure_url', { transform: transformImageUrl })
  image = null;
}
