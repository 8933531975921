import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { injectStyles } from '~app/theme';
import { formContainer, formCreate } from 'deforms';
import cn from 'classnames';
import { freezeScroll } from '../../utils/helpers';
import { connect } from 'react-redux';

// import { mainClient } from '~app/network'; // TODO надо через редакс

import { Button, TextInput, Icon, Card, CardContent } from '~app/components/ui';

import styles from './ReviewModalStyles';
import actions from '~app/redux/actions';


const mapStateToProps = (state, { offerId = null }) => {
  let inProgress = false;
  let success = false;
  let error = null;

  if (offerId !== null && offerId in state.reviews.createByOfferId) {
    inProgress = state.reviews.createByOfferId[offerId].inProgress;
    success = state.reviews.createByOfferId[offerId].result !== null;
    error = state.reviews.createByOfferId[offerId].error;
  }

  return {
    inProgress,
    success,
    error,
  };
};


@connect(mapStateToProps)
@injectStyles(styles)
@formContainer
class ReviewModal extends Component {
  state = {
    activeRate: null,
  }

  @formCreate()
  form = {
    schema: {
      emotion: {
        defaultValue: null,
        validation: {
          conform: (x) => x !== null
        }
      },
      text: {
        defaultValue: '',
      }
    },
    onSubmit: (formData) => {
      this.submit(formData);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.success && nextProps.success) {
      this.onSuccess();
    } else if (this.props.error === null && nextProps.error !== null) {
      this.onError(nextProps.error);
    }
  }

  onRequestClose = () => {
    this.props.onRequestClose();
    freezeScroll(true);
  }
  onOpen = () => {
    freezeScroll();
  }

  onSuccess() {
    actions.common.openModal({
      type: 'generic',
      params: {
        theme: 'general',
        icon: 'tickCircle',
        title: 'Спасибо за отзыв!',
        text: 'Оценка важна для формирования рейтинга дилеров на сайте.',
      }
    });
    if (this.props.onSuccess) {
      this.props.onSuccess();
    } else {
      this.onRequestClose();
    }
  }

  onError(error) {
    actions.common.openModal({
      type: 'generic',
      params: {
        theme: 'rare',
        icon: 'timesCircle',
        title: 'Ошибка!',
        text: error.message || 'Отзыв не создан.',
      }
    });
  }

  submit = (formData) => {
    actions.reviews.createByOfferId(this.props.offerId, {
      positive: !!formData.emotion,
      review: formData.text
    });
  }

  render() {
    const form = this.form;
    const {
      classes,
      isOpen,
    } = this.props;

    return (
      <ReactModal
        className={{
          base: classes.modal,
          afterOpen: classes.modalAfterOpen,
          beforeClose: classes.modalBeforeClose,
        }}
        overlayClassName={{
          base: classes.overlay,
          afterOpen: classes.overlayAfterOpen,
          beforeClose: classes.overlayBeforeClose,
        }}
        isOpen={isOpen}
        onAfterOpen={this.onOpen}
        closeTimeoutMS={300}
        key={this.props.isOpen}
        contentLabel={'modal'}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick>

        <a className={classes.close} onClick={this.onRequestClose} />

        <div className={classes.modalWrapper}>

          <Card>
            <CardContent>

              <div className={classes.title}>{'Оценить работу дилера'}</div>

              <div className={classes.ratingOptions}>

                <div
                  className={cn(
                    classes.ratingOption,
                    classes.ratingOptionPositive,
                    form.fields.emotion.value ? classes.ratingOptionPositiveActive : null
                  )}
                  onClick={() => {
                    form.fields.emotion.changeValue(true);
                  }}
                >
                  <div className={classes.ratingOptionIcon}>
                    <Icon name={'thumbUp'} />
                  </div>
                  <div className={classes.ratingOptionText}>{'Положительная оценка'}</div>
                </div>

                <div
                  className={cn(
                    classes.ratingOption,
                    classes.ratingOptionNegative,
                    form.fields.emotion.value === false ? classes.ratingOptionNegativeActive : null
                  )}
                  onClick={() => {
                    form.fields.emotion.changeValue(false);
                  }}
                >
                  <div className={classes.ratingOptionIcon}>
                    <Icon name={'thumbDown'} />
                  </div>
                  <div className={classes.ratingOptionText}>{'Отрицательная оценка'}</div>
                </div>

              </div>

              <div className={classes.formInput}>
                <TextInput
                  name={'message'}
                  type={'textarea'}
                  placeholder={'Сообщение'}
                  onChange={form.fields.text.change}
                  value={form.fields.text.value}
                />
              </div>

              <div className={classes.formButton}>
                <Button
                  mobileBlock
                  theme={'brand'}
                  text={'Отправить'}
                  onClick={form.submit}
                />
              </div>

            </CardContent>
          </Card>

        </div>

      </ReactModal>
    );
  }
}

export default ReviewModal;
