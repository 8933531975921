export const styles = theme => ({
  root: {
    position: 'relative',
  },
  select: {
    '& .Select-control': {
      width: '100%',
      height: 46,
      padding: '0 15px',

      borderColor: theme.colors.gray.lighter,
      borderRadius: 3,
      boxShadow: '0 2px 1px 0 rgba(60,60,80,.04)',

      fontSize: 14,

      color: theme.colors.gray.dark,

      transition: 'background-color 1s ease, border-color .5s ease',

      cursor: 'pointer',

      '& .Select-value': {
        padding: '0 15px',

        lineHeight: '46px',
      },
    },
    '& .Select-placeholder': {
      padding: '0 15px',

      lineHeight: '46px',

      color: theme.colors.gray.main,
    },
    '& .Select-input': {
      height: 'auto',
      paddingLeft: 0,

      fontSize: 14,
      lineHeight: '46px',
    },
    '& .Select-arrow-zone': {
      paddingRight: 0,
      paddingTop: 4,
    },
    '& .Select-arrow': {
      borderColor: '#c8c8c8 transparent transparent transparent'
    },
    '& .Select-menu-outer': {
      top: 'calc(100% - 10px)',

      borderBottomRightRadius: 2,
      borderBottomLeftRadius: 2,
      borderColor: theme.colors.gray.lighter,
      borderTopColor: 'transparent',

      backgroundColor: theme.colors.gray.lightest,
    },
    '& .Select-option': {
      padding: '0 15px',

      fontSize: 14,
      lineHeight: '30px',

      backgroundColor: 'transparent',

      '&.is-focused': {
        color: theme.colors.brand.main,
        backgroundColor: 'transparent',
      }
    },

    '&.is-focused': {

      '& > .Select-control': {
        borderColor: theme.colors.gray.lighter,

        backgroundColor: theme.colors.gray.lightest,
      },

      '&:not(.is-open) > .Select-control': {
        borderColor: theme.colors.gray.lighter,
        boxShadow: '0 2px 1px 0 rgba(60,60,80,.04)',

        backgroundColor: '#fff',
      },
      '& .Select-placeholder': {
        display: 'none',
      }
    },
  },

  withPadding: {
    '& .Select-control': {
      paddingLeft: 46,
      '& .Select-value': {
        paddingLeft: 46,
      },
    },
    '& .Select-placeholder': {
      paddingLeft: 46
    },
    '& .Select-option': {
      paddingLeft: 46
    }
  },

  selectError: {
    borderColor: theme.colors.rare.main,

    '& > .Select-control': {
      borderColor: theme.colors.rare.main,
    }
  },

  input: {
    width: '100%',
    height: 46,
    padding: '10px 15px',

    border: '1px solid #dcdcdc',
    borderRadius: 2,
    boxShadow: '0 2px 1px 0 rgba(60,60,80,.04)',
    outline: 'none',

    fontSize: 14,

    backgroundColor: '#fff',
    color: theme.colors.gray.darker,

    transition: 'border-color 1s ease',

    '&::placeholder': {
      color: '#a0a0a0',
    },
    '&:focus': {
      // borderColor: theme.colors.accent.main,
    },
  },

  inputError: {
    borderColor: theme.colors.rare.main,
  },

  errorMessage: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    paddingTop: 5,

    fontSize: 14,

    color: theme.colors.rare.main,

    transform: 'translateY(100%)',
  },

  [theme.queries.smDevicesDown]: {
    select: {
      '& .Select-control': {
        fontSize: 16,
      },
      '& .Select-input': {
        fontSize: 16,
      },
      '& .Select-option': {
        fontSize: 16,
      },
    },

  }
});
