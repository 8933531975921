export default theme => ({

  name: {
    paddingBottom: 11,
    fontSize: 14,
    fontWeight: '700',
  },

  text: {
    fontSize: 14,
    lineHeight: '24px',
  },

  date: {
    display: 'flex',
    paddingTop: 11,

    fontSize: 14,

    color: theme.colors.gray.main,

    alignItems: 'center',
  },
  dateIcon: {
    width: 14,
    marginRight: 8,
  },

  buttonWrapper: {
    padding: '12px 0',
  },
  buttonIcon: {
    width: 16,
  },
  

  [theme.queries.lgDevicesUp]: {
    buttonWrapper: {
      display: 'flex',
      
      justifyContent: 'flex-end',
    },
  },
  
  [theme.queries.mdDevicesDown]: {
    colAdditional: {
      paddingTop: 11,
    },

    button: {
      width: '100%',
    },
  },
});