import reducers from '~app/core/reducers';
import { mainClient } from '~app/network';

// get dealers list
export const getDealers = async (params) => {
  reducers.dealers.getDealersRequest();
  const { data, error } = await mainClient.dealers.getDealersList(params);

  if (error !== null) {
    reducers.dealers.getDealersFailure(error);
    return;
  }

  reducers.dealers.getDealersSuccess(data);
};

export const getDealersReset = () => {
  reducers.dealers.getDealersReset();
};

// get dealer by id
export const getDealer = async (params) => {
  reducers.dealers.getDealerRequest();
  const { data, error } = await mainClient.dealers.getDealer(params);

  if (error !== null) {
    reducers.dealers.getDealerFailure(error);
    return;
  }
  reducers.dealers.getDealerSuccess(data);
};

export const getDealerReset = () => {
  reducers.dealers.getDealerReset();
};

// Get brands by dealer
export const getBrandsByDealer = async (params) => {
  reducers.dealers.getBrandsByDealerRequest();
  const { data, error } = await mainClient.dealers.getBrandsByDealer(params);

  if (error !== null) {
    reducers.dealers.getBrandsByDealerFailure(error);
    return;
  }

  reducers.dealers.getBrandsByDealerSuccess(data);
};

export const getBrandsByDealerReset = () => {
  reducers.dealers.getBrandsByDealerReset();
};