export default (theme) => ({
  root: {
    position: 'relative',
  },
  closed: {

  },
  main: {
    height: 216,
    padding: '40px 60px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 40,
    '& $title': {
      fontSize: 36,
      lineHeight: 1.33,
      fontWeight: 'bold',
    }
  },

  dealers: {
    height: 468,
    padding: '30px 30px 40px',
    width: 270,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '& $title': {
      fontSize: 24,
      lineHeight: 1.67,
    }
  },

  title: {
    position: 'relative',
    zIndex: 1,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    color: '#ffffff',
    margin: '0 0 12px',
  },

  button: {
    position: 'relative',
    zIndex: 1,
    fontSize: 14,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '22px',
    letterSpacing: 'normal',
    color: '#ffffff',
    padding: '12px 20px',
    border: '1px solid #fff',
    borderRadius: 24,
    textAlign: 'center',
    outline: 'none',
    boxShadow: 'none',
    appearance: 'none',
    transition: 'all .3s ease',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#000',
    }
  },

  image: {
    borderRadius: 6,
    boxShadow: '0 2px 4px 0 rgba(60, 60, 80, 0.08)',
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#737981',
      opacity: .6,
    }
  },

  close: {
    position: 'absolute',
    zIndex: 1,
    top: 17,
    right: 20,
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#fff',
    '& svg': {
      width: 12,
      height: 12,
    }
  },

  [theme.queries.xsDevicesDown]: {
  },
  [theme.queries.mdDevicesDown]: {
    closed: {
      transform: 'translateY(100%)',
      opacity: 0,
    },
    main: {
      height: 98,
      padding: '12px 20px',
      position: 'fixed',
      zIndex: 10,
      bottom: 0,
      left: 0,
      marginBottom: 0,
      transition: 'transform .3s ease, opacity .1s ease .3s',
      '& $image': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      '& $title': {
        fontSize: 14,
        lineHeight: 1.57,
        marginBottom: 12,
      },
    },
    dealers: {
      width: '100%',
      height: 98,
      padding: '12px 20px',
      position: 'fixed',
      zIndex: 10,
      bottom: 0,
      left: 0,
      marginBottom: 0,
      transition: 'transform .3s ease, opacity .1s ease .3s',
      '& $image': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      '& $title': {
        fontSize: 14,
        lineHeight: 1.57,
        marginBottom: 12,
      },
    },
    title: {

    },
    image: {

    },

    button: {
      padding: '8px 20px',
      width: '100%',
      maxWidth: 400,
    }
  },
  [theme.queries.lgDevicesUp]: {
    close: {
      display: 'none',
    },
  },
  [theme.queries.lgDevicesOnly]: {
  },
});
