import React from 'react';

import { Helmet } from 'react-helmet';

import { Container } from '~app/components/ui';
import { HeaderLander, FooterLander, Partners, LayoutGeneral } from '~app/components';
import BrandsBanner from '~app/components/BrandsBanner/BrandsBanner';
import LanderIconsBlock from '~app/components/LanderIconsBlock/LanderIconsBlock';

import PromoBanner from '~app/components/PromoBanner/PromoBanner';
import CarCarousel from '~app/components/CarCarousel/CarCarousel';
import RequestLanderForm from '~app/components/RequestLanderForm/RequestLanderForm';

import { triggersIcons, roadmapIcons } from '~app/config/landersConfig';

// import { push } from 'react-router-redux';

import { connect } from 'react-redux';
import actions from '~app/redux/actions';
import NotFoundScreen from '../NotFoundScreen/NotFoundScreen';

const mapStateToProps = ({ lander, partners }) => ({
  data: lander.contentByBrandData,
  inProgress: lander.contentByBrandInProgress,
  success: lander.contentByBrandSuccess,
  error: lander.contentByBrandError,
  partnersData: partners.partnersByBrandsData,
});

@connect(mapStateToProps)

class BrandsLanderScreen extends React.Component {

  static async fetchData({ store, params }) {
    await Promise.all([
      actions.lander.getContentByBrand(params.brand),
      // actions.partners.getPartnersByBrands([params.brand]),
    ]);
  }

  componentDidMount() {
    actions.lander.getContentByBrand(this.props.match.params.brand);
    // actions.partners.getPartnersByBrands([this.props.match.params.brand]);
  }

  componentWillReceiveProps(next) {
    // if (next.error) {
    //   this.props.dispatch(push('/404'));
    // }

    if (this.props.inProgress && !next.inProgress && next.success) {
      if (next.data && next.data.brand && next.data.brand.id) {
        actions.partners.getPartnersByBrands([next.data.brand.id]);
      }
    }
  }

  render() {
    const { partnersData, error } = this.props;
    const { content = {}, brand, model, similarModels } = this.props.data;
    const {
      hero = null,
      steps = null,
      footer = null,
      preFooter = null,
      landingAdvantages = null,
      header = null,
      modelChoose = null,
    } = content;

    return (
      error ?
        <LayoutGeneral>
          <NotFoundScreen />
        </LayoutGeneral>
        :
        <React.Fragment>
          {brand &&
            <Helmet>
              <title>Автомобили {brand.name} в Петербурге от дилера</title>
              <meta name={'description'} content={`Предложения от официальных дилеров. Весь модельный ряд ${brand.name} на одном сайте. В наличие и под заказ.`} />
            </Helmet>
          }
          {brand &&
            <HeaderLander
              city={header.city}
              iconText1={header.iconText1}
              iconText2={header.iconText2}
              phone={header.phone}
              brandLogoUrl={brand.landingLogoUrl} />
          }
          {hero && header && brand &&
            <BrandsBanner
              title={`${hero.header} ${brand.name}`}
              subtitle={hero.subheader}
              text={hero.description}
              bgUrl={brand.heroUrl}
              brandName={brand.name}
              iconText1={header.iconText1}
              iconText2={header.iconText2}
              invertedTextColor={brand.invertedTextColor}
            />
          }
          <Container background={'#fff'}>
            {partnersData &&
              partnersData.items &&
              partnersData.items.length > 0 &&
              <Partners lander data={partnersData} />
            }
            {landingAdvantages &&
              <LanderIconsBlock
                texts={landingAdvantages}
                icons={triggersIcons} />}
            {brand &&
              <RequestLanderForm
                title={brand.name}
                titleAdditional={modelChoose.header}
                brand={brand}
                model={model}
              />
            }
            {steps &&
              <LanderIconsBlock
                variant={'left'}
                dividers={false}
                texts={steps}
                icons={roadmapIcons}
              />
            }
            {similarModels && brand &&
              <CarCarousel
                title={'Эти автомобили уже купили на нашей платформе с выгодой'}
                content={similarModels.map(item => ({
                  brand: item.brand,
                  brandImg: item.brandLOgo,
                  model: item.name,
                  img: item.image,
                  package: item.package,
                  discountPercent: item.discountPercent,
                  discountValue: item.discountValue,
                  color: item.colorName,
                  colorId: item.colorId,
                  packageId: item.packageId,
                  modelId: item.modelId,

                }))}
              />
            }
          </Container>
          {preFooter &&
            <PromoBanner
              image={preFooter.image}
              header={preFooter.header}
              buttonText={preFooter.buttonText}
              description={preFooter.description} />
          }
          {footer &&
            <FooterLander
              agreement={footer.agreement}
              copyright={footer.copyright}
              description={footer.description}
              header={footer.header}
            />
          }
        </React.Fragment>
    );
  }
}

export default BrandsLanderScreen;
