import { mapper, fromInput } from 'demapper';
import { DealerModel, BrandBaseModel, ModelBaseModel } from '..';

@mapper
export default class {

  @fromInput('_id')
  id = null;

  @fromInput('name')
  name = null;

  @fromInput('image.secure_url')
  image = null;

  @fromInput('dealer', { transform: x => new DealerModel(typeof x === 'string' ? { '_id': x } : x) })
  dealer = null;

  @fromInput('brand', { transform: x => new BrandBaseModel(typeof x === 'string' ? { '_id': x } : x) })
  brand = null;

  @fromInput('model', { transform: x => new ModelBaseModel(typeof x === 'string' ? { '_id': x } : x) })
  model = null;

  @fromInput('fullDescription')
  fullDescription = null;

  @fromInput('shortDescription')
  shortDescription = null;

  @fromInput('date', { model: Date })
  date = null;

  @fromInput('link.url', { transform: x => x !== null && x !== '' ? x : null })
  url = null;

  @fromInput('link.buttonText', { transform: x => x !== null && x !== '' ? x : null })
  buttonText = null;

}
