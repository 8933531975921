import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import { styles } from './BurgerStyles';

@injectStyles(styles)
class Burger extends Component {

  onPress = () => {
    this.props.onPress();
  }

  render() {
    const {
      classes,
      active,
    } = this.props;

    return (
      <div onClick={this.onPress} className={cn(classes.root, active ? classes.active : null)}>
        <div className={classes.burger}>
          <div className={classes.line}/>
          <div className={classes.line}/>
          <div className={classes.line}/>
        </div>
      </div>
    );
  }
}

Burger.propTypes = {
  onPress: PropTypes.func,
  active: PropTypes.bool,
  size: PropTypes.number,
  padding: PropTypes.number,
  color: PropTypes.string,
};

Burger.defaultProps = {
  onPress: null,
  active: false,
  size: 40,
  padding: 10,
  color: null,
};

export default Burger;