import client from './_client';
import { UserMeModel } from './models';

// Get user info
export const getUserInfo = () => {
  return client.request({
    url: '/v1/user',
    method: 'GET',
    transformData: data => new UserMeModel(data)
  });
};

// Get user info
export const getDealerInfo = () => {
  return client.request({
    url: '/v1/dealer',
    method: 'GET',
    transformData: data => new UserMeModel(data)
  });
};

// Get counts info
export const getCounts = () => {
  return client.request({
    url: '/v1/counts',
    method: 'GET',
    transformData: data => ({
      applications: data.applicationsCount || 0,
      applicationsNew: data.applicationsNew || 0,
      offers: data.offersCount || 0,
      offersNew: data.offersNew || 0,
      deals: data.dealsCount || 0,
      dealsNew: data.dealsNew || 0,
      reviews: data.reviewsCount || 0,
      reviewsNew: data.reviewsNew || 0,
      archived: data.archivedCount || 0
    })
  });
};

// Update user
export const updateUser = ({ firstName, lastName, email, phone }) => {
  return client.request({
    url: '/v1/user',
    method: 'PATCH',
    data: {
      email,
      phone,
      name: firstName,
      surname: lastName
    }
  });
};

// Update dealer
export const updateDealer = (params) => {
  return client.request({
    url: '/v1/dealer',
    method: 'PATCH',
    data: params
  });
};

// Email confirm
export const emailConfirm = (params) => {
  return client.request({
    url: '/v1/email/check',
    method: 'POST',
    params
  });
};

// Send email confirmation
export const sendEmailConfirmation = (params) => {
  return client.request({
    url: '/v1/email/verify',
    method: 'POST'
  });
};