import React, { Component } from 'react';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import styles from './CheckboxStyles';

@injectStyles(styles)
class Checkbox extends Component {

  onChange(event) {
    let checked = event.target.checked;
    event.target.value = !checked ? false : (this.props.value || true);
    const value = !checked ? false : (this.props.value || true);

    if (this.props.onChange) {
      this.props.onChange(event);
    }
    
    if (this.props.onChangeValue) {
      this.props.onChangeValue(value);
    }
  }

  renderLabel = () => {
    const {
      classes,
      label,
      link = null,
      labelStyles,
    } = this.props;
    if (link !== null) {
      let labelParts = label.split(link.placeholder);
      return (
        <span className={cn(classes.labelText, labelStyles ? labelStyles : null)}>
          {labelParts[0]}{<a className={classes.labelLink} href={link.url} target='_blank'>{link.text}</a>}{labelParts[1]}
        </span>
      );
    } else {
      return (
        <span className={cn(classes.labelText, labelStyles ? labelStyles : null)}>
          {label}
        </span>
      );
    }
  }

  render() {
    let {
      classes,

      label,
      hint,
      name,
      value,
      checked,
      disabled,
      icon,
      error,
      handleArea = 'label',
    } = this.props;

    return (
      <label className={cn(classes.checkbox, {
        [classes._disabled]: disabled,
        [classes[`_handle-${handleArea}`]]: handleArea !== null,
      })}>
        <input 
          type={'checkbox'}
          className={classes.input}
          name={name}
          value={value}
          checked={checked}
          onChange={this.onChange.bind(this)}
          disabled={disabled} 
        />
        <span className={cn(classes.ui, {
          [classes.ui_disabled]: disabled,
          [classes.ui_error]: error,
        })}>
          <span className={cn(classes.check, {
            [classes.check_active]: checked,
          })} />
        </span>
        {(label || hint || icon) &&
          <span className={classes.label}>
            {icon &&
              <span className={classes.labelIcon}>{icon}</span>
            }
            {label && this.renderLabel()}
            {hint &&
              <span className={classes.labelHint}>{hint}</span>
            }
          </span>
        }
      </label>
    );
  }
}

export default Checkbox;