import client from './_client';
// import { ReviewListModel } from './models';

// Create support message
export const createSupportMessage = (params = {}) => {
  let {
    message,
    subject,
  } = params;

  return client.request({
    url: '/v1/support',
    method: 'POST',
    data: {
      message,
      subject
    }
  });
};

// Subscrive
export const subscribe = (params = {}) => {

  return client.request({
    url: '/v1/subscribe',
    method: 'POST',
    data: params
  });
};
