import React, { Component } from 'react';
import { injectStyles } from '~app/theme';
import cn from 'classnames';
import { formContainer, formCreate } from 'deforms';

import { Countdown } from '~app/components';
import { TextInput, Icon } from '~app/components/ui';

import styles from './TwoFactorStepStyles';

@injectStyles(styles)
@formContainer
class TwoFactorStep extends Component {

  @formCreate()
  formChangeUserSMS = {
    schema: {
      code: {
        defaultValue: '',
        validation: {
          type: 'string',
          minLength: 4,
          maxLength: 4,
        }
      },
    },
    onSubmit: (formData) => {
      this.props.onSubmit();
      // actions.auth.registerSMS({
      //   ...formData,
      //   email: this.formRegister.fields.email.value,
      //   phone: this.formRegister.fields.phone.value,
      // });
    },
    onChange: (form) => {
      if (form.valid) {
        form.submit();
      }
    }
  }

  render() {
    const {
      classes,
      user,
      onBack
    } = this.props;
    const formChangeUserSMS = this.formChangeUserSMS;

    return (
      <div>
        <div className={classes.title}>
          {user.phone}
        </div>
        <div className={classes.nav}
          onClick={onBack}
        >
          <span className={cn(classes.navIcon, classes.navIconChange)}>
            <Icon name={'arrowRight'} />
          </span>
          <span className={classes.navButton}>{'Изменить номер'}</span>
        </div>
        <div className={classes.text}>
          {'Изменение данных профиля, необходимо ввести код из СМС'}
        </div>
        <Countdown
          date={180000}
          renderTimer={({ timeString }) => (
            <div className={classes.timer}>
              <span className={classes.timerIcon}>
                <Icon name={'clock'} />
              </span>
              <span>{timeString}</span>
            </div>
          )}
          renderAction={() => (
            <div className={classes.nav}
            // onClick={() => {formRegister.submit();}}
            >
              <span className={classes.navButton}>{'Повторить отправку'}</span>
            </div>
          )}
        />
        <form noValidate className={classes.form} onSubmit={formChangeUserSMS.submit}>
          <div className={classes.formInput}>
            {/* {registerSMSSuccess === null && registerSMSError === null &&
              <label className={classes.formInputLabel}>Ввести код</label>
            }
            {registerSMSError !== null &&
              <label className={cn(classes.formInputLabel, classes.formInputLabelError)}>Неверный код</label>
            }
            {registerSMSSuccess !== null &&
              <label className={cn(classes.formInputLabel, classes.formInputLabelSuccess)}>Код принят</label>
            } */}
            <label className={classes.formInputLabel}>Ввести код</label>
            <TextInput
              code
              maxLength={4}
              // success={registerSMSSuccess}
              // error={registerSMSError}
              onChange={formChangeUserSMS.fields.code.change}
              value={formChangeUserSMS.fields.code.value}
              autofocus
            />
          </div>
        </form>
      </div>
    );
  }
}

export default TwoFactorStep;
