import React, { Component } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { formContainer, formCreate } from 'deforms';

import actions from '~app/redux/actions';
import { injectStyles } from '~app/theme';
import { Icon, Container, TextInput } from '../ui';
import styles from './FooterStyles';
import Config from '~app/config';
import Constants from 'Constants';

const mapStateToProps = state => ({
  user: state.auth.user,

  subscribeInProgress: state.common.subscribeInProgress,
  subscribeSuccess: state.common.subscribeSuccess,
  subscribeError: state.common.subscribeError,
});

@connect(mapStateToProps)
@injectStyles(styles)
@formContainer
class Footer extends Component {

  @formCreate()
  formSubscribe = {
    schema: {
      email: {
        defaultValue: '',
        validation: {
          type: 'string',
          pattern: Config.regex.email
        }
      },
    },
    onSubmit: (formData) => {
      this.subscribeFormSubmit(formData);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.subscribeSuccess && nextProps.subscribeSuccess) {
      this.onSubscribeSuccess();
    } else if (this.props.subscribeError === null && nextProps.subscribeError !== null) {
      this.onSubscribeError(nextProps.subscribeError);
    }
  }

  subscribeFormSubmit = (formData) => {
    if (this.props.subscribeInProgress) {
      return;
    }
    actions.common.subscribe(formData);
  }

  onSubscribeSuccess = () => {
    this.formSubscribe.fields.email = '';
    actions.common.openModal({
      type: 'generic',
      params: {
        theme: 'general',
        icon: 'tickCircle',
        title: 'Спасибо за подписку!',
        text: 'Ждите новостей.',
      }
    });
  }

  onSubscribeError = (error) => {
    actions.common.openModal({
      type: 'generic',
      params: {
        theme: 'rare',
        icon: 'timesCircle',
        title: 'Ошибка!',
        text: error.message || 'Неизвестная ошибка.',
      }
    });
  }

  openDealerAuthModal = (tab) => {
    actions.common.openDealerAuthModal(tab);
  }
  openAuthModal = (tab) => {
    actions.common.openAuthModal(tab);
  }

  openSupportModal = () => {
    actions.common.openModal({
      type: 'support'
    });
  }

  render = () => {
    const {
      classes,
      user,
    } = this.props;
    const formSubscribe = this.formSubscribe;

    return (
      <div className={classes.root}>

        <Container>
          <div className={classes.content}>
            <div className={cn(classes.col, classes.colLogo)}>
              <div className={classes.logo}>
                <Icon name={'logoFooter'}/>
              </div>
              <div className={classes.logoText}>{'Скидки и выгодные предложения на покупку нового авто'}</div>
            </div>
            <div className={cn(classes.col, classes.colDesk)}>
              <div className={classes.colTitle}>{'Навигация'}</div>
              <div className={classes.colList}>
                <Link to='/' className={classes.colLink}>{'Главная'}</Link>
                <Link to='/deals' className={classes.colLink}>{'Сделки'}</Link>
                {/* <Link to='#' className={classes.colLink}>{'Дилерам'}</Link> */}
                <Link to='/contacts' className={classes.colLink}>{'Контакты'}</Link>
              </div>
            </div>
            <div className={cn(classes.col, classes.colDesk)}>
              <div className={classes.colTitle}>{'Сервис'}</div>
              <div className={classes.colList}>
                {user === null || !user.isDealer ?
                  <Link to='/application' className={classes.colLink}>{'Создать заявку'}</Link>
                  :
                  <a className={classes.colLink}>{'Создать заявку'}</a>
                }
                {user !== null && !user.isDealer ?
                  <Link to='/cabinet' className={classes.colLink}>{'Личный кабинет'}</Link>
                  :
                  <a onClick={() => this.openAuthModal('auth')} className={classes.colLink}>{'Личный кабинет'}</a>
                }
                {user === null ?
                  <a onClick={() => this.openAuthModal('register')} className={classes.colLink}>{'Регистрация'}</a>
                  :
                  <a className={classes.colLink}>{'Регистрация'}</a>
                }
                <a onClick={this.openSupportModal} className={classes.colLink}>{'Поддержка'}</a>
              </div>
            </div>
            <div className={cn(classes.col, classes.colDealers)}>
              <div className={classes.colTitle}>{'Дилерам'}</div>
              <div className={classes.colList}>
                {user !== null && user.isDealer ?
                  <Link to='/dealer' className={classes.colLink}>{'Кабинет Дилера'}</Link>
                  :
                  <a onClick={() => this.openDealerAuthModal('auth')} className={classes.colLink}>{'Кабинет Дилера'}</a>
                }
                <a onClick={() => this.openDealerAuthModal('register')} className={classes.colLink}>{'Регистрация'}</a>
              </div>
            </div>

            <div className={classes.devider}/>

            <div className={cn(classes.col, classes.colDesk)}>
              <div className={classes.colTitle}>{'Контакты'}</div>
              <div className={classes.colList}>
                <a href={`tel:${Constants.PHONE_NUMBER_PLAIN}`} className={classes.colLink}>{Constants.PHONE_NUMBER_BEAUTY}</a>
                <a href='mailto:support@avicar.ru' className={classes.colLink}>{'support@avicar.ru'}</a>
              </div>
            </div>
            <div className={cn(classes.col, classes.colDesk)}>
              <div className={classes.colTitle}>{'Наш адрес'}</div>
              <div className={classes.colList}>
                <Link to='/contacts' className={classes.colLink} style={{ maxWidth: 150 }}>{'Санкт–Петербург, Льва Толстого 1-3'}</Link>
              </div>
            </div>
            <div className={cn(classes.col, classes.colDesk)}>
              <div className={classes.colTitle}>{'Социальные сети'}</div>
              <div className={classes.colList}>
                <div className={classes.socials}>
                  <div className={classes.socialItem}>
                    <a href='https://www.facebook.com/avicar.ru/' target={'_blank'} className={classes.socialItemLink}/>
                    <div className={classes.socialItemIcon}>
                      <Icon name={'socialFb'}/>
                    </div>
                  </div>
                  <div className={classes.socialItem}>
                    <a href='https://vk.com/avicar' target={'_blank'} className={classes.socialItemLink}/>
                    <div className={classes.socialItemIcon}>
                      <Icon name={'socialVk'}/>
                    </div>
                  </div>
                  <div className={classes.socialItem}>
                    <a href='https://ok.ru/avicar' target={'_blank'} className={classes.socialItemLink}/>
                    <div className={classes.socialItemIcon} style={{ width: 10 }}>
                      <Icon name={'socialOk'}/>
                    </div>
                  </div>
                  <div className={classes.socialItem}>
                    <a href='https://www.instagram.com/avicar.ru/' target={'_blank'} className={classes.socialItemLink}/>
                    <div className={classes.socialItemIcon}>
                      <Icon name={'socialIg'}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cn(classes.col, classes.colSubscribe)}>
              <div className={classes.colTitle}>{'Подпишись на новости'}</div>
              <div className={classes.colList}>
                <div className={classes.inputBtn}>
                  <TextInput
                    name={'email'}
                    type={'text'}
                    placeholder={'Email'}
                    onChange={formSubscribe.fields.email.change}
                    value={formSubscribe.fields.email.value}
                    error={formSubscribe.submitted && !formSubscribe.fields.email.valid}
                  />
                  <div onClick={this.formSubscribe.submit} className={classes.inputBtnBtn}>
                    <div className={classes.inputBtnBtnIcon}>
                      <Icon name={'pointRight'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.bottom}>
              <div className={cn(classes.col, classes.colCopyright)}>
                <div className={classes.copyright}>{'© avicar.ru'}</div>
              </div>
              <div className={cn(classes.col, classes.colRules)}>
                <a href={'/static/files/agreement.pdf'} target={'_blank'} className={classes.rules}>{'Пользовательское соглашение'}</a>
              </div>
              <div className={cn(classes.col, classes.colMadeby)}>
                <span>{'Made by '}</span>
                <a href='https://www.behance.net/Choose_design' target={'_blank'} className={classes.madeBy}>{'Choose.'}</a>
                <p className={classes.developed}>
                  <span>{'Developed by '}</span>
                  <a href="https://initflow.com" target={'_blank'} rel={'noopener'} className={classes.developedLink}>Initflow</a>
                </p>
              </div>
            </div>

          </div>
        </Container>
      </div>
    );
  }
}

export default Footer;
