import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import { Container, Icon, Price } from '~app/components/ui';
import Banner from './Sections/Banner/Banner';
import Advantages from './Sections/Advantages/Advantages';
import About from './Sections/About/About';
import { Partners, PromotionBanner } from '~app/components';
import { PromoPreviews } from '~app/components/Promo';
import PreFooter from './Sections/PreFooter/PreFooter';

import image1 from '~assets/images/visual1.png';
import image2 from '~assets/images/visual2.png';
import image3 from '~assets/images/visual3.png';
import image4 from '~assets/images/visual4.png';
import image5 from '~assets/images/visual5.png';

import actions from '~app/redux/actions';
import { connect } from 'react-redux';
import { NewsPreviews } from '~app/components/News';

const mapStateToProps = ({ partners, news, promo }, { reduxKey = 'homepage' }) => {

  let newsState = {
    items: null,
  };

  let promoState = {
    items: null,
  };

  if (reduxKey in news.newsByKey) {
    newsState = news.newsByKey[reduxKey];
  }

  if (reduxKey in promo.promoByKey) {
    promoState = promo.promoByKey[reduxKey];
  }

  return {
    data: partners.partnersData,
    inProgress: partners.partnersDataInProgress,
    success: partners.partnersDataSuccess,
    error: partners.partnersDataError,

    newsItems: newsState.items,
    promoItems: promoState.items,
  };
};

@connect(mapStateToProps)

class HomeScreen extends Component {

  state = {
  }

  static async fetchData({ store, params }) {
    await Promise.all([
      actions.partners.getPartners(),
      actions.news.getNewsByKey('homepage', {
        limit: 4,
        page: 1,
      }),
      actions.promo.getPromoByKey('homepage', {
        limit: 4,
        page: 1,
      })
    ]);
  }

  componentDidMount() {
    actions.partners.getPartners();
    actions.news.getNewsByKey('homepage', {
      limit: 4,
      page: 1,
    });
    actions.promo.getPromoByKey('homepage', {
      limit: 4,
      page: 1,
    });
  }


  render = () => (
    <div>
      <Helmet>
        <title>Avicar - Эксклюзивные цены от дилеров!</title>
        <meta name={'description'} content={'Новые автомобили со скидкой до 20%'} />
      </Helmet>
      <Container background={'#fff'}>

        <Banner
          bubbles={[
            { price: <Price price={'1930000'} inline />, comment: 'Есть в наличии' },
            { price: <Price price={'1915000'} inline />, comment: 'Под заказ от 30 дней' },
            { price: <Price price={'1920000'} inline />, comment: 'Есть в наличии' },
            { price: <Price price={'1900000'} inline />, comment: 'Поступление до 7 дней' },
            { price: <Price price={'1895000'} inline />, comment: 'Поступление до 14 дней' },
          ]}
          updateInterval={3000}
        />

        <Advantages
          items={[
            { title: '50 марок\nавтомобилей', text: 'На сайте представлены самые актуальные бренды автопроизводителей' },
            { title: 'Более 120\nдилеров', text: 'Выгодные предложения от официальных дилеров Санкт-Петербурга' },
            { title: 'Скидки\nОт 5 до 20%', text: 'Сокращаем рекламные расходы дилера и получаем выгодную цену' },
            { title: <div>{'Стоимость\nзаявки '}<Price price={'0'} inline /></div>, text: 'Работа с сервисом абсолютно бесплатна для покупателя' },
          ]} />

        <PromotionBanner type={'main'} />

        <Partners data={this.props.data} />

        {this.props.promoItems !== null &&
          Array.isArray(this.props.promoItems) &&
          this.props.promoItems.length > 0 &&
          <PromoPreviews
            items={this.props.promoItems} />
        }

        {this.props.newsItems !== null &&
          Array.isArray(this.props.newsItems) &&
          this.props.newsItems.length > 0 &&
          <NewsPreviews
            items={this.props.newsItems} />
        }

        <About
          items={[
            {
              image: image1,
              icon: <Icon name='searchStar' stroked />,
              iconWidth: '32px',
              title: 'Выбери желаемый автомобиль и создай заявку на сайте',
              text: 'Для получения выгодных предложений от дилеров достаточно указать марку и модель автомобиля. Более выгодные офферы поступают если определен цвет, комплектация и условия покупки.'
            },
            {
              image: image2,
              icon: <Icon name='bubblePercent' stroked />,
              iconWidth: '31px',
              title: 'Просматривай предложения от дилеров в любое удобное время',
              text: 'Все предложения с ценой поступают в твой личный кабинет и хранятся там. Ты можешь в любой момент просматривать информацию по заявке, нужен только доступ в интернет.'
            },
            {
              image: image3,
              icon: <Icon name='scales' stroked />,
              iconWidth: '42px',
              title: 'Сравнивай отзывы и рейтинги потенциальных дилеров по заявке',
              text: 'Сервис дает инструменты влияния на качество работы дилеров. После сделки, покупатель может оценить оффер и работу дилера. Хорошая репутация важна для участников сервиса.'
            },
            {
              image: image4,
              icon: <Icon name='star' stroked />,
              iconWidth: '27px',
              title: 'Определи самое выгодное предложение из списка офферов',
              text: 'Приняв оффер дилера, дождись его подтверждения. Продавец может отклонить предложение если оно уже не действительно. В случае если дилер подтвердил оффер заявка превратится в сделку.'
            },
            {
              image: image5,
              icon: <Icon name='shield' stroked />,
              iconWidth: '42px',
              title: 'Приезжай в салон и забирай свой автомобиль со скидкой',
              text: 'Окно сделки позволяет увидеть контактную информацию продавца. Дилер не заставит себя ждать и свяжется как можно быстрее. Помоги другим пользователям сервиса – оставь отзыв о сделке.'
            },
          ]} />

      </Container>

      <Container background={'#f6fafe'}>
        <PreFooter />
      </Container>
    </div>
  );
}

export default HomeScreen;
