import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { injectStyles } from '~app/theme';
import styles from './LoaderStyles';

@injectStyles(styles)
class Loader extends Component {
  render() {
    const {
      classes
    } = this.props;

    return (
      <span className={classes.root}>
        <span className={cn(classes.bar, classes.barFirst)} />
        <span className={cn(classes.bar, classes.barSecond)} />
      </span>
    );
  }
}

Loader.propTypes = {
  theme: PropTypes.string
};

Loader.defaultProps = {
  theme: 'primary'
};

export default Loader;