import client from './_client';
import { transformListModel } from './_helpers';
import { BannerModel } from './models';

// Get banners
export const getBanners = () => {
  return client.request({
    url: '/v1/banners',
    method: 'GET',
    transformData: data => ({ items: transformListModel(BannerModel)(data) }),
  });
};
