import { mapper, fromInput } from 'demapper';

@mapper
export default class {

  @fromInput('_id')
  id = null

  @fromInput('name')
  name = null

  @fromInput('premium')
  premium = false

  @fromInput('hidden')
  hidden = false

  @fromInput('universal')
  universal = false

  @fromInput('shortDescription')
  shortDescription = null

  @fromInput('fullDescription')
  fullDescription = null

  @fromInput('date', { model: Date })
  date = null

  @fromInput('logo.secure_url')
  logo = null

  @fromInput('address')
  address = null

  @fromInput('phone')
  phone = null

  @fromInput('url')
  url = null

  @fromInput('logo.secure_url')
  pageImage = null

  @fromInput('brands')
  brands = null

}
