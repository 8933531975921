import React, { Component } from 'react';
import { injectStyles } from '~app/theme';
import cn from 'classnames';
import moment from 'moment';

import { Card, CardContent, Icon, Hint, Price } from '~app/components/ui';
import { PackageDetailsModal } from '~app/components';

import styles from './ApplicationCardStyles';

import orderOptions from '~app/config/orderOptions';
import additionalKitOptions from '~app/config/additionalKitOptions';

@injectStyles(styles)
class Option extends Component {
  state = {
    optionHovered: false,
  };

  render() {
    const {
      classes,
      item,
      option
    } = this.props;

    return (
      <div
        className={cn(classes.option, item.options.indexOf(option.id) > -1 ? classes.optionActive : null)}
        onMouseOver={() => { this.setState({ optionHovered: true }); }}
        onMouseLeave={() => { this.setState({ optionHovered: false }); }}
      >
        <div className={classes.optionIcon}>
          {option.icon}
        </div>
        <Hint isActive={this.state.optionHovered}>
          <div className={classes.optionHint}>
            {option.name}
            {option.id === 'credit' && item.loanOption !== null &&
              <div>
                <br />
                {`Взнос: ${item.loanOption} руб`}
              </div>
            }
            {option.id === 'credit' && item.payWithCash !== null &&
              <div dangerouslySetInnerHTML={{
                __html:
                  item.payWithCash ? 'Оплата&nbsp;наличными' : 'Безналичный&nbsp;рассчёт'
              }} />
            }
          </div>
        </Hint>
      </div>
    );
  }
}

@injectStyles(styles)
class ApplicationCard extends Component {

  state = {
    tradeInHovered: false,
    additionalKitHovered: false,
    modalOpened: false,
  };


  renderAdditionalKit = () => {
    const {
      classes,
      item
    } = this.props;

    let optionsValues = [];

    if (item.additionalKit !== null) {
      for (let key in item.additionalKit) {
        if (item.additionalKit[key] && key !== 'comment') {
          try {
            optionsValues.push(additionalKitOptions.filter(option => option.id == key)[0].name);
          } catch (e) { }
        }
      }
    }

    const hintContent = (item.additionalKit &&
      <div className={classes.additionalKitPopup}>
        {optionsValues.length > 0 &&
          <div className={classes.additionalKitPopupValues}>{optionsValues.join(', ')}</div>
        }
        {item.additionalKit.comment && item.additionalKit.comment != '' && [
          <div key={'title'} className={classes.additionalKitPopupTitle}>
            {'Комментарий:'}
          </div>,
          <div className={classes.additionalKitPopupComment} key={'content'}>
            {item.additionalKit.comment}
          </div>
        ]}
      </div>
    );

    return (
      <div className={classes.additionalKitWrapper}>
        <div
          className={cn(classes.tradeIn, item.additionalKit ? classes.tradeInActive : null)}
          onMouseOver={() => { item.additionalKit && this.setState({ additionalKitHovered: true }); }}
          onMouseLeave={() => { item.additionalKit && this.setState({ additionalKitHovered: false }); }}
        >
          <div className={classes.additionalKitIcon}>
            <Icon name={'mosaic'} />
          </div>
          {'Доп. оборудование'}
        </div>
        {item.additionalKit &&
          <React.Fragment>
            <div className={classes.additionalKitPopupDesk}>
              <Hint
                isActive={this.state.additionalKitHovered}
                direction={'left'}>
                {hintContent}
              </Hint>
            </div>
            <div className={classes.additionalKitPopupMob}>
              <Hint
                isActive={this.state.additionalKitHovered}
                direction={'top'}>
                {hintContent}
              </Hint>
            </div>
          </React.Fragment>
        }
      </div>
    );
  }

  renderTradeIn = () => {
    const {
      classes,
      item
    } = this.props;

    const hintContent = (item.tradeIn &&
      <div className={classes.tradeInPopup}>
        <div className={classes.tradeInPopupItem}>
          <div className={classes.tradeInPopupItemName}>{'Марка авто:'}</div>
          <div className={classes.tradeInPopupItemValue}>{item.tradeInData.brand}</div>
        </div>
        <div className={classes.tradeInPopupItem}>
          <div className={classes.tradeInPopupItemName}>{'Модель авто:'}</div>
          <div className={classes.tradeInPopupItemValue}>{item.tradeInData.model}</div>
        </div>
        <div className={classes.tradeInPopupItem}>
          <div className={classes.tradeInPopupItemName}>{'Объем двигателя:'}</div>
          <div className={classes.tradeInPopupItemValue}>{item.tradeInData.engineVolume}</div>
        </div>
        <div className={classes.tradeInPopupItem}>
          <div className={classes.tradeInPopupItemName}>{'Год выпуска:'}</div>
          <div className={classes.tradeInPopupItemValue}>{item.tradeInData.year}</div>
        </div>
        <div className={classes.tradeInPopupItem}>
          <div className={classes.tradeInPopupItemName}>{'Привод:'}</div>
          <div className={classes.tradeInPopupItemValue}>{item.tradeInData.drive}</div>
        </div>
        <div className={classes.tradeInPopupItem}>
          <div className={classes.tradeInPopupItemName}>{'Коробка:'}</div>
          <div className={classes.tradeInPopupItemValue}>{item.tradeInData.gear}</div>
        </div>
        {item.tradeInData.vin &&
          <div className={classes.tradeInPopupItem}>
            <div className={classes.tradeInPopupItemName}>{'VIN:'}</div>
            <div className={classes.tradeInPopupItemValue}>{item.tradeInData.vin}</div>
          </div>
        }
      </div>
    );

    return (
      <div className={classes.tradeInWrapper}>
        <div
          className={cn(classes.tradeIn, item.tradeIn ? classes.tradeInActive : null)}
          onMouseOver={() => { item.tradeIn && this.setState({ tradeInHovered: true }); }}
          onMouseLeave={() => { item.tradeIn && this.setState({ tradeInHovered: false }); }}
        >
          <div className={classes.tradeInIcon}>
            <Icon name={'tick'} />
          </div>
          {'Программа Trade-in'}
        </div>
        {item.tradeIn &&
          <div>
            <div className={classes.tradeInPopupDesk}>
              <Hint
                isActive={this.state.tradeInHovered}
                direction={'left'}
              >
                {hintContent}
              </Hint>
            </div>
            <div className={classes.tradeInPopupMob}>
              <Hint
                isActive={this.state.tradeInHovered}
                direction={'top'}
              >
                {hintContent}
              </Hint>
            </div>
          </div>
        }
      </div>
    );
  }

  renderOptions = () => {
    const {
      classes,
      item
    } = this.props;

    return (
      <div className={classes.options}>
        {orderOptions.map((option, index) => {
          return (
            <div key={index}>
              <Option item={item} option={option} />
            </div>
          );
        })}
      </div>
    );
  }

  renderPriceOrInfo = () => {
    const {
      classes,
      item,
      deal
    } = this.props;

    if (deal) {
      return (
        <div className={classes.price}>
          <Price price={deal.price} />
        </div>
      );
    }
    if (item.acceptedOffer) {
      return (
        <div className={classes.price}>
          <Price price={item.acceptedOffer.price} />
        </div>
      );
    }
    return (
      <div className={classes.offers}>
        <div className={classes.offersIcon}>
          <Icon name={'offer'} />
        </div>
        {`Офферы: ${item.offersCount}`}
      </div>
    );
  }

  closeModal = () => {
    this.setState({
      modalOpened: false,
    });
  }

  openModal = () => {
    this.setState({
      modalOpened: true,
    });
  }

  render() {
    const {
      classes,
      item,
      deal = null,
      hover,
      readonly = true,
    } = this.props;

    const isCustomPackage = item && item.customPackage;

    let deliveryOption = null;
    if (deal !== null && deal.delivery !== null) {
      deliveryOption = deal.delivery.name;
    } else if (item.acceptedOffer !== null && item.acceptedOffer.delivery !== null) {
      deliveryOption = item.acceptedOffer.delivery.name;
    }

    return (
      <div className={classes.wrapper}>
        <Card hover={hover} borderRadius={this.props.borderRadius}>
          <CardContent>
            <div className={classes.root}>

              <div className={cn(classes.image, deliveryOption !== null ? classes.imageWitDelivery : null)}>
                <img src={item.model.image !== null && item.model.image} />
                {deliveryOption !== null &&
                  <div className={classes.delivery}>
                    <div className={classes.deliveryIcon}><Icon name={'calendar'} /></div>
                    {deliveryOption}
                  </div>
                }
              </div>


              <div className={cn(classes.column, classes.columnMain)}>
                <div className={classes.brand}>
                  <div>{item.brand.name}</div>
                  <img className={classes.brandImage} src={item.brand.logoUrl} />
                </div>
                <div
                  className={classes.value}
                  title={item.model.name}
                >
                  {item.model.name}
                </div>
                <div
                  className={cn(classes.value, item.color === null ? classes.valueAny : null)}
                  title={item.color !== null ? item.color.name : ''}
                >
                  {item.color !== null ? item.color.name : 'Любой цвет'}
                </div>
                <div
                  className={cn(classes.value, item.package === null ? classes.valueAny : classes.package, {
                    [classes.valueCustom]: item.package !== null && readonly && isCustomPackage
                  })}
                  onClick={item.package !== null ? this.openModal : null}
                  title={item.package !== null ? item.package.name : ''}
                >
                  <span className={classes.valueText}>{item.package !== null ? item.package.name : 'Любая комплектация'}</span>
                  {item.package !== null && readonly && isCustomPackage &&
                    <div
                      onMouseOver={() => { this.setState({ customPackageHovered: true }); }}
                      onMouseLeave={() => { this.setState({ customPackageHovered: false }); }}
                      className={classes.gear}>
                      <Icon name={'gear'} />
                      <Hint direction={'top'} isActive={this.state.customPackageHovered}>
                        {'Нестандартная комплектация'}
                      </Hint>
                    </div>
                  }
                </div>
              </div>

              <div className={cn(classes.column, classes.columnDetails)}>
                <div className={classes.detailsItem}>
                  <div className={classes.detailsItemName}>{'Мотор:'}</div>
                  <div
                    className={classes.detailsValue}
                    title={item.package !== null && item.package.engineType !== null ? item.package.engineType : ''}
                  >
                    {item.package !== null && item.package.engineType !== null ? item.package.engineType : '-'}
                  </div>
                </div>
                <div className={classes.detailsItem}>
                  <div className={classes.detailsItemName}>{'Л.с.:'}</div>
                  <div
                    className={classes.detailsValue}
                    title={item.package !== null && item.package.hp !== null ? item.package.hp : ''}
                  >
                    {item.package !== null && item.package.hp !== null ? item.package.hp : '-'}
                  </div>
                </div>
                <div className={classes.detailsItem}>
                  <div className={classes.detailsItemName}>{'Привод:'}</div>
                  <div
                    className={classes.detailsValue}
                    title={item.package !== null && item.package.drive !== null ? item.package.drive : ''}
                  >
                    {item.package !== null && item.package.drive !== null ? item.package.drive : '-'}
                  </div>
                </div>
                <div className={classes.detailsItem}>
                  <div className={classes.detailsItemName}>{'Коробка:'}</div>
                  <div
                    className={classes.detailsValue}
                    title={item.package !== null && item.package.gear !== null ? item.package.gear : ''}
                  >
                    {item.package !== null && item.package.gear !== null ? item.package.gear : '-'}
                  </div>
                </div>
              </div>

              <div className={cn(classes.column, classes.columnAdditional)}>
                {this.renderTradeIn()}
                {this.renderAdditionalKit()}
                {this.renderOptions()}
                <div className={classes.additionalBottom}>
                  {this.renderPriceOrInfo()}
                  <div className={classes.date}>
                    <div className={classes.dateIcon}>
                      <Icon name={'clock'} />
                    </div>
                    {item.createdDate !== null && moment(item.createdDate).format('DD.MM.YY в HH:mm')}
                  </div>
                </div>
                {deliveryOption !== null &&
                  <div className={cn(classes.delivery, classes.deliveryMobile)}>
                    <div className={classes.deliveryIcon}><Icon name={'calendar'} /></div>
                    {deliveryOption}
                  </div>
                }

              </div>

            </div>
          </CardContent>
        </Card>
        {item.package !== null &&
          <PackageDetailsModal
            isOpen={this.state.modalOpened}
            onRequestClose={this.closeModal}
            item={item.package}
            customPackage={item.customPackage}
          />
        }
      </div>
    );
  }
}

export default ApplicationCard;
