export default (theme) => ({
  icon: {
    width: '100%',
    height: '1em',
    display: 'inline-flex',
    lineHeight: 0,
    alignItems: 'center',
    '& svg': {
      width: '100%',
      fill: 'currentColor',
    }
  },
  block: {
    display: 'block',
    height: '100%'
  },
  stroked: {
    '& svg': {
      stroke: 'currentColor',
    }
  }
});
