export default (theme) => ({
  lander: {},
  withoutCarousel: {},
  root: {
    padding: '20px 0 83px',
    margin: '0 -15px',
    width: 'calc(100% + 30px)',
    '&$lander': {
      paddingTop: 40,
      paddingBottom: 20,
    },
    '&$withoutCarousel' : {
      paddingBottom: 10
    }
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#282828',
    margin: '0 15px 34px',
    '&$lander': {
      textAlign: 'center',
      fontSize: 30
    },
  },
  pagination: {},
  page: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    paddingBottom: 30,
    '&$lander': {
      justifyContent: 'center',
    },
  },
  item: {
    margin: '0 15px 30px',
    width: 'calc(25% - 30px)',
    height: 80,
    padding: 15,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 0,
      top: 0,
      left: 4,
      width: 'calc(100% - 8px)',
      height: '100%',
      borderRadius: 6,
      boxShadow: '0 8px 14px 0 rgba(60, 60, 60, 0.08)',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 6,
      backgroundColor: '#ffffff',
      boxShadow: '0 6px 20px 0 rgba(20, 100, 220, 0.09)',
    },
    '&:hover': {
      filter: 'grayscale(0%)',
    },
    '&:hover $img': {
      opacity: 1,
    },
  },
  itemLink: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 5,
  },
  img: {
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    transition: 'opacity .5s ease',
    opacity: .6,
    position: 'relative',
    zIndex: 2,
  },
  bullet: {
    width: 9,
    height: 9,
    border: '1px solid #fac81e',
    borderRadius: '50%',
    backgroundColor: 'transparent',
    transition: 'background-color .3s ease',
    opacity: 1,
  },
  bulletActive: {
    backgroundColor: '#fac81e'
  },

  [theme.queries.mdDevicesDown]: {
    root: {
      paddingTop: 14,
      paddingBottom: 68,
      margin: '0 -8px',
      width: 'calc(100% + 16px)',
    },
    title: {
      fontSize: 24,
      lineHeight: 1.5,
      margin: '0 8px 12px'
    },
    item: {
      margin: '0 8px 12px',
      width: 'calc(50% - 16px)',
      height: 64,
      padding: 10,
    }
  },
  [theme.queries.lgDevicesUp]: {
  },
  [theme.queries.lgDevicesOnly]: {
  },
});
