import React, { Component } from 'react';

import { Helmet } from 'react-helmet';


import { Container, Loader, Time } from '~app/components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './NewsScreenStyles';

import cn from 'classnames';

import actions from '~app/redux/actions';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import InfiniteScroll from 'react-infinite-scroller';

// import moment from 'moment';
import PreFooter from '../HomeScreen/Sections/PreFooter/PreFooter';
import { HeroSlider } from '~app/components/News';

const mapStateToProps = (state, { reduxKey = 'dealersNews' }) => {
  let newsState = {
    items: null,
    paging: null,
    inProgress: false,
    error: null
  };

  let generalNewsState = newsState;

  if (reduxKey in state.news.newsByKey) {
    newsState = state.news.newsByKey[reduxKey];
  }

  if ('generalNews' in state.news.newsByKey) {
    generalNewsState = state.news.newsByKey.generalNews;
  }

  return {
    reduxKey,
    items: newsState.items,
    paging: newsState.paging,
    inProgress: newsState.inProgress,
    error: newsState.error,

    generalNewsItems: generalNewsState.items,
    generalNewsInProgress: generalNewsState.inProgress,
    generalNewsError: generalNewsState.error,

  };
};

@connect(mapStateToProps)
@injectStyles(styles)
class NewsScreen extends Component {

  state = {
    currentPage: 1,
    lock: false,
    items: null,
  };

  static async fetchData({ store, params }) {
    await Promise.all([
      actions.news.getNewsByKey('dealersNews', {
        type: 'withDealer',
        limit: 8,
      }),
      actions.news.getNewsByKey('generalNews', {
        limit: 4,
        type: 'withoutDealer',
      })
    ]);
  }

  componentDidMount() {
    this.loadDealerNews();
    this.loadGeneralNews();
  }

  loadDealerNews = () => {
    actions.news.getNewsByKey(this.props.reduxKey, {
      page: this.state.currentPage,
      limit: 8,
      type: 'withDealer',
    });
  }

  loadGeneralNews = () => {
    actions.news.getNewsByKey('generalNews', {
      page: 1,
      limit: 4,
      type: 'withoutDealer',
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inProgress && !this.props.inProgress) {
      this.setState({ lock: false }, () => {
        if (this.props.error === null && Array.isArray(this.props.items)) {
          this.setState(prevState => ({
            items: prevState.items !== null ?
              [...prevState.items, ...this.props.items] :
              this.props.items
          }));
        }
      });
    }
  }

  reloadData = () => {
    this.loadDealerNews();
  }

  loadMore = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    // infinity scroll шлет сразу несколько запросов асинхронно, из-за этого в did update
    // не поймаешь их по inProgress. приходится лочить через state.

    if (this.state.lock) {
      return;
    }

    this.setState(prevState => ({ lock: true, currentPage: prevState.currentPage + 1 }), () => {
      this.reloadData();
    });

  }

  renderItems() {
    const {
      items,
    } = this.state;

    const { classes } = this.props;

    return items !== null &&
      Array.isArray(items) &&
      items.map(item =>
        <Link to={`/news/${item.id}`} className={classes.item} key={item.id}>
          {item.image !== null &&
            <div className={classes.itemLeft}>
              <div
                className={classes.image}
                style={{ backgroundImage: `url(${item.image})` }} />
              {item.dealer !== null && item.dealer.logo !== null &&
                <div className={classes.dealerLogo}>
                  <div
                    style={{ backgroundImage: `url(${item.dealer.logo})` }}
                    className={classes.dealerLogoContent} />
                </div>
              }
            </div>
          }
          <div className={classes.itemRight}>
            {item.name !== null &&
              <h3 className={cn(classes.name, classes.big)}>{item.name}</h3>
            }
            {item.shortDescription !== null &&
              <p className={classes.descr}>{item.shortDescription}</p>
            }
            {item.date !== null &&
              <Time date={item.date} />
            }
          </div>
        </Link>
      );
  }

  render() {
    const {
      classes,
      inProgress,
      paging,
      generalNewsItems,
    } = this.props;

    const {
      items,
      lock,
    } = this.state;

    const hasMore = paging !== null && items !== null ?
      items.length < paging.totalItems : false;

    return (
      <React.Fragment>
        <Helmet>
          <title>{'Avicar - Новости дилеров'}</title>
        </Helmet>
        <Container background={'white'} fullHeight>
          <div className={classes.root}>
            <div className={classes.title}>{'Новости дилеров'}</div>
            {items !== null &&
              Array.isArray(items) &&
              items.length > 0 &&
              <HeroSlider place={'news'} items={items.slice(0, 4)} />
            }
            <div className={classes.content}>
              <div className={classes.left}>
                <div className={classes.desktop}>
                  <InfiniteScroll
                    pageStart={1}
                    loadMore={this.loadMore}
                    hasMore={!inProgress && !lock && hasMore}
                    loader={<Loader key={'loader'} />}>
                    {this.renderItems()}
                  </InfiniteScroll>
                </div>
                <div className={classes.mobile}>
                  {this.renderItems()}
                  {hasMore &&
                    <a onClick={this.loadMore} className={classes.showMore}>
                      {'Больше новостей'}
                    </a>
                  }
                </div>
              </div>
              <div className={classes.divider} />
              <div className={classes.right}>
                <div className={classes.title}>{'Новости рынка'}</div>
                {generalNewsItems !== null &&
                  Array.isArray(generalNewsItems) &&
                  generalNewsItems.map(item =>
                    <Link
                      to={`/news/${item.id}`}
                      key={item.id}
                      className={classes.generalItem}>
                      {item.name !== null &&
                        <h3 className={classes.name}>{item.name}</h3>
                      }
                      {item.date !== null &&
                        <Time date={item.date} />
                      }
                    </Link>
                  )
                }
              </div>
            </div>
          </div>
        </Container>
        <Container >
          <PreFooter />
        </Container>
      </React.Fragment>
    );
  }
}

export default NewsScreen;
