import React, { Component } from 'react';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import { Icon, Container } from '~app/components/ui';
import styles from '~app/components/FooterLander/FooterLanderStyles';

@injectStyles(styles)

class FooterLander extends Component {
  render = () => {
    const {
      classes,
      agreement,
      copyright,
      description,
      header
    } = this.props;

    return (
      <div className={classes.root}>
        <Container>
          <div className={classes.content}>
            <div className={cn(classes.col, classes.colLogo)}>
              <div className={cn(classes.logo, classes.desktop)}>
                <Icon block className={classes.logoIcon} name={'footerCheck'} />
              </div>
            </div>
            <div className={cn(classes.col, classes.colText)}>
              <div className={cn(classes.logo, classes.mobile)}>
                <Icon block className={classes.logoIcon} name={'footerCheck'} />
              </div>
              {header &&
                <h3 className={classes.text}>
                  {header}
                </h3>
              }
              {description &&
                <p className={cn(classes.text, classes.transparent)}>
                  {description}
                </p>
              }
            </div>
            <div className={classes.bottom}>
              <div className={cn(classes.col, classes.colCopyright)}>
                {copyright &&
                  <div className={classes.copyright}>{copyright}</div>
                }
              </div>
              <div className={cn(classes.col, classes.colRules)}>
                {agreement &&
                  <a href={'/static/files/agreement.pdf'} target={'_blank'} className={classes.rules}>{agreement}</a>
                }
              </div>
              <div className={cn(classes.col, classes.colMadeby)}>
                <span>{'Made by '}</span>
                <a href='https://www.behance.net/Choose_design' target={'_blank'} className={classes.madeBy}>{'Choose.'}</a>
                <p className={classes.developed}>
                  <span>{'Developed by '}</span>
                  <a href="https://initflow.com" target={'_blank'} rel={'noopener'} className={classes.developedLink}>Initflow</a>
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default FooterLander;
