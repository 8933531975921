import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '~app/redux/actions';
import { injectStyles } from '~app/theme';
import { push } from 'connected-react-router-exposed';

import { Container, Icon, Breadcrumbs, Spacer } from '../../../components/ui';
import { UserProfileCard, DealCard, MessageCard } from '../../../components';

import { styles } from './CabinetUserDealScreenStyles';


const messages = [];


const mapStateToProps = (state, ownProps) => {
  const offerId = ownProps.match.params.id;

  let itemState = {
    item: null,
    inProgress: false,
    error: null
  };
  if (offerId in state.offers.itemById) {
    itemState = state.offers.itemById[offerId];
  }

  return {
    offerId,
    ...itemState
  };
};

const mapDispatchToProps = {
  push
};

@connect(mapStateToProps, mapDispatchToProps)
@injectStyles(styles)
class CabinetUserDealScreen extends Component {

  componentDidMount = () => {
    this.loadData();
  }

  loadData = (props = this.props) => {
    actions.offers.getItemById(this.props.offerId);
  }

  archiveOnClick = () => {
    const offerId = this.props.item !== null ? this.props.item.id : null;

    if (offerId === null) {
      return;
    }

    actions.common.openModal({
      type: 'archive',
      params: {
        offerId,
        title: 'Убрать в архив',
        text: 'Сделка и контактная информация будут храниться в архиве до момента удаления.',
        btnText: 'В архив',
        onSuccess: () => {
          this.props.push('/cabinet/deals');
        }
      }
    });

  }

  getDefaultMessage = () => {
    let name = '...';
    let date = null;
    if (this.props.item !== null) {
      name = this.props.item.dealer.fullName;
      date = this.props.item.updatedDate;
    }
    return {
      name,
      text: 'Здравствуйте! Рады, что выбрали именно наше предложение. Наш менеджер выйдет на связь в ближайшее время.  Ожидайте звонка!',
      date,
    };
  }

  onReviewCreated = () => {
    this.loadData();
  }

  renderMessages = () => {
    return (
      <div>
        <MessageCard
          item={this.getDefaultMessage()}
          offer={this.props.item}
          onReviewCreated={this.onReviewCreated}
        />
        {messages.map((item, index) => {
          return (
            <div key={index}>
              <MessageCard item={item} />
              <Spacer v={12} />
            </div>
          );
        })}
      </div>
    );
  }

  render = () => {
    const {
      classes,
      item,
    } = this.props;

    return (
      <div className={classes.root}>
        <Container>
          <div className={classes.head}>
            <Breadcrumbs
              options={[
                {
                  href: '/',
                  name: 'Главная',
                },
                {
                  href: '/cabinet',
                  name: 'Мой кабинет',
                },
                {
                  href: '/cabinet/deals',
                  name: 'Мои сделки',
                }
              ]}
            />
            {item !== null && !item.archivedByUser &&
              <div
                onClick={this.archiveOnClick}
                className={classes.headLink}
              >
                <div className={classes.headLinkIcon}>
                  <Icon name={'timesThinest'} />
                </div>
                {'В архив'}
              </div>
            }
          </div>

          {item !== null &&
            <div className={classes.headCard}>
              <UserProfileCard
                dealer={item.dealer}
              />
              <div className={classes.headDevider} />
              {/* todo переделать */}
              <DealCard
                item={item}
                borderRadius={'0 0 6px 6px'}
                showActions={false}
                readonly={true}
              />
            </div>
          }

          <div className={classes.body}>
            {this.renderMessages()}
          </div>

        </Container>
      </div>
    );
  }
}

export default CabinetUserDealScreen;
