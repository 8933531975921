import { mapper, fromInput } from 'demapper';
import { PreFooterModel } from '~app/network/mainClient/models';

@mapper
export default class {
  @fromInput('_id')
  id = null;

  @fromInput('name')
  name = null;

  @fromInput('footer')
  footer = null;

  @fromInput('preFooter', { transform: x => new PreFooterModel(x) })
  preFooter = null;

  @fromInput('kupleno')
  kupleno = null;

  @fromInput('modelChoose')
  modelChoose = null;

  @fromInput('advantages', {
    transform: x => x.map(item => ({
      header: item.split(' | ')[0],
      description: item.split(' | ')[1],
    }))
  })
  advantages = null;

  @fromInput('landingAdvantages')
  landingAdvantages = null;

  @fromInput('hero')
  hero = null;

  @fromInput('steps')
  steps = null;

  @fromInput('header')
  header = null;

}
