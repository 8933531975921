import { mapper, fromInput } from 'demapper';

// import ModelBaseModel from '../Model/ModelBaseModel';
// import PackageBaseModel from '../Package/PackageBaseModel';
// import DeliveryBaseModel from '../Delivery/DeliveryBaseModel';

@mapper
export default class {

  @fromInput('_id')
  id = null;

  @fromInput('text')
  text = null;

  @fromInput('positive')
  positive = false;

  @fromInput('createdAt', { model: Date })
  createdDate = null;

  @fromInput('model', { transform: ({ name, brandname }) => `${brandname} ${name}` })
  modelName = null;

  @fromInput('user', { transform: ({ name, surname }) => `${name} ${surname}` })
  authorName = null;
}
