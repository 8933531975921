import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import { Spacer } from '~app/components/ui';
import { styles } from './ButtonStyles';

@injectStyles(styles)
class Button extends Component {
  render() {
    const {
      text,
      classes,
      theme,
      variant,
      size,
      onClick,
      leftIcon,
      rightIcon,
      active,
      expandable,
      placeholder,
      unactive,
    } = this.props;

    return (
      <div
        className={cn(
          classes.root, {
            [classes._rare]: theme === 'rare',
            [classes._brand]: theme === 'brand',
            [classes._general]: theme === 'general',
            [classes._accent]: theme === 'accent',
            [classes._transparent]: variant === 'transparent',
            [classes._pressed]: active === true,
            [classes._unactive]: unactive === true,
            [classes._small]: size === 'sm',
          },
          expandable ? classes.expandable : null,
          expandable === 'right' ? classes.expandableRight : null)}
      >

        <button className={classes.button} onClick={onClick}>
          {(placeholder && expandable === 'left') && <div className={classes.placeholder}>{placeholder}</div>}
          {leftIcon !== null &&
            <span className={classes.icon}>
              {leftIcon}
              <Spacer h={8} />
            </span>
          }
          <span className={classes.buttonBackground}></span>
          <span className={classes.buttonText}>{text}</span>
          {rightIcon !== null &&
            <span className={classes.icon}>
              <Spacer h={8} />
              {rightIcon}
            </span>
          }
          {(placeholder && expandable === 'right') && <div className={classes.placeholder}>{placeholder}</div>}
        </button>
        {/* <span className={classes.loader}>
          <Loader />
        </span> */}
      </div>
    );
  }
}

Button.propTypes = {
  theme: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md']),
  text: PropTypes.node,
  block: PropTypes.bool,
  mobileBlock: PropTypes.bool,
  // loading: PropTypes.bool,
  onClick: PropTypes.func,
  leftIcon: PropTypes.object,
  rightIcon: PropTypes.object,
  active: PropTypes.bool,
  expandable: PropTypes.oneOf(['left', 'right']),
  placeholder: PropTypes.object,
  unactive: PropTypes.bool,
};

Button.defaultProps = {
  theme: 'brand',
  variant: '',
  size: 'md',
  text: '',
  block: false,
  mobileBlock: false,
  // loading: false,
  onClick: null,
  leftIcon: null,
  rightIcon: null,
  active: false,
  expandable: null,
  placeholder: null,
  unactive: false,
};

export default Button;
