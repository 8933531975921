export const initialState = {
  sendInProgress: false,
  sendSuccess: null,
  sendError: null,
};

// Send message
export const sendMessageRequest = (state) => ({
  ...state,
  sendInProgress: true,
  sendSuccess: null,
  sendError: null
});
export const sendMessageSuccess = (state) => ({
  ...state,
  sendInProgress: false,
  sendSuccess: true,
  sendError: null
});
export const sendMessageFailure = (state, payload) => ({
  ...state,
  sendInProgress: false,
  sendSuccess: null,
  sendError: payload
});

// Reset
export const resetChatState = (state) => ({ ...initialState });
