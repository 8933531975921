import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectStyles } from '~app/theme';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { Button, Icon } from '~app/components/ui';
import { YoutubeModal } from '~app/components';

import PropTypes from 'prop-types';
import styles from './BannerStyles';

const mapStateToProps = (state, ownProps) => {
  return {};
};

@connect(mapStateToProps)
@injectStyles(styles)
class Banner extends Component {

  state = {
    currentBubbleIndex: 0,
    videoModalOpened: false
  }

  componentDidMount = () => {
    const intervalId = setInterval(this.updateBubbles, this.props.updateInterval || 5000);
    this.setState({ intervalId: intervalId });
  }

  componentWillUnmount = () => {
    clearInterval(this.state.intervalId);
  }

  updateBubbles = () => {
    const {
      currentBubbleIndex,
    } = this.state;
    const {
      bubbles
    } = this.props;

    this.setState({
      currentBubbleIndex: currentBubbleIndex + 1 >= bubbles.length ? 0 : currentBubbleIndex + 1,
    });
  }

  openVideoModal = () => {
    this.setState({ videoModalOpened: true });
  }

  closeVideoModal = () => {
    this.setState({ videoModalOpened: false });
  }

  renderBubbles = () => {
    const {
      classes,
      bubbles,
    } = this.props;
    const {
      currentBubbleIndex,
    } = this.state;

    return bubbles.map((bubble, index) => (
      <div key={index} className={cn(classes.bubble, currentBubbleIndex === index ? classes.bubble_shown : classes.bubble_hidden)}>
        <div className={classes.bubblePrice}>
          {bubble.price}
        </div>
        <div className={classes.bubbleComment}>
          {bubble.comment}
        </div>
      </div>
    ));
  }
  render() {
    const {
      classes,
      style,
      title
    } = this.props;

    const { videoModalOpened } = this.state;

    return (
      <div className={cn(classes.banner, {[classes._brand]: style === 'brand'})}>
        <div className={classes.title}>
          {title}
        </div>
        <div className={classes.subtitle}>
          <div className={classes.subtitleLine}>
            {'Эксклюзивные цены от дилеров!'}
          </div>
          {style == 'default' ?
            <a
              className={cn(classes.subtitleLine, classes.howItWorksLink)}
              onClick={this.openVideoModal}>
              <div className={classes.subtitleLineIcon}>
                <Icon name={'video'} stroked />
              </div>
              {'Видео как это работает?'}
            </a>
            : null
          }
        </div>
        <div className={classes.button}>
          <Link to={'/application'}>
            <Button
              mobileBlock
              block
              theme={ style == 'default' ? 'brand' : 'accent'}
              text={'Создать заявку'}
              rightIcon={<Icon name={'arrowRight'} stroked />}
            />
          </Link>
        </div>
        {this.renderBubbles()}
        <div className={classes.car}>
        </div>
        <div className={classes.man}>
        </div>
        <YoutubeModal
          id={'0JKNB6O0bsE'}
          isOpen={videoModalOpened}
          onRequestClose={this.closeVideoModal}
          autoplay
        />
      </div>
    );
  }
}

Banner.propTypes = {
  style: PropTypes.oneOf(['default', 'brand']),
  title: PropTypes.string,
};

Banner.defaultProps = {
  style: 'default',
  title: 'Новые автомобили со скидкой до 20%'
};

export default Banner;
