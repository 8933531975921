import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import { styles } from './CardContentStyles';

@injectStyles(styles)
class CardContent extends Component {
  render () {
    const {
      classes,
      children,
      leftIcon,
    } = this.props;

    return (
      <div className={cn(classes.root, {
        [classes.leftIcon]: leftIcon
      })}>
        {children}
      </div>
    );
  }
}

CardContent.propTypes = {
  leftIcon: PropTypes.bool,
};

CardContent.defaultProps = {
  leftIcon: false,
};

export default CardContent;
