export const initialState = {
  contentByBrandData: {},
  contentByBrandInProgress: false,
  contentByBrandSuccess: null,
  contentByBrandError: null,

  contentByModelData: {},
  contentByModelInProgress: false,
  contentByModelSuccess: null,
  contentByModelError: null,

};

export const getContentByBrandRequest = (state) => ({
  ...state,
  contentByBrandInProgress: true,
  contentByBrandSuccess: null,
  contentByBrandError: null
});

export const getContentByBrandSuccess = (state, { data }) => ({
  ...state,
  contentByBrandData: data,
  contentByBrandInProgress: false,
  contentByBrandSuccess: true,
  contentByBrandError: null
});

export const getContentByBrandFailure = (state, { error }) => ({
  ...state,
  contentByBrandInProgress: false,
  contentByBrandSuccess: false,
  contentByBrandError: error
});


export const getContentByModelRequest = (state) => ({
  ...state,
  contentByModelInProgress: true,
  contentByModelSuccess: null,
  contentByModelError: null
});

export const getContentByModelSuccess = (state, { data }) => ({
  ...state,
  contentByModelData: data,
  contentByModelInProgress: false,
  contentByModelSuccess: true,
  contentByModelError: null
});

export const getContentByModelFailure = (state, { error }) => ({
  ...state,
  contentByModelInProgress: false,
  contentByModelSuccess: false,
  contentByModelError: error
});

