import React from 'react';

import { Helmet } from 'react-helmet';

import { Container } from '~app/components/ui';
import { HeaderLander, FooterLander, Partners, LayoutGeneral } from '~app/components';

import BrandsBanner from '~app/components/BrandsBanner/BrandsBanner';
// import ModelsBanner from '~app/components/ModelsBanner/ModelsBanner';
import LanderIconsBlock from '~app/components/LanderIconsBlock/LanderIconsBlock';
import PromoBanner from '~app/components/PromoBanner/PromoBanner';
import CarCarousel from '~app/components/CarCarousel/CarCarousel';
import RequestLanderForm from '~app/components/RequestLanderForm/RequestLanderForm';

import { triggersIcons, roadmapIcons, requestIcons } from '~app/config/landersConfig';

// import { push } from 'react-router-redux';

import { connect } from 'react-redux';
import actions from '~app/redux/actions';
import NotFoundScreen from '../NotFoundScreen/NotFoundScreen';

const mapStateToProps = ({ lander, partners }) => ({
  data: lander.contentByModelData,
  inProgress: lander.contentByModelInProgress,
  success: lander.contentByModelSuccess,
  error: lander.contentByModelError,
  partnersData: partners.partnersByBrandsData,
});

@connect(mapStateToProps)

class ModelsLanderScreen extends React.Component {

  static async fetchData({ store, params }) {
    const classId = params.class.split('-class')[0];
    await Promise.all([
      actions.lander.getContentByModel({ ...params, class: classId }),
      // actions.partners.getPartnersByBrands([params.brand]),
    ]);
  }

  componentDidMount() {
    const classId = this.props.match.params.class.split('-class')[0];
    actions.lander.getContentByModel({ ...this.props.match.params, class: classId });
    // actions.partners.getPartnersByBrands([this.props.match.params.brand]);
  }

  componentWillReceiveProps(next) {
    // if (next.error) {
    //   this.props.dispatch(push('/404'));
    // }
    if (this.props.inProgress && !next.inProgress && next.success) {
      if (next.data && next.data.brand && next.data.brand.id) {
        actions.partners.getPartnersByBrands([next.data.brand.id]);
      }
    }
  }

  render() {
    const { partnersData, error } = this.props;

    const { content = {}, brand, advantages, similarModels } = this.props.data;

    const configuration = this.props.data.package;

    const {
      header = null,
      // hero = null,
      landingAdvantages = null,
      steps = null,
      preFooter = null,
      footer = null,
    } = content;

    return (
      error ?
        <LayoutGeneral>
          <NotFoundScreen />
        </LayoutGeneral>
        :
        <React.Fragment>
          {brand && configuration &&
            <Helmet>
              <title>{brand.name} {configuration.model.name} от {configuration.price}₽ в Петербурге</title>
              <meta name={'description'} content={`Новый ${brand.name} ${configuration.model.name} с выгодой до ${configuration.discountValue}₽. Все комплектации от официальных дилеров в наличии и под заказ.`} />
            </Helmet>
          }
          {brand &&
            <HeaderLander
              city={header.city}
              iconText1={header.iconText1}
              iconText2={header.iconText2}
              phone={header.phone}
              brandLogoUrl={brand.logoUrl} />
          }
          {header && brand && configuration &&
            <BrandsBanner
              title={`${brand.name} ${configuration.model.name} от дилеров Санкт-Петербурга`}
              subtitle={`Оставьте заявку и получите персональное предложение от официального дилера ${brand.name} прямо сейчас`}
              text={'Все комплектации в наличие или под заказ на одном сайте. Делай заявку, это просто!'}
              bgUrl={brand.heroUrl}
              brandName={brand.name}
              iconText1={header.iconText1}
              iconText2={header.iconText2}
              invertedTextColor={brand.invertedTextColor}
            />
          }
          {/* header && brand && configuration &&
          <ModelsBanner
            brandName={brand.name}
            modelName={configuration.model.name}
            iconText1={header.iconText1}
            iconText2={header.iconText2}
            bgUrl={configuration.model.heroUrl}
          />
        */}
          <Container background={'#fff'}>
            {partnersData &&
              partnersData.items &&
              partnersData.items.length > 0 &&
              <Partners lander data={partnersData} />
            }
            {landingAdvantages &&
              <LanderIconsBlock
                texts={landingAdvantages}
                icons={triggersIcons} />}
            {brand && configuration &&
              <RequestLanderForm
                fromModelPage
                brand={brand}
                model={configuration.model}
                advantages={advantages}
                icons={requestIcons}
              />
            }
            {steps &&
              <LanderIconsBlock
                variant={'left'}
                dividers={false}
                texts={steps}
                icons={roadmapIcons} />
            }
            {similarModels && brand &&
              <CarCarousel
                title={'Эти автомобили уже купили на нашей платформе с выгодой'}
                content={similarModels.map(item => ({
                  brand: item.brand,
                  brandImg: item.brandLOgo,
                  model: item.name,
                  img: item.image,
                  package: item.package,
                  discountPercent: item.discountPercent,
                  discountValue: item.discountValue,
                  color: item.colorName,
                  colorId: item.colorId,
                  packageId: item.packageId,
                  modelId: item.modelId,
                }))}
              />
            }
          </Container>
          {preFooter && brand &&
            <PromoBanner
              brandName={brand.name}
              image={preFooter.image}
              header={preFooter.header}
              buttonText={preFooter.buttonText}
              description={preFooter.description} />
          }
          {footer &&
            <FooterLander
              agreement={footer.agreement}
              copyright={footer.copyright}
              description={footer.description}
              header={footer.header}
            />}
        </React.Fragment>
    );
  }
}

export default ModelsLanderScreen;
