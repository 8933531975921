export const styles = theme => ({
  root: {
    display: 'flex',
    height: '100%',
    paddingBottom: '40px',
    
    alignItems: 'flex-end',
    overflow: 'hidden'
  },

  map: {
    position: 'absolute',
    top: 'calc(-100% - 250px)',
    right: '0',
    bottom: '-100%',
    left: '0',

    backgroundColor: '#e5e5e5',

    '& iframe': {
      width: '100%',
      height: '100%',
      border: 'none',
    }
  },

  card: {
    width: '100%',
  },

  head: {
    display: 'flex',

    alignItems: 'baseline',
  },
  title: {
    paddingRight: '20px',

    fontSize: '36px',
    fontWeight: '700',
    lineHeight: '48px',
  },
  time: {
    fontSize: '18px',

    '& span': {
      marginRight: '4px',
    },
  },

  body: {
    padding: '35px 0',
  },
  itemTitle: {
    paddingBottom: '6px',

    fontSize: '12px',
    lineHeight: '20px',

    color: theme.colors.gray.main,
  },
  itemContent: {
    fontSize: '16px',
    lineHeight: '20px',

    '& a': {
      fontWeight: '400',
    }
  },

  bottom: {
    paddingTop: '5px',
    paddingBottom: '11px',

    fontSize: '12px',
    lineHeight: '20px',

    color: theme.colors.gray.main,
  },


  [theme.queries.lgDevicesUp]: {

    root: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },

    head: {
      paddingTop: '8px',
    },

    body: {
      display: 'flex',

      justifyContent: 'space-between',
    },

    item: {
      width: 'calc(25% - 60px/4)',
      maxWidth: '200px',
    },
  },

  [theme.queries.mdDevicesDown]: {

    root: {
      position: 'relative',
    },

    card: {
      marginTop: '340px',
    },

    title: {
      fontSize: '24px',
    },

    body: {
      padding: '28px 0',
    },

    item: {
      '& + &': {
        paddingTop: '18px',
      },
    },

    itemTitle: {
      paddingBottom: '2px',
    },

    bottom: {
      paddingTop: 0,
    },
  },
});