import React, { Component } from 'react';
import { connect } from 'react-redux';

import actions from '~app/redux/actions';
import { GenericModal } from '~app/components';
import { Button, Icon } from '~app/components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './RefuseOfferModalStyles';

const mapStateToProps = (state, { offerId = null }) => {
  
  let inProgress = false;
  let success = false;
  let error = null;

  if (offerId !== null && state.offers.refuseId === offerId) {
    inProgress = state.offers.refuseInProgress;
    success = state.offers.refuseSuccess;
    error = state.offers.refuseError;
  }

  return {
    inProgress,
    success,
    error,
    
    refuseOfferInProgress: state.offers.refuseInProgress,
  };
};


@connect(mapStateToProps)
@injectStyles(styles)
class RefuseOfferModal extends Component {
  
  componentWillReceiveProps(nextProps) {
    if (!this.props.success && nextProps.success) {
      this.onSuccess();
    }
  }

  onSuccess = () => {
    this.props.onSuccess();
  }

  refuse = () => {
    const {
      offerId = null,
    } = this.props;

    if (!this.props.inProgress && !this.props.refuseOfferInProgress) {
      actions.offers.refuseItem(offerId);
    }
  }

  render() {
    const {
      classes,
      isOpen,
      onRequestClose,
    } = this.props;

    return (
      <GenericModal
        open={isOpen}
        title={'Отменить сделку'}
        text={'Ты сможешь выбрать другое предложение. Хочешь отменить сделку?'}
        theme={'general'}
        onRequestClose={onRequestClose}
        additionalContent={
          <div className={classes.button}>
            <Button 
              mobileBlock
              theme={'rare'}
              text={'Отменить'}
              leftIcon={<Icon name={'times'}/>}
              onClick={this.refuse}
              block
            />
          </div>
        }
      />
    );
  }
}

export default RefuseOfferModal;