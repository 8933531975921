export const styles = theme => ({
  root: {
    margin: '0 0 38px',
  },
  content: {
    width: '100%',
    minHeight: '430px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '50px 100px',
    position: 'relative',
  },
  image: {
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: 6,
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
      boxShadow: '0 2px 4px 0 rgba(60, 60, 80, 0.08)',
      backgroundColor: '#1a1b1d',
      opacity: .5,
    }
  },
  // dealerLogo: {
  //   zIndex: 1,
  //   position: 'absolute',
  //   top: 30,
  //   left: 60,
  //   width: 137,
  //   height: 65,
  //   borderRadius: 6.5,
  //   boxShadow: '0 8px 21px 0 rgba(20, 100, 220, 0.09)',
  //   backgroundColor: '#ffffff',
  //   padding: '20px 10px',
  //   display: 'flex',
  // },
  // dealerLogoContent: {
  //   backgroundPosition: '50% 50%',
  //   backgroundSize: 'contain',
  //   backgroundRepeat: 'no-repeat',
  //   width: '100%',
  //   height: '100%',
  // },
  label: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: '#ffffff',
    position: 'relative',
    zIndex: 1,
    margin: '0 0 7px',
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#ffffff',
    maxWidth: 970,
    margin: '0 0 30px',
    position: 'relative',
    zIndex: 1,
  },
  descr: {
    position: 'relative',
    zIndex: 2,
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: '#ffffff',
  },

  footer: {

  },
  promoDescr: {
    maxWidth: 970,
    width: '100%',
    margin: '0 0 40px',
    position: 'relative',
    zIndex: 1,
  },
  html: {
    marginBottom: 0,
    fontSize: 18,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.56,
    letterSpacing: 'normal',
    color: '#fff',
  },

  linkDealer: {
    position: 'relative',
    zIndex: 1,

    fontSize: '14px',
    lineHeight: '20px',
    textDecoration: 'underline',

    color: '#fff',
  },
  
  [theme.queries.lgDevicesUp]: {
    link: {
      marginRight: 17,
    }
  },

  [theme.queries.mdDevicesDown]: {
    root: {
      marginBottom: 10,
    },
    content: {
      padding: '20px 20px 30px',
    },
    // dealerLogo: {
    //   top: 20,
    //   left: 20,
    //   width: 122,
    //   height: 68,
    //   padding: '18px 15px',
    // },
    title: {
      fontSize: 24,
      lineHeight: 1.5,
    },

    footer: {
      width: '100%',
    },

    linkDealer: {
      display: 'block',
      marginTop: '24px',

      textAlign: 'center',
    },
  },
});
