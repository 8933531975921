export default (theme) => ({
  root: {
    position: 'absolute',
    left: props => props.direction == 'bottom' || props.direction == 'top' ? '50%' : (
      props.direction == 'left' ? 0 : 'auto'
    ),
    right: props => props.direction == 'right' ? 0 : 'auto',
    top: props => props.direction == 'left' || props.direction == 'right' ? '50%' : (
      props.direction == 'top' ? 0 : 'auto'
    ),
    bottom: props => props.direction == 'bottom' ? 0 : 'auto',
    zIndex: 2,
    opacity: 0,

    transition: 'opacity .5s ease, transform .5s ease',
    transform: props => props.direction == 'top' ? 'translate(-50%, -120%)' : (
      props.direction == 'right' ? 'translate(120%, -50%)' : (
        props.direction == 'bottom' ? 'translate(-50%, 120%)' : (
          props.direction == 'left' && 'translate(-120%, -50%)'
        )
      )
    ),

    pointerEvents: 'none',

    '&:before, &:after': {
      content: '""',
      display: 'block',
      position: 'absolute',

      transformOrigin: 'center',
      transform: 'rotate(45deg)',
    },

    '&:before': {
      width: 10,
      height: 10,
      zIndex: 0,
      top: props => props.direction == 'left' || props.direction == 'right' ? 'calc(50% - 5px)' : (
        props.direction == 'bottom' ? '-5px' : 'auto'
      ),
      right: props => props.direction == 'left' ? '-5px' : 'auto',
      bottom: props => props.direction == 'top' ? '-5px' : 'auto',
      left: props => props.direction == 'bottom' || props.direction == 'top' ? 'calc(50% - 5px)' : (
        props.direction == 'right' ? '-5px' : 'auto'
      ),

      boxShadow: '0 2px 4px 0 rgba(60,60,80,0.08)',
    },

    '&:after': {
      width: 10,
      height: 10,
      zIndex: 3,
      top: props => props.direction == 'left' || props.direction == 'right' ? 'calc(50% - 5px)' : (
        props.direction == 'bottom' ? '-5px' : 'auto'
      ),
      right: props => props.direction == 'left' ? '-5px' : 'auto',
      bottom: props => props.direction == 'top' ? '-5px' : 'auto',
      left: props => props.direction == 'bottom' || props.direction == 'top' ? 'calc(50% - 5px)' : (
        props.direction == 'right' ? '-5px' : 'auto'
      ),

      borderLeft: props => props.direction == 'bottom' || props.direction == 'right' ? `1px solid ${theme.colors.gray.lighter}` : 'none',
      borderTop: props => props.direction == 'bottom' || props.direction == 'left' ? `1px solid ${theme.colors.gray.lighter}` : 'none',
      borderRight: props => props.direction == 'top' || props.direction == 'left' ? `1px solid ${theme.colors.gray.lighter}` : 'none',
      borderBottom: props => props.direction == 'top' || props.direction == 'right' ? `1px solid ${theme.colors.gray.lighter}` : 'none',

      backgroundColor: '#fff',
    }

  },

  applicationTable: {
    minWidth: 400,
  },

  menuItems: {
    minWidth: 245,
  },

  offerBubble: {
    display: 'none',
    cursor: 'pointer',
    width: 'calc(100% - 915px)',
    left: 'auto',
    right: 0,
    top: 0,
    transform: 'translate(0)',
  },
  rootActive: {
    opacity: 1,

    transition: 'opacity .5s ease .25s, transform .5s ease .25s',
    transform: props => props.direction == 'top' ? 'translate(-50%, -100%)' : (
      props.direction == 'right' ? 'translate(100%, -50%)' : (
        props.direction == 'bottom' ? 'translate(-50%, 100%)' : (
          props.direction == 'left' && 'translate(-100%, -50%)'
        )
      )
    ),

    pointerEvents: 'all',

    '&$offerBubble': {
      top: 0,
      transform: 'translate(0)'
    }
  },

  content: {
    minWidth: 140,
    position: 'relative',
    zIndex: 2,
    padding: '12px 7px',

    borderRadius: 6,
    border: `1px solid ${theme.colors.gray.lighter}`,
    boxShadow: '0 2px 4px 0 rgba(60,60,80,0.08)',

    fontSize: 14,
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'center',

    backgroundColor: '#fff',
    color: theme.colors.gray.dark,
  },

  [theme.queries.mdDevicesDown]: {
    applicationTable: {
      minWidth: 280,
    },
  },
  [theme.queries.xlDevicesOnly]: {
    'offerBubble': {
      display: 'block'
    }
  },

});
