import { mapper, fromInput } from 'demapper';

@mapper
export default class {
  @fromInput('param')
  name = null;

  @fromInput('value')
  value = null;
}
