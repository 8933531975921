export default (theme) => ({
  root: {
    padding: '33px 0 16px'
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
    lineHeight: 1.33,
    textAlign: 'center',
    color: '#282828',
    margin: '0 0 45px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: 6,
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 4px 0 rgba(60, 60, 60, 0.08)',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 0,
      width: 'calc(100% - 20px)',
      height: '100%',
      top: 0,
      left: 10,
      borderRadius: 6,
      boxShadow: '0 8px 24px 0 rgba(10, 80, 180, 0.12)',
    }
  },
  model: {
    '& $form': {
      backgroundColor: '#fff',
      padding: '30px 40px 36px',
    },
    '& $info $imgContainer': {
      display: 'none'
    },
    '& $form $imgContainer': {
      display: 'block'
    },
    '& $footer': {
      width: '100%',
      textAlign: 'center',
      backgroundColor: '#f6fafe',
      padding: '24px 0 28px'
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f6fafe',
    padding: '40px 40px 36px',
    position: 'relative',
    zIndex: 1,
    '& $imgContainer': {
      display: 'none',
      marginBottom: 20
    }
  },
  field: {
    marginBottom: 12,
    width: '100%',
    position: 'relative',
  },
  detailsLink: {
    color: '#1464dc',
    cursor: 'pointer',
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 500,
    textDecoration: 'none',
  },
  cols: {
    padding: '27px 0 23px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
  },
  colsItem: {
    marginBottom: 12,
  },
  label: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#3c3c3c',
  },

  info: {
    width: '100%',
    backgroundColor: '#fff',
    padding: '30px 40px 40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    // zIndex: 1,
    justifyContent: 'flex-start'
  },
  chosen: {
    width: '100%',
    margin: '0 0 1px',
    fontSize: 18,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.56,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#282828',
  },
  imgContainer: {
    position: 'relative',
    padding: '0 28px',
    width: '100%',
  },
  modelText: {
    position: 'absolute',
    top: 10,
    left: 0,
    zIndex: 1,
    fontStyle: 'normal',
    fontWeight: '700',
    fontStretch: 'normal',
    lineHeight: 1.56,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#282828',
  },
  img: {
    width: '100%',
    height: 224,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    margin: 0,
    position: 'relative',
    zIndex: 0,
  },
  price: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#14dc64',
    width: '100%',
    textAlign: 'center',
    position: 'relative',
    zIndex: 1,
    margin: '0 0 22px',
    '& span': {
      fontSize: 24,
    }
  },
  check: {
    display: 'inline-block',
    verticalAlign: 'middle',
    height: '7px',
    width: '13px',
    transformOrigin: 'bottom left',
    transform: 'rotate(-45deg) translate(4px, 2px)',
    transition: 'opacity 0.3s ease',
    marginRight: 3,
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      right: '3px',

      display: 'block',
      height: '2px',

      borderRadius: '2px',

      background: '#1464DC',

      transition: 'background-color 0.3s ease',
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,

      display: 'block',
      width: '2px',

      background: '#1464DC',

      borderRadius: '2px',

      transition: 'background-color 0.3s ease',
    },
  },
  plus: {
    display: 'inline-block',
    verticalAlign: 'middle',
    lineHeight: '18px',
    width: 8,
    height: 8,
    position: 'relative',
    marginRight: 8,
    '&:before': {
      content: '""',
      position: 'absolute',
      backgroundColor: '#1464DC',
      top: 0,
      left: 3,
      width: 2,
      height: 8,
      display: 'block'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      backgroundColor: '#1464DC',
      top: 3,
      left: 0,
      width: 8,
      height: 2,
      display: 'block'
    }
  },
  tradeIn: {
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 500,
    color: '#1464dc',
  },
  divider: {
    width: 2,
    height: 580,
    opacity: 0.3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderImageSource: 'linear-gradient(to bottom, rgba(160, 170, 180, 0), #a0aab4 50%, rgba(160, 170, 180, 0))',
    borderImageSlice: 1,
  },
  footer: {
    width: '100%',
    textAlign: 'center'
  },
  modelTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#282828',
    margin: '0 0 18px',
    width: '100%',
  },
  icons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    // flexWrap: 'wrap',
    padding: '0 0 25px',
    width: '100%',
  },
  iconItem: {
    margin: '18px 27px',
    // maxWidth: 177,
    width: '100%',
    display: 'flex',
    // flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 12,
    color: '#1464dc',
    flexShrink: 0
  },
  iconText: {
    maxWidth: '300px',
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#282828',
    margin: 0
  },
  [theme.queries.mdDevicesDown]: {
    title: {
      fontSize: 24,
      margin: '0 0 33px',
    },
    colsItem: {
      width: 'calc(50% - 12px)',
      marginRight: 12
    },
    model: {
      '& $form': {
        padding: '20px 20px 0px',
      },
      '& $img': {
        width: 'calc(100% + 96px)',
        transform: 'translateX(-48px)',
      },
      '& $imgContainer': {
        marginBottom: -5
      },
      '& $info': {
        padding: '32px 0 0',
        width: '100%'
      },
      '& $footer': {
        padding: '28px 20px 32px'
      },
      '& $price': {
        margin: '0 0 21px'
      }
    },
    footer: {},
    divider: {
      display: 'none'
    },
    form: {
      padding: '32px 20px 28px',
    },
    info: {
      padding: '54px 20px 32px'
    },
    modelTitle: {
      display: 'none',
    },
    icons: {
      display: 'none',
    },
    img: {},
    imgContainer: {
      marginBottom: 8
    },
    price: {
      margin: '0 0 45px',
    },
    detailsLink: {
      display: 'inline-block',
      marginTop: 10,
      marginBottom: -5
    },
  },
  [theme.queries.smDevicesDown]: {
    colsItem: {
      width: '100%'
    },
    formButtonContainer: {
      height: '48px',
    },
    formButtonFixed: {
      position: 'fixed',
      zIndex: 100,
      right: 0,
      bottom: 0,
      left: 0,
      paddingBottom: '32px',
    },
  },
  [theme.queries.lgDevicesUp]: {
    form: {
      width: '50%',
    },
    info: {
      width: '50%'
    },
    colsItem: {
      marginRight: 10,
      width: 'calc(50% - 10px)'
    },
    model: {
      '& $form': {
        width: 'calc(50% - 1px)',
      },
      '& $info': {
        width: 'calc(50% - 1px)',
      },
    },
    detailsLink: {
      position: 'absolute',
      top: 16,
      right: 43,
    },
  },

  [theme.queries.lgDevicesOnly]: {

  },

});
