import React, { Component } from 'react';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import { styles } from './CarouselStyles';

import { IS_BROWSER } from '~app/config';
import queries from '~app/theme/main/queries';

import Swiper from 'swiper/dist/js/swiper.js';
import PropTypes from 'prop-types';

@injectStyles(styles)

class Carousel extends Component {

  static propTypes = {
    name: PropTypes.string,
    desktopItems: PropTypes.number,
    mobileItems: PropTypes.number,
  };

  static defaultProps = {
    name: 'basic-swiper',
    desktopItems: null,
    mobileItems: null,
  };

  swiper = null;

  state = {
    swiperInited: false
  };

  componentDidMount() {
    this.initSwiper();
  }

  initSwiper() {
    const { classes, items, name } = this.props;

    const perPage = this.getPerPage();

    const swiperConfig = {
      loop: false,
      slidesPerView: 1,
      autoHeight: true,
      pagination: {
        el: `.${classes.pagination}`,
        bulletActiveClass: cn('swiper-pagination-bullet-active', classes.bulletActive),
      },
    };
    if (perPage == null && items && items.length > 0 || perPage !== null && items && items.length > perPage) {
      this.swiper = new Swiper(`.${name}`, swiperConfig);
      this.setState({ swiperInited: true });
    }
  }

  getPerPage = () => IS_BROWSER ? window.innerWidth < queries.MD ? this.props.mobileItems : this.props.desktopItems : this.props.desktopItems;

  componentDidUpdate(prevProps) {
    if (prevProps.items != this.props.items) {
      setTimeout(() => {
        if (this.swiper !== null) {
          this.swiper.update();
        } else {
          this.initSwiper();
        }
      }, 500);
    }
  }

  render = () => {
    const {
      classes,
      items,
      name,
      lander,
    } = this.props;

    const perPage = this.getPerPage();
    let pages = new Array(items.length).fill(0);
    if (perPage !== null) {
      pages = new Array(Math.ceil(items.length / perPage)).fill(0);
    }

    return (
      <div className={cn('swiper-container', name)}>
        <div className="swiper-wrapper">
          {pages.map((page, pageIndex) => {
            let slide = items[pageIndex];
            if (perPage !== null) {
              slide = items.slice(pageIndex * perPage, (pageIndex + 1) * perPage);
            }
            return (
              <div
                key={pageIndex}
                className={'swiper-slide'}>
                {perPage == null ?
                  slide :
                  <div className={cn(classes.page, {
                    [classes.lander]: lander,
                  })}>
                    {slide.map(elem => elem)}
                  </div>
                }
              </div>
            );
          })}
        </div>
        <div className={cn('swiper-pagination', classes.pagination)} />
      </div>
    );
  }
}

export default Carousel;
