import reducers from '~app/core/reducers';
import { mainClient } from '~app/network';

// Get my reviews
export const getMyReviews = async (params) => {
  reducers.reviews.getMyReviewsRequest();
  const { data, error } = await mainClient.reviews.getReviews(params);
  
  if (error !== null) {
    reducers.reviews.getMyReviewsFailure(error);
    return;
  }

  reducers.reviews.getMyReviewsSuccess(data);
};

// Get items by key
export const getItemsByKey = async (key, params) => {
  reducers.reviews.getItemsByKeyRequest({ key });
  const { data, error } = await mainClient.reviews.getReviews({ ...params });
  
  if (error !== null) {
    reducers.reviews.getItemsByKeyFailure({ key, error });
    return;
  }

  reducers.reviews.getItemsByKeySuccess({ key, data });
};


// Create by offer id
export const createByOfferId = async (offerId, params) => {
  reducers.reviews.createByOfferIdRequest({ offerId });
  
  const { data, error } = await mainClient.reviews.createReview({ offerId, ...params });
  
  if (error !== null) {
    reducers.reviews.createByOfferIdFailure({ offerId, error });
    return;
  }

  reducers.reviews.createByOfferIdSuccess({ offerId, data });
};
