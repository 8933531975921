import { mapper, fromInput } from 'demapper';

import OfferBaseModel from './OfferBaseModel';
import PagingModel from '../PagingModel';

@mapper
export default class {
  @fromInput('items', { model: OfferBaseModel })
  items = []

  @fromInput('paging', { model: PagingModel })
  paging = null
}
