export const styles = theme => ({
  root: {
    paddingBottom: 50,
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 63,
  },
  headerColumn: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: 470,
  },
  headerTitle: {
    width: '100%',
    fontSize: '36px',
    fontWeight: 'bold',
    lineHeight: 1.33,
    color: theme.colors.accent.main,
  },
  headerSubtitle: {
    marginBottom: 3,

    fontSize: '14px',
    lineHeight: 2,
    letterSpacing: '2px',
    textTransform: 'uppercase',
    color: theme.colors.gray.main,
  },
  headerSeparator: {
    flex: '1 0 auto',
    width: 1,
    height: 180,
    marginRight: 29,

    background: `linear-gradient(to bottom, rgba(160, 170, 180, 0), ${theme.colors.gray.main} 50%, rgba(160, 170, 180, 0))`,
    
    opacity: 0.3,
  },
  headerText: {
    flex: '0 1 auto',

    fontSize: '14px',
    lineHeight: 2,
    color: theme.colors.gray.main,
  },

  item: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBottom: 40,
  },
  item_reversed: {
    flexDirection: 'row-reverse',
  },
  itemImage: {
    flex: '1 0 auto',
    width: 570,
    height: 480,
    
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  itemSeparator: {
    flex: '1 0 auto',
    width: 60,
  },
  itemInfo: {
    flex: '1 1 auto',
    paddingTop: 67,
  },
  itemIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 60,
    marginBottom: 25,

    color: theme.colors.accent.main,

    background: '#FFF',
    
    borderRadius: '50%',
    boxShadow: '0 12px 26px 0 rgba(20, 100, 220, 0.08)',
  },
  itemTitle: {
    marginBottom: 15,

    fontSize: '24px',
    fontWeight: 500,
    lineHeight: 1.5,
    color: theme.colors.gray.dark,
  },
  itemText: {
    marginBottom: 19,

    fontSize: '14px',
    lineHeight: 2,
    color: theme.colors.gray.dark,
  },
  itemCount: {
    fontSize: '48px',
    fontWeight: 300,
    color: theme.colors.brand.main,
  },
  itemCountCurrent: {
    marginRight: 7,
    fontSize: '76px',
    fontWeight: 200,
  },
  itemCountTotal: {
    marginLeft: 7,
  },

  [theme.queries.mdDevicesDown]: {
    root: {
      maxWidth: 640,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    header: {
      flexDirection: 'column',
    },
    headerColumn: {
      width: '100%',
    },
    headerSeparator: {
      display: 'none',
    },
    item: {
      flexDirection: 'column',
    },
    item_reversed: {
      flexDirection: 'column',
    },
    itemImage: {
      width: 'calc(100% + 24px)',
      margin: '0 -12px -96px',
      height: 270,

      backgroundSize: 'contain',
    },
  },

  [theme.queries.smDevicesDown]: {
    root: {
      paddingBottom: 2,
    },
    header: {
      padding: '0 8px',
      marginBottom: 71,
    },
    headerSubtitle: {
      letterSpacing: '1px',
      fontSize: '12px',
      marginBottom: '0px',
    },
    headerTitle: {
      fontSize: '24px',
      marginBottom: '22px',
    },


    item: {
      marginBottom: 76,
    },
    itemSeparator: {
      display: 'none',
    },
    itemInfo: {
      paddingLeft: 12,
      paddingRight: 12,
    },
    itemIcon: {
      marginBottom: 31,
    },
    itemTitle: {
      marginBottom: '17px',

      fontSize: '18px',
      lineHeight: 1.56,
    },
    itemText: {
      marginBottom: 20,
    },
    itemCount: {
      fontSize: '40px',
    },
    itemCountCurrent: {
      marginRight: 5,
      fontSize: '64px',
    },
  },
});