export default theme => ({

  title: {
    width: 330,
    paddingTop: 14,
    paddingBottom: 26,

    fontSize: 18,
    fontWeight: '500',
    textAlign: 'center',
  },

  ratingOptions: {
    display: 'flex',

    justifyContent: 'space-between',
  },

  
  formInput: {
    width: '100%',
    margin: '12px 0 32px',
  },

  formButton: {
    display: 'flex',
    paddingBottom: 17,

    justifyContent: 'center',
  },


  ...theme.styles.modal,

  [theme.queries.smDevicesDown]: {
    modal: {
      width: 'calc(100% - 22px)',
    },

    title: {
      width: '100%',
    },

    content: {
      padding: '34px 20px 40px',
    },
  },
  

});