import { updateStateItemByKey } from '~app/utils/reduxHelpers';

export const initialState = {
  promoByKey: {},

  promoItem: null,
  promoItemInProgress: false,
  promoItemError: null,

};

const initialStateNewsByKey = {
  items: null,
  paging: null,
  inProgress: false,
  error: null,
};

// get promos by key
export const getPromoByKeyRequest = (state, { key }) => {
  return updateStateItemByKey(state, 'promoByKey', initialStateNewsByKey, key, {
    inProgress: true,
    error: null
  });
};

export const getPromoByKeySuccess = (state, { key, data }) => {
  const {
    items,
    paging,
  } = data;

  return updateStateItemByKey(state, 'promoByKey', initialStateNewsByKey, key, {
    items,
    paging,
    inProgress: false,
    error: null
  });
};
export const getPromoByKeyFailure = (state, { key, error }) => {
  return updateStateItemByKey(state, 'promoByKey', initialStateNewsByKey, key, {
    inProgress: false,
    error
  });
};

// get promo item by id
export const getPromoItemRequest = (state) => ({
  ...state,
  promoItemInProgress: true
});

export const getPromoItemSuccess = (state, payload) => ({
  ...state,
  promoItem: payload,
  promoItemInProgress: false,
  promoItemError: null,
});

export const getPromoItemFailure = (state, payload) => ({
  ...state,
  promoItemInProgress: false,
  promoItemError: null,
});


export const getPromoItemReset = (state) => ({
  ...state,

  promoByKey: {},

  promoItem: null,
  promoItemInProgress: false,
  promoItemError: null,
});