export const styles = theme => ({
  root: {
    margin: '0 0 60px',
  },
  slide: {
    width: '100%',
    height: '510px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    padding: '30px 60px 70px',
    textDecoration: 'none',
    color: 'inherit',
    cursor: 'pointer',
  },
  image: {
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '470px',
    overflow: 'hidden',
    borderRadius: 6,
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
      backgroundImage: 'linear-gradient(to bottom, rgba(238, 238, 238, 0), #151515)',
    }
  },
  dealerLogo: {
    zIndex: 1,
    position: 'absolute',
    top: 30,
    left: 60,
    width: 137,
    height: 65,
    borderRadius: 6.5,
    boxShadow: '0 8px 21px 0 rgba(20, 100, 220, 0.09)',
    backgroundColor: '#ffffff',
    padding: '20px 10px',
    display: 'flex',
  },
  dealerLogoContent: {
    backgroundPosition: '50% 50%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#ffffff',
    maxWidth: 970,
    margin: '0 0 12px',
    position: 'relative',
    zIndex: 1,
  },
  [theme.queries.lgDevicesUp]: {
  },

  [theme.queries.mdDevicesDown]: {
    root: {
      marginBottom: 10,
    },
    slide: {
      padding: '20px 20px 70px',
    },
    dealerLogo: {
      top: 20,
      left: 20,
      width: 122,
      height: 68,
      padding: '18px 15px',
    },
    title: {
      fontSize: 24,
      lineHeight: 1.5,
    }
  },
});
