import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { injectStyles } from '~app/theme';
import { connect } from 'react-redux';
import { formContainer, formCreate } from 'deforms';
import actions from '~app/redux/actions';

import { Button, TextInput } from '~app/components/ui';
import { freezeScroll } from '../../utils/helpers';

import styles from './ChangeDealerModalStyles';

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    inProgress: state.user.updateDealerInProgress,
    success: state.user.updateDealerSuccess,
    error: state.user.updateDealerError,
  };
};

@connect(mapStateToProps)
@injectStyles(styles)
@formContainer
class ChangeDealerModal extends Component {

  @formCreate()
  formChangeDealer = {
    schema: {
      name: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
          minlength: 2
        }
      },
      address: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
          minlength: 5
        }
      },
      email: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
          minlength: 5
        }
      },
      phone: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
          minlength: 12
        }
      },
    },
    onSubmit: (formData) => {
      this.changeDealerOnSubmit(formData);
    }
  }

  componentDidMount() {
    if (this.props.user !== null) {
      this.fillForm();
    }
  }

  fillForm = () => {
    const user = this.props.user;
    const formChangeDealer = this.formChangeDealer;

    formChangeDealer.fields.name.changeValue(user.firstName);
    formChangeDealer.fields.address.changeValue(user.address);
    formChangeDealer.fields.email.changeValue(user.email);
    formChangeDealer.fields.phone.changeValue(user.phone);
  }

  componentWillReceiveProps = (nextProps) => {
    if (!this.props.success && nextProps.success) {
      this.onSuccess();
    }
  }

  changeDealerOnSubmit = (formData) => {
    if (this.props.inProgress) {
      return;
    }
    actions.user.updateDealer(formData);
  }

  onSuccess = () => {
    this.props.onRequestClose();
    actions.common.openGenericModal({
      icon: 'support',
      title: 'Запрос отправлен!',
      text: 'Наша служба поддержки обработает запрос на изменение данных профиля в ближайшее время.',
    });
  }

  onRequestClose = () => {
    this.props.onRequestClose();
    freezeScroll(true);
  }
  onOpen = () => {
    freezeScroll();
  }

  render() {
    const {
      classes,
      isOpen,
    } = this.props;
    const formChangeDealer = this.formChangeDealer;

    return (
      <ReactModal
        className={{
          base: classes.modal,
          afterOpen: classes.modalAfterOpen,
          beforeClose: classes.modalBeforeClose,
        }}
        overlayClassName={{
          base: classes.overlay,
          afterOpen: classes.overlayAfterOpen,
          beforeClose: classes.overlayBeforeClose,
        }}
        isOpen={isOpen}
        onAfterOpen={this.onOpen}
        closeTimeoutMS={300}
        key={this.props.isOpen}
        contentLabel={'modal'}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick>

        <a className={classes.close} onClick={this.onRequestClose} />
        <div className={classes.content}>
          <div className={classes.title}>{'Изменение данных профиля'}</div>

          <form noValidate className={classes.form}
            onSubmit={formChangeDealer.submit}
          >
            <div className={classes.formInput}>
              <TextInput
                name={'name'}
                type={'text'}
                placeholder={'Название дилера'}
                onChange={formChangeDealer.fields.name.change}
                value={formChangeDealer.fields.name.value}
                error={formChangeDealer.submitted && !formChangeDealer.fields.name.valid}
              />
            </div>
            <div className={classes.formInput}>
              <TextInput
                name={'address'}
                type={'text'}
                placeholder={'Адрес салона'}
                onChange={formChangeDealer.fields.address.change}
                value={formChangeDealer.fields.address.value}
                error={formChangeDealer.submitted && !formChangeDealer.fields.address.valid}
              />
            </div>
            <div className={classes.formInput}>
              <TextInput
                name={'email'}
                type={'text'}
                placeholder={'E-mail'}
                onChange={formChangeDealer.fields.email.change}
                value={formChangeDealer.fields.email.value}
                error={formChangeDealer.submitted && !formChangeDealer.fields.email.valid}
              />
            </div>
            <div className={classes.formInput}>
              <TextInput
                name={'phone'}
                type={'text'}
                placeholder={'Телефон'}
                onChange={formChangeDealer.fields.phone.change}
                value={formChangeDealer.fields.phone.value}
                error={formChangeDealer.submitted && !formChangeDealer.fields.phone.valid}
                mask={'+7 999 999 99 99'}
              />
            </div>
            <div className={classes.formAction}>
              <Button
                mobileBlock
                theme={'brand'}
                text={'Отправить запрос'}
              // onClick={this.onSuccess}
              />
            </div>
          </form>
        </div>

      </ReactModal>
    );
  }
}

export default ChangeDealerModal;
