export const styles = theme => ({
  pagination: {},
  bulletActive: {
    backgroundColor: '#fac81e'
  },
  lander: {
    
  },
  page: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    paddingBottom: 30,
    '&$lander': {
      justifyContent: 'center',
    },
  },
});
