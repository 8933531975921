import React, { Component } from 'react';

import { injectStyles } from '~app/theme';
import styles from './NewsItemCardStyles';

import { Link } from 'react-router-dom';
import { Time } from '~app/components/ui';

@injectStyles(styles)
class NewsItemCard extends Component {

  render = () => {
    const {
      classes,
      item,
      ...rest
    } = this.props;

    return (
      <Link to={`/news/${item.id}`} key={item.id} className={classes.item} {...rest}>
        <div
          className={classes.img}
          style={{
            backgroundImage: `url(${item.image})`
          }} />
        <div className={classes.itemContent}>
          <div className={classes.itemName}>{item.name}</div>
          {item.date !== null &&
            <Time date={item.date} />
          }
        </div>
      </Link>
    );
  }
}

export default NewsItemCard;
