import React, { Component } from 'react';
import { Switch, Route } from 'react-router';

import { Helmet } from 'react-helmet';

import { LayoutGeneral } from '~app/components';
import HomeScreen from '~app/modules/HomeScreen/HomeScreen';
import ApplicationScreen from '~app/modules/ApplicationScreen/ApplicationScreen';
import NotFoundScreen from '~app/modules/NotFoundScreen/NotFoundScreen';
import DealsScreen from '~app/modules/DealsScreen/DealsScreen';
import DealersScreen from '~app/modules/DealersScreen/DealersScreen';
import NewsScreen from '~app/modules/NewsScreen/NewsScreen';
import NewsItemScreen from '~app/modules/NewsItemScreen/NewsItemScreen';
import ContactsScreen from '~app/modules/ContactsScreen/ContactsScreen';
import EmailConfirmScreen from '~app/modules/EmailConfirmScreen/EmailConfirmScreen';
import PromoScreen from '~app/modules/PromoScreen/PromoScreen';
import PromoItemScreen from '~app/modules/PromoItemScreen/PromoItemScreen';
import DealerItemScreen from '~app/modules/DealerItemScreen/DealerItemScreen';

class ContainerMain extends Component {
  render = () => {
    return (
      <LayoutGeneral>
        <Helmet>
          <title>Avicar - Эксклюзивные цены от дилеров!</title>
          <meta name={'description'} content={'Новые автомобили со скидкой до 20%'} />
        </Helmet>
        <Switch>
          <Route exact path={'/'} component={HomeScreen} />
          <Route path={'/application/:brand?/:model?/:color?/:configuration?/:options?'} component={ApplicationScreen} />
          <Route path={'/deals'} component={DealsScreen} />
          <Route exact path={'/dealers'} component={DealersScreen} />
          <Route path={'/dealers/:id'} component={DealerItemScreen} />
          <Route exact path={'/news'} component={NewsScreen} />
          <Route path={'/news/:id'} component={NewsItemScreen} />
          <Route exact path={'/promo'} component={PromoScreen} />
          <Route path={'/promo/:id'} component={PromoItemScreen} />
          <Route path={'/contacts'} component={ContactsScreen} />
          <Route path={'/email-verification'} component={EmailConfirmScreen} />

          <Route path='*' component={NotFoundScreen} />
        </Switch>
      </LayoutGeneral>
    );
  }
}

export default ContainerMain;
