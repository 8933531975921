import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import FlipMove from 'react-flip-move';
import cn from 'classnames';

import actions from '~app/redux/actions';
import { inflection } from '../../../../utils/helpers';
import { ApplicationCard } from '../../../../components';
import { Button, Icon, Spacer, EmptyListPlaceholder, HintHoverContainer, SectionHint } from '../../../../components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './ApplicationsStyles';

const mapStateToProps = (state, { reduxKey = 'UserMyApplications' }) => {
  let applications = null;
  if (reduxKey in state.applications.itemsByKey) {
    applications = state.applications.itemsByKey[reduxKey];
  }
  return {
    reduxKey,
    applications,

    archiveInProgress: state.applications.archiveInProgress,
    archiveSuccess: state.applications.archiveSuccess,
  };
};


@connect(mapStateToProps)
@injectStyles(styles)
class Applications extends Component {

  watchIntevalInstance = null;
  watchInteval = 5000;

  componentDidMount = () => {
    this.loadData();
    this.startWatchData();
  }

  componentWillUnmount = () => {
    this.stopWatchData();
  }

  loadData = (props = this.props) => {
    actions.applications.getItemsByKey(this.props.reduxKey, {
      limit: 1000,
      my: true
    });
  }

  startWatchData = () => {
    this.watchIntevalInstance = setInterval(() => {
      this.loadData();
    }, this.watchInteval);
  }

  stopWatchData = () => {
    clearInterval(this.watchIntevalInstance);
    this.watchIntevalInstance = null;
  }

  reloadData = () => {
    this.stopWatchData();
    this.loadData();
    this.startWatchData();
  }

  archiveOnClick = (item) => {
    actions.common.openModal({
      type: 'archive',
      params: {
        title: 'Удалить заявку',
        text: 'На данный момент нет предложений от дилеров. Ты уверен, что хочешь удалить заявку?',
        applicationId: item.id,
        onSuccess: this.reloadData,
        offersCount: item.offersCount
      }
    });
  }

  cancelOffer = (item) => {
    actions.common.openModal({
      type: 'refuseOffer',
      params: {
        offerId: item.acceptedOffer.id,
        onSuccess: this.reloadData
      }
    });
  }

  renderItemFooter = (item) => {
    const {
      classes
    } = this.props;
    if (item.acceptedOffer !== null) {
      return (
        <div className={classes.itemButtons}>
          <div
            onClick={() => this.cancelOffer(item)}
            className={classes.itemButton}
          >
            <div className={classes.itemButtonIcon}>
              <Icon name={'timesThin'} />
            </div>
            {'Отменить оффер'}
          </div>
          <Spacer h={8} />
          <HintHoverContainer
            direction={'bottom'}
            hint={'Дождись подтверждения сделки от дилера'}
          >
            <Button
              theme={'brand'}
              text={'Открыть сделку'}
              unactive
              mobileBlock
            />
          </HintHoverContainer>
        </div>
      );
    }
    if (item.offersCount > 0) {
      return (
        <div className={classes.itemButtons}>
          <Link className={classes.itemButtonFull} to={`/cabinet/application/${item.id}`}>
            <Button
              theme={'general'}
              mobileBlock
              text={item.newOffersCount > 0 ?
                `${item.newOffersCount} ${inflection(item.newOffersCount, ['новый оффер', 'новых оффера', 'новых офферов'])}`
                :
                'Все офферы'}
            />
          </Link>
        </div>
      );
    }
    return (
      <div className={cn(classes.itemButtons, classes.withComment)}>
        <div className={classes.comment}>
          {'Дилеры уже увидели вашу заявку и приступили к ее обработке!'}
        </div>
        <div
          onClick={() => this.archiveOnClick(item)}
          className={cn(classes.itemButton, classes.itemButtonFull)}
        >
          <div className={classes.itemButtonIcon}>
            <Icon name={'timesThin'} />
          </div>
          {'Удалить'}
        </div>
        {/* TODO: надо ли?
        <Spacer h={8}/>
        <div className={classes.itemButton}>
          {'Редактировать'}
        </div> */}
      </div>
    );
  }


  render() {
    const {
      classes,
      applications,
    } = this.props;

    if (applications === null) {
      return null;
    }

    const {
      items
    } = applications;

    return (
      <div className={classes.root}>
        <SectionHint place={'applications'} />
        {items !== null && items.length > 0 &&
          <FlipMove>
            {items.map((item) => {
              return (
                <div key={item.id} className={classes.item}>
                  <ApplicationCard
                    item={item} />
                  {this.renderItemFooter(item)}
                </div>
              );
            })}
          </FlipMove>
        }
        {items !== null && items.length === 0 &&
          <EmptyListPlaceholder
            iconName={'car'}
            text={'Нет активных заявок на покупку автомобиля'}
            additional={
              <Link to={'/application'} style={{ width: '100%' }}>
                <Button
                  block
                  mobileBlock
                  theme={'brand'}
                  text={'Создать заявку'}
                  rightIcon={
                    <div className={classes.emptyBtnIcon}>
                      <Icon name={'arrowRight'} />
                    </div>
                  }
                />
              </Link>
            }
          />
        }
      </div>
    );
  }
}

export default Applications;
