import React, { Component } from 'react';
import cn from 'classnames';

import { Select } from '~app/components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './BrandStyles';

@injectStyles(styles)
class Brand extends Component {

  onChange = (name) => {
    this.props.onChangeOption(name);
  }

  renderItem = (item) => {
    const { classes } = this.props;

    return (
      <div
        className={classes.item}
        onClick={() => this.onChange(item.slug)}
        key={item.slug}
      >
        <div className={classes.itemIcon}>
          <img src={item.logoUrl} className={classes.itemIconImg}/>
        </div>
        <div className={classes.itemName}>{item.name}</div>
      </div>
    );
  }

  render() {
    const {
      classes,
      brands = null,
    } = this.props;
    const selectOptions = [];
    if (brands !== null) {
      brands.map((item) => {
        selectOptions.push({
          label: item.name,
          value: item.slug
        });
      });
    }

    return (
      <div className={classes.root}>
        <div className={cn(classes.list, classes.listDesk)}>
          {brands !== null && brands.map((item) => {
            return (this.renderItem(item));
          })}
        </div>
        <div className={cn(classes.list, classes.listMobile)}>
          <Select
            options={selectOptions}
            placeholder={'Марка'}
            onChange={(name) => this.onChange(name)}
          />
        </div>
      </div>
    );
  }
}

export default Brand;