import client from './_client';
// import { transformListModel } from './_helpers';
import { BrandLanderModel, ModelLanderModel } from './models';


export const getContentByBrand = slug => {
  return client.request({
    url: '/v1/brand/landing',
    method: 'GET',
    params: { slug },
    transformData: data => new BrandLanderModel(data)
  });
};

export const getContentByModel = (params = {}) => {
  return client.request({
    url: '/v1/model/landing',
    method: 'GET',
    params: {
      model: params.model,
      class: params.class,
      type: params.type,
    },
    transformData: data => new ModelLanderModel(data)
  });
};

// export const getModels = (brandId) => {
//   return client.request({
//     url: `/v1/brand/${brandId}/models`,
//     method: 'GET',
//     transformData: data => ({ items: transformListModel(ModelBaseModel)(data) })
//   });
// };


// export const getColors = (modelId) => {
//   return client.request({
//     url: `/v1/model/${modelId}`,
//     method: 'GET',
//     transformData: data => ({ items: transformListModel(ColorBaseModel)(data.colors) })
//   });
// };



// export const getPackages = (modelId) => {
//   return client.request({
//     url: `/v1/model/${modelId}/packages`,
//     method: 'GET',
//     transformData: data => ({ items: transformListModel(PackageBaseModel)(data) })
//   });
// };


// export const getDeliveryOptions = () => {
//   return client.request({
//     url: '/v1/deliveryOptions',
//     method: 'GET',
//     transformData: data => ({ items: transformListModel(DeliveryOptionBaseModel)(data.deliveryOptions) })
//   });
// };
