import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Collapse } from 'react-collapse';

import { Card, CardContent } from '../';
import { injectStyles } from '~app/theme';
import styles from './CollapseSectionStyles';

@injectStyles(styles)
class CollapseSection extends Component {

  render = () => {
    const {
      classes,
      head,
      body,

      title,
      number,
      background,
      optionAny,

      expanded,
      onOpen,
      notCollapse
    } = this.props;

    return (
      <div className={classes.root}>
        <Card shadow={expanded}>
          <div className={classes.wrapper}>
            <div className={cn(classes.head, !expanded ? classes.headActive : null)}>
              <CardContent>
                {head}
              </CardContent>
            </div>
            {notCollapse ?
              <CardContent>
                <div className={cn(classes.body, expanded ? classes.bodyActive : null)}>
                  <div className={cn(classes.bodyHead, background ? classes.bodyHeadBackground : null)}>
                    <div className={classes.bodyHeadMain}>
                      <div className={classes.bodyHeadTitle}>{title}</div>
                      {optionAny !== null &&
                        optionAny
                      }
                    </div>
                    <div className={classes.bodyHeadNumber}>{number}</div>
                  </div>
                  {body}
                </div>
              </CardContent>
              :
              <Collapse
                isOpened={expanded}
                springConfig={{stiffness: 100, damping: 20}}
                onRest={onOpen}
              >
                <CardContent>
                  <div className={cn(classes.body, expanded ? classes.bodyActive : null)}>
                    <div className={cn(classes.bodyHead, background ? classes.bodyHeadBackground : null)}>
                      <div className={classes.bodyHeadMain}>
                        <div className={classes.bodyHeadTitle}>{title}</div>
                        {optionAny !== null &&
                          optionAny
                        }
                      </div>
                      <div className={classes.bodyHeadNumber}>{number}</div>
                    </div>
                    {body}
                  </div>
                </CardContent>
              </Collapse>
            }
          </div>
        </Card>
      </div>
    );
  }
}

CollapseSection.propTypes = {
  head: PropTypes.object,
  body: PropTypes.object,

  title: PropTypes.string,
  number: PropTypes.string,
  background: PropTypes.bool,
  optionAny: PropTypes.object,

  expanded: PropTypes.bool,
  notCollapse: PropTypes.bool,
};

CollapseSection.defaultProps = {
  head: null,
  body: null,

  title: '',
  number: '',
  background: false,
  optionAny: null,

  expanded: true,
  notCollapse: false,
};

export default CollapseSection;