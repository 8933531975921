import client from './_client';
import { NewsListModel, NewsItemModel } from './models';

// Get news
export const getNews = (params = {}) => {
  let {
    limit = 8,
    page = 1,
    id,
    type = 'all',
    ...otherParams
  } = params;

  let url = id ? `v1/news/${id}` : 'v1/news';

  if(type == 'withDealer') {
    url += '?withdealers=true';
  }

  if(type == 'withoutDealer') {
    url += '?withdealers=false';
  }

  return client.request({
    url,
    method: 'GET',
    params: {
      limit,
      page,
      ...otherParams
    },
    transformData: data => new NewsListModel({
      items: data.data,
      paging: {
        count: data.count || 0,
        page: page,
        limit: limit,
      }
    })
  });
};

export const getNewsItem = ({ id }) => {
  return client.request({
    url: `/v1/new/${id}`,
    method: 'GET',
    transformData: data => new NewsItemModel(data),
  });
};
