import React, { Component } from 'react';

import { Helmet } from 'react-helmet';


import { Container, Loader } from '~app/components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './PromoScreenStyles';

import actions from '~app/redux/actions';
import { connect } from 'react-redux';

import InfiniteScroll from 'react-infinite-scroller';

import PreFooter from '../HomeScreen/Sections/PreFooter/PreFooter';
import { HeroSlider } from '~app/components/News';
import { PromoItemCard } from '~app/components/Promo';

const mapStateToProps = ({ promo }, { reduxKey = 'promoPage' }) => {
  let promoState = {
    items: null,
    paging: null,
    inProgress: false,
    error: null
  };

  if (reduxKey in promo.promoByKey) {
    promoState = promo.promoByKey[reduxKey];
  }

  return {
    reduxKey,
    items: promoState.items,
    paging: promoState.paging,
    inProgress: promoState.inProgress,
    error: promoState.error,

  };
};

@connect(mapStateToProps)
@injectStyles(styles)

class PromoScreen extends Component {

  state = {
    currentPage: 1,
    lock: false,
    items: null,
  };

  static async fetchData({ store, params }) {
    await actions.promo.getPromoByKey('promoPage', {
      limit: 6,
    });
  }

  loadPromo = () => {
    actions.promo.getPromoByKey(this.props.reduxKey, {
      page: this.state.currentPage,
      limit: 6,
    });
  }

  componentDidMount() {
    this.loadPromo();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inProgress && !this.props.inProgress) {
      this.setState({ lock: false }, () => {
        if (this.props.error === null && Array.isArray(this.props.items)) {
          this.setState(prevState => ({
            items: prevState.items !== null ?
              [...prevState.items, ...this.props.items] :
              this.props.items
          }));
        }
      });
    }
  }

  reloadData = () => {
    this.loadPromo();
  }

  loadMore = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    // infinity scroll шлет сразу несколько запросов асинхронно, из-за этого в did update
    // не поймаешь их по inProgress. приходится лочить через state.

    if (this.state.lock) {
      return;
    }

    this.setState(prevState => ({ lock: true, currentPage: prevState.currentPage + 1 }), () => {
      this.reloadData();
    });
  }

  renderItems() {
    const {
      items
    } = this.state;

    const { classes } = this.props;

    return items !== null &&
      Array.isArray(items) &&
      items.length > 0 &&
      <div className={classes.items}>
        {items.map(item =>
          <PromoItemCard item={item} key={item.id} />
        )}
      </div>;
  }

  render() {
    const {
      classes,
      inProgress,
      paging,
    } = this.props;

    const {
      items,
      lock,
    } = this.state;

    const hasMore = paging !== null && items !== null ?
      items.length < paging.totalItems : false;

    return (
      <React.Fragment>
        <Helmet>
          <title>{'Avicar - Avicar рекомендует'}</title>
        </Helmet>
        <Container background={'white'} fullHeight>
          <div className={classes.root}>
            <div className={classes.title}>{'Avicar рекомендует'}</div>
            {items !== null &&
              Array.isArray(items) &&
              items.length > 0 &&
              <HeroSlider place={'promo'} items={items.slice(0, 4)} />
            }
            <div className={classes.content}>
              <div className={classes.title}>{'Все акции от дилеров'}</div>
              <div className={classes.desktop}>
                <InfiniteScroll
                  pageStart={1}
                  loadMore={this.loadMore}
                  hasMore={!inProgress && !lock && hasMore}
                  loader={<Loader key={'loader'} />}>
                  {this.renderItems()}
                </InfiniteScroll>
              </div>
              <div className={classes.mobile}>
                {this.renderItems()}
                {hasMore &&
                  <a onClick={this.loadMore} className={classes.showMore}>
                    {'Больше новостей'}
                  </a>
                }
              </div>
            </div>
          </div>
        </Container>
        <Container >
          <PreFooter />
        </Container>
      </React.Fragment>
    );
  }
}

export default PromoScreen;
