import React from 'react';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import { styles } from './HeroSliderStyles';
import { Carousel, Time } from '~app/components/ui';

import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

@injectStyles(styles)
class HeroSlider extends React.Component {
  static propTypes = {
    place: PropTypes.oneOf(['promo', 'news']),
  };

  static defaultProps = {
    place: 'news'
  };

  render() {
    const { classes, items, place } = this.props;
    return (
      <div className={cn(classes.root)}>
        <Carousel
          name={'hero-carousel'}
          items={items.map((item, key) =>
            <Link to={`/${place}/${item.id}`} className={classes.slide} key={key}>
              {item.dealer !== null && item.dealer.logo !== null &&
                <div className={classes.dealerLogo}>
                  <div
                    style={{ backgroundImage: `url(${item.dealer.logo})` }}
                    className={classes.dealerLogoContent} />
                </div>
              }
              {item.name !== null &&
                <h3 className={classes.title}>{item.name}</h3>
              }
              {place == 'news' && item.date !== null &&
                <Time date={item.date} />
              }
              <div
                className={classes.image}
                style={{ backgroundImage: `url(${item.image})` }} />
            </Link>
          )} />
      </div>
    );
  }
}

export default HeroSlider;
