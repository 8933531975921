import React, { Component } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import { freezeScroll } from '../../utils/helpers';

import styles from './YoutubeModalStyles';

@injectStyles(styles)

class YoutubeModal extends Component {
  onRequestClose = () => {
    this.props.onRequestClose();
    freezeScroll(true);
  }
  onOpen = () => {
    freezeScroll();
  }

  render() {
    const {
      classes,
      isOpen,
      autoplay,
      id,
    } = this.props;

    return (
      <ReactModal
        className={{
          base:  cn(classes.modal, classes.customModal),
          afterOpen: classes.modalAfterOpen,
          beforeClose: classes.modalBeforeClose,
        }}
        overlayClassName={{
          base: classes.overlay,
          afterOpen: classes.overlayAfterOpen,
          beforeClose: classes.overlayBeforeClose,
        }}
        isOpen={isOpen}
        onAfterOpen={this.onOpen}
        closeTimeoutMS={300}
        contentLabel={'modal'}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick>

        <a className={classes.close} onClick={this.onRequestClose} />

        <div className={classes.modalWrapper}>
          {id &&
            <div className={classes.video}>
              <iframe
                className={classes.iframe}
                src={`https://www.youtube.com/embed/${id}${autoplay && '?autoplay=1'}`}
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen />
            </div>
          }
        </div>

      </ReactModal>
    );
  }
}

YoutubeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  autoplay: PropTypes.bool
};

YoutubeModal.defaultProps = {
  isOpen: false,
  onRequestClose: null,
  id: null,
  autoplay: false
};

export default YoutubeModal;
