export const styles = theme => ({
  root: {
    position: 'relative',
    padding: '23px 50px',
  },
  leftIcon: {
    paddingLeft: 114,
  },
  [theme.queries.mdDevicesDown]: {
    root: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    leftIcon: {
      paddingLeft: 72
    },
  },
});