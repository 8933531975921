import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '~app/redux/actions';
import FlipMove from 'react-flip-move';

import { DealCard } from '../../../../components';
import { EmptyListPlaceholder, Spacer } from '../../../../components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './DealsStyles';



const mapStateToProps = (state, { reduxKey = 'myDeals' }) => {
  let offersState = {
    items: null,
    paging: null,
    inProgress: null,
    error: null,
  };
  if (reduxKey in state.offers.itemsByKey) {
    offersState = state.offers.itemsByKey[reduxKey];
  }

  return {
    reduxKey,

    offers: offersState.items,
    offersPaging: offersState.paging,
    offersInProgress: offersState.inProgress,
    offersError: offersState.error,
  };
};


@connect(mapStateToProps)
@injectStyles(styles)
class Deals extends Component {
  watchIntevalInstance = null;
  watchInteval = 5000;

  componentDidMount = () => {
    this.loadData();
    this.startWatchData();
  }

  componentWillUnmount = () => {
    this.stopWatchData();
  }

  loadData = (props = this.props) => {
    actions.offers.getItemsByKey(this.props.reduxKey, {
      types: 'accepted,deal'
    });
  }

  reloadData = () => {
    this.stopWatchData();
    this.loadData();
    this.startWatchData();
  }

  startWatchData = () => {
    this.watchIntevalInstance = setInterval(() => {
      this.loadData();
    }, this.watchInteval);
  }

  stopWatchData = () => {
    clearInterval(this.watchIntevalInstance);
    this.watchIntevalInstance = null;
  }

  render() {
    const {
      classes,
      offers,
    } = this.props;
    
    return (
      <div className={classes.root}>
        {offers !== null && offers.length > 0 ?
          <FlipMove>
            {offers.map((item) => {
              return (
                <div key={item.id} className={classes.item}>
                  <DealCard 
                    asDealer
                    item={item}
                    onChangeStatus={this.reloadData}
                  />
                  <Spacer v={12} />
                </div>
              );
            })}
          </FlipMove>
          :
          <EmptyListPlaceholder
            iconName={'deal'}
            text={'Нет активных сделок с пользователями'}
          />
        }
      </div>
    );
  }
}

export default Deals;