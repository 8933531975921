export const styles = theme => ({
  brand: {
    width: 'calc(25% - 30px)',
    margin: '0 15px 30px',
    padding: '16px 20px',
    borderRadius: 6,
    backgroundColor: '#ffffff',
    boxShadow: '0 6px 20px 0 rgba(20, 100, 220, 0.09)',
  },
  brandImage: {
    display: 'block',
    width: 63,
    height: 40,
    margin: '0 auto 6px',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
  },
  brandTitle: {
    display: 'block',
    fontSize: 18,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#282828',
    margin: 0,
    textAlign: 'center',
  },
  [theme.queries.smDevicesDown]: {
    brand: {
      width: 'calc(100% - 30px)',
    }
  },
});
