import React from 'react';
import { Route } from 'react-router';
import { ConnectedRouter } from 'connected-react-router-exposed';

import history from '~app/core/history';
import ContainerRoot from './ContainerRoot';

const Router = () => {
  return (
    <ConnectedRouter history={history}>
      <Route component={ContainerRoot} />
    </ConnectedRouter>
  );
};

export default Router;