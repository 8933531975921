import React from 'react';
import moment from 'moment';

import { Icon } from '~app/components/ui';

import { styles } from './TimeStyles';
import { injectStyles } from '~app/theme';

@injectStyles(styles)
class Time extends React.Component {
  render() {
    const { classes, date } = this.props;
    return (
      <p className={classes.time}>
        <span className={classes.timeIcon}>
          <Icon block name={'clock'} />
        </span>
        <span>
          {moment(date).format('DD.MM.YY')}
        </span>
      </p>
    );
  }
}
export default Time;

