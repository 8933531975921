import React from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from '~app/theme';

import styles from './SectionHintStyles';

@injectStyles(styles)
class SectionHint extends React.Component {
  render() {
    const { classes, place } = this.props;

    return (
      <div className={classes.root}>
        {place == 'applications' && 'Здесь хранятся все Ваши заявки на автомобили'}
        {place == 'deals' && 'Здесь хранятся все Ваши сделки'}
        {place == 'reviews' && 'Здесь хранятся все отзывы от Вас'}
        {place == 'archived' && 'Здесь хранятся все заявки, которые Вы отправили в архив'}
      </div>
    );
  }
}

SectionHint.propTypes = {
  place: PropTypes.oneOf(['applications', 'deals', 'reviews']).isRequired
};

export default SectionHint;
