import background from './images/filters-bg-desktop.png';

export default theme => ({
  filters: {
    padding: '21px 50px 32px',

    backgroundColor: theme.colors.accent.main,
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

    borderRadius: '6px',

  },
  filtersSimple: {
    borderRadius: '0 0 6px 6px',

    background: 'none',
    backgroundColor: 'transparent',
  },

  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '10px',

    color: '#fff',
  },
  deals: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  dealsButton: {
    paddingRight: '20px',

    color: '#fff',
    fontSize: '14px',
    lineHeight: '40px',

    opacity: 0.6,
  },
  dealsButtonActive: {
    fontSize: '18px',

    opacity: 1,
  },
  createButton: {
    display: 'flex',

    fontSize: 14,

    color: '#fff',

    justifyContent: 'flex-start',
    alignItems: 'center',

    cursor: 'pointer',

    '& a': {
      color: '#fff',
    }
  },
  createButtonIcon: {
    display: 'inline-block',
    marginRight: '8px',

    fontSize: 22,
    lineHeight: '14px',
  },
  createButtonText: {
    fontSize: '14px',
    color: '#fff',
  },


  selects: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    margin: '-4px 0',
  },
  selectsSeveral: {
    '& $select': {
      width: '50%',
      '& ~ $select': {
        width: 'calc(50% + 10px)',
        padding: '4px 0 4px 10px',
      }
    }
  },

  select: {
    width: '100%',
    position: 'relative',
    padding: '4px 0',

    transition: 'width .5s ease',

    flexBasis: 'auto',
  },
  selectHidden: {
    width: '0 !important',
    overflow: 'hidden',
    padding: '4px 0 !important',
  },

  [theme.queries.mdDevicesUp]: {
    selectsSeveral: {
      // '& $select': {
      //   width: '50%',
      //   '& ~ $select': {
      //     width: 'calc(50% + 10px)',
      //     padding: '4px 0 4px 10px',
      //   }
      // }
    },
  },

  [theme.queries.smDevicesDown]: {
    filters: {
      padding: '10px 20px 28px',
    },
    buttons: {
      marginBottom: -4,
    },
    dealsButton: {
      paddingRight: '16px',

      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '50px',
    },
    dealsButtonActive: {
      fontSize: '14px',
    },
    selects: {
      flexDirection: 'column',
      width: '100%',
      margin: 0,
    },
    selectsSeveral: {
      '& $select': {
        width: '100%',
        padding: '4px 0 !important',
        '& ~ $select': {
          width: '100%',
        },
      },
    },
    select: {
      width: '100%',
    },
    selectHidden: {
      display: 'none',
      width: '100% !important',
    }
  },
});
