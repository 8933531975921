export default (theme) => ({
  root: {
    width: '100%',
    maxWidth: theme.sizes.containerWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingRight: theme.sizes.containerPaddingH,
    paddingLeft: theme.sizes.containerPaddingH,
  },
  fullHeight: {
    height: '100%'
  },
  // root: props => {
  //   let root = {
  //     width: '100%',
  //     maxWidth: theme.sizes.containerWidth,
  //     marginLeft: 'auto',
  //     marginRight: 'auto',
  //     paddingRight: theme.sizes.containerPaddingH,
  //     paddingLeft: theme.sizes.containerPaddingH,
  //   };

  //   if (props.centerPage) {
  //     root = {
  //       ...root,
  //       minHeight: `calc(100vh - ${theme.sizes.headerHeight}px)`,
  //       display: 'flex',
  //       flexDirection: 'column',
  //       justifyContent: 'center',
  //       alignItems: 'center'
  //     };
  //   }

  //   return root;
  // },

  [theme.queries.mdDevicesDown]: {
    root: {
      paddingLeft: 12,
      paddingRight: 12,
    }
  }
});
