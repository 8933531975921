import React, { Component } from 'react';
import { connect } from 'react-redux';
import { freezeScroll } from '~app/utils/helpers';

import actions from '~app/redux/actions';
import {
  ReviewsModal,
  ReviewModal,
  MessageModal,
  SupportModal,
  ArchiveModal,
  RefuseOfferModal,
  GenericModal,
  RemoveOfferModal,
  ChangeDealerModal,
  ChangeUserModal,
  TradeInModal,
  AdditionalKitModal,
  CancelOfferModal,
} from '~app/components';


const mapStateToProps = state => ({
  modals: state.common.modals,
  user: state.auth.user,
});

@connect(mapStateToProps)
class ModalsContainer extends Component {

  types = {
    'dealerReviews': {
      render: (modal) => {
        return (
          <ReviewsModal
            key={modal.uuid}
            onRequestClose={this.onRequestClose(modal)}
            isOpen={modal.isOpen}
            {...modal.params}
          />
        );
      }
    },
    'createReview': {
      render: (modal) => {
        return (
          <ReviewModal
            key={modal.uuid}
            onRequestClose={this.onRequestClose(modal)}
            isOpen={modal.isOpen}
            {...modal.params}

            onSuccess={() => {
              if (modal.params.onSuccess) {
                modal.params.onSuccess();
              }
              this.onRequestClose(modal)();
            }}
          />
        );
      }
    },
    'createMessage': {
      render: (modal) => {
        return (
          <MessageModal
            key={modal.uuid}
            onRequestClose={this.onRequestClose(modal)}
            isOpen={modal.isOpen}
            {...modal.params}

            onSuccess={() => {
              if (modal.params.onSuccess) {
                modal.params.onSuccess();
              }
              this.onRequestClose(modal)();
            }}
          />
        );
      }
    },
    'support': {
      render: (modal) => {
        if (this.props.user !== null) {
          return (
            <SupportModal
              key={modal.uuid}
              onRequestClose={this.onRequestClose(modal)}
              isOpen={modal.isOpen}
              {...modal.params}
            />
          );
        }
        else {
          return (
            <GenericModal
              key={modal.uuid}
              onRequestClose={this.onRequestClose(modal)}
              open={modal.isOpen}
              title={'Необходима авторизация'}
              text={'Зарегистрируйся на сайте для того, чтобы написать в службу поддержки.'}
              theme={'lighter'}
              icon={'profile'}
            />
          );
        }
      }
    },
    'changeDealer': {
      render: (modal) => {
        if (this.props.user !== null && this.props.user.isDealer) {
          return (
            <ChangeDealerModal
              key={modal.uuid}
              onRequestClose={this.onRequestClose(modal)}
              isOpen={modal.isOpen}
              {...modal.params}
            />
          );
        }
        else {
          return (
            <GenericModal
              key={modal.uuid}
              onRequestClose={this.onRequestClose(modal)}
              open={modal.isOpen}
              title={'Необходима авторизация'}
              text={'Зарегистрируйся на сайте.'}
              theme={'lighter'}
              icon={'profile'}
            />
          );
        }
      }
    },
    'changeUser': {
      render: (modal) => {
        if (this.props.user !== null && !this.props.user.isDealer) {
          return (
            <ChangeUserModal
              key={modal.uuid}
              onRequestClose={this.onRequestClose(modal)}
              isOpen={modal.isOpen}
              {...modal.params}
            />
          );
        }
        else {
          return (
            <GenericModal
              key={modal.uuid}
              onRequestClose={this.onRequestClose(modal)}
              open={modal.isOpen}
              title={'Необходима авторизация'}
              text={'Зарегистрируйся на сайте.'}
              theme={'lighter'}
              icon={'profile'}
            />
          );
        }
      }
    },
    'archive': {
      render: (modal) => {
        return (
          <ArchiveModal
            key={modal.uuid}
            onRequestClose={this.onRequestClose(modal)}
            isOpen={modal.isOpen}
            {...modal.params}

            onSuccess={() => {
              if (modal.params.onSuccess) {
                modal.params.onSuccess();
              }
              this.onRequestClose(modal)();
            }}
          />
        );
      }
    },
    'refuseOffer': {
      render: (modal) => {
        return (
          <RefuseOfferModal
            key={modal.uuid}
            onRequestClose={this.onRequestClose(modal)}
            isOpen={modal.isOpen}
            {...modal.params}

            onSuccess={() => {
              if (modal.params.onSuccess) {
                modal.params.onSuccess();
              }
              this.onRequestClose(modal)();
            }}
          />
        );
      }
    },
    'removeOffer': {
      render: (modal) => {
        return (
          <RemoveOfferModal
            key={modal.uuid}
            onRequestClose={this.onRequestClose(modal)}
            isOpen={modal.isOpen}
            {...modal.params}

            onSuccess={() => {
              if (modal.params.onSuccess) {
                modal.params.onSuccess();
              }
              this.onRequestClose(modal)();
            }}
          />
        );
      }
    },
    'cancelOffer': {
      render: (modal) => {
        return (
          <CancelOfferModal
            key={modal.uuid}
            onRequestClose={this.onRequestClose(modal)}
            isOpen={modal.isOpen}
            {...modal.params}

            onSuccess={() => {
              if (modal.params.onSuccess) {
                modal.params.onSuccess();
              }
              this.onRequestClose(modal)();
            }}
          />
        );
      }
    },
    'tradeIn': {
      render: modal =>
        <TradeInModal
          key={modal.uuid}
          onRequestClose={this.onRequestClose(modal)}
          isOpen={modal.isOpen}
          {...modal.params}
        />
    },
    'additionalKit': {
      render: modal =>
        <AdditionalKitModal
          key={modal.uuid}
          onRequestClose={this.onRequestClose(modal)}
          isOpen={modal.isOpen}
          {...modal.params}
        />
    },
    'generic': {
      render: (modal) => {
        return (
          <GenericModal
            key={modal.uuid}
            onRequestClose={this.onRequestClose(modal)}
            open={modal.isOpen}
            {...modal.params}
          />
        );
      }
    }
  };

  onRequestClose = (modal) => () => {
    let feedback = undefined;
    freezeScroll(true);
    if (modal.onRequestClose) {
      feedback = modal.onRequestClose();
    }
    if (feedback !== false) {
      actions.common.closeModal(modal.uuid);
    }
  }

  render = () => {
    const {
      modals,
    } = this.props;

    return (
      modals.map(modal => {
        if (!(modal.type in this.types)) {
          return null;
        }
        else {
          return this.types[modal.type].render(modal);
        }
      })
    );
  }
}

export default ModalsContainer;
