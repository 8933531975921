export const initialState = {
  banners: null,
  bannersInProgress: false,
  bannersError: null,
};

// Banners
export const getBannersRequest = (state) => ({
  ...state,
  bannersInProgress: true,
  bannersError: null
});
export const getBannersSuccess = (state, { items }) => ({
  ...state,
  banners: items,
  bannersInProgress: false,
  bannersError: null
});
export const getBannersFailure = (state, payload) => ({
  ...state,
  bannersInProgress: false,
  bannersError: payload
});

export const getBannersReset = (state) => ({
  ...state,
  banners: null,
  bannersInProgress: false,
  bannersError: null,
});
