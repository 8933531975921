export default theme => ({

  modal: {
    width: 430,
  },

  title: {
    paddingTop: 14,
    paddingBottom: 26,

    fontSize: 18,
    fontWeight: '500',
    textAlign: 'center',
  },

  ratingOptions: {
    display: 'flex',

    justifyContent: 'space-between',
  },

  ratingOption: {
    display: 'inline-flex',
    width: 'calc(50% - 4px)',
    padding: 20,

    border: `1px solid ${theme.colors.gray.lighter}`,
    borderRadius: 6,

    transition: 'border-color .5s ease, background-color .5s ease',

    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    cursor: 'pointer',
  },

  ratingOptionPositive: {
    '&:hover': {
      borderColor: theme.colors.general.main,
    },
  },
  ratingOptionPositiveActive: {
    borderColor: theme.colors.general.main,
    backgroundColor: theme.colors.general.transparent,
    '& $ratingOptionIcon': {
      color: theme.colors.general.main,
    }
  },

  ratingOptionNegative: {
    '&:hover': {
      borderColor: theme.colors.rare.main,
    }
  },
  ratingOptionNegativeActive: {
    borderColor: theme.colors.rare.main,
    backgroundColor: theme.colors.rare.transparent,
    '& $ratingOptionIcon': {
      color: theme.colors.rare.main,
    }
  },


  ratingOptionIcon: {
    width: 24,
    marginBottom: 12,

    color: theme.colors.gray.lighter,

    transition: 'color .5s ease',
  },
  ratingOptionIconPositive: {
    color: theme.colors.general.main,
  },
  ratingOptionIconNegative: {
    color: theme.colors.rare.main,
  },
  ratingOptionText: {
    width: '100%',
    fontSize: 14,
    textAlign: 'center',
  },

  
  formInput: {
    width: '100%',
    margin: '12px 0 32px',
  },

  formButton: {
    display: 'flex',
    paddingBottom: 17,

    justifyContent: 'center',
  },


  ...theme.styles.modal,

  [theme.queries.smDevicesDown]: {
    modal: {
      width: 'calc(100% - 22px)',
    },
    
    ratingOptions: {
      flexDirection: 'column',
    },
    ratingOption: {
      width: '100%',
      marginTop: 8,
    },

    content: {
      padding: '34px 20px 40px',
    },
  },
  

});