import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import { styles } from './TypographyStyles';

@injectStyles(styles)
class Typography extends Component {

  render() {
    const {
      classes,
      children,
      type,
    } = this.props;

    return (
      <div className={cn(classes.root, (type == 'title' ? classes.title : null))}>
        {children}
      </div>
    );
  }
}

Typography.propTypes = {
  type: PropTypes.oneOf(['title', 'text']),
};

Typography.defaultProps = {
  type: 'text',
};

export default Typography;