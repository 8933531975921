import { Component } from 'react';
import { connect } from 'react-redux';

import { freezeScroll } from '../../utils/helpers';
import actions from '~app/redux/actions';

const mapStateToProps = state => ({
  isAuthorized: state.auth.isAuthorized,
  pathname: state.router.location.pathname
});

@connect(mapStateToProps)
class DataHooks extends Component {

  countsWatchIntevalInstance = null;
  countsWatchInteval = 5000;

  componentDidMount = () => {
  }

  componentWillReceiveProps = (nextProps) => {
    this.checkChangesPathname(this.props.pathname, nextProps.pathname);

    if (this.props.isAuthorized !== nextProps.isAuthorized) {
      if (nextProps.isAuthorized) {
        this.startWatchCounts();
      } else {
        this.stopWatchCounts();
      }
    }
  }

  componentWillUnmount = () => {
    this.stopWatchCounts();
  }

  loadCounts = () => {
    actions.user.getCounts();
  }

  startWatchCounts = () => {
    this.loadCounts();
    this.countsWatchIntevalInstance = setInterval(() => this.loadCounts(), this.countsWatchInteval);
  }

  stopWatchCounts = () => {
    clearInterval(this.countsWatchIntevalInstance);
  }

  checkChangesPathname = (prev, next) => {
    const applicationRegex = /^\/application/;
    if (prev === next) {
      return;
    }
    if (applicationRegex.test(prev) && applicationRegex.test(next)) {
      return;
    }

    window.scrollTo(0, 0);
    freezeScroll(true);
  }  

  render = () => null;
}

export default DataHooks;