import React, { Component } from 'react';

import Constants from 'Constants';
import { Link } from 'react-router-dom';
import { IS_BROWSER } from '~app/config';

class ParentDomainLink extends Component {
  render = () => {
    const {
      children, to, ...rest
    } = this.props;

    if (IS_BROWSER && window.location.host === Constants.LANDER_DOMAIN) {
      return <a href={`${window.location.protocol}//${Constants.UI_DOMAIN}${to}`} {...rest}>
        {children}
      </a>;
    } else {
      return <Link to={to} {...rest}>{children}</Link>;
    }

  }
}
export default ParentDomainLink;