export default theme => ({

  
  modal: {
    display: 'flex',
    width: 'calc(100% - 80px)',

    position: 'relative',
    margin: 'auto',
    // padding: '30px 10px 35px 50px',

    backgroundColor: '#ffffff',

    outline: 'none',
    boxShadow: '0 2px 4px 0 rgba(60,60,80,0.08)',
    borderRadius: 6,

    flexDirection: 'column',

    cursor: 'auto',
    opacity: 0,
    transform: 'scale(0.8)',

    transition: 'all 0.3s ease-in-out',
  },
  modalAfterOpen: {
    opacity: 1,
    transform: 'scale(1)',
  },
  modalBeforeClose: {
    opacity: 0,
    transform: 'scale(0.8)',
  },

  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    
    backgroundColor: 'rgba(60,60,76,0.20)',
    
    outline: 'none',
    cursor: 'pointer',
    opacity: 0,

    transition: 'opacity 0.3s ease-in-out',
  },
  overlayAfterOpen: {
    opacity: 1,
  },
  overlayBeforeClose: {
    opacity: 0,
  },

  head: {
    display: 'flex',

    borderBottom: `1px solid ${theme.colors.gray.lighter}`,

    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headTitle: {
    width: 'calc(100% - 31px)',
  },
  close: {
    display: 'inline-flex',
    width: 50,
    height: 50,
    marginRight: -19,
    padding: 19,

    transform: 'scale(1)',
    transformOrigin: 'center',
    transition: 'transform 0.3s',

    alignItems: 'center',
    justifyContent: 'center',

    cursor: 'pointer',

    '&:hover': {
      transform: 'scale(1.2)',
    },
  },

  content: {
    height: '100%',
    position: 'relative',
    overflowY: 'scroll',
  },

  reviewItem: {
    borderBottom: `1px solid ${theme.colors.gray.lighter}`,

    '&:last-child': {
      borderBottom: 'none',
    }
  },
  

  [theme.queries.lgDevicesUp]: {
    modal: {
      maxWidth: `calc(${theme.sizes.containerWidth}px - 2*${theme.sizes.containerPaddingH}px)`,
      minHeight: 300,
      maxHeight: 'calc(100% - 300px)',
    },

    head: {
      padding: '28px 50px 9px',
    },
  },
  
  [theme.queries.mdDevicesDown]: {
    modal: {
      width: 'calc(100% - 22px)',
      height: 'calc(100% - 22px)',
    },

    head: {
      padding: '8px 21px',
    },
    close: {
      width: 40,
      height: 40,
      marginRight: -15,
      paddingRight: 0,

      fontSize: 28,
    },
  },
});