import { mapper, fromInput } from 'demapper';
import { transformImageUrl } from '../../_helpers';

@mapper
export default class {
  @fromInput('_id')
  id = null;

  @fromInput('name')
  name = null;

  @fromInput('name', { transform: x => x.replace(/[ -.]/g, '').toLowerCase() })
  slug = null;

  @fromInput('image.secure_url', { transform: transformImageUrl })
  image = null;
}
