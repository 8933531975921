export const styles = theme => ({

  root: {
    marginTop: -20,
  },

  paging: {
    display: 'flex',

    justifyContent: 'flex-end',
  },


  item: {
    position: 'relative',
    zIndex: 0,
    marginBottom: 12,

    // '&:hover': {
    //   zIndex: 2,
    // }
  },
  itemActive: {
    zIndex: 2,
  },
  itemWrapper: {
    position: 'relative',
    // zIndex: 1,

    '&:before': {
      content: '""',
      display: 'block',
      height: 6,
      position: 'absolute',
      zIndex: 10000,
      right: 0,
      bottom: 0,
      left: 0,

      backgroundColor: '#fff',
      
      transformOrigin: 'top',
      transform: 'scaleY(0)',
      transition: 'transform .25s ease .5s',
    }
  },
  itemWrapperActive: {
    '&:before': {
      opacity: 1,

      transform: 'scaleY(1)',
      transition: 'transform .15s ease',
    }
  },

  itemBottom: {
    position: 'relative',
    zIndex: 2,
  },

  itemOffered: {
    width: 32,
    position: 'absolute',
    top: 16,
    left: 16,
    zIndex: 400,

    color: theme.colors.general.main,
  },
  
  itemFilters: {
    borderRadius: '0 0 6px 6px',
    borderTop: `1px solid ${theme.colors.gray.lighter}`,
    boxShadow: '0 2px 4px 0 rgba(60,60,80,.08)',

    backgroundColor: '#fff',
  },

  itemButtons: {
    display: 'flex',
    height: 84,
    padding: '12px 0 24px',

    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  itemButton: {
    display: 'inline-flex',
    height: 48,
    padding: '5px 10px',

    fontSize: 14,
    fontWeight: '700',

    color: theme.colors.brand.main,

    alignItems: 'center',

    cursor: 'pointer',
  },

  itemButtonIcon: {
    width: 12,
    height: 12,
    position: 'relative',
    marginRight: 9,

    '&:before, &:after': {
      content: '""',
      display: 'block',
      width: 14,
      height: 2.5,
      position: 'absolute',
      top: 4.75,
      left: -1,

      backgroundColor: 'currentColor',

      transformOrigin: 'center',
    },
    '&:before': {
      transform: 'rotate(-45deg)',
    },
    '&:after': {
      transform: 'rotate(45deg)',
    },
  },

  modalArchiveButton: {
    display: 'flex',
    width: 130,
    margin: '0 auto',
    padding: '21px 0 10px',

    justifyContent: 'center',
  },


  [theme.queries.mdDevicesDown]: {
    paging: {
      justifyContent: 'center'
    },

    modalArchiveButton: {
      width: '100%',
    },
  },
});