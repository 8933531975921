export default {
  rare: {
    main: '#FF3C1E',
    active: '#FF5F41',
    light: '#E6463C',
    transparent: 'rgba(255, 80, 40, 0.04)',
  },
  general: {
    main: '#46D26E',
    active: '#46E178',
    transparent: 'rgba(20, 220, 100, 0.04)',
  },
  brand: {
    main: '#1464DC',
    active: '#1478F0',
    lightest: '#F6FAFE',
  },
  accent: {
    main: '#FAC81E',
    active: '#FFD72D',
    light: 'rgba(255, 140, 40, .12)',
  },

  gray: {
    background: 'rgb(246, 250, 254)',
    lightest: '#FAFDFF',
    light: '#F6F8FA',
    lighter: '#E1E6EB',
    main: '#A0AAB4',
    darker: '#3C3C3C',
    dark: '#282828',
  }
};
