import React, { Component } from 'react';
import { injectStyles } from '~app/theme';
import cn from 'classnames';

import { Icon } from '~app/components/ui';

import styles from './DealerRatingStyles';


@injectStyles(styles)
class DealerRating extends Component {
  render() {
    const {
      userDealer,
      classes,
    } = this.props;
    const negativeRating = userDealer.positiveReviewsCount < userDealer.negativeReviewsCount;

    return (
      <div className={classes.root}>

        <div className={classes.scoreIcon}>
          <Icon name={negativeRating ? 'ratingDown' : 'ratingUp'}/>
        </div>

        <div className={classes.textContent}>

          <div className={cn(classes.score, negativeRating ? classes.scoreNegative : null)}>
            {userDealer.ratingScore}
          </div>

          {userDealer.positiveReviewsCount === 0 && userDealer.negativeReviewsCount === 0 ?
            <div className={classes.reviews}>
              <div className={classes.reviewsTitle}>{'Отзывы'}:</div>
              <div className={cn(classes.reviewsItem)}>0</div>
            </div>
            :
            <div className={classes.reviews}>
              <div className={classes.reviewsTitle}>{'Отзывы'}:</div>
              <div className={cn(classes.reviewsItem, userDealer.positiveReviewsCount > 0 ? classes.reviewsItemPositive : null)}>
                {userDealer.positiveReviewsCount > 0 && '+'}
                {userDealer.positiveReviewsCount}
              </div>
              <div className={cn(classes.reviewsItem, userDealer.negativeReviewsCount > 0 ? classes.reviewsItemNegative : null)}>
                {userDealer.negativeReviewsCount > 0 && '-'}
                {userDealer.negativeReviewsCount}
              </div>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default DealerRating;
