import React, { Component } from 'react';

import { injectStyles } from '~app/theme';
import { styles } from './ModelStyles';

@injectStyles(styles)
class Model extends Component {

  onChange = (name) => {
    this.props.onChangeOption(name);
  }

  renderItem = (item) => {
    const { classes } = this.props;

    return (
      <div
        className={classes.item}
        onClick={() => this.onChange(item.slug)}
        key={item.slug}
      >
        <div className={classes.itemIcon}>
          <img src={item.image} className={classes.itemIconImg}/>
        </div>
        <div className={classes.itemName}>{item.name}</div>
      </div>
    );
  }

  render() {
    const {
      classes,
      models,
    } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.list}>
          {models !== null && models.map((item) => {
            return (this.renderItem(item));
          })}
        </div>
      </div>
    );
  }
}

export default Model;