export default theme => ({

  title: {
    paddingBottom: 6,
    color: theme.colors.gray.dark,
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: '25px',
    textAlign: 'center',
  },
  text: {
    marginTop: '20px',
    marginBottom: '20px',
    
    fontSize: '14px',
    color: theme.colors.gray.dark,
    textAlign: 'center',
    lineHeight: '24px',
  },
  
  form: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: 'calc(100% + 10px)',
    margin: '19px -5px -6px',
  },
  formInput: {
    width: '100%',
    margin: '6px 5px',
    textAlign: 'left',
  },
  formInputLabel: {
    display: 'block',
    fontSize: '12px',
    color: theme.colors.gray.main,
    textAlign: 'center',
  },
  formInputLabelError: {
    color: theme.colors.rare.light,
  },
  formInputLabelSuccess: {
    color: theme.colors.general.main,
  },

  nav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: theme.colors.brand.main,
    fontSize: '12px',
    textAlign: 'center',
    
    cursor: 'pointer',

    transition: 'color 0.3s',
    '&:hover': {
      color: theme.colors.brand.active,
    }
  },
  navIcon: {
    display: 'inline-block',
    width: '16px',
  },
  navIconChange: {
    width: 10,
    marginRight: 4,
    transform: 'rotate(180deg)',
  },

  timer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: theme.colors.gray.main,
    fontSize: '12px',
    textAlign: 'center',
  },
  timerIcon: {
    display: 'inline-block',
    width: '14px',
    marginRight: 8,
  },

  codeTitle: {
    marginTop: '40px',

    color: theme.colors.gray.main,
    fontSize: '12px',
  },

  [theme.queries.smDevicesDown]: {
    formInputLabel: {
      paddingTop: '20px',
    },
  },
  

});