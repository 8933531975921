export default (theme) => ({
  
  root: {
    position: 'relative',

    cursor: 'pointer',
  },

  [theme.queries.mdDevicesDown]: {
    mobileFullWidth: {
      width: '100%',
    },
  }
});