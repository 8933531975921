import reducers from '~app/core/reducers';
import { mainClient } from '~app/network';


// Get news by key
export const getNewsByKey = async (key, params) => {
  reducers.news.getNewsByKeyRequest({ key });
  const { data, error } = await mainClient.news.getNews({ ...params });

  if (error !== null) {
    reducers.news.getNewsByKeyFailure({ key, error });
    return;
  }
  reducers.news.getNewsByKeySuccess({ key, data });
};

// Get news item by id
export const getNewsItem = async (params) => {
  reducers.news.getNewsItemRequest();
  const { data, error } = await mainClient.news.getNewsItem(params);

  if (error !== null) {
    reducers.news.getNewsItemFailure(error);
    return;
  }

  reducers.news.getNewsItemSuccess(data);
};

export const getNewsItemReset = () => {
  reducers.news.getNewsItemReset();
};