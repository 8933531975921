export const styles = theme => ({
  root: {
    paddingBottom: '75px',

    background: theme.colors.brand.lightest,
  },
  head: {
    display: 'flex',
    paddingBottom: 21,

    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headLink: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    fontSize: 12,
    fontWeight: '400',
    textDecoration: 'none',

    color: theme.colors.brand.main,

    cursor: 'pointer'
  },
  headLinkIcon: {
    display: 'inline-block',
    width: '10px',
    marginLeft: '5px',
    marginTop: '2px',
  },

  bodyHead: {
    display: 'flex',
    padding: '20px 0 4px',
  },
  bodyBottom: {
    display: 'flex',
    padding: '4px 0 96px',
  },

  body: {
    paddingTop: 40,
  },

  [theme.queries.lgDevicesUp]: {
    head: {
      paddingTop: 40,
    },

    bodyHead: {
      justifyContent: 'flex-end',
    },
    bodyBottom: {
      justifyContent: 'flex-end',
    },
  },

  [theme.queries.mdDevicesDown]: {
    head: {
      paddingTop: 34,
    },
    headLink: {
      fontSize: 10,
    },

    bodyHead: {
      justifyContent: 'center',
    },
    bodyBottom: {
      justifyContent: 'center',
    },
  },
});