import reducers from '~app/core/reducers';
import { mainClient } from '~app/network';

// Create

export const createApplication = async (params) => {
  reducers.applications.createApplicationRequest();
  const { data, error } = await mainClient.applications.createApplication(params);
  
  if (error !== null) {
    reducers.applications.createApplicationFailure(error);
    return;
  }

  reducers.applications.createApplicationSuccess(data);
};

// Get Applications

export const getApplications = async (params) => {
  reducers.applications.getApplicationsRequest();
  const { data, error } = await mainClient.applications.getApplications({ ...params });
  
  if (error !== null) {
    reducers.applications.getApplicationsFailure(error);
    return;
  }

  reducers.applications.getApplicationsSuccess(data);
};

// Get applications by key
export const getItemsByKey = async (key, params) => {
  reducers.applications.getItemsByKeyRequest({ key });
  const { data, error } = await mainClient.applications.getApplications({ ...params });
  
  if (error !== null) {
    reducers.applications.getItemsByKeyFailure({ key, error });
    return;
  }

  reducers.applications.getItemsByKeySuccess({ key, data });
};

// Get application by id
export const getItemById = async (id) => {
  reducers.applications.getItemByIdRequest({ id });
  const { data, error } = await mainClient.applications.getApplication({ id });
  
  if (error !== null) {
    reducers.applications.getItemByIdFailure({ id, error });
    return;
  }

  reducers.applications.getItemByIdSuccess({ id, data });
};


// Archive item
export const archiveItem = async (id) => {
  reducers.applications.archiveApplicationRequest({ id });
  const { data, error } = await mainClient.applications.archiveApplication({ id });
  
  if (error !== null) {
    reducers.applications.archiveApplicationFailure({ id, error });
    return;
  }

  reducers.applications.archiveApplicationSuccess({ id, data });
};

