export default (theme) => ({
  root: {
    width: '100%',
    height: 500,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    position: 'relative',
    backgroundColor: '#f6fafe',
    padding: '12px 0 8px'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
    position: 'relative',
    width: '100%',
    zIndex: 1,
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%'
  },
  image: {
    width: '100%',
    height: 0,
    paddingBottom: 'calc(100%/570*480)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
    lineHeight: 1.33,
    color: '#1464dc',
    margin: '0 0 18px',
    maxWidth: 500,
    whiteSpace: 'pre-line',
  },
  text: {
    fontSize: 14,
    lineHeight: 2,
    letterSpacing: 0,
    color: '#282828',
    margin: '0 0 51px',
    maxWidth: 540,
    whiteSpace: 'pre-line',
  },
  [theme.queries.mdDevicesDown]: {
    root: {
      height: 'auto',
      padding: '28px 0 80px'
    },
    container: {
      flexWrap: 'wrap'
    },
    link: {
      width: '100%'
    },
    title: {
      fontSize: 24,
      margin: '0 0 20px'
    },
    text: {
      lineHeight: 1.71,
      margin: '0 0 45px',
    },
    left: {
      order: 2,
    },
    right: {
      order: 1
    }
  },

  [theme.queries.lgDevicesUp]: {
    left: {
      width: 'calc(50% - 30px)',
      paddingLeft: 30,
    },
    right: {
      width: 'calc(50% - 30px)',
      paddingRight: 30,
    }
  },

  [theme.queries.lgDevicesOnly]: {

  },

});
