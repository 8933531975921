import car from '~assets/images/banner-car.png';
import man from '~assets/images/banner-man.png';

export default theme => ({
  banner: {
    position: 'relative',

    minHeight: 360,
    padding: '51px 60px 56px',
    marginBottom: 40,

    backgroundImage: 'radial-gradient(circle at 68% 50%, #fadc3c, #fabe1e)',

    borderRadius: '6px',
    boxShadow: '0px 33px 15px -15px rgba(60, 60, 80, 0.08)',
  },

  _brand: {
    backgroundImage: 'radial-gradient(circle at 68% 50%, #599bff, #1464dc)',
  },

  title: {
    maxWidth: 420,
    marginBottom: 15,

    color: theme.colors.gray.dark,
    fontSize: '36px',
    fontWeight: 'bold',
    lineHeight: 1.35,

    '$_brand &': {
      color: '#ffffff',
    }
  },
  subtitle: {
    maxWidth: 370,
    marginBottom: 30,

    color: '#FFF',
    fontSize: '18px',
    letterSpacing: '0.2px',
    lineHeight: 1.8,
  },
  subtitleLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  subtitleLineIcon: {
    width: 14,
    height: '1.8em',
    marginRight: 8,

    color: 'currentColor'
  },
  button: {
    width: 184,
  },
  howItWorksLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit'
  },
  bubble: {
    position: 'absolute',
    bottom: 224,
    left: 570,
    zIndex: 1,
    
    padding: '20px 28px 19px 28px',

    backgroundColor: '#FFF',
    
    boxShadow: '0 2px 4px 0 rgba(60, 60, 80, 0.08)',
    borderRadius: 20,
    '&:before': {
      content: '""',

      position: 'absolute',
      left: 0,
      bottom: 3,
      zIndex: 1,

      width: 6,
      height: 17,

      backgroundColor: '#FFF',

      transform: 'skewY(-45deg)',
      boxShadow: '-2px 2px 2px 0 rgba(60, 60, 80, 0.04)',

    }
  },
  bubblePrice: {
    marginBottom: 5,
    color: '#14dc64',
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: 1,
  },
  bubblePriceSign: {
    fontSize: '18px',
    fontWeight: 800,
    letterSpacing: '-0.2px',
  },
  bubbleComment: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.43,
    color: theme.colors.gray.dark,
  },
  bubble_hidden: {
    animation: 'hide 0.5s 1 ease-in-out forwards',
  },
  bubble_shown: {
    animation: 'show 0.5s 1 ease-in-out forwards',
  },

  car: {
    position: 'absolute',
    bottom: 0,
    right: 260,
    zIndex: 2,
    
    width: 410,
    height: 268,
    overflow: 'hidden',
    
    backgroundImage: `url(${car})`,
    backgroundPosition: 'top left',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
  },
  man: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 121,
    
    width: 370,
    height: 370,
    overflow: 'hidden',
    
    backgroundImage: `url(${man})`,
    backgroundPosition: 'top left',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',

    pointerEvents: 'none'

  },
  [theme.queries.lgDevicesDown]: {
    man: {
      display: 'none',
    },
    car: {
      right: 130,
    }
  },
  [theme.queries.mdDevicesDown]: {
    banner: {
      padding: '25px 20px 0px',

      '&:before': {
        content: '""',
        display: 'block',
        height: 100,
        position: 'absolute',
        top: -100,
        right: -12,
        left: -12,

        backgroundColor: '#fff',
      },
    },
    car: {
      right: 30,
    },
    bubble: {
      left: 470,
      bottom: 250,
    },
    button: {
      position: 'absolute',
      bottom: 33,
      left: 20,
      zIndex: 3,

      maxWidth: 320,
      width: 'calc(100% - 40px)',
    },
    subtitle: {
      position: 'relative',
      zIndex: 2,
    }
  },
  [theme.queries.smDevicesDown]: {
    banner: {
      padding: '26px 20px 360px',
    },
    car: {
      bottom: 33,
      left: 0,
      width: '100%',
      backgroundSize: 340,
      backgroundPosition: 'center 0',
    },
    title: {
      maxWidth: 260,
      marginBottom: 7,
      fontSize: '24px',
      lineHeight: 1.5,
    },
    subtitle: {
      marginBottom: 0,
  
      fontSize: '14px',
      lineHeight: 2,
    },
    button: {
      left: '50%',

      transform: 'translateX(-50%)',
    },
    bubble: {
      bottom: 265,
      left: 48,
      
      padding: '17px 27px 18px 24px',

      borderRadius: 17,
    },
    bubblePrice: {
      marginBottom: 7,
      fontSize: '18px',
    },
    bubblePriceSign: {
      fontSize: '14px',
    },
    bubbleComment: {
      fontSize: '12px',
      lineHeight: 1,
    },
  },
  [theme.queries.xsDevicesDown]: {
    car: {
      backgroundPosition: 'center 0',
    },
  },
  '@keyframes show': {
    '0%': {
      transform: 'translateY(100px) scale(0.6)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
  '@keyframes hide': {
    '0%': {
      transform: 'translateY(0) scale(1)',
      opacity: 1,
    },
    '100%': {
      transform: 'translateY(-75px) scale(0.6)',
      opacity: 0,
    },
  },
});