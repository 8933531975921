export const styles = theme => ({
  root: {
    padding: '30px 0 0',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%',
    marginBottom: 60,
  },
  left: {

  },
  divider: {
    width: 1,
    backgroundColor: '#a0aab4',
    opacity: .19,
    margin: '0 28px',
    flexShrink: '0',
    alignSelf: 'stretch',
  },
  right: {
    width: 270,
    flexShrink: '0',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#282828',
    margin: '0 0 30px',
  },
  paging: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  item: {
    overflow: 'hidden',
    height: 222,
    borderRadius: 6,
    boxShadow: '0 2px 4px 0 rgba(60, 60, 60, 0.08)',
    backgroundColor: '#ffffff',
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'inherit',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 30,
  },
  generalItem: {
    borderRadius: 6,
    boxShadow: '0 2px 4px 0 rgba(60, 60, 60, 0.08)',
    backgroundColor: '#ffffff',
    padding: '30px 28px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 30
  },
  itemLeft: {
    width: 370,
    height: '100%',
    display: 'flex',
    padding: 20,
    position: 'relative',
    flexShrink: '0'
  },
  itemRight: {
    padding: '30px 40px 25px 40px',
  },
  dealerLogo: {
    zIndex: 1,
    width: 76,
    height: 36,
    borderRadius: 3.6,
    boxShadow: '0 4px 12px 0 rgba(20, 100, 220, 0.09)',
    backgroundColor: '#ffffff',
    padding: 9,
    display: 'flex',
  },
  dealerLogoContent: {
    backgroundPosition: '50% 50%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
  },
  showMore: {
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2.29,
    letterSpacing: 'normal',
    color: '#1464dc',
    textAlign: 'center',
    textDecoration: 'none',
    marginTop: 24,
    display: 'block',
    cursor: 'pointer',
  },
  name: {
    fontSize: 14,
    lineHeight: 1.71,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    color: '#282828',
    margin: '0 0 14px',
  },
  big: {
    maxHeight: 'calc(14px * 1.71 * 2)',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
  },
  descr: {
    fontSize: 14,
    lineHeight: 2,
    maxHeight: 'calc(14px * 2 * 2)',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    color: '#282828',
    margin: '0 0 30px',
  },
  [theme.queries.lgDevicesUp]: {
    mobile: {
      display: 'none',
    },
  },

  [theme.queries.mdDevicesDown]: {
    desktop: {
      display: 'none',
    },
    title: {
      fontSize: 18,
      margin: '0 0 20px',
    },
    itemRight: {
      padding: '20px',
    },
    item: {
      height: 'auto',
      flexDirection: 'column',
      margin: '0 0 12px',
    },
    name: {
      fontSize: 14,
      lineHeight: 1.71,
      margin: '0 0 25px',
    },
    content: {
      flexWrap: 'wrap'
    },
    itemLeft: {
      width: '100%',
      height: 222,
    },
    divider: {
      display: 'none'
    },
    descr: {
      display: 'none',
    },
    left: {
      marginBottom: 40,
    },
    right: {
      width: '100%',
      maxWidth: 350,
    }
  },
});
