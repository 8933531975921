

export default theme => ({

  root: {

  },

  selects: {
    position: 'relative',
    zIndex: 2,
    padding: '27px 50px 28px',

    borderRadius: '0 0 6px 6px',
    borderTop: `1px solid ${theme.colors.gray.lighter}`,
    boxShadow: '0 2px 4px 0 rgba(60,60,80,.08)',

    backgroundColor: '#fff',
  },
  form: {
    display: 'flex',
  },

  select: {
    width: '50%',
    position: 'relative',
    paddingRight: 10,

    flexBasis: 'auto',

    '&:last-child': {
      paddingRight: 0,
    }
  },

  bottom: {
    display: 'flex',
    paddingTop: 12,
    paddingBottom: 12,

    justifyContent: 'flex-end',
  },
  gearIcon: {
    position: 'absolute',
    zIndex: 1,
    top: 15,
    left: 17,
    width: 20,
    height: 20,
    cursor: 'pointer'
  },
  // 'disabled': {
  //   color: '#696969'
  // },
  'enabled': {
    color: '#fac81e'
  },

  changeLink: {
    cursor: 'pointer',
    color: '#1464dc',
    marginTop: 12,
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'right',
    display: 'inline-block',
  },

  [theme.queries.smDevicesDown]: {

    selects: {
      width: '100%',
      padding: '31px 20px 24px',
    },
    form: {
      flexDirection: 'column',
    },
    select: {
      width: '100%',
      paddingRight: 0,
      paddingBottom: 8,
    },
  }
});
