import React, { Component } from 'react';
import { injectStyles } from '~app/theme';
import { formContainer, formCreate } from 'deforms';
import cn from 'classnames';

import { Button, TextInput, Checkbox, Spacer } from '~app/components/ui';

import styles from './ChangeUserStepStyles';

@injectStyles(styles)
@formContainer
class ChangeUserStep extends Component {

  @formCreate()
  formChangeUser = {
    schema: {
      firstName: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
          minlength: 2
        }
      },
      lastName: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
          minlength: 2
        }
      },
      email: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
          minlength: 5
        }
      },
      phone: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
          minlength: 12
        }
      },
      agreement: {
        defaultValue: true,
        validation: {
          type: 'boolean',
          conform: x => x
        }
      },
    },
    onSubmit: (formData) => {
      this.changeUserOnSubmit(formData);
    }
  }

  componentDidMount = () => {
    this.fillForm();
  }

  fillForm = () => {
    const {
      user, 
      data = {}
    } = this.props;

    const formData = {
      ...user,
      ...data
    };
    
    const formChangeUser = this.formChangeUser;


    formChangeUser.fields.firstName.changeValue(formData.firstName);
    formChangeUser.fields.lastName.changeValue(formData.lastName);
    formChangeUser.fields.email.changeValue(formData.email);
    formChangeUser.fields.phone.changeValue(formData.phone);
  }


  changeUserOnSubmit = (formData) => {
    this.props.onSubmit(formData);
  }

  render() {
    const {
      classes
    } = this.props;
    const formChangeUser = this.formChangeUser;

    return (
      <div>
        <div className={classes.title}>{'Изменение данных профиля'}</div>
        <form noValidate
          className={classes.form}
          onSubmit={formChangeUser.submit}
        >
          <div className={cn(classes.formInput,classes.formInput_half)}>
            <TextInput 
              name={'firstName'}
              type={'string'}
              placeholder={'Имя'}
              onChange={formChangeUser.fields.firstName.change}
              value={formChangeUser.fields.firstName.value}
              error={formChangeUser.submitted && !formChangeUser.fields.firstName.valid}
            />
          </div>
          <div className={cn(classes.formInput,classes.formInput_half)}>
            <TextInput 
              name={'lastName'}
              type={'string'}
              placeholder={'Фамилия'}
              onChange={formChangeUser.fields.lastName.change}
              value={formChangeUser.fields.lastName.value}
              error={formChangeUser.submitted && !formChangeUser.fields.lastName.valid}
            />
          </div>
          <div className={classes.formInput}>
            <TextInput 
              name={'email'}
              type={'email'}
              placeholder={'Email'}
              onChange={formChangeUser.fields.email.change}
              value={formChangeUser.fields.email.value}
              error={formChangeUser.submitted && !formChangeUser.fields.email.valid}
            />
          </div>
          <div className={classes.formInput}>
            <TextInput 
              name={'phone'}
              type={'tel'}
              placeholder={'Телефон'}
              onChange={(event) => {
                formChangeUser.fields.phone.changeValue(event.target.value.replace(/ /g, ''));
              }}
              value={formChangeUser.fields.phone.value}
              error={formChangeUser.submitted && !formChangeUser.fields.phone.valid}
              mask={'+7 999 999 99 99'}
            />
          </div>
          <Spacer v={12}/>
          <div className={classes.formInput}>
            <Checkbox
              name={'agreement'}
              label={'Я принимаю %LINK%'}
              labelStyles={classes.checkboxLabel}
              link={{
                placeholder: '%LINK%',
                text: 'условия пользовательского соглашения',
                url: '/static/files/agreement.pdf'
              }}
              checked={formChangeUser.fields.agreement.value}
              onChangeValue={formChangeUser.fields.agreement.changeValue}
              error={formChangeUser.submitted && !formChangeUser.fields.agreement.valid}
            />
          </div>
          <div className={classes.formAction}>
            <Button
              mobileBlock
              theme={'brand'}
              text={'Изменить'}
              onClick={formChangeUser.submit}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default ChangeUserStep;
