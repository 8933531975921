import React, { Component } from 'react';

import { injectStyles } from '~app/theme';
import styles from './SalePercentStyles';
import { Icon, Hint } from '~app/components/ui';

import PropTypes from 'prop-types';
import cn from 'classnames';

@injectStyles(styles)

class SalePercent extends Component {
  state = {
    showHint: false
  };

  onMouseOver = () => {
    if (this.props.hint !== null) {
      this.setState({
        showHint: true
      });
    }
  }

  onMouseLeave = () => {
    if (this.props.hint !== null) {
      this.setState({
        showHint: false
      });
    }
  }

  render = () => {
    const {
      classes,
      hint = null,
      absolute = false,
      value
    } = this.props;

    const { showHint } = this.state;

    return (
      <div
        onMouseLeave={this.onMouseLeave}
        onMouseOver={this.onMouseOver}
        className={cn(classes.root, { [classes.absolute]: absolute })}>
        <Icon block name={'crown'} />
        {`Скидка ${value}%`}
        {hint &&
          <Hint direction={'left'} isActive={showHint}>{hint}</Hint>
        }
      </div>
    );
  }
}

SalePercent.propTypes = {
  value: PropTypes.number,
  hint: PropTypes.string,
  absolute: PropTypes.bool
};

export default SalePercent;
