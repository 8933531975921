import React, { Component } from 'react';

import { Hint } from '../';
import { injectStyles } from '~app/theme';
import styles from './HintHoverContainerStyles';

import cn from 'classnames';

@injectStyles(styles)
class HintHoverContainer extends Component {

  state = {
    hintShown: this.props.shown,
  };

  render = () => {
    const {
      classes,
      direction,
      hint,
      html,
      mobileFullWidth,
    } = this.props;

    return (
      <div
        className={cn(classes.root, {
          [classes.mobileFullWidth]: mobileFullWidth,
        })}
        onMouseOver={() => { this.setState({ hintShown: true }); }}
        onMouseLeave={() => { this.setState({ hintShown: false }); }}
      >
        {this.props.children}
        <Hint
          isActive={this.state.hintShown}
          direction={direction}
        >
          {html ?
            <div dangerouslySetInnerHTML={{ __html: html }} />
            :
            <div>{hint}</div>
          }
        </Hint>
      </div>
    );
  }
}

export default HintHoverContainer;