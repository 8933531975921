export const styles = theme => ({
  head: {
    paddingTop: '40px',
    paddingBottom: '34px',
  },

  sectionActive: {
    position: 'relative',
    zIndex: 3,
  },

  sectionHead: {
    display: 'flex',
    height: '62px',

    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sectionHeadOption: {
    display: 'inline-flex',

    fontSize: '18px',

    alignItems: 'center',
  },
  sectionHeadOptionLogo: {
    width: '26px',
    marginLeft: '12px',
  },

  sectionHeadChange: {
    fontSize: '14px',
    
    color: theme.colors.brand.main,

    cursor: 'pointer',
  },

  sectionOptionAny: {
    fontSize: '14px',
    fontWeight: '500',

    color: theme.colors.brand.main,

    cursor: 'pointer',
  },

  bottom: {
    display: 'flex',
  },


  [theme.queries.lgDevicesUp]: {
    bottom: {
      justifyContent: 'flex-end',
    },
    mobile: {
      display: 'none'
    },
  },

  [theme.queries.mdDevicesDown]: {
    head: {
      paddingTop: '34px',
      paddingBottom: '31px',
    },

    bottom: {
      justifyContent: 'center',
      flexWrap: 'wrap',
    },

    desktop: {
      display: 'none'
    },

  },
});