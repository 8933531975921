import React, { Component } from 'react';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import styles from './PromoPreviewsStyles';

import { Link } from 'react-router-dom';
import { Carousel } from '~app/components/ui';
import { PromoItemCard } from '..';

@injectStyles(styles)

class PromoPreviews extends Component {

  render = () => {
    const {
      classes,
      items,
    } = this.props;

    return (
      <div className={cn(classes.root)}>
        <div className={classes.head}>
          <h3 className={classes.title}>
            {'Акции'}
          </h3>
          <Link
            to={'/promo'}
            className={cn(classes.link, classes.hideMob)}>
            {'Смотреть все акции'}
          </Link>
        </div>
        <Carousel
          desktopItems={4}
          mobileItems={4}
          items={items.map(item =>
            <PromoItemCard item={item} key={item.id} />
          )} />
        <div className={cn(classes.foot, classes.hideDesk)}>
          <Link to={'/promo'} className={classes.link}>{'Смотреть все акции'}</Link>
        </div>
      </div>
    );
  }
}

export default PromoPreviews;
