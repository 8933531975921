export const styles = theme => ({
  root: {
    padding: '30px 0 0',
  },
  content: {
    // display: 'flex',
    // justifyContent: 'space-between',
    // alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    marginBottom: 60,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#282828',
    margin: '0 0 30px',
  },
  paging: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  items: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    margin: '0 -15px',
    paddingBottom: 30,
  },
  showMore: {
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2.29,
    letterSpacing: 'normal',
    color: '#1464dc',
    textAlign: 'center',
    textDecoration: 'none',
    marginTop: 24,
    display: 'block',
    cursor: 'pointer',
  },
  [theme.queries.lgDevicesUp]: {
    mobile: {
      display: 'none',
    },
  },

  [theme.queries.mdDevicesDown]: {
    desktop: {
      display: 'none',
    },
    title: {
      fontSize: 18,
      margin: '0 0 20px',
    },
  },
});
