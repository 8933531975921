import React, { Component } from 'react';
import cn from 'classnames';
import { Icon, ParentDomainLink } from '~app/components/ui';

import { injectStyles } from '~app/theme';
import styles from '~app/components/HeaderLander/HeaderLanderStyles';

import PropTypes from 'prop-types';
import Triggers from '~app/components/Triggers/Triggers';

@injectStyles(styles)

class HeaderLander extends Component {
  render = () => {
    const {
      classes,
      city,
      iconText1,
      iconText2,
      phone,
      brandLogoUrl
    } = this.props;

    return (
      <div className={cn(classes.wrapper)}>
        <div className={cn(classes.root)}>
          <div className={classes.content}>
            <div className={classes.left}>
              {brandLogoUrl &&
                <div
                  style={{ backgroundImage: `url(${brandLogoUrl})` }}
                  className={classes.brandLogo} />
              }
              <Triggers iconText1={iconText1} iconText2={iconText2} />
            </div>
            <div className={classes.right}>
              <div className={classes.city}>
                <div className={classes.cityIcon}>
                  <Icon name={'pin'} />
                </div>
                {city && <div className={classes.cityActive}>{city}</div>}
              </div>
              <div className={classes.phone}>
                {phone &&
                  <a href={`tel:${phone}`} className={classes.phoneLink} />
                }
                <div className={classes.phoneIcon}>
                  <Icon name={'phone'} />
                </div>
                {phone &&
                  <div className={classes.phoneText}>{phone}</div>
                }
              </div>
              <div className={classes.logo}>
                <ParentDomainLink to={'/'} className={classes.logoLink} />
                <div className={classes.logoImg}>
                  <Icon name={'logo'} />
                </div>
              </div>
            </div>
            <div className={classes.mobileContacts}>
              {phone &&
                <a
                  href={`tel:${phone}`}
                  className={classes.mobilePhone}>
                  {phone}
                </a>
              }
              {city &&
                <div className={classes.mobileCity}>{city}</div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HeaderLander.propTypes = {
  brandLogoUrl: PropTypes.string.isRequired
};

export default HeaderLander;
