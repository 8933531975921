export default (theme) => ({
  
  root: {
    display: 'flex',
    maxWidth: '300px',
    margin: '0 auto',
    padding: '100px 25px',

    color: theme.colors.gray.main,

    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    width: 48,
    fontSize: '48px',
    marginBottom: 15,
  },
  text: {
    maxWidth: 240,
    paddingBottom: 25,

    fontSize: 14,
    lineHeight: '24px',
    textAlign: 'center',
  },

  [theme.queries.mdDevicesDown]: {
    root: {
      padding: '100px 0',
    }
  }
});