import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { injectStyles } from '~app/theme';
import { connect } from 'react-redux';
import { formContainer, formCreate } from 'deforms';
import actions from '~app/redux/actions';

import { Button, TextInput } from '~app/components/ui';
import { freezeScroll } from '../../utils/helpers';

import styles from './SupportModalStyles';

const mapStateToProps = (state) => {
  return {
    inProgress: state.common.supportMessageInProgress,
    success: state.common.supportMessageSuccess,
    error: state.common.supportMessageError,
  };
};

@connect(mapStateToProps)
@injectStyles(styles)
@formContainer
class SupportModal extends Component {

  @formCreate()
  formSupport = {
    schema: {
      subject: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
          minlength: 5
        }
      },
      message: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
          minlength: 5
        }
      }
    },
    onSubmit: (formData) => {
      this.supportOnSubmit(formData);
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (!this.props.success && nextProps.success) {
      this.onSuccess();
    }
  }

  supportOnSubmit = (formData) => {
    if (this.props.inProgress) {
      return;
    }
    actions.common.sendSupportMessage(formData);
  }

  onSuccess = () => {
    this.props.onRequestClose();
    actions.common.openGenericModal({
      icon: 'support',
      title: 'Спасибо за обращение!',
      text: 'Наша служба поддержки всегда готова помочь и ответить на все вопросы.',
    });
  }

  onRequestClose = () => {
    this.props.onRequestClose();
    freezeScroll(true);
  }
  onOpen = () => {
    freezeScroll();
  }

  render() {
    const {
      classes,
      isOpen,
    } = this.props;
    const formSupport = this.formSupport;

    return (
      <ReactModal
        className={{
          base: classes.modal,
          afterOpen: classes.modalAfterOpen,
          beforeClose: classes.modalBeforeClose,
        }}
        overlayClassName={{
          base: classes.overlay,
          afterOpen: classes.overlayAfterOpen,
          beforeClose: classes.overlayBeforeClose,
        }}
        isOpen={isOpen}
        onAfterOpen={this.onOpen}
        closeTimeoutMS={300}
        key={this.props.isOpen}
        contentLabel={'modal'}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick>

        <a className={classes.close} onClick={this.onRequestClose} />
        <div className={classes.content}>
          <div className={classes.title}>{'Служба поддержки'}</div>

          <form noValidate className={classes.form}
            onSubmit={formSupport.submit}
          >
            <div className={classes.formInput}>
              <TextInput
                name={'subject'}
                type={'text'}
                placeholder={'Тема письма'}
                onChange={formSupport.fields.subject.change}
                value={formSupport.fields.subject.value}
                error={formSupport.submitted && !formSupport.fields.subject.valid}
              />
            </div>
            <div className={classes.formInput}>
              <TextInput
                name={'message'}
                type={'textarea'}
                placeholder={'Сообщение'}
                onChange={formSupport.fields.message.change}
                value={formSupport.fields.message.value}
                error={formSupport.submitted && !formSupport.fields.message.valid}
              />
            </div>
            <div className={classes.formAction}>
              <Button
                mobileBlock
                theme={'brand'}
                text={'Отправить'}
              />
            </div>
          </form>
        </div>

      </ReactModal>
    );
  }
}

export default SupportModal;
