export const styles = theme => ({
  root: {
    position: 'relative',
    display: props => props.block ? 'block' : 'inline-block',
    width:  props => props.block ? '100%' : 'auto',

    '&:before': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      opacity: 0,
      zIndex: 0,

      borderRadius: 24,

      transition: 'opacity .5s ease, transform .5s ease',
      transform: 'translateY(-8px)',

      pointerEvents: 'none',
    },
    '&:hover': {
      '& $buttonBackground': {
        transform: 'scale(1.05 ,1.16)',
      },
      '&:before': {
        opacity: .6,
        transform: 'translateY(0)',
      },
      '& $placeholder': {
        color: '#fff',
      },
    },
    '&:hover$_transparent:before': {
      opacity: 0
    }
  },
  button: {
    display: props => props.block ? 'flex' : 'inline-flex',
    width:  props => props.block ? '100%' : 'auto',
    height: 48,
    minWidth: 48,
    // position: 'relative',
    zIndex: 1,
    padding: '15px 24px',

    outline: 'none',
    border: 'none',

    fontSize: 14,
    fontWeight: 'bold',

    color: '#fff',
    backgroundColor: 'transparent',

    // opacity: props => props.loading ? 0 : 1,
    cursor: 'pointer',
    // pointerEvents: props => props.loading ? 'none' : 'all',

    justifyContent: 'center',
    alignItems: 'center',

    '$_small &': {
      height: 30,
      padding: '5px 20px',
      fontSize: 12,
    }
  },
  _transparent: {

  },
  _small: {

  },
  buttonBackground: {
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 0,

    borderRadius: 24,

    transition: 'transform .5s ease, background-color .5s ease',
    transform: 'scale(1)',
  },
  // loader: (props) => ({
  //   display: 'flex',
  //   width: '100%',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   position: 'absolute',
  //   top: 0,
  //   left: 0,
  //   right: 0,
  //   bottom: 0,
  //   opacity: props.loading ? 1 : 0,
  //   pointerEvent: 'none',
  //   transform: props.loading ? 'scaleY(1)' : 'scaleY(0)',
  //   transition: 'transform .3s ease, opacity .3s ease',
  // }),
  buttonText: {
    position: 'relative',
    zIndex: 1,
  },

  icon: {
    display: 'inline-flex',
    width: 20,
    fontSize: '13px',
    position: 'relative',
    zIndex: 1,

    alignItems: 'center',
  },


  _rare: {
    '&:before': {
      boxShadow: `0 8px 20px 0 ${theme.colors.rare.active}`,
    },
    '& $buttonBackground': {
      borderColor: theme.colors.rare.main,
      backgroundColor: theme.colors.rare.main,
    },
    '&$_transparent $buttonBackground': {
      backgroundColor: 'transparent',
      color: theme.colors.rare.main,
    },
    '& $placeholder': {
      color: theme.colors.rare.main,
    },
    '&:hover $buttonBackground': {
      borderColor: theme.colors.rare.active,
      backgroundColor: theme.colors.rare.active,
    },
    '&:hover$_transparent $buttonBackground': {
      backgroundColor: 'transparent',
    },
  },
  _brand: {
    '&:before': {
      boxShadow: `0 8px 20px 0 ${theme.colors.brand.active}`,
    },
    '& $buttonBackground': {
      borderColor: theme.colors.brand.main,
      backgroundColor: theme.colors.brand.main,
    },
    '&$_transparent $button': {
      color: theme.colors.brand.main,
    },
    '&$_transparent $buttonBackground': {
      backgroundColor: 'transparent',
    },
    '& $placeholder': {
      color: theme.colors.brand.main,
    },
    '&:hover $buttonBackground': {
      borderColor: theme.colors.brand.active,
      backgroundColor: theme.colors.brand.active,
    },
    '&:hover$_transparent $buttonBackground': {
      backgroundColor: 'transparent',
    },
  },
  _general: {
    '&:before': {
      boxShadow: `0 8px 20px 0 ${theme.colors.general.active}`,
    },
    '& $buttonBackground': {
      borderColor: theme.colors.general.main,
      backgroundColor: theme.colors.general.main,
    },
    '&$_transparent $button': {
      color: theme.colors.general.main,
    },
    '&$_transparent $buttonBackground': {
      backgroundColor: 'transparent',
    },
    '& $placeholder': {
      color: theme.colors.general.main,
    },
    '&:hover $buttonBackground': {
      borderColor: theme.colors.general.active,
      backgroundColor: theme.colors.general.active,
    },
    '&:hover$_transparent $buttonBackground': {
      backgroundColor: 'transparent',
    },
  },
  _accent: {
    '&:before': {
      boxShadow: `0 8px 20px 0 ${theme.colors.accent.active}`,
    },
    '& $buttonBackground': {
      borderColor: theme.colors.accent.main,
      backgroundColor: theme.colors.accent.main,
    },

    '&$_transparent $button': {
      color: theme.colors.accent.main,
    },

    '&$_transparent $buttonBackground': {
      backgroundColor: 'transparent',
    },
    '& $placeholder': {
      color: theme.colors.accent.main,
    },

    '&:hover $buttonBackground': {
      borderColor: theme.colors.accent.active,
      backgroundColor: theme.colors.accent.active,
    },
    '&:hover$_transparent $buttonBackground': {
      backgroundColor: 'transparent',
    },

  },

  _pressed: {
    '& $buttonBackground': {
      animation: 'scaleBounceBack .5s forwards',
    }
  },

  _unactive: {
    '& $buttonBackground': {
      borderColor: theme.colors.gray.lighter,
      backgroundColor: theme.colors.gray.lighter,
    },
    '&$_transparent $button': {
      color: theme.colors.gray.lighter
    },

    '&$_transparent $buttonBackground': {
      backgroundColor: 'transparent',
    },

    '& $placeholder': {
      color: theme.colors.gray.lighter,
    },

    '&:hover': {
      '& $buttonBackground': {
        borderColor: theme.colors.gray.lighter,
        backgroundColor: theme.colors.gray.lighter,
        transform: 'scale(1)',
      },
      '&:before': {
        opacity: 0,
        transform: 'translateY(0)',
      },
      '& $placeholder': {
        color: '#fff',
      },
    },
    '&:hover$_transparent $buttonBackground': {
      backgroundColor: 'transparent',
    },
  },

  expandable: {
    '& $button': {
      height: 40,
      minWidth: 0,
      padding: '10px 20px 10px 40px',
    },
    '& $buttonBackground': {
      width: 40,
      borderWidth: 4,
      borderStyle: 'solid',

      transition: 'background-color .7s ease, border-color .7s ease, width .7s ease',
    },
    '& $buttonText': {
      opacity: 0,

      transition: 'opacity .5s ease, transform .5s ease',
      transform: 'translateX(-40%)',
    },

    '&:not(:hover)': {
      '& $buttonBackground': {
        backgroundColor: 'transparent',
      },
    },

    '&:hover': {
      '&:before': {
        opacity: 0,
      },
      '& $buttonBackground': {
        width: '100%',
        transform: 'scale(1)',
      },
      '& $buttonText': {
        opacity: 1,
        transition: 'opacity .5s ease .4s, transform .5s ease .4s',
        transform: 'translateX(0)',
      }
    }
  },
  expandableRight: {
    '& $placeholder, & $buttonBackground': {
      left: 'auto',
      right: 0,
    },
    '& $button': {
      padding: '10px 40px 10px 20px',
    },
    '& $buttonText': {
      transform: 'translateX(30%)',
    }
  },

  placeholder: {
    display: 'flex',
    width: 40,
    height: 40,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    padding: '0 10px',

    transition: 'color .7s ease',

    justifyContent: 'center',
    alignItems: 'center',
  },


  [theme.queries.smDevicesDown]: {
    root: {
      display: props => props.mobileBlock ? 'block' : 'inline-block',
      width: props => props.mobileBlock ? '100%' : 'auto',
    },
    button: {
      width: props => props.mobileBlock ? '100%' : 'auto',
    }
  },


  '@keyframes scaleBounceBack': {
    '0%': {
      transform: 'scale(1.16)'
    },
    '40%': {
      transform: 'scale(.9)'
    },
    '60%': {
      transform: 'scale(1.1)'
    },
    '80%': {
      transform: 'scale(.95)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  }
});
