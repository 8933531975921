import React, { Component } from 'react';
import { injectStyles } from '~app/theme';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Switch, Route } from 'react-router';

import actions from '~app/redux/actions';
import { UserProfileCard } from '~app/components';
import { Container, Icon, Breadcrumbs } from '~app/components/ui';

import Applications from './Sections/Applications/Applications';
import Deals from './Sections/Deals/Deals';
import Reviews from './Sections/Reviews/Reviews';
import Archive from './Sections/Archive/Archive';

import { styles } from './CabinetUserScreenStyles';

import qs from 'qs';

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

@connect(mapStateToProps)
@injectStyles(styles)
class CabinetUserScreen extends Component {

  userWatchIntevalInstance = null;
  userWatchInteval = 10000;

  componentDidMount = () => {
    this.startWatchUser();
    this.openWelcomeModal();
  }

  openWelcomeModal() {
    if(this.props.location) {
      const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
      if(query.promo) {
        actions.common.openGenericModal({
          theme: 'general',
          icon: 'letter',
          title: 'Добро пожаловать в Личный Кабинет клиента!',
          text:
            <div>
              {'Здесь вы сможете получать предложения от дилеров на вашу заявку:'}<br />
              {'— сравнивать их,'}<br />
              {'— выбирать лучшее,'}<br />
              {'— оставлять отзывы!'}
            </div>
        });
      }
    }
  }

  componentWillUnmount = () => {
    this.storWatchUser();
  }

  startWatchUser = () => {
    this.userWatchIntevalInstance = setInterval(actions.auth.refreshUser, this.userWatchInteval);
  }

  storWatchUser = () => {
    clearInterval(this.userWatchIntevalInstance);
  }
  
  render = () => {
    const {
      classes,
      user,
    } = this.props;

    return (
      <div className={classes.root}>
        <Container>
          <div className={classes.head}>
            <Breadcrumbs
              options={[
                {
                  href: '/',
                  name: 'Главная',
                },
                {
                  name: 'Мой кабинет',
                }
              ]}
            />
            <Link
              to='/application'
              className={classes.headLink}
            >
              {'Создать заявку'}
              <span className={classes.headLinkIcon}>
                <Icon name={'arrowRight'}/>
              </span>
            </Link>
          </div>
          <div className={classes.section}>
            <UserProfileCard
              user={user}
              editable
              showNavigation
            />
          </div>

          <div className={classes.body}>
            <Switch>
              <Route exact path={'/cabinet'} component={Applications} />
              <Route path={'/cabinet/deals'} component={Deals} />
              <Route path={'/cabinet/reviews'} component={Reviews} />
              <Route path={'/cabinet/archive'} component={Archive} />
            </Switch>
          </div>

        </Container>
      </div>
    );
  }
}

export default CabinetUserScreen;