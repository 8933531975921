import React, { Component } from 'react';

import { injectStyles } from '~app/theme';
import styles from './NotificationStyles';

@injectStyles(styles)
class Notification extends Component {
  render = () => {
    const {
      classes
    } = this.props;

    return (
      <div className={classes.root}>
      </div>
    );
  }
}
export default Notification;