import client from './_client';
import { OfferListModel } from './models';

// Get
export const getDeals = (params = {}) => {
  let {
    limit = 15,
    page = 1,
    options = {},
    ...otherParams
  } = params;

  return client.request({
    url: '/v1/deals',
    method: 'GET',
    params: {
      limit,
      page,
      ...options,
      ...otherParams
    },
    transformData: data => new OfferListModel({
      items: data.data,
      paging: {
        count: data.count || 0,
        page: page,
        limit: limit,
      },
      ...data
    })
  });
};