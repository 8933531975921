import React, { Component } from 'react';
import { injectStyles } from '~app/theme';
import PropTypes from 'prop-types';

import styles from './IconStyles';

import socialFb from './svg/facebook.svg';
import socialVk from './svg/vk.svg';
import socialIg from './svg/Insta.svg';
import socialOk from './svg/socialOk.svg';
import warning from './svg/warning.svg';
import thumbDown from './svg/Bad.svg';
import deal from './svg/Deal.svg';
import thumbUp from './svg/Good.svg';
import optionCredit from './svg/Icon 1.svg';
import optionInsuranse from './svg/Icon 2.svg';
import optionSubsidy from './svg/Icon 3.svg';
import optionGibdd from './svg/Icon 4.svg';
import optionGuaranty from './svg/Icon 5.svg';
import optionHelpOnRoads from './svg/Icon 6.svg';
import pin from './svg/location.svg';
import arrowRight from './svg/Go to.svg';
import pointRight from './svg/Mail.svg';
import point2Down from './svg/Spoiler.svg';
import offer from './svg/Offer.svg';
import offers from './svg/offers.svg';
import tick from './svg/Ok 2.svg';
import times from './svg/times.svg';
import timesCircle from './svg/timesCircle.svg';
import timesThin from './svg/timesThin.svg';
import timesThinest from './svg/timesThinest.svg';
import tickCircle from './svg/Ok 3.svg';
import ratingDown from './svg/Rating -.svg';
import ratingUp from './svg/Rating +.svg';
import calendar from './svg/Receipt.svg';
import car from './svg/Requests.svg';
import reviews from './svg/Reviews.svg';
import support from './svg/Support.svg';
import supportQuestion from './svg/support2.svg';
import phone from './svg/Telephone.svg';
import clock from './svg/Time.svg';
import clock2 from './svg/Date.svg';
import logo from './svg/logo.svg';
import logoDealer from './svg/logoDealer.svg';
import logoFooter from './svg/logoFooter.svg';
import offered from './svg/offered.svg';
import email from './svg/Mail Go.svg';
import enter from './svg/Enter.svg';
import video from './svg/Video.svg';
import searchStar from './svg/1.svg';
import bubblePercent from './svg/2.svg';
import scales from './svg/3.svg';
import star from './svg/4.svg';
import shield from './svg/5.svg';
import archive from './svg/archive.svg';
import profile from './svg/profile.svg';
import footerCheck from './svg/footerCheck.svg';
import userCar from './svg/userCar.svg';
import sale from './svg/sale.svg';
import roundTime from './svg/roundTime.svg';
import roundLike from './svg/roundLike.svg';
import roundShield from './svg/roundShield.svg';
import roundSale from './svg/roundSale.svg';
import processCar from './svg/car.svg';
import processOffer from './svg/dealerOffer.svg';
import processClick from './svg/click.svg';
import processList from './svg/list.svg';
import crown from './svg/crown.svg';
import offerUserCar from './svg/offerUserCar.svg';
import offerUser from './svg/offerUser.svg';
import offerCar from './svg/offerCar.svg';
import offerDoc from './svg/offerDoc.svg';
import offerFast from './svg/offerFast.svg';
import offerMoneyHand from './svg/offerMoneyHand.svg';
import gear from './svg/gear.svg';
import gift from './svg/gift.svg';
import ooops from './svg/ooops.svg';
import letter from './svg/letter.svg';
import firstOffer from './svg/first-offer.svg';
import mosaic from './svg/mosaic.svg';
import search from './svg/search.svg';
import target from './svg/target.svg';

const icons = {
  socialFb,
  socialVk,
  socialIg,
  socialOk,
  warning,
  thumbDown,
  deal,
  thumbUp,
  optionCredit,
  optionInsuranse,
  optionSubsidy,
  optionGibdd,
  optionGuaranty,
  optionHelpOnRoads,
  pin,
  arrowRight,
  pointRight,
  point2Down,
  offer,
  offers,
  tick,
  times,
  timesCircle,
  timesThin,
  timesThinest,
  tickCircle,
  ratingDown,
  ratingUp,
  calendar,
  car,
  reviews,
  support,
  supportQuestion,
  phone,
  clock,
  clock2,
  logo,
  logoDealer,
  logoFooter,
  offered,
  email,
  enter,
  video,
  searchStar,
  bubblePercent,
  scales,
  star,
  shield,
  archive,
  profile,
  footerCheck,
  sale,
  userCar,
  roundTime,
  roundSale,
  roundShield,
  roundLike,
  processCar,
  processClick,
  processList,
  processOffer,
  crown,
  offerUserCar,
  offerUser,
  offerCar,
  offerDoc,
  offerFast,
  offerMoneyHand,
  gear,
  gift,
  ooops,
  letter,
  firstOffer,
  mosaic,
  search,
  target,
};

// Make icon aliases
for (const key in icons) {
  const aliases = [];

  aliases.push(key.toLowerCase());
  aliases.push(key.replace(/^(.)/, $1 => $1.toLowerCase()));
  aliases.push(key.replace(/\.?([A-Z])/g, (x,y) => '-' + y.toLowerCase()).replace(/^-/, ''));

  aliases.forEach(alias => {
    if (alias in icons) {
      return;
    }
    icons[alias] = icons[key];
  });
}

@injectStyles(styles)
class Icon extends Component {
  render = () => {
    const { classes, colored, name, stroked, block } = this.props;
    const classNames = [classes.icon];

    if (colored) {
      classNames.push(classes.colored);
    }

    if (stroked) {
      classNames.push(classes.stroked);
    }

    if(block) {
      classNames.push(classes.block);
    }

    return (
      <span
        className={classNames.join(' ')}
        dangerouslySetInnerHTML={{ __html: icons[name] }}
      />
    );

  }
}

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(icons)).isRequired,
  colored: PropTypes.bool,
  block: PropTypes.bool,
};

Icon.defaultProps = {
  colored: true
};

export default Icon;
