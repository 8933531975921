import React from 'react';
import cn from 'classnames';

import { injectStyles } from '~app/theme';
import { styles } from './HeroImageStyles';
import { Button } from '~app/components/ui';

// import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

@injectStyles(styles)
class HeroImage extends React.Component {
  static propTypes = {
    place: PropTypes.oneOf(['promo', 'news']),
  };

  static defaultProps = {
    place: 'news'
  };

  render() {
    const { classes, data, place } = this.props;
    return (
      <div className={cn(classes.root)}>
        <div className={classes.content}>
          <div className={classes.top}>
            {place == 'news' && data.dealer !== null && data.dealer.name !== null &&
              <div className={classes.label}>{`Новость от ${data.dealer.name}`}</div>
            }
            {place == 'promo' && data.dealer !== null && data.dealer.name !== null &&
              <div className={classes.label}>{`Акция от ${data.dealer.name}`}</div>
            }
            {/*data.dealer !== null && data.dealer.logo !== null &&
            <div className={classes.dealerLogo}>
              <div
                style={{ backgroundImage: `url(${data.dealer.logo})` }}
                className={classes.dealerLogoContent} />
            </div>
          */}
            {data.name !== null &&
              <h3 className={classes.title}>{data.name}</h3>
            }
            {data.shortDescription !== null &&
              <p className={classes.descr}>{data.shortDescription}</p>
            }
            {place == 'promo' &&
              (data.fullDescription !== null && data.shortDescription === null) &&
              <div className={classes.promoDescr}>
                <div
                  className={classes.html}
                  dangerouslySetInnerHTML={{ __html: data.fullDescription }} />
              </div>
            }
            <div
              className={classes.image}
              style={{ backgroundImage: `url(${data.image})` }} />
          </div>
          <div className={classes.footer}>
            {data.url !== null && data.buttonText !== null &&
              <a
                className={classes.link}
                href={data.url}
                target={'_blank'}
                rel={'noreferrer noopener'}>
                <Button
                  mobileBlock
                  theme={'accent'}
                  text={data.buttonText}
                />
              </a>
            }
            {data.dealer !== null && data.dealer.url !== null && data.dealer.url !== '' &&
              <a href={data.dealer.url} className={classes.linkDealer}>{'Перейти на сайт дилера'}</a>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default HeroImage;
