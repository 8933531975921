export const styles = theme => ({

  item: {
    position: 'relative',
    overflow: 'hidden',

    borderRadius: 6,
  },


  [theme.queries.mdDevicesDown]: {
  },
});