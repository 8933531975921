export default (theme) => ({
  '@global': {
    '*,*:before,*:after': {
      boxSizing: 'border-box',
    },
    html: {
      height: '100%',
      position: 'relative',
    },
    body: {
      height: '100%',
      position: 'relative',
      overflowY: 'scroll',
      overflowX: 'hidden',
      fontFamily: theme.typography.fontFamily,
      background: '#fff',
      color: theme.colors.gray.dark,
      fontSmoothing: 'antialiased',

      '&.fixed-scroll': {
        overflowY: 'hidden',

        '& .root': {
          height: '100%',
          overflowY: 'hidden',
        }
      }
    },
    'a': {
      color: '#000000',
      fontWeight: 500,
      textDecoration: 'none',
    },
    '.root': {
      position: 'relative',
      overflowX: 'hidden',
    },
  },
  root: {
    // position: 'relative',
    // height: '100%',
    // overflow: 'hidden',
  },
  content: {
    minHeight: 'calc(100vh - 100px)',
    position: 'relative',

    backgroundColor: theme.colors.gray.background,
  },

  [theme.queries.mdDevicesDown]: {
    content: {
      minHeight: '100vh',
      paddingTop: 60,
    }
  },
  [theme.queries.lgDevicesUp]: {
    content: {
      // paddingTop: 100
    }
  }
});
