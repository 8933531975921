export default theme => ({

  content: {
    padding: '44px 50px 37px',
  },

  contentThinPaddings: {
    padding: '30px 50px',
  },

  icon: {
    marginBottom: '17px',

    width: '50px',
    fontSize: '50px',
    margin: '0 auto',
  },
  iconBrand: {
    color: theme.colors.brand.main,
  },
  iconRare: {
    color: theme.colors.rare.main,
  },
  iconGeneral: {
    color: theme.colors.general.main,
  },
  iconAccent: {
    color: theme.colors.accent.main,
  },
  iconLighter: {
    color: theme.colors.gray.lighter,
  },

  title: {
    marginBottom: '13px',

    color: theme.colors.gray.dark,
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '25px',
    textAlign: 'center',
  },
  text: {
    fontSize: '14px',
    color: theme.colors.gray.dark,
    textAlign: 'center',
    lineHeight: '24px',
  },

  ...theme.styles.modal,

  [theme.queries.smDevicesDown]: {
    content: {
      padding: '44px 20px 37px',
    },
    contentThinPaddings: {
      padding: '15px 20px 17px',
    }
  },
});
