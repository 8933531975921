import reducers from '~app/core/reducers';
import { mainClient } from '~app/network';
import { CredentialsStorage } from '~app/storage';

export const restoreAuth = async () => {
  reducers.auth.restoreRequest();
  const token = await CredentialsStorage.getAccessToken();
  if (token === null) {
    reducers.auth.restoreFailure({});
    return;
  }
  let requrest = mainClient.user.getUserInfo;
  if (token.type === 'dealer') {
    requrest = mainClient.user.getDealerInfo;
  }
  const { data, error } = await requrest();
  
  if (error !== null) {
    reducers.auth.restoreFailure(error);
    return;
  }

  reducers.auth.restoreSuccess({
    user: data
  });
};

export const refreshUser = async () => {
  const token = await CredentialsStorage.getAccessToken();
  if (token === null) {
    reducers.auth.restoreFailure({});
    return;
  }
  let requrest = mainClient.user.getUserInfo;
  if (token.type === 'dealer') {
    requrest = mainClient.user.getDealerInfo;
  }
  const { data } = await requrest();

  if (data !== null) {
    reducers.auth.setUser(data);
  }
};

export const logout = async() => {
  // Remove token
  await CredentialsStorage.cleanStorage();
  reducers.auth.logout();
};

// Registration
export const register = async (params) => {
  reducers.auth.registerRequest();
  const { data, error } = await mainClient.auth.register(params);
  
  if (error !== null) {
    reducers.auth.registerFailure(error);
    return;
  }

  reducers.auth.registerSuccess(data);
};
export const registerReset = async () => {reducers.auth.registerReset();};

export const registerSMS = async (params) => {
  reducers.auth.registerSMSRequest();
  const { data, error } = await mainClient.auth.checkCode(params);
  
  if (error !== null) {
    reducers.auth.registerSMSFailure(error);
    return;
  }

  reducers.auth.registerSMSSuccess(data);
};

// Login
export const login = async (params) => {
  reducers.auth.loginRequest();
  const { data, error } = await mainClient.auth.login(params);
  
  if (error !== null) {
    reducers.auth.loginFailure(error);
    return;
  }

  reducers.auth.loginSuccess(data);
};

export const loginReset = async () => {reducers.auth.loginReset();};

export const loginSMS = async (params) => {
  reducers.auth.loginSMSRequest();
  const { data, error } = await mainClient.auth.checkCode(params);
  
  if (error !== null) {
    reducers.auth.loginSMSFailure(error);
    return;
  }

  reducers.auth.loginSMSSuccess(data);
};


export const dealerLogin = async (params) => {
  reducers.auth.dealerLoginRequest();
  const { data, error } = await mainClient.auth.dealerLogin(params);
  
  if (error !== null) {
    reducers.auth.dealerLoginFailure(error);
    return;
  }

  reducers.auth.dealerLoginSuccess(data);
};
export const dealerLoginReset = () => reducers.auth.dealerLoginReset();


export const dealerRegistration = async (params) => {
  reducers.auth.dealerRegistrationRequest();
  const { data, error } = await mainClient.auth.dealerRegistration(params);
  
  if (error !== null) {
    reducers.auth.dealerRegistrationFailure(error);
    return;
  }

  reducers.auth.dealerRegistrationSuccess(data);
};
export const dealerRegistrationReset = () => reducers.auth.dealerRegistrationReset();

export const setAuthorized = async (params) => {
  let user = params.user || params.dealer;

  CredentialsStorage.setAccessToken({
    accessToken: params.token,
    type: user.type
  });

  reducers.auth.setAuthorized(user);
};
