import reducers from '~app/core/reducers';
import { mainClient } from '~app/network';

// Get items by key
export const getItemsByKey = async (key, params) => {
  reducers.offers.getItemsByKeyRequest({ key });
  const { data, error } = await mainClient.offers.getOffers({ ...params });
  
  if (error !== null) {
    reducers.offers.getItemsByKeyFailure({ key, error });
    return;
  }

  reducers.offers.getItemsByKeySuccess({ key, data });
};

export const getItemById = async (id) => {
  reducers.offers.getItemByIdRequest({ id });
  const { data, error } = await mainClient.offers.getOffer({ id });
  
  if (error !== null) {
    reducers.offers.getItemByIdFailure({ id, error });
    return;
  }

  reducers.offers.getItemByIdSuccess({ id, data });
};



export const getItemsByApplicationId = async (applicationId, params = {}) => {
  reducers.offers.getItemsByApplicationIdRequest({ applicationId });
  const { data, error } = await mainClient.offers.getOffers({ applicationId, ...params });
  
  if (error !== null) {
    reducers.offers.getItemsByApplicationIdFailure({ applicationId, error });
    return;
  }

  reducers.offers.getItemsByApplicationIdSuccess({ applicationId, data });
};

// Accept 
export const acceptByApplicationId = async (applicationId, { id }) => {
  reducers.offers.acceptByApplicationIdRequest({ applicationId, id });
  const { data, error } = await mainClient.offers.acceptOffer({ id });
  
  if (error !== null) {
    reducers.offers.acceptByApplicationIdFailure({ applicationId, id, error });
    return;
  }

  reducers.offers.acceptByApplicationIdSuccess({ applicationId, id, data });
};

export const createByKey = async (key, params) => {
  reducers.offers.createByKeyRequest({ key });
  const { data, error } = await mainClient.offers.createOffer({ ...params });
  
  if (error !== null) {
    reducers.offers.createByKeyFailure({ key, error });
    return;
  }

  reducers.offers.createByKeySuccess({ key, data });
};
export const resetCreateByKey = (key) => reducers.offers.createByKeyReset(key);

// Archive item
export const archiveItem = async (id) => {
  reducers.offers.archiveOfferRequest({ id });
  const { data, error } = await mainClient.offers.archiveOffer({ id });
  
  if (error !== null) {
    reducers.offers.archiveOfferFailure({ id, error });
    return;
  }

  reducers.offers.archiveOfferSuccess({ id, data });
};

// Cancel item
export const cancelItem = async (id) => {
  reducers.offers.cancelOfferRequest({ id });
  const { data, error } = await mainClient.offers.cancelOffer({ id });
  
  if (error !== null) {
    reducers.offers.cancelOfferFailure({ id, error });
    return;
  }

  reducers.offers.cancelOfferSuccess({ id, data });
};


// Refuse item
export const refuseItem = async (id) => {
  reducers.offers.refuseOfferRequest({ id });
  const { data, error } = await mainClient.offers.refuseOffer({ id });
  
  if (error !== null) {
    reducers.offers.refuseOfferFailure({ id, error });
    return;
  }

  reducers.offers.refuseOfferSuccess({ id, data });
};

// Remove item
export const removeItem = async (id) => {
  reducers.offers.removeOfferRequest({ id });
  const { data, error } = await mainClient.offers.removeOffer({ id });
  
  if (error !== null) {
    reducers.offers.removeOfferFailure({ id, error });
    return;
  }

  reducers.offers.removeOfferSuccess({ id, data });
};