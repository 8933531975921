export const triggersIcons = [
  'roundShield',
  'roundSale',
  'roundTime',
  'roundLike',
];

export const roadmapIcons = [
  'processCar',
  'processClick',
  'processOffer',
  'processList',
];

export const requestIcons = [
  'offerCar',
  'offerUserCar',
  'offerMoneyHand',
  'offerDoc',
  'offerUser',
  'offerFast',
];
