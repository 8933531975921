import React, { Component } from 'react';
import { injectStyles } from '~app/theme';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router';

import actions from '~app/redux/actions';
import { UserProfileCard } from '~app/components';
import { Container, Breadcrumbs } from '~app/components/ui';

import Applications from './Sections/Applications/Applications';
import Offers from './Sections/Offers/Offers';
import Deals from './Sections/Deals/Deals';
import Reviews from './Sections/Reviews/Reviews';
import Archive from './Sections/Archive/Archive';

import { styles } from './CabinetDealerScreenStyles';

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

@connect(mapStateToProps)
@injectStyles(styles)
class CabinetDealerScreen extends Component {

  userWatchIntevalInstance = null;
  userWatchInteval = 5000;

  componentDidMount = () => {
    this.startWatchUser();
  }

  componentWillUnmount = () => {
    this.storWatchUser();
  }

  startWatchUser = () => {
    this.userWatchIntevalInstance = setInterval(actions.auth.refreshUser, this.userWatchInteval);
  }

  storWatchUser = () => {
    clearInterval(this.userWatchIntevalInstance);
  }
  
  render = () => {
    const {
      classes,
      user,
    } = this.props;

    return (
      <div className={classes.root}>
        <Container>
          <div className={classes.head}>
            <Breadcrumbs
              options={[
                {
                  href: '/',
                  name: 'Главная',
                },
                {
                  name: 'Кабинет дилера',
                }
              ]}
            />
          </div>
          <div className={classes.section}>
            <UserProfileCard
              editable
              showNavigation
              showFilters
              dealer={user}
            />
          </div>

          <div className={classes.body}>
            <Switch>
              <Route exact path={'/dealer'} component={Applications}/>
              <Route path={'/dealer/offers'} component={Offers} />
              <Route path={'/dealer/deals'} component={Deals} />
              <Route path={'/dealer/reviews'} component={Reviews} />
              <Route path={'/dealer/archive'} component={Archive} />
            </Switch>
          </div>

        </Container>
      </div>
    );
  }
}

export default CabinetDealerScreen;