import React, { Component } from 'react';
import cn from 'classnames';
import { Collapse } from 'react-collapse';
import { formContainer, formCreate } from 'deforms';
import { connect } from 'react-redux';

import { TextInput, Select, Checkbox } from '../../../../components/ui';
import { injectStyles } from '~app/theme';
import { styles } from './OptionsStyles';

import orderOptions from '~app/config/orderOptions';
import { schema as tradeInSchema } from '~app/config/tradeInOptions';

const mapStateToProps = state => ({
  driveWheelTypes: state.dictionary.driveWheelTypes,
  transmissionTypes: state.dictionary.transmissionTypes,
  productionYears: state.dictionary.productionYears,
  // loanOptions: state.dictionary.loanOptions,
});

@connect(mapStateToProps)
@injectStyles(styles)
@formContainer
class Options extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tradeInOpened: false,
      chosenOption: props.options
    };
  }

  @formCreate()
  tradeInForm = {
    schema: tradeInSchema,
    // onSubmit: (formData) => this.props.actionTradeIn(formData),
    onChange: () => this.validateTradeIn()
  }

  @formCreate()
  landForm = {
    schema: {
      loanOption: {
        defaultValue: '',
        validation: {
          type: 'string',
          allowEmpty: false,
          minlength: 1,
        }
      },
      payWithCash: {
        defaultValue: false,
        validation: {
          type: 'boolean',
        }
      },
    },
    onChange: () => this.validateLoanOptions()
  };

  componentDidMount() {
    this.validateLoanOptions();
  }

  onClickOption = option => () => {
    let arr = this.props.options;

    const optionIndex = arr.indexOf(option.id);

    if (optionIndex > -1) {
      arr.splice(optionIndex, 1);
    }
    else {
      arr.push(option.id);
    }

    this.setState({
      chosenOption: arr
    }, this.validateLoanOption);

    this.props.onChangeOption({
      options: arr
    });
  }

  tradeInOnClick = () => {
    let newTradeInState = !this.state.tradeInOpened;

    this.setState({
      tradeInOpened: newTradeInState
    }, this.validateTradeIn);
  }

  validateLoanOptions = () => {
    let valid = true;
    let data = null;
    const { chosenOption } = this.state;
    const creditSelected = (chosenOption || []).indexOf('credit') > -1;
    if (creditSelected) {
      if (this.landForm.valid) {
        data = this.landForm.getFormData();
      } else {
        valid = false;
      }
    }

    if (this.props.onChangeLoanOptions) {
      this.props.onChangeLoanOptions({
        data,
        valid
      });
    }

  }

  validateTradeIn = () => {
    let valid = true;
    let data = null;
    if (this.state.tradeInOpened) {
      if (this.tradeInForm.valid) {
        data = this.tradeInForm.getFormData();
      } else {
        valid = false;
      }
    }

    if (this.props.onChangeTradeInForm) {
      this.props.onChangeTradeInForm({
        valid,
        data,
      });
    }
  }

  renderOption = (option) => {
    const {
      classes,
      options,
    } = this.props;

    return (
      <div
        key={option.id}
        className={cn(classes.option, options.indexOf(option.id) > -1 ? classes.optionActive : null, {
          [classes.credit]: option.id == 'credit'
        })}
        onClick={this.onClickOption(option)}
      >
        <div>
          <div className={classes.optionIcon}>
            {option.icon}
          </div>
          <div className={classes.optionName}>{option.name}</div>
        </div>
      </div>
    );
  }

  render() {
    const {
      classes,
      submitted,
      driveWheelTypes,
      transmissionTypes,
      productionYears,
    } = this.props;
    const form = this.tradeInForm;

    const { chosenOption } = this.state;
    const creditSelected = (chosenOption || []).indexOf('credit') > -1;

    return (
      <div className={classes.root}>
        <div className={classes.head}>{'Дополнительные опции позволят получать больше выгодных предложений  и ускорят отклик дилеров на заявку:'}</div>
        <div className={classes.list}>
          {orderOptions.map((option) => {
            return (this.renderOption(option));
          })}
        </div>

        <Collapse isOpened={creditSelected}>
          <div className={cn(classes.formInput, classes.formInputThird)}>
            <TextInput
              name={'loanOption'}
              placeholder={'Первый взнос, руб'}
              onChange={this.landForm.fields.loanOption.change}
              value={this.landForm.fields.loanOption.value}
              error={submitted && !this.landForm.fields.loanOption.valid}
            />
            <div className={classes.formInputCheckbox}>
              <Checkbox
                name={'payWithCash'}
                label={'Оплата наличными'}
                checked={this.landForm.fields.payWithCash.value}
                onChangeValue={this.landForm.fields.payWithCash.changeValue}
              />
            </div>
          </div>
        </Collapse>

        <div className={classes.tradeIn}>
          <div
            className={classes.tradeInTitle}
            onClick={this.tradeInOnClick}
          >
            <div className={classes.tradeInTitleIcon}>{this.state.tradeInOpened ? '-' : '+'}</div>
            {'Программа Trade-in'}
          </div>

          <div className={classes.tradeInBody}>
            <Collapse isOpened={this.state.tradeInOpened}>

              <form
                noValidate
                className={classes.form}
                onSubmit={form.submit}>
                <div className={classes.formInput}>
                  <TextInput
                    name={'brand'}
                    placeholder={'Марка авто'}
                    onChange={form.fields.brand.change}
                    value={form.fields.brand.value}
                    error={submitted && !form.fields.brand.valid}
                  />
                </div>
                <div className={classes.formInput}>
                  <TextInput
                    name={'model'}
                    placeholder={'Модель авто'}
                    onChange={form.fields.model.change}
                    value={form.fields.model.value}
                    error={submitted && !form.fields.model.valid}
                  />
                </div>
                <div className={cn(classes.formInput, classes.formInputHalf)}>
                  <TextInput
                    name={'engineVolume'}
                    placeholder={'Объем двигателя'}
                    onChange={form.fields.engineVolume.change}
                    value={form.fields.engineVolume.value}
                    error={submitted && !form.fields.engineVolume.valid}
                  />
                </div>
                <div className={cn(classes.formInput, classes.formInputHalf)}>
                  <Select
                    name={'drive'}
                    placeholder={'Привод'}
                    onChange={form.fields.drive.changeValue}
                    value={form.fields.drive.value}
                    error={submitted && !form.fields.drive.valid}
                    options={driveWheelTypes}
                    textField={'name'}
                    valueField={'id'}
                  />
                </div>
                <div className={cn(classes.formInput, classes.formInputHalf)}>
                  <Select
                    name={'gear'}
                    placeholder={'Коробка'}
                    onChange={form.fields.gear.changeValue}
                    value={form.fields.gear.value}
                    error={submitted && !form.fields.gear.valid}
                    options={transmissionTypes}
                    textField={'name'}
                    valueField={'id'}
                  />
                </div>
                <div className={cn(classes.formInput, classes.formInputHalf)}>
                  <Select
                    name={'year'}
                    placeholder={'Год выпуска'}
                    onChange={form.fields.year.changeValue}
                    value={form.fields.year.value}
                    error={submitted && !form.fields.year.valid}
                    options={productionYears}
                    textField={'name'}
                    valueField={'id'}
                  />
                </div>
                <div className={classes.formAdditional}>
                  <div className={classes.formAdditionalTitle}>{'Идентификационный номер транспортного средства позволит дилеру сделать самое точное и выгодное предложение:'}</div>
                  <div className={classes.formAdditionalInput}>
                    <TextInput
                      name={'vin'}
                      placeholder={'VIN (17 символов)'}
                      onChange={form.fields.vin.change}
                      value={form.fields.vin.value}
                      error={submitted && !form.fields.vin.valid}
                    />
                  </div>
                </div>
              </form>

            </Collapse>
          </div>

        </div>
      </div>
    );
  }
}

export default Options;
