import reducers from '~app/core/reducers';
import { mainClient } from '~app/network';

export const getBanners = async (params) => {
  reducers.banners.getBannersRequest();
  const { data, error } = await mainClient.banners.getBanners(params);
  
  if (error !== null) {
    reducers.banners.getBannersFailure(error);
    return;
  }

  reducers.banners.getBannersSuccess(data);
};

export const getBannersReset = () => {
  reducers.banners.getBannersReset();
};
